import React, { Fragment } from "react";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
    UncontrolledDropdown
} from "reactstrap";
import "./Configurator.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../store/Configurator";
import { RouteComponentProps } from "react-router-dom";
import PartImage from "./PartImage";
import moreImage from "../images/mini-more.png";
import locale from "../Locale";
import Button from "./Button";
import logoImage from "../images/logo-doors.png";

interface IProps extends RouteComponentProps<any> {
    configuration?: any;
    popupParameters?: any;
    selectedParameters?: any;
    theme?: any;
    print?: boolean;
}

interface IState {
    images: any;
    imageBase64: string;
    loading: boolean;
    configuration?: any;
}

class Part extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            images: [],
            imageBase64: "",
            loading: true,
            configuration: null,
        };
    }
    getSelectedParameter(parameterId: string, defaultValue: string): string {
        let selectedParameters = this.props.selectedParameters || [];
        if (this.props.selectedParameters) {
            for (let i = 0; i < this.props.selectedParameters.length; i++) {
                if (selectedParameters[i].parameterId === parameterId) {
                    if (selectedParameters[i].parameterValue) {
                        return selectedParameters[i].parameterValue;
                    }
                }
            }
        }
        return defaultValue;
    }
    setSelectedParameter(
        parameterId: string,
        parameterPartId: string,
        parameterValue: string,
        parameterValueId: string
    ) {
        let selectedParameter: any = {
            parameterId: parameterId,
            parameterValue: parameterValue,
            parameterValueId: parameterValueId
        };
        (this.props as any).addSelectedParameter(selectedParameter);
        (this.props as any).updateCategories(
            parameterPartId,
            parameterId,
            parameterValueId,
            "",
            true
        );
        this.forceUpdate();
    }
    render() {
        let parameterList: any = [];
        let headerTitle = locale.configurator;
        if (this.props.theme && this.props.theme.title) {
            headerTitle = this.props.theme.title;
        }
        const logo =
            this.props.theme && this.props.theme.logo
                ? this.props.theme.logo
                : logoImage;

        if (this.props.popupParameters && this.props.popupParameters.length > 0) {
            const _this = this;
            parameterList = this.props.popupParameters.map(
                (parameter: any, i: number) => {
                    const parameterDefaultValue = parameter.defaultValue;
                    const defaultValue = _this.getSelectedParameter(
                        parameter.id,
                        parameterDefaultValue.name || ""
                    );
                    const values = parameter.values;

                    const parameterValuesList = values.map(
                        (value: any, j: string) => {
                            if (value.name !== defaultValue) {
                                return (
                                    <DropdownItem
                                        key={j}
                                        onClick={() =>
                                            _this.setSelectedParameter(
                                                parameter.id,
                                                parameter.partId,
                                                value.name,
                                                value.value
                                            )
                                        }
                                    >
                                        {value.name}
                                    </DropdownItem>
                                );
                            }
                            else 
                                return (null);
                        }
                    );

                    return (
                        <div
                            key={i}
                            className="configurator-button d-print-none"
                        >
                            <UncontrolledDropdown className="configuration-filter">
                                <DropdownToggle color="selector-white btn-configurator">
                                    {defaultValue}
                                    <img
                                        src={moreImage}
                                        alt={" "}
                                        className="filter-image"
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {parameterValuesList}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                    );
                }
            );
        }
        if (this.props.print) {
            parameterList.push(
                <div key={9999} className="configurator-button d-print-none">
                    <div className="configuration-filter">
                        <Button
                            className="btn-selector-white btn-configurator"
                            color="none"
                            onClick={() => {
                                window.print();
                            }}
                        >
                            {locale.print}
                        </Button>
                    </div>
                </div>
            );
        }
        return (
            <Fragment>
                {this.props.configuration.loadingImage && <div className="parameter-image-loading"></div>}
                <Row>
                    <Col className="text-center d-none my-4 d-print-block">
                        <img
                            className="logo"
                            alt="Doors"
                            src={logo}
                            style={{ maxWidth: "30vw" }}
                        />{" "}
                        <span id="logo-title">{headerTitle}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="text-center">
                        {parameterList}
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs="12"
                        className={
                            "door-image-large mt-4 mb-2 " + this.props.print
                                ? " print-image"
                                : ""
                        }
                    >
                        <PartImage
                            imageBase64={this.props.configuration.image}
                            images={[]}
                            alt={" "}
                        />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}
export default connect(
    (state: any) => state.configurator,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Part);
