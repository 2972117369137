import LocalizedStrings from "react-localization";
import slovenian from './languages/sl';
import english from './languages/en';
import german from './languages/de';
import french from './languages/fr';

const locale = new LocalizedStrings({
    sl: slovenian,
    en: english,
    de: german,
    fr: french
});
export default locale;
