import React from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import './Configurator.css';
import locale from '../Locale';
import Button from './Button';
import Helper from '../Helper';

interface IProps {
	setUserContactData(contactData: any): void;
	contact: any;
}

interface IState {
	Addressing: { value: string; validate: string };
	AddressingD: { value: string; validate: string };
	AddressingR: { value: string; validate: string };
	FirstName: { value: string; validate: string };
	FirstNameD: { value: string; validate: string };
	FirstNameR: { value: string; validate: string };
	LastName: { value: string; validate: string };
	LastNameD: { value: string; validate: string };
	LastNameR: { value: string; validate: string };
	Email: { value: string; validate: string };
	Telephone: { value: string; validate: string };
	TelephoneD: { value: string; validate: string };
	Address: { value: string; validate: string };
	AddressD: { value: string; validate: string };
	AddressR: { value: string; validate: string };
	City: { value: string; validate: string };
	CityD: { value: string; validate: string };
	CityR: { value: string; validate: string };
	Zip: { value: string; validate: string };
	ZipD: { value: string; validate: string };
	ZipR: { value: string; validate: string };
	CountryId: string;
	CountryIdD: string;
	CountryIdR: string;
	Countries: any[];
	CompanyName: { value: string; validate: string };
	CompanyNameD: { value: string; validate: string };
	CompanyNameR: { value: string; validate: string };
	CompanyRegistration: { value: string; validate: string };
	CompanyRegistrationD: { value: string; validate: string };
	CompanyRegistrationR: { value: string; validate: string };
	CompanyVAT: { value: string; validate: string };
	CompanyVATD: { value: string; validate: string };
	CompanyVATR: { value: string; validate: string };
	Marketing: boolean;
	Notifications: boolean;
	PersonalData: boolean;
	DeliveryAddress: boolean;
	ReceiptAddress: boolean;
	ShowConsents: boolean;
	SaveMessage: string;
}

export default class Inquiry extends React.Component<IProps, IState> {
	private timeout: any;

	constructor(props: IProps) {
		super(props);

		this.timeout = 0;

		this.state = {
			Addressing: { value: (this.props.contact && this.props.contact.Addressing) || '', validate: '' },
			AddressingD: { value: (this.props.contact && this.props.contact.AddressingD) || '', validate: '' },
			AddressingR: { value: (this.props.contact && this.props.contact.AddressingR) || '', validate: '' },
			FirstName: { value: (this.props.contact && this.props.contact.FirstName) || '', validate: '' },
			FirstNameD: { value: (this.props.contact && this.props.contact.FirstNameD) || '', validate: '' },
			FirstNameR: { value: (this.props.contact && this.props.contact.FirstNameR) || '', validate: '' },
			LastName: { value: (this.props.contact && this.props.contact.LastName) || '', validate: '' },
			LastNameD: { value: (this.props.contact && this.props.contact.LastNameD) || '', validate: '' },
			LastNameR: { value: (this.props.contact && this.props.contact.LastNameR) || '', validate: '' },
			Email: { value: (this.props.contact && this.props.contact.Email) || '', validate: '' },
			Telephone: { value: (this.props.contact && this.props.contact.Telephone) || '', validate: '' },
			TelephoneD: { value: (this.props.contact && this.props.contact.TelephoneD) || '', validate: '' },
			Address: { value: (this.props.contact && this.props.contact.Address) || '', validate: '' },
			AddressD: { value: (this.props.contact && this.props.contact.AddressD) || '', validate: '' },
			AddressR: { value: (this.props.contact && this.props.contact.AddressR) || '', validate: '' },
			City: { value: (this.props.contact && this.props.contact.City) || '', validate: '' },
			CityD: { value: (this.props.contact && this.props.contact.CityD) || '', validate: '' },
			CityR: { value: (this.props.contact && this.props.contact.CityR) || '', validate: '' },
			Zip: { value: (this.props.contact && this.props.contact.Zip) || '', validate: '' },
			ZipD: { value: (this.props.contact && this.props.contact.ZipD) || '', validate: '' },
			ZipR: { value: (this.props.contact && this.props.contact.ZipR) || '', validate: '' },
			CountryId: (this.props.contact && this.props.contact.CountryId) || '',
			CountryIdD: (this.props.contact && this.props.contact.CountryIdD) || '',
			CountryIdR: (this.props.contact && this.props.contact.CountryIdR) || '',
			Countries: [],
			CompanyName: { value: (this.props.contact && this.props.contact.CompanyName) || '', validate: '' },
			CompanyNameD: { value: (this.props.contact && this.props.contact.CompanyNameD) || '', validate: '' },
			CompanyNameR: { value: (this.props.contact && this.props.contact.CompanyNameR) || '', validate: '' },
			CompanyRegistration: { value: (this.props.contact && this.props.contact.CompanyRegistration) || '', validate: '' },
			CompanyRegistrationD: { value: (this.props.contact && this.props.contact.CompanyRegistrationD) || '', validate: '' },
			CompanyRegistrationR: { value: (this.props.contact && this.props.contact.CompanyRegistrationR) || '', validate: '' },
			CompanyVAT: { value: (this.props.contact && this.props.contact.CompanyVAT) || '', validate: '' },
			CompanyVATD: { value: (this.props.contact && this.props.contact.CompanyVATD) || '', validate: '' },
			CompanyVATR: { value: (this.props.contact && this.props.contact.CompanyVATR) || '', validate: '' },
			Marketing: false,
			Notifications: false,
			PersonalData: false,
			DeliveryAddress: false,
			ReceiptAddress: false,
			ShowConsents: true,
			SaveMessage: '',
		};
	}

	componentDidMount() {
		Helper.getData('/selection/countrylist')
			.then((data: any) => {
				let countriesFromApi = data
					.map((country: any) => {
						return { value: country.CountryId, display: Helper.getLocalizedName(country.Name) };
					})
					.sort((a: any, b: any) => a.display.localeCompare(b.display));
				this.setState({
					Countries: [
						{
							value: '',
							display: locale.selectCountry,
						},
					].concat(countriesFromApi),
				});
			})
			.catch((error) => {
				console.log(error);
			});

		Helper.getData('/cart/settings?brandPartnerId=' + Helper.brandPartnerId())
			.then((data: any) => {
				this.setState({ ShowConsents: data.ShowConsents });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	validateEmail(e: any) {
		let state = this.state;
		const emailRex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let { validate } = state.Email;
		let value = e.target.value;
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (emailRex.test(value)) {
				validate = 'has-success';
			} else {
				validate = 'has-danger';
			}
			state.Email.validate = validate;
			this.setState(state);
		}, 700);
	}

	handleChange(e: any) {
		const state: any = this.state;
		const { target } = e;
		if (target.type === 'checkbox') {
			state[target.name] = target.checked;
		} else {
			state[target.name].value = target.value;
		}
		this.setState(state);
	}

	setUserContactData() {
		const state: IState = this.state;
		const ContactData = {
			Addressing: state['Addressing'].value,
			FirstName: state['FirstName'].value,
			LastName: state['LastName'].value,
			Email: state['Email'].value,
			Telephone: state['Telephone'].value,
			Address: state['Address'].value,
			City: state['City'].value,
			Zip: state['Zip'].value,
			CountryId: state['CountryId'],
			CompanyName: state['CompanyName'].value,
			CompanyRegistration: state['CompanyRegistration'].value,
			CompanyVAT: state['CompanyVAT'].value,
			Marketing: state['Marketing'],
			Notifications: state['Notifications'],
			PersonalData: state['PersonalData'],
		};
		let DeliveryData;
		if (state['DeliveryAddress']) {
			DeliveryData = {
				Addressing: state['AddressingD'].value,
				FirstName: state['FirstNameD'].value,
				LastName: state['LastNameD'].value,
				Telephone: state['TelephoneD'].value,
				Address: state['AddressD'].value,
				City: state['CityD'].value,
				Zip: state['ZipD'].value,
				CountryId: state['CountryIdD'],
				CompanyName: state['CompanyNameD'].value,
				CompanyRegistration: state['CompanyRegistrationD'].value,
				CompanyVAT: state['CompanyVATD'].value,
			};
		} else {
			DeliveryData = null;
		}
		let ReceiptData;
		if (state['ReceiptAddress']) {
			ReceiptData = {
				Addressing: state['Addressing'].value,
				FirstName: state['FirstNameR'].value,
				LastName: state['LastNameR'].value,
				Address: state['AddressR'].value,
				City: state['CityR'].value,
				Zip: state['ZipR'].value,
				CountryId: state['CountryIdR'],
				CompanyName: state['CompanyNameR'].value,
				CompanyRegistration: state['CompanyRegistrationR'].value,
				CompanyVAT: state['CompanyVATR'].value,
			};
		} else {
			ReceiptData = null;
		}
		const contactFormData = {
			ContactData: ContactData,
			DeliveryAddress: state['DeliveryAddress'],
			ReceiptAddress: state['ReceiptAddress'],
			DeliveryData: DeliveryData,
			ReceiptData: ReceiptData,
		};
		this.props.setUserContactData(contactFormData);
	}

	render() {
		const {
			Addressing,
			AddressingD,
			AddressingR,
			Email,
			FirstName,
			FirstNameD,
			FirstNameR,
			LastName,
			LastNameD,
			LastNameR,
			Address,
			AddressD,
			AddressR,
			City,
			CityD,
			CityR,
			Zip,
			ZipD,
			ZipR,
			Telephone,
			TelephoneD,
			CompanyName,
			CompanyNameD,
			CompanyNameR,
			CompanyRegistration,
			CompanyRegistrationD,
			CompanyRegistrationR,
			CompanyVAT,
			CompanyVATD,
			CompanyVATR,
		} = this.state;
		const disabled =
			FirstName.value === '' ||
			LastName.value === '' ||
			//Address.value === "" ||
			City.value === '' ||
			Zip.value === '' ||
			this.state.CountryId === '' ||
			this.state.CountryId === '00000000-0000-0000-0000-000000000000' ||
			//Telephone.value === "" ||
			Email.value === '' ||
			Email.validate === 'has-danger' ||
			(this.state.DeliveryAddress &&
				(FirstNameD.value === '' ||
					LastNameD.value === '' ||
					AddressD.value === '' ||
					CityD.value === '' ||
					ZipD.value === '' ||
					this.state.CountryIdD === '' ||
					this.state.CountryIdD === '00000000-0000-0000-0000-000000000000' ||
					TelephoneD.value === '')) ||
			(this.state.ReceiptAddress &&
				(FirstNameR.value === '' ||
					LastNameR.value === '' ||
					AddressR.value === '' ||
					CityR.value === '' ||
					ZipR.value === '' ||
					this.state.CountryIdR === '' ||
					this.state.CountryIdR === '00000000-0000-0000-0000-000000000000'));
		return (
			<Row>
				<Col xs='12' className='inquiry'>
					<FormGroup>
						<Row>
							<Col xs='12' className='head-column'>
								<div dangerouslySetInnerHTML={{ __html: locale.inquiryTitle }} />
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='Addressing'>
									{locale.addressing}
								</Label>
								<Input
									type='text'
									name='Addressing'
									id='Addressing'
									maxLength={50}
									value={Addressing.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'></Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='FirstName' className='required'>
									{locale.firstName}
								</Label>
								<Input
									type='text'
									name='FirstName'
									id='FirstName'
									maxLength={50}
									value={FirstName.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='LastName' className='required'>
									{locale.lastName}
								</Label>
								<Input
									type='text'
									name='LastName'
									id='LastName'
									maxLength={50}
									value={LastName.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='Address'>
									{locale.address}
								</Label>
								<Input
									type='text'
									name='Address'
									id='Address'
									maxLength={250}
									value={Address.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='City' className='required'>
									{locale.city}
								</Label>
								<Input
									type='text'
									name='City'
									id='City'
									maxLength={250}
									value={City.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='Zip' className='required'>
									{locale.post}
								</Label>
								<Input
									type='text'
									name='Zip'
									id='Zip'
									maxLength={50}
									value={Zip.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='Country' className='required'>
									{locale.country}
								</Label>
								<select className='form-control' value={this.state.CountryId} onChange={(e) => this.setState({ CountryId: e.target.value })}>
									{this.state.Countries.map((Country) => (
										<option key={Country.value} value={Country.value}>
											{Country.display}
										</option>
									))}
								</select>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='Email' className='required'>
									{locale.email}
								</Label>
								<Input
									type='email'
									name='Email'
									id='Email'
									value={Email.value}
									valid={Email.validate === 'has-success'}
									invalid={Email.validate === 'has-danger'}
									maxLength={250}
									onChange={(e) => {
										this.validateEmail(e);
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='Telephone'>
									{locale.telephone}
								</Label>
								<Input
									type='text'
									name='Telephone'
									id='Telephone'
									maxLength={50}
									value={Telephone.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col xs='12' className='full-column'>
								<Row>
									<Col xs='12'>
										<h6>{locale.inquiryLegalAdd}</h6>
									</Col>
								</Row>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='CompanyName'>
									{locale.companyName}
								</Label>
								<Input
									type='text'
									name='CompanyName'
									id='CompanyName'
									maxLength={250}
									value={CompanyName.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='CompanyRegistration'>
									{locale.companyRegistration}
								</Label>
								<Input
									type='text'
									name='CompanyRegistration'
									id='CompanyRegistration'
									maxLength={50}
									value={CompanyRegistration.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='CompanyVAT'>
									{locale.companyVAT}
								</Label>
								<Input
									type='text'
									name='CompanyVAT'
									id='CompanyVAT'
									maxLength={50}
									value={CompanyVAT.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col xs='12' className='full-column'>
								<Row>
									<Col xs='12'>
										<h6>{locale.profileDeliveryTitle}</h6>
									</Col>
								</Row>
							</Col>
							<Col xs='12' className='foot-column'>
								<Row>
									<Input
										type='checkbox'
										name='DeliveryAddress'
										id='differentDelivery-checkbox'
										onChange={(e) => {
											this.handleChange(e);
										}}
									/>

									<p className='checkbox'>{locale.differentThenContactAddress}</p>
								</Row>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='AddressingD'>
									{locale.addressing}
								</Label>
								<Input
									type='text'
									name='AddressingD'
									id='AddressingD'
									maxLength={50}
									value={AddressingD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}></Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='FirstNameD' className='required'>
									{locale.firstName}
								</Label>
								<Input
									type='text'
									name='FirstNameD'
									id='FirstNameD'
									maxLength={50}
									value={FirstNameD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='LastNameD' className='required'>
									{locale.lastName}
								</Label>
								<Input
									type='text'
									name='LastNameD'
									id='LastNameD'
									maxLength={50}
									value={LastNameD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='AddressD' className='required'>
									{locale.address}
								</Label>
								<Input
									type='text'
									name='AddressD'
									id='AddressD'
									maxLength={250}
									value={AddressD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='CityD' className='required'>
									{locale.city}
								</Label>
								<Input
									type='text'
									name='CityD'
									id='CityD'
									maxLength={250}
									value={CityD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='ZipD' className='required'>
									{locale.post}
								</Label>
								<Input
									type='text'
									name='ZipD'
									id='ZipD'
									maxLength={50}
									value={ZipD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='CountryIdD' className='required'>
									{locale.country}
								</Label>
								<select
									className='form-control'
									value={this.state.CountryIdD}
									onChange={(e) =>
										this.setState({
											CountryIdD: e.target.value,
										})
									}
								>
									{this.state.Countries.map((Country) => (
										<option key={Country.value} value={Country.value}>
											{Country.display}
										</option>
									))}
								</select>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='TelephoneD' className='required'>
									{locale.telephone}
								</Label>
								<Input
									type='text'
									name='TelephoneD'
									id='TelephoneD'
									maxLength={50}
									value={TelephoneD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col xs='12' className='full-column' hidden={!this.state.DeliveryAddress}>
								<Row>
									<Col xs='12'>
										<h6>{locale.inquiryLegalAdd}</h6>
									</Col>
								</Row>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='CompanyNameD'>
									{locale.companyName}
								</Label>
								<Input
									type='text'
									name='CompanyNameD'
									id='CompanyNameD'
									maxLength={250}
									value={CompanyNameD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='CompanyRegistrationD'>
									{locale.companyRegistration}
								</Label>
								<Input
									type='text'
									name='CompanyRegistrationD'
									id='CompanyRegistrationD'
									maxLength={50}
									value={CompanyRegistrationD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
								<Label check htmlFor='CompanyVATD'>
									{locale.companyVAT}
								</Label>
								<Input
									type='text'
									name='CompanyVATD'
									id='CompanyVATD'
									maxLength={50}
									value={CompanyVATD.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col xs='12' className='full-column'>
								<Row>
									<Col xs='12'>
										<h6>{locale.profileReceiptTitle}</h6>
									</Col>
								</Row>
							</Col>
							<Col xs='12' className='foot-column'>
								<Row>
									<Input
										type='checkbox'
										name='ReceiptAddress'
										id='differentReceipt-checkbox'
										onChange={(e) => {
											this.handleChange(e);
										}}
									/>
									<p className='checkbox'>{locale.differentThenContactAddress}</p>
								</Row>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='AddressingR'>
									{locale.addressing}
								</Label>
								<Input
									type='text'
									name='AddressingR'
									id='AddressingR'
									maxLength={50}
									value={AddressingR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}></Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='FirstNameR' className='required'>
									{locale.firstName}
								</Label>
								<Input
									type='text'
									name='FirstNameR'
									id='FirstNameR'
									maxLength={50}
									value={FirstNameR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='LastNameR' className='required'>
									{locale.lastName}
								</Label>
								<Input
									type='text'
									name='LastNameR'
									id='LastNameR'
									maxLength={50}
									value={LastNameR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='AddressR' className='required'>
									{locale.address}
								</Label>
								<Input
									type='text'
									name='AddressR'
									id='AddressR'
									maxLength={250}
									value={AddressR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='CityR' className='required'>
									{locale.city}
								</Label>
								<Input
									type='text'
									name='CityR'
									id='CityR'
									maxLength={250}
									value={CityR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='ZipR' className='required'>
									{locale.post}
								</Label>
								<Input
									type='text'
									name='ZipR'
									id='ZipR'
									maxLength={50}
									value={ZipR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='CountryIdR' className='required'>
									{locale.country}
								</Label>
								<select
									className='form-control'
									value={this.state.CountryIdR}
									onChange={(e) =>
										this.setState({
											CountryIdR: e.target.value,
										})
									}
								>
									{this.state.Countries.map((Country) => (
										<option key={Country.value} value={Country.value}>
											{Country.display}
										</option>
									))}
								</select>
							</Col>
							<Col xs='12' className='full-column' hidden={!this.state.ReceiptAddress}>
								<Row>
									<Col xs='12'>
										<h6>{locale.inquiryLegalAdd}</h6>
									</Col>
								</Row>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='CompanyNameR'>
									{locale.companyName}
								</Label>
								<Input
									type='text'
									name='CompanyNameR'
									id='CompanyNameR'
									maxLength={250}
									value={CompanyNameR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='CompanyRegistrationR'>
									{locale.companyRegistration}
								</Label>
								<Input
									type='text'
									name='CompanyRegistrationR'
									id='CompanyRegistrationR'
									maxLength={50}
									value={CompanyRegistrationR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
								<Label check htmlFor='CompanyVATR'>
									{locale.companyVAT}
								</Label>
								<Input
									type='text'
									name='CompanyVATR'
									id='CompanyVATR'
									maxLength={50}
									value={CompanyVATR.value}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col xs='12' className='full-column' hidden={!this.state.ShowConsents}>
								<Row>
									<Col xs='12'>
										<h6>{locale.inquiryConsents}</h6>
									</Col>
								</Row>
							</Col>
							<Col xs='12' className='foot-column' hidden={!this.state.ShowConsents}>
								<Row>
									<Input
										type='checkbox'
										name='Marketing'
										id='marketing-checkbox'
										onChange={(e) => {
											this.handleChange(e);
										}}
									/>
									<p className='checkbox'>
										<strong>{locale.inquiryConsent1Title}</strong> - {locale.inquiryConsent1}
									</p>
								</Row>
								<Row>
									<Input
										type='checkbox'
										name='Notifications'
										id='notifications-checkbox'
										onChange={(e) => {
											this.handleChange(e);
										}}
									/>
									<p className='checkbox'>
										<strong>{locale.inquiryConsent2Title}</strong> - {locale.inquiryConsent2}
									</p>
								</Row>
								<Row>
									<Input
										type='checkbox'
										name='PersonalData'
										id='personal-data-checkbox'
										onChange={(e) => {
											this.handleChange(e);
										}}
									/>
									<p className='checkbox mb-0'>
										<strong>{locale.inquiryConsent3Title}</strong> - {locale.inquiryConsent3}
									</p>
								</Row>
							</Col>
						</Row>
					</FormGroup>
					<Button color='secondary' className='my-2' disabled={disabled} onClick={() => this.setUserContactData()}>
						{locale.send}
					</Button>{' '}
				</Col>
			</Row>
		);
	}
}
