import React, { Fragment } from 'react';
import { Navbar, NavbarToggler, NavbarBrand, Row, Col, FormGroup, Label, Input, UncontrolledCollapse, Collapse } from 'reactstrap';
import './Configurator.css';
import selectedIcon from '../images/selected-transparent.png';
import searchIcon from '../images/search.png';
import clearIcon from '../images/clear-search-24.png';
import locale from '../Locale';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../Helper';
import Loading from 'react-fullscreen-loading';
import Notify from './Notify';
import queryString from 'query-string';

const imagePath = process.env.REACT_APP_IMG_URL;

interface IProps extends RouteComponentProps<any> {
	part: any;
	theme?: any;
	brandPartnerId?: string;
	search?: any;
	showMessage: any;
	configuration?: any;
}

interface IState {
	collapsed: boolean;
	parts: any;
	partsPage: any;
	error: any;
	showMessage: any;
	filterCategories: any;
	categories: any[];
	query: '';
	loading: boolean;
	selectedPartId: string;
	brandPartnerId: string;
	notify: any;
}

class ConfiguratorStep1 extends React.Component<IProps, IState> {
	private timeout: any;
	private pageNumber: number = 0;
	private pageSize: number = 25;
	private pageStart = (): number => this.pageSize * this.pageNumber;
	private lastRequestedItem = () => this.pageStart() + this.pageSize;

	constructor(props: IProps) {
		super(props);

		this.timeout = 0;

		this.state = {
			collapsed: true,
			parts: [],
			partsPage: [],
			query: (this.props.search && this.props.search.query) || '',
			error: null,
			showMessage: this.props.showMessage || null,
			loading: false,
			selectedPartId: (this.props.part && this.props.part.id) || '',
			brandPartnerId: this.props.brandPartnerId || '',
			filterCategories: [],
			categories: [],
			notify: { open: false, title: '', message: '', isError: false },
		};
	}

	isValueSelected(catId: string, id: string): boolean {
		const foundCategory = this.state.filterCategories.find((cat: any) => cat.id === catId);
		if (foundCategory) {
			return !!foundCategory.values.some((val: any) => val.id === id && val.selected);
		}
		return false;
	}

	getModels() {
		this.setState({ loading: true });
		let urlParts = '';
		let urlCategories = '';
		if (Helper.userLoggedIn()) {
			urlParts = '/selection/auth/models/' + Helper.brandPartnerId();
			urlCategories = '/selection/auth/categories/' + Helper.brandPartnerId();
		} else {
			urlParts = '/selection/models/' + Helper.brandPartnerId();
			urlCategories = '/selection/categories/' + Helper.brandPartnerId();
		}
		const categories = this.state.filterCategories.map((category: any) => {
			const values = category.values
				.filter((value: any) => {
					return value.selected;
				})
				.map((value: any) => ({ id: value.id }));

			return {
				id: category.id,
				values: values,
			};
		});
		Promise.all([
			Helper.postData<any>(
				urlParts,
				JSON.stringify({
					categories: categories,
					textFilter: this.state.query,
					lang: Helper.userLang(),
				})
			),
			Helper.postData<any>(
				urlCategories,
				JSON.stringify({
					categories: categories,
					textFilter: this.state.query,
					lang: Helper.userLang(),
				})
			),
		])
			.then(([partsData, catData]) => {
				/* CATEGORIES */
				this.pageNumber = 0;

				let categories: any[] = [];

				if (catData) {
					catData.forEach((cat: any) => {
						let values: any[] = [];
						let catValues = cat.CategoryValues;
						if (catValues) {
							catValues.forEach((catValue: any) => {
								values.push({
									id: catValue.BrandPartnerCategoryValueId,
									name: Helper.getLocalizedName(catValue.Name),
									selected: this.isValueSelected(cat.BrandPartnerCategoryId, catValue.BrandPartnerCategoryValueId),
									disabled: !catValue.BrandPartnerPartIds || !catValue.BrandPartnerPartIds.length,
									order: catValue.Order ?? Number.MAX_SAFE_INTEGER,
								});
							});
						}
						categories.push({
							id: cat.BrandPartnerCategoryId,
							name: Helper.getLocalizedName(cat.Name),
							order: cat.Order?? Number.MAX_SAFE_INTEGER,
							values: values,
						});
					});
				}
				/* PARTS */
				const parts = this.partsData(partsData);
				//First order, then by name
				parts.sort((a: any, b: any) => a.order - b.order || a.brandName?.localeCompare(b.brandName) || a.name.localeCompare(b.name));
				let partsPage = [];
				if (parts && parts.length > this.pageSize) {
					partsPage = parts.slice(0, this.pageSize);
				} else if (parts && parts.length > 0) {
					partsPage = parts.slice(0);
				}
				this.setState({
					parts: parts,
					partsPage: partsPage,
					filterCategories: categories,
					loading: false,
				});
			})
			.catch((error) => {
				this.setState({ error: error.message + '', loading: false });
			});
	}

	loadModels() {
		if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight - 200) {
			const { parts } = this.state;
			if (parts && parts.length > 0) {
				if (this.pageStart() < parts.length) {
					this.pageNumber += 1;

					if (this.lastRequestedItem() >= parts.length - 1) {
						//reached end
						this.setState({ partsPage: parts.slice(0) });
					} else {
						//not reached end yet

						this.setState({
							partsPage: parts.slice(0, this.lastRequestedItem()),
						});
					}
				}
			}
		}
	}

	getTheme(brandPartnerId: string) {
		const url = '/brand/' + brandPartnerId + '/data';
		Helper.getData<any>(url).then((data: any) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
				this.setState({ loading: false });
				setTimeout(() => {
					this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/selection');
				}, 200);
			} else {
				const logo = (data.Style && data.Style.HeaderLogo) || '';
				const logotype = (data.Style && data.Style.HeaderLogotype) || '';
				const title = Helper.getLocalizedName(data.BrandName) || '';
				const header = (data.Style && Helper.getLocalizedName(data.Style.HeaderText)) || '';
				const color = (data.Style && data.Style.BackgroundColor) || '';
				const headerFontColor = (data.Style && data.Style.HeaderFontColor) || '';
				const buttonColor = (data.Style && data.Style.ButtonColor) || '';
				const buttonFontColor = (data.Style && data.Style.ButtonFontColor) || '';

				const theme = {
					logo: logo,
					title: title,
					header: header,
					color: color,
					headerFontColor,
					buttonColor,
					buttonFontColor,
				};
				localStorage.setItem('theme', JSON.stringify(theme));
				localStorage.setItem('brandPartnerId', data.BrandPartnerId);

				(this.props as any).setBrand(logo, logotype, title, header, color, headerFontColor, data.BrandPartnerId, buttonColor, buttonFontColor);

				localStorage.setItem('brandPartnerId', brandPartnerId.toString());
			}
		});
	}

	getFooter() {
		const url = '/footer/' + Helper.brandPartnerId();
		Helper.getData<any>(url).then((data: any) => {
			if (data) {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
					this.setState({ loading: false });
					setTimeout(() => {
						this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/selection?bpi=' + (process.env.REACT_APP_BRANDPARTNER ?? ''));
					}, 200);
				} else {
					const footer1text = data.Footer1text || '';
					const footer1url = data.Footer1url || '';
					const footer2text = data.Footer2text || '';
					const footer2url = data.Footer2url || '';
					const footer3text = data.Footer3text || '';
					const footer3url = data.Footer3url || '';
					const footer = {
						footer1text: footer1text,
						footer1url: footer1url,
						footer2text: footer2text,
						footer2url: footer2url,
						footer3text: footer3text,
						footer3url: footer3url,
					};
					localStorage.setItem('footer', JSON.stringify(footer));
					(this.props as any).setFooter(footer1text, footer1url, footer2text, footer2url, footer3text, footer3url);
				}
			}
		});
	}

	async componentDidMount() {
		const queryValues = queryString.parse(this.props.location.search);
		const brandPartnerId = queryValues && queryValues.bpi ? queryValues.bpi.toString() : Helper.brandPartnerId();

		const isActive: { isActive: boolean; redirectBpi?: string; message?: string } = await Helper.getData('/brand/' + brandPartnerId + '/active');

		if (!isActive.isActive) {
			setTimeout(() => {
				if (isActive.message) (this.props as any).setError(locale.exception, isActive.message);

				this.getTheme(isActive.redirectBpi ?? process.env.REACT_APP_BRANDPARTNER ?? '');
				this.props.history.push(
					process.env.REACT_APP_ROOT_PATH + '/configurator/selection?bpi=' + (isActive.redirectBpi ?? process.env.REACT_APP_BRANDPARTNER ?? '')
				);
			}, 200);

			return;
		}

		if (queryValues && queryValues.bpi) {
			if (queryValues.bpi.toString() !== Helper.brandPartnerId()) {
				this.getTheme(queryValues.bpi.toString());
			}
		}

		this.getFooter();
		this.getModels();
		const state: IState = this.state;
		if (state.showMessage && state.showMessage.show) {
			state.notify.title = state.showMessage.title;
			state.notify.message = state.showMessage.message;
			(this.props as any).setShowMessage(false, '', '');
			state.notify.open = true;
			this.setState(state);
		}

		(this.props as any).configuration.loader = false;

		window.addEventListener('scroll', () => {
			this.loadModels();
		});
	}
	componentDidUpdate(nextProps: any) {
		if (nextProps.brandPartnerId !== this.props.brandPartnerId) {
			this.getModels();
		}
	}
	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	}

	partsData(partsData: any): any {
		let parts: any = [];

		if (partsData && partsData.BrandModelsSpecial && partsData.BrandModelsSpecial.length) {
			partsData.BrandModelsSpecial.forEach((part: any) => {
				parts.push({
					id: part.Id,
					name: part.Name,
					title: part.Title,
					brandName: Helper.getLocalizedValueOrNull(part.BrandName),
					image: part.ImageUrl ? imagePath + part.ImageUrl.replace(imagePath, '').replace('_Original.', '_XXL.') : '',
					selected: 'invisible',
					special: true,
					order: part.Order?? Number.MAX_SAFE_INTEGER,
				});
			});
		}
		if (partsData && partsData.BrandModelsSpecial && partsData.BrandModelsNotSpecial.length) {
			partsData.BrandModelsNotSpecial.forEach((part: any) => {
				parts.push({
					id: part.Id,
					name: part.Name,
					title: part.Title,
					brandName: Helper.getLocalizedValueOrNull(part.BrandName),
					image: part.ImageUrl ? imagePath + part.ImageUrl.replace(imagePath, '').replace('_Original.', '_XXL.') : '',
					selected: 'invisible',
					special: false,
					order: part.Order?? Number.MAX_SAFE_INTEGER,
				});
			});
		}
		return parts;
	}

	mutateFilters(id: string, checked: boolean, reset: boolean): any[] {
		//apply new filter, remove filter, reset all filters
		return this.state.filterCategories.map((category: any) => {
			let values: any[] = [];
			category.values.forEach((value: any) => {
				if (reset) {
					values.push({ ...value, selected: false });
				} else if (value.id === id) {
					values.push({ ...value, selected: checked });
				} else {
					values.push(value);
				}
			});
			return { ...category, values: values };
		});
	}
	filtersReset() {
		const state: IState = this.state;
		state.filterCategories = this.mutateFilters('', false, true);
		state.query = '';
		this.setState(state, () => this.getModels());
	}

	applyFilterCategory(e: any) {
		const state: any = this.state;
		state.filterCategories = this.mutateFilters(e.target.id, e.target.checked, false);
		this.setState(state, () => this.getModels());
	}

	isGuid(stringToTest: string): boolean {
		if (stringToTest[0] === '{') {
			stringToTest = stringToTest.substring(1, stringToTest.length - 1);
		}
		var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
		return regexGuid.test(stringToTest);
	}

	applySearch(e: any) {
		//Open configuration by search with configurationId
		const query = e.target.value;
		this.setState({ query: query });
		if (this.isGuid(query)) {
			(this.props as any).buildCategories(query);
			setTimeout(() => {
				this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/configuration');
			}, 700);
		} else {
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.getModels();
			}, 700);
		}
	}

	selectPart(id: string) {
		const state: IState = this.state;
		state.selectedPartId = id;
		this.setState(state);
		(this.props as any).clearPart(false, (this.props as any).configuration.position);
		(this.props as any).buildCategories(null, id, null, null, (this.props as any).configuration.position);
		setTimeout(() => {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/configuration?bpi=' + Helper.brandPartnerId());
		}, 700);
	}

	clearSearch() {
		this.setState({ query: '' }, () => this.getModels());
	}

	renderPart(part: any, i: number) {
		return (
			<Col key={i} onClick={() => this.selectPart(part.id)} className='door-item' xs='6' lg='2'>
				<img alt={part.brandName ?? Helper.getLocalizedValueOrNull(part.title) ?? part.name} onError={Helper.addDefaultSrc} src={part.image} />
				<div className='text-smaller'>{part.brandName ?? Helper.getLocalizedValueOrNull(part.title) ?? part.name}</div>
				<img
					alt='Selected'
					className={'selected-item ' + (part.id === this.state.selectedPartId ? 'visible' : 'invisible')}
					src={selectedIcon}
					style={{
						backgroundColor: Helper.themeButtonColor(), // selected part image
						borderRadius: '50%',
					}}
				/>
			</Col>
		);
	}
	render() {
		const state: IState = this.state;

		const categoriesList = state.filterCategories.map((cat: any, i: number) => {
			if (cat.values && cat.values.length > 0) {
				const values = cat.values.map((value: any, j: number) => {
					const disabled = value.disabled;
					const checked = value.selected;
					return (
						<FormGroup key={j} check>
							<Label check>
								<Input
									type='checkbox'
									checked={checked && !disabled}
									disabled={disabled}
									name='radioGroup2'
									id={value.id}
									onChange={(e) => this.applyFilterCategory(e)}
								/>
								{value.name}
							</Label>
						</FormGroup>
					);
				});
				return (
					<div key={i} className='mr-auto py-2 mt-1 navbar-wrapper '>
						<NavbarBrand id={'toggler' + i} className='mr-auto text-uppercase my-1'>
							{cat.name}
						</NavbarBrand>
						<NavbarToggler id={'toggler' + i} />
						<UncontrolledCollapse defaultOpen={true} toggler={'toggler' + i} navbar>
							{values}
						</UncontrolledCollapse>
					</div>
				);
			}
			return null;
		});

		let partsList: any = null;
		let partsListSpecial: any = null;
		let configuratorBackgroundClass = '';
		let configuratorBackgroundClassSpecial = '';

		if (state.loading) {
			partsList = null;
		} else if (state.partsPage && state.partsPage.length > 0) {
			partsList = state.partsPage.filter((part: any) => !part.special).map((part: any, i: number) => this.renderPart(part, i));
			partsListSpecial = state.partsPage.filter((part: any) => part.special).map((part: any, i: number) => this.renderPart(part, i));
			configuratorBackgroundClassSpecial = 'configurator-background p-2 pb-3';
			configuratorBackgroundClass = 'configurator-background p-2 pb-3 mb-4';
		}
		return (
			<div id='configurator' className='step1'>
				{this.state.notify.open ? (
					<Notify message={this.state.notify.message} isError={this.state.notify.isError} title={this.state.notify.title} />
				) : null}
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col md='4' lg='3' xl='2' className='d-md-block d-none no-gutters'>
							<div className='filter-reset text-smaller' onClick={() => this.filtersReset()}>
								{locale.filtersClear}
							</div>
							<Navbar color='faded' light className='filters-navbar configurator-filters pt-2'>
								{categoriesList}
							</Navbar>
						</Col>
						<Col md='4' lg='3' xl='2' className='d-block d-md-none no-gutters'>
							<div className='filter-reset text-left text-smaller mt-2 pb-2' onClick={() => this.filtersReset()}>
								{locale.filtersClear}
							</div>
							<Navbar color='faded' light className='filters-navbar configurator-filters navbar-wrapper pt-2'>
								<NavbarBrand onClick={() => this.toggleNavbar()} className='mr-auto text-uppercase'>
									{locale.filters}
								</NavbarBrand>
								<NavbarToggler onClick={() => this.toggleNavbar()} />
								<Collapse className='mt-2' isOpen={!state.collapsed} navbar>
									{categoriesList}
								</Collapse>
							</Navbar>
						</Col>
						<Col md='8' lg='9' xl='10'>
							<Row>
								<Col xs='12' className='mt-4'>
									<Row>
										<Col lg='6' xl='4'>
											<FormGroup>
												<Input
													type='search'
													value={state.query}
													onChange={(e) => this.applySearch(e)}
													className='search-input'
													id='search'
													placeholder={locale.searchPlaceholder}
												/>
											</FormGroup>
										</Col>
										<Col lg='6' xl='8'>
											{state.query.length > 0 ? (
												<img
													alt='clearIcon'
													onClick={() => this.clearSearch()}
													id='clearIcon'
													className='clear-icon d-none d-lg-block'
													src={clearIcon}
												/>
											) : (
												<img alt='searchIcon' id='searchIcon' className='search-icon' src={searchIcon} />
											)}
										</Col>
									</Row>
									{partsListSpecial && partsListSpecial.length ? (
										<Fragment>
											<Row>
												<Col lg='12' className='mb-2 special-bold'>
													{locale.specialOffer}
												</Col>
											</Row>
											<Row>
												<Col lg='12' className='my-2'>
													<div className={configuratorBackgroundClassSpecial}>
														<Row id='doors-list' className='doors-wrapper'>
															{partsListSpecial}
														</Row>
													</div>
												</Col>
											</Row>
										</Fragment>
									) : null}
									{partsList && partsList.length ? (
										<Fragment>
											<Row>
												<Col lg='12' className='my-2'>
													<div className={configuratorBackgroundClass}>
														<Row id='doors-list' className='doors-wrapper'>
															{partsList}
														</Row>
													</div>
												</Col>
											</Row>
										</Fragment>
									) : null}
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(ConfiguratorStep1);
