import React from 'react';
import {
	Row,
	Col,
	Input,
	Form,
	Label,
	FormGroup,
	Modal,
	ModalHeader,
	ModalBody,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../Configurator.css';
import './Admin.css';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import CustomerEditMenu from './CustomerEditMenu';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from '../Button';
import Helper from '../../Helper';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	configuration?: any;
	document?: any;
}
interface IState {
	discountModal: boolean;
	costModal: boolean;
	netCostModal: boolean;
	discountValue: any;
	discountLevel: any;
	discountType: { value: any; validate: any };
	discountTypeId: any;
	costValue: any;
	netCostValue: any;
	discountText: any;
	costText: any;
	netCostText: any;
	discounts: any;
	additionalCosts: any;
	netCosts: any;
	configurationPrice: string;
	additionalCost: string;
	configurationWithAdditionalCost: string;
	discountValueSum: string;
	discountPercentageSum: string;
	valueAfterDiscountSum: string;
	configurationWithDiscountCost: string;
	netCost: string;
	totalPrice: string;
	taxCost: string;
	totalPriceWithTax: string;
	totalPriceWithoutTax: string;
	totalTax: string;
	finalPrice: string;
	selectedTax: string;
	taxList: any[];
	discountTypeList: any[];
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	selectedId: string;
	loading: boolean;
	errorTextInput: false;
	errorNumberInput: false;
	errorNumber2Input: false;
	errorTextMessage: string;
	errorNumberMessage: string;
	errorNumber2Message: string;
	addEditError: boolean;
	addEditMessage: string;
}
class CustomerCalculation extends React.Component<IProps, IState> {
	private timeout: any;

	constructor(props: IProps) {
		super(props);

		this.timeout = 0;
		this.state = {
			discountModal: false,
			costModal: false,
			netCostModal: false,
			discounts: [],
			additionalCosts: [],
			netCosts: [],
			costText: { value: '', validate: '' },
			netCostText: { value: '', validate: '' },
			discountText: { value: '', validate: '' },
			costValue: { value: '', validate: '' },
			netCostValue: { value: '', validate: '' },
			discountValue: { value: '', validate: '' },
			discountLevel: { value: '', validate: '' },
			discountType: { value: '', validate: '' },
			discountTypeId: '',
			configurationPrice: '',
			additionalCost: '',
			configurationWithAdditionalCost: '',
			discountValueSum: '',
			discountPercentageSum: '',
			valueAfterDiscountSum: '',
			configurationWithDiscountCost: '',
			netCost: '',
			totalPrice: '',
			taxCost: '',
			totalPriceWithTax: '',
			totalPriceWithoutTax: '',
			totalTax: '',
			finalPrice: '',
			selectedTax: '',
			taxList: [],
			discountTypeList: [],
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			selectedId: '',
			loading: false,
			errorTextInput: false,
			errorNumberInput: false,
			errorNumber2Input: false,
			errorTextMessage: '',
			errorNumberMessage: '',
			errorNumber2Message: '',
			addEditError: false,
			addEditMessage: '',
		};
	}
	getCalculation() {
		if (!this.props.configuration.documentConfigurationId) {
			return;
		}
		this.setState({ loading: true });
		const url =
			'/document/configuration/' + this.props.configuration.documentConfigurationId + '/calculation?brandPartnerId=' + Helper.brandPartnerId();

		Helper.getData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					this.getCalculationData(data);
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	getCalculationData(data: any) {
		let state: IState = this.state;

		let additonalCosts: any = [];
		let netCosts: any = [];
		let discounts: any = [];

		if (data) {
			state.configurationPrice = data.ConfigurationPrice.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.additionalCost = data.AdditionalCost.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.configurationWithAdditionalCost = data.ConfigurationWithAdditionalCost.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.discountValueSum = data.DiscountValue.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.discountPercentageSum = data.DiscountPercentage.toLocaleString('de-DE', {
				style: 'currency',
				currency: 'EUR', // always EUR because of replace
			}).replace('€', '%');
			state.valueAfterDiscountSum = data.ValueAfterDiscount.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.configurationWithDiscountCost = data.ConfigurationWithDiscountCost.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.netCost = data.NetCost.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.totalPrice = data.TotalPrice.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.taxCost = data.TaxCost.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			state.totalPriceWithTax = data.TotalPriceWithTax.toLocaleString('de-DE', {
				style: 'currency',
				currency: data.Currency,
			});
			if (data.Tax && data.Tax.length > 0) {
				state.taxList = data.Tax;
				const selectedTax = data.Tax.find((item: any) => item.IsSelected === true);
				if (selectedTax) {
					state.selectedTax = selectedTax.TaxValue;
				}
			}
			if (data.Discounts && data.Discounts.length > 0) {
				data.Discounts.forEach((item: any) => {
					discounts.push({
						id: item.DiscountId,
						name: Helper.getLocalizedValueOrNull(item.Name),
						value: item.Value,
						level: item.Level,
						typeId: item.TypeId,
						typeName: Helper.getLocalizedName(item.TypeName),
						tableValue: item.Value.toLocaleString('de-DE', {
							style: 'currency',
							currency: 'EUR', // always EUR because of replace
						}).replace('€', '%'),
						discountValue: item.DiscountValue.toLocaleString('de-DE', {
							style: 'currency',
							currency: data.Currency,
						}),
						valueAfterDiscount: item.ValueAfterDiscount.toLocaleString('de-DE', {
							style: 'currency',
							currency: data.Currency,
						}),
					});
				});
			}
			if (data.AdditionalCosts && data.AdditionalCosts.length > 0) {
				data.AdditionalCosts.forEach((item: any) => {
					additonalCosts.push({
						id: item.CostId,
						name: Helper.getLocalizedValueOrNull(item.Name),
						value: item.Value,
						tableValue: item.Value.toLocaleString('de-DE', {
							style: 'currency',
							currency: data.Currency,
						}),
					});
				});
			}
			if (data.NetCosts && data.NetCosts.length > 0) {
				data.NetCosts.forEach((item: any) => {
					netCosts.push({
						id: item.CostId,
						name: Helper.getLocalizedValueOrNull(item.Name),
						value: item.Value,
						tableValue: item.Value.toLocaleString('de-DE', {
							style: 'currency',
							currency: data.Currency,
						}),
					});
				});
			}
			state.discounts = discounts;
			state.additionalCosts = additonalCosts;
			state.netCosts = netCosts;
			state.costText.value = '';
			state.costText.validate = '';
			state.costValue.value = '';
			state.costValue.validate = '';
			state.costModal = false;
			state.netCostModal = false;
			state.discountText.value = '';
			state.discountText.validate = '';
			state.discountValue.value = '';
			state.discountValue.validate = '';
			state.discountLevel.value = '';
			state.discountLevel.validate = '';
			state.discountType.value = '';
			state.discountTypeId = '';
			state.discountType.validate = '';
			state.netCostText.value = '';
			state.netCostText.validate = '';
			state.netCostValue.value = '';
			state.netCostValue.validate = '';
			state.discountModal = false;
			this.props.configuration.canEdit = data.CanEdit;
			this.setState(state);
		}
	}

	componentDidMount() {
		if (!Helper.userAdmin()) {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/configuration/monter');
		}
		this.getCalculation();

		const url = '/document/discountType';

		Helper.getData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					let discountTypesFromApi = data.map((discountType: any) => {
						return { value: discountType.TypeId, code: discountType.Code, display: Helper.getLocalizedName(discountType.Name) };
					});
					this.setState({
						discountTypeList: [
							{
								value: '',
								code: '',
								display: locale.selectDiscountType,
							},
						].concat(discountTypesFromApi),
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	toggleDiscountModal(id?: string) {
		const state: IState = this.state;
		state.discountText.value = '';
		state.discountText.validate = '';
		state.discountValue.value = '';
		state.discountValue.validate = '';
		state.discountLevel.value = '';
		state.discountLevel.validate = '';
		state.discountType.value = '';
		state.discountTypeId = '';
		state.discountType.validate = '';
		state.errorTextMessage = '';
		state.errorNumberMessage = '';
		state.errorNumber2Message = '';
		state.errorTextInput = false;
		state.errorNumberInput = false;
		state.errorNumber2Input = false;
		state.discountModal = !state.discountModal;
		state.addEditError = false;
		if (id && state.discounts && state.discounts.length > 0) {
			const discount = state.discounts.find((item: any) => item.id === id);
			state.selectedId = id;
			state.discountText.value = discount.name;
			state.discountValue.value = discount.value;
			state.discountLevel.value = discount.level;
			state.discountType.value = discount.typeId;
			state.discountTypeId = discount.typeId;
			state.confirmationModalAction = 'editDiscount';
		} else {
			state.confirmationModalAction = 'addDiscount';
		}
		this.setState(state);
	}
	toggleAdditionalCostModal(id?: string) {
		const state: IState = this.state;
		state.costText.value = '';
		state.costText.validate = '';
		state.costValue.value = '';
		state.costValue.validate = '';
		state.errorTextMessage = '';
		state.errorNumberMessage = '';
		state.errorTextInput = false;
		state.errorNumberInput = false;
		state.costModal = !state.costModal;
		if (id && state.additionalCosts && state.additionalCosts.length > 0) {
			const cost = state.additionalCosts.find((item: any) => item.id === id);
			state.selectedId = id;
			state.costText.value = cost.name;
			state.costValue.value = cost.value;
			state.confirmationModalAction = 'editAdditionalCost';
		} else {
			state.confirmationModalAction = 'addAdditionalCost';
		}
		this.setState(state);
	}
	toggleNetCostModal(id?: string) {
		const state: IState = this.state;
		state.netCostText.value = '';
		state.netCostText.validate = '';
		state.netCostValue.value = '';
		state.netCostValue.validate = '';
		state.errorTextMessage = '';
		state.errorNumberMessage = '';
		state.errorTextInput = false;
		state.errorNumberInput = false;
		state.netCostModal = !state.netCostModal;
		if (id && state.netCosts && state.netCosts.length > 0) {
			const cost = state.netCosts.find((item: any) => item.id === id);
			state.selectedId = id;
			state.netCostText.value = cost.name;
			state.netCostValue.value = cost.value;
			state.confirmationModalAction = 'editNetCost';
		} else {
			state.confirmationModalAction = 'addNetCost';
		}
		this.setState(state);
	}
	validateDiscountValue(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		let { target } = e;
		let { validate } = state.discountValue;
		let value = e.target.value.replace('%', '');
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			var pattern = /^-?\d{1,3}([,.]\d{1,2})?$/;
			if (!pattern.test(value)) {
				validate = 'has-danger';
				state.errorNumberMessage = locale.inputNumber + ' ( -100 - 100 , ' + locale.twoDecimalPlaces + ')';
				state.errorNumberInput = true;
			} else {
				value = value.replace(',', '.');
				if (value.length > 0 && parseFloat(value) >= -100 && parseFloat(value) <= 100) {
					validate = 'has-success';
					state.errorNumberInput = false;
				} else {
					validate = 'has-danger';
					state.errorNumberMessage = locale.inputNumber + ' ( -100 - 100 , ' + locale.twoDecimalPlaces + ')';
					state.errorNumberInput = true;
				}
			}
			state[target.name].validate = validate;
			this.setState(state);
		}, 300);
	}
	validateCostValue(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		let { target } = e;
		let validate = '';
		let value = e.target.value;
		var pattern = /^-?\d{1,6}([,.]\d{1,2})?$/;
		if (pattern.test(value)) {
			validate = 'has-success';
			state.errorNumberInput = false;
		} else {
			validate = 'has-danger';
			state.errorNumberMessage = locale.inputNumber + ' (' + locale.twoDecimalPlaces + ')';
			state.errorNumberInput = true;
		}
		state[target.name].validate = validate;
		this.setState(state);
	}
	validateLevelValue(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		let { target } = e;
		let { validate } = state.discountLevel;
		let value = e.target.value.replace('%', '');
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			var pattern = /^[0-9]\d*$/;
			if (!pattern.test(value)) {
				validate = 'has-danger';
				state.errorNumber2Message = locale.inputNumber + ' ( 0 - 100 )';
				state.errorNumber2Input = true;
			} else {
				value = value.replace(',', '.');
				if (value.length > 0 && parseFloat(value) >= 0 && parseFloat(value) <= 100) {
					validate = 'has-success';
					state.errorNumber2Input = false;
				} else {
					validate = 'has-danger';
					state.errorNumber2Message = locale.inputNumber + ' ( 0 - 100 )';
					state.errorNumber2Input = true;
				}
			}
			state[target.name].validate = validate;
			this.setState(state);
		}, 300);
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		const { target } = e;
		const value = target.value;
		state[target.name].value = value;
		state.saved = false;
		this.setState(state);
	}
	saveAdditionalCost() {
		this.setState({ loading: true });
		const state: IState = this.state;
		if (!state.costText.value || !state.costValue.value) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/additionalCost';
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: state.costText.value,
			Value: state.costValue.value.toString().replace(',', '.'),
		};

		Helper.postData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					if (this.timeout) clearTimeout(this.timeout);
				}
				this.getCalculation();
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	editAdditionalCost() {
		this.setState({ loading: true });
		const state: IState = this.state;
		if (state.costText.value === undefined || state.costValue.value === undefined) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/additionalCost/' + this.state.selectedId;
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: state.costText.value,
			Value: state.costValue.value.toString().replace(',', '.'),
		};

		Helper.putData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					if (this.timeout) clearTimeout(this.timeout);
				}
				this.getCalculation();
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	removeAdditionalCost() {
		this.setState({ loading: true });
		const url =
			'/document/configuration/' +
			this.props.configuration.documentConfigurationId +
			'/additionalCost/' +
			this.state.selectedId +
			'?brandPartnerId=' +
			Helper.brandPartnerId();
		this.setState({ selectedId: '' });

		Helper.deleteData<any>(url).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.getCalculation();
			this.setState({ loading: false });
		});
	}
	saveDiscount() {
		this.setState({ loading: true });
		const state: IState = this.state;
		if (!state.discountText.value || !state.discountValue.value || !state.discountLevel.value || !state.discountTypeId) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/discount';
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: state.discountText.value,
			Value: state.discountValue.value.toString().replace(',', '.'),
			Level: state.discountLevel.value,
			TypeId: state.discountTypeId,
		};

		Helper.postData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					if (this.timeout) clearTimeout(this.timeout);
				}
				this.getCalculation();
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	editDiscount() {
		this.setState({ loading: true });
		const state: IState = this.state;
		if (state.discountLevel.value === undefined || state.discountText.value === undefined || !state.discountValue.value || !state.discountTypeId) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/discount/' + this.state.selectedId;
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: state.discountText.value,
			Value: state.discountValue.value.toString().replace(',', '.'),
			Level: state.discountLevel.value,
			TypeId: state.discountTypeId,
		};

		Helper.putData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					if (this.timeout) clearTimeout(this.timeout);
				}
				this.getCalculation();
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	removeDiscount() {
		this.setState({ loading: true });
		const url =
			'/document/configuration/' +
			this.props.configuration.documentConfigurationId +
			'/discount/' +
			this.state.selectedId +
			'?brandPartnerId=' +
			Helper.brandPartnerId();
		this.setState({ selectedId: '' });

		Helper.deleteData<any>(url).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.getCalculation();
			this.setState({ loading: false });
		});
	}
	saveNetCost() {
		this.setState({ loading: true });
		const state: IState = this.state;
		if (!state.netCostText.value || !state.netCostValue.value) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/netCost/';
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: state.netCostText.value,
			Value: state.netCostValue.value.toString().replace(',', '.'),
		};

		Helper.postData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					if (this.timeout) clearTimeout(this.timeout);
				}
				this.getCalculation();
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	editNetCost() {
		this.setState({ loading: true });
		const state: IState = this.state;
		if (state.netCostText.value === undefined || state.netCostValue.value === undefined) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/netCost/' + this.state.selectedId;
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: state.netCostText.value,
			Value: state.netCostValue.value.toString().replace(',', '.'),
		};

		Helper.putData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				} else {
					if (this.timeout) clearTimeout(this.timeout);
				}
				this.getCalculation();
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}
	removeNetCost() {
		this.setState({ loading: true });
		const url =
			'/document/configuration/' +
			this.props.configuration.documentConfigurationId +
			'/netCost/' +
			this.state.selectedId +
			'?brandPartnerId=' +
			Helper.brandPartnerId();
		this.setState({ selectedId: '' });

		Helper.deleteData<any>(url).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.getCalculation();
			this.setState({ loading: false });
		});
	}
	setNewTax(id: string) {
		this.setState({ loading: true });

		const url =
			'/document/configuration/' +
			this.props.configuration.documentConfigurationId +
			'/taxoption/' +
			id +
			'?brandPartnerId=' +
			Helper.brandPartnerId();

		Helper.putData<any>(url, '').then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			} else {
				if (this.timeout) clearTimeout(this.timeout);
			}
			this.getCalculation();
			this.setState({ loading: false });
		});
	}
	toggleConfirmationModal(title?: string, message?: string, action?: string, id?: string) {
		const state: IState = this.state;

		state.confirmationModal = !state.confirmationModal;
		if (title && message && action && id) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.confirmationModalAction = action;
			state.selectedId = id;
		}

		this.setState(state);
	}
	confirmationAction() {
		if (this.state.confirmationModalAction === 'addAdditionalCost') {
			this.saveAdditionalCost();
		} else if (this.state.confirmationModalAction === 'editAdditionalCost') {
			this.editAdditionalCost();
		} else if (this.state.confirmationModalAction === 'deleteAdditionalCost') {
			this.removeAdditionalCost();
			this.setState({ confirmationModal: false });
		} else if (this.state.confirmationModalAction === 'addNetCost') {
			this.saveNetCost();
		} else if (this.state.confirmationModalAction === 'editNetCost') {
			this.editNetCost();
		} else if (this.state.confirmationModalAction === 'deleteNetCost') {
			this.removeNetCost();
			this.setState({ confirmationModal: false });
		} else if (this.state.confirmationModalAction === 'addDiscount') {
			this.saveDiscount();
		} else if (this.state.confirmationModalAction === 'editDiscount') {
			this.editDiscount();
		} else if (this.state.confirmationModalAction === 'deleteDiscount') {
			this.removeDiscount();
			this.setState({ confirmationModal: false });
		}
	}
	render() {
		const {
			discountText,
			costText,
			discountValue,
			discountLevel,
			discountType,
			discountTypeId,
			costValue,
			discountModal,
			costModal,
			netCostModal,
			netCostText,
			netCostValue,
			errorTextInput,
			errorNumberInput,
			errorNumber2Input,
			errorTextMessage,
			errorNumberMessage,
			errorNumber2Message,
			addEditError,
			addEditMessage,
		} = this.state;

		const disabled1 =
			discountValue.value === '' ||
			discountText.value === '' ||
			discountLevel.value === '' ||
			discountTypeId === '' ||
			discountValue.validate === 'has-danger' ||
			discountText.validate === 'has-danger' ||
			discountLevel.validate === 'has-danger' ||
			discountType.validate === 'has-danger' ||
			errorTextInput ||
			errorNumberInput ||
			errorNumber2Input;

		const disabled2 =
			costValue.value === '' ||
			costText.value === '' ||
			costValue.validate === 'has-danger' ||
			costText.validate === 'has-danger' ||
			errorTextInput ||
			errorNumberInput;

		const disabled3 =
			netCostValue.value === '' ||
			netCostText.value === '' ||
			netCostValue.validate === 'has-danger' ||
			netCostText.validate === 'has-danger' ||
			errorTextInput ||
			errorNumberInput;
		const disableEditDocument =
			(this.props.document && this.props.document.disableEdit) || !(this.props.configuration && this.props.configuration.canEdit);
		const options = {
			noDataText: locale.noResults,
		};
		const errorTextVisible = errorTextInput ? 'block' : 'none';
		const errorNumberVisible = errorNumberInput ? 'block' : 'none';
		const errorNumber2Visible = errorNumber2Input ? 'block' : 'none';
		const addEditFailedVisible = addEditError ? 'block' : 'none';
		const deleteDiscount = (cell: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button
					onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'deleteDiscount', cell)}
					color='none'
					className='close float-left'
					aria-label='Close'
				>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};
		const deleteAdditionalCost = (cell: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button
					onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'deleteAdditionalCost', cell)}
					color='none'
					className='close float-left'
					aria-label='Close'
				>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};
		const editCost = (cell: any, row: any) => {
			if (disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleAdditionalCostModal(row.id)} color='none' disabled={disableEditDocument} className='float-left cell-button'>
					<i className='fa fa-edit' aria-hidden='true'></i>
				</Button>
			);
		};

		const editDiscount = (cell: any, row: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleDiscountModal(row.id)} color='none' disabled={disableEditDocument} className='float-left cell-button'>
					<i className='fa fa-edit' aria-hidden='true'></i>
				</Button>
			);
		};
		const deleteNetCost = (cell: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button
					onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'deleteNetCost', cell)}
					color='none'
					className='close float-left'
					aria-label='Close'
				>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};

		const editNetCost = (cell: any, row: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleNetCostModal(row.id)} color='none' disabled={disableEditDocument} className='float-left cell-button'>
					<i className='fa fa-edit' aria-hidden='true'></i>
				</Button>
			);
		};
		const taxes = this.state.taxList.map((tax: any) => {
			return (
				<DropdownItem key={tax.TaxOptionId} disabled={disableEditDocument} onClick={() => this.setNewTax(tax.TaxOptionId)}>
					{tax.TaxValue.toString().replace('.', ',')}
				</DropdownItem>
			);
		});
		return (
			<div id='configurator' className='admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background calculation'>
							<Row>
								<Col xs='12' className='mt-3'>
									<Form>
										<Row form>
											<Col className='mb-3 admin-form-border'>
												<FormGroup row>
													<Col xl={12}>
														<Row>
															<Col sm={7} xl={7}></Col>
															<Col sm={5} xl={5}>
																<FormGroup row>
																	<Label xs={6}>{locale.adminCalculationConfigurationPrice}</Label>
																	<Col xs={6}>
																		<Input type='text' className='table-summary' id='costs' disabled value={this.state.configurationPrice} />
																	</Col>
																</FormGroup>
															</Col>
														</Row>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label xl={12}>{locale.adminCalculationAdditional}</Label>
													<Col xl={12}>
														<BootstrapTable
															data={this.state.additionalCosts}
															options={options}
															striped
															keyField='id'
															hover
															bordered={true}
															containerClass='customer-configurations'
															tableStyle={{
																backgroundColor: 'white',
															}}
														>
															<TableHeaderColumn thStyle={{ borderRight: 0 }} dataField='id' dataFormat={deleteAdditionalCost} width='25px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn thStyle={{ borderLeft: 0 }} dataField='id' dataFormat={editCost} width='30px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='name' width='60%' dataSort>
																{locale.costText}
															</TableHeaderColumn>

															<TableHeaderColumn dataField='tableValue' width='30%' dataAlign='right' headerAlign='right'>
																{locale.value}
															</TableHeaderColumn>
														</BootstrapTable>
														<Row>
															<Col sm={7} xl={7}>
																<Button
																	onClick={() => this.toggleAdditionalCostModal()}
																	disabled={disableEditDocument}
																	className={'button btn-secondary mt-3 mb-3'}
																>
																	{locale.adminCalculationAddAdditionalCost}
																</Button>
															</Col>
															<Col sm={5} xl={5}>
																<FormGroup row>
																	<Label xs={6}>{locale.adminCalculationAdditionalSum}</Label>
																	<Col xs={6}>
																		<Input type='text' className='table-summary' id='costs' disabled value={this.state.additionalCost} />
																	</Col>
																	<Label xs={6}>{locale.adminCalculationConfigurationWithAdditionalSum}</Label>
																	<Col xs={6}>
																		<Input
																			type='text'
																			className='table-summary'
																			id='finalPrice'
																			disabled
																			value={this.state.configurationWithAdditionalCost}
																		/>
																	</Col>
																</FormGroup>
															</Col>
														</Row>
													</Col>
													<Modal isOpen={costModal} toggle={() => this.toggleAdditionalCostModal()}>
														<ModalHeader toggle={() => this.toggleAdditionalCostModal()}>{locale.addCost}</ModalHeader>
														<ModalBody>
															<FormGroup>
																<Label for='text'>{locale.costText}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='costText'
																	id='costText'
																	maxLength={255}
																	minLength={1}
																	value={costText.value}
																	valid={costText.validate === 'has-success'}
																	invalid={costText.validate === 'has-danger'}
																	onChange={(e) => {
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorTextVisible }}>
																	{errorTextMessage}
																</div>
															</FormGroup>
															<FormGroup>
																<Label for='value'>{locale.costValue}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='costValue'
																	id='costValue'
																	value={costValue.value.toString().replace('.', ',')}
																	valid={costValue.validate === 'has-success'}
																	invalid={costValue.validate === 'has-danger'}
																	onChange={(e) => {
																		this.validateCostValue(e);
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorNumberVisible }}>
																	{errorNumberMessage}
																</div>
															</FormGroup>
															<Button
																className={'button btn-secondary my-1'}
																disabled={disabled2 || disableEditDocument}
																onClick={() => this.confirmationAction()}
															>
																{locale.save}
															</Button>
														</ModalBody>
													</Modal>
												</FormGroup>
												<FormGroup row>
													<Label xl={12}>{locale.adminCalculationDiscounts}</Label>
													<Col xl={12}>
														<BootstrapTable
															data={this.state.discounts}
															options={options}
															striped={true}
															bordered={true}
															keyField='id'
															hover
															containerClass='customer-configurations'
															tableStyle={{
																backgroundColor: 'white',
															}}
														>
															<TableHeaderColumn thStyle={{ borderRight: 0 }} dataField='id' dataFormat={deleteDiscount} width='25px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn thStyle={{ borderLeft: 0 }} dataField='id' dataFormat={editDiscount} width='30px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='name' width='23%' dataSort>
																{locale.name}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='typeName' width='17%' dataSort>
																{locale.discountType}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='level' width='10%' dataSort>
																{locale.discountLevel}
															</TableHeaderColumn>

															<TableHeaderColumn dataAlign='right' headerAlign='right' width='10%' dataField='tableValue'>
																{locale.discount}
															</TableHeaderColumn>
															<TableHeaderColumn dataAlign='right' headerAlign='right' width='10%' dataField='discountValue'>
																{locale.discountAmount}
															</TableHeaderColumn>
															<TableHeaderColumn dataAlign='right' headerAlign='right' width='15%' dataField='valueAfterDiscount'>
																{locale.discountValueAfter}
															</TableHeaderColumn>
														</BootstrapTable>
														<Row>
															<Col sm={7} xl={7}>
																<Button
																	onClick={() => this.toggleDiscountModal()}
																	disabled={disableEditDocument}
																	className={'button btn-secondary mt-3 mb-3'}
																>
																	{locale.adminCalculationAddDiscount}
																</Button>
															</Col>
															<Col sm={5} xl={5}>
																<FormGroup row>
																	<Label xs={6}>{locale.adminCalculationDiscountPercentageSum}</Label>
																	<Col xs={6}>
																		<Input type='text' className='table-summary' id='costs' disabled value={this.state.discountPercentageSum} />
																	</Col>
																	<Label xs={6}>{locale.adminCalculationDiscountSum}</Label>
																	<Col xs={6}>
																		<Input type='text' className='table-summary' id='finalPrice' disabled value={this.state.discountValueSum} />
																	</Col>
																	<Label xs={6}>{locale.adminCalculationConfigurationWithDiscountSum}</Label>
																	<Col xs={6}>
																		<Input type='text' className='table-summary' id='costs' disabled value={this.state.valueAfterDiscountSum} />
																	</Col>
																</FormGroup>
															</Col>
														</Row>
													</Col>
													<Modal isOpen={discountModal} toggle={() => this.toggleDiscountModal()}>
														<ModalHeader toggle={() => this.toggleDiscountModal()}>{locale.addDiscount}</ModalHeader>
														<ModalBody>
															<FormGroup>
																<Label for='text'>{locale.discountText}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='discountText'
																	id='discountText'
																	maxLength={255}
																	minLength={1}
																	value={discountText.value}
																	valid={discountText.validate === 'has-success'}
																	invalid={discountText.validate === 'has-danger'}
																	onChange={(e) => {
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorTextVisible }}>
																	{errorTextMessage}
																</div>
															</FormGroup>
															<FormGroup>
																<Label for='type'>{locale.discountType}</Label>
																<select
																	className='form-control'
																	value={this.state.discountTypeId}
																	onChange={(e) =>
																		this.setState({
																			discountTypeId: e.target.value,
																		})
																	}
																>
																	{this.state.discountTypeList.map((discountType) => (
																		<option key={discountType.value} value={discountType.value}>
																			{discountType.display}
																		</option>
																	))}
																</select>
															</FormGroup>
															<FormGroup>
																<Label for='value'>{locale.discountValue}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='discountValue'
																	id='discountValue'
																	value={discountValue.value.toString().replace('.', ',')}
																	valid={discountValue.validate === 'has-success'}
																	invalid={discountValue.validate === 'has-danger'}
																	onChange={(e) => {
																		this.validateDiscountValue(e);
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorNumberVisible }}>
																	{errorNumberMessage}
																</div>
															</FormGroup>
															<FormGroup>
																<Label for='value'>{locale.discountLevel}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='discountLevel'
																	id='discountLevel'
																	value={discountLevel.value}
																	valid={discountLevel.validate === 'has-success'}
																	invalid={discountLevel.validate === 'has-danger'}
																	onChange={(e) => {
																		this.validateLevelValue(e);
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorNumber2Visible }}>
																	{errorNumber2Message}
																</div>
															</FormGroup>
															<Button
																className={'button btn-secondary my-1'}
																disabled={disabled1 || disableEditDocument}
																onClick={() => this.confirmationAction()}
															>
																{locale.save}
															</Button>
															<Col>
																<div className={'invalid-feedback mt-2'} style={{ display: addEditFailedVisible }}>
																	{addEditMessage}
																</div>
															</Col>
														</ModalBody>
													</Modal>
												</FormGroup>
												<FormGroup row>
													<Label xl={12}>{locale.adminCalculationCostsNet}</Label>
													<Col xl={12}>
														<BootstrapTable
															data={this.state.netCosts}
															options={options}
															striped
															bordered={true}
															keyField='id'
															hover
															containerClass='customer-configurations'
															tableStyle={{
																backgroundColor: 'white',
															}}
														>
															<TableHeaderColumn thStyle={{ borderRight: 0 }} dataField='id' dataFormat={deleteNetCost} width='30px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn thStyle={{ borderLeft: 0 }} dataField='id' dataFormat={editNetCost} width='25px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='name' width='60%' dataSort>
																{locale.name}
															</TableHeaderColumn>

															<TableHeaderColumn dataField='tableValue' width='30%' dataAlign='right' headerAlign='right'>
																{locale.value}
															</TableHeaderColumn>
														</BootstrapTable>

														<Row>
															<Col sm={7} xl={7}>
																<Button
																	onClick={() => this.toggleNetCostModal()}
																	disabled={disableEditDocument}
																	className={'button btn-secondary mt-3 mb-3'}
																>
																	{locale.adminCalculationAddNetCost}
																</Button>
															</Col>
															<Col sm={5} xl={5}>
																<FormGroup row>
																	<Label xs={6}>{locale.adminCalculationServiceSum}</Label>
																	<Col xs={6}>
																		<Input type='text' className='table-summary' id='costs' disabled value={this.state.netCost} />
																	</Col>
																</FormGroup>
															</Col>
														</Row>
													</Col>
													<Modal isOpen={netCostModal} toggle={() => this.toggleNetCostModal()}>
														<ModalHeader toggle={() => this.toggleNetCostModal()}>{locale.addNetCost}</ModalHeader>
														<ModalBody>
															<FormGroup>
																<Label for='text'>{locale.costText}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='netCostText'
																	id='netCostText'
																	maxLength={255}
																	minLength={1}
																	value={netCostText.value}
																	valid={netCostText.validate === 'has-success'}
																	invalid={netCostText.validate === 'has-danger'}
																	onChange={(e) => {
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorTextVisible }}>
																	{errorTextMessage}
																</div>
															</FormGroup>
															<FormGroup>
																<Label for='value'>{locale.costValue}</Label>
																<Input
																	type='text'
																	autoComplete='off'
																	name='netCostValue'
																	id='netCostValue'
																	value={netCostValue.value.toString().replace('.', ',')}
																	valid={netCostValue.validate === 'has-success'}
																	invalid={netCostValue.validate === 'has-danger'}
																	onChange={(e) => {
																		this.validateCostValue(e);
																		this.handleChange(e);
																	}}
																/>
																<div className={'invalid-feedback mt-2'} style={{ display: errorNumberVisible }}>
																	{errorNumberMessage}
																</div>
															</FormGroup>
															<Button
																className={'button btn-secondary my-1'}
																disabled={disabled3 || disableEditDocument}
																onClick={() => this.confirmationAction()}
															>
																{locale.save}
															</Button>
														</ModalBody>
													</Modal>
												</FormGroup>
												<FormGroup row>
													<Col xl={12}>
														<Row>
															<Col sm={7} xl={7}></Col>
															<Col sm={5} xl={5}>
																<FormGroup row>
																	<Label xs={6} xl={6}>
																		{locale.totalPriceWithoutTax}
																	</Label>
																	<Col xs={6} xl={6}>
																		<Input type='text' id='vatNoTax' className='table-summary' disabled value={this.state.totalPrice} />
																	</Col>
																</FormGroup>
																<FormGroup row>
																	<Label xs={6} xl={6}>
																		{locale.tax}
																	</Label>
																	<Col xs={6} xl={6}>
																		<UncontrolledDropdown>
																			<DropdownToggle caret color='selector' className='dropdown-selector tax-dropdown'>
																				{this.state.selectedTax !== ''
																					? this.state.selectedTax.toString().replace('.', ',') + ' %'
																					: locale.selectTax}
																				&nbsp;
																			</DropdownToggle>
																			<DropdownMenu>{taxes}</DropdownMenu>
																		</UncontrolledDropdown>
																	</Col>
																</FormGroup>
																<FormGroup row>
																	<Label xs={6} xl={6}>
																		{locale.totalTax}
																	</Label>
																	<Col xs={6} xl={6}>
																		<Input type='text' id='costs' className='table-summary' disabled value={this.state.taxCost} />
																	</Col>
																</FormGroup>
																<FormGroup row>
																	<Label xs={6} xl={6}>
																		{<b>{locale.finalPrice}</b>}
																	</Label>
																	<Col xs={6} xl={6}>
																		<Input type='text' id='netCost' className='table-summary' disabled value={this.state.totalPriceWithTax} />
																	</Col>
																</FormGroup>
															</Col>
														</Row>
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</Form>
									<Modal size='lg' isOpen={this.state.confirmationModal} toggle={() => this.toggleConfirmationModal()}>
										<ModalHeader toggle={() => this.toggleConfirmationModal()}>{locale.delete}</ModalHeader>
										<ModalBody>
											{locale.confirmDelete}
											<br />
											<br />
											<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationAction()}>
												{locale.confirm}
											</Button>
											<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
												{locale.cancel}
											</Button>
										</ModalBody>
									</Modal>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerCalculation);
