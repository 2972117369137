import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-xxl/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';
import './styles/common.css';
import './styles/buttons.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/find-index';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/features/string/repeat';
import "es6-promise/auto";

// Create browser history to use in the Redux store
export const history = createBrowserHistory();
export const store = configureStore(history);

const rootElement = document.getElementById('root');

document.addEventListener("visibilitychange", (event) => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()))
});

  ReactDOM.render(
  <Provider store={store as any}>
    <ConnectedRouter history={history}>
    <App />
    </ConnectedRouter>
  </Provider>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
