import React, { Fragment } from 'react';
import { Navbar, Row, Col, Modal, ModalHeader, ModalBody, FormGroup, ModalFooter } from 'reactstrap';
import './Configurator.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Part from './Part';
import Categories from './Categories';
import queryString from 'query-string';
import locale from '../Locale';
import Button from './Button';
import Helper from '../Helper';
import Loading from 'react-fullscreen-loading';
import DelayedLoading from './Loading';

const Tree = (data: any) => (
	<ul>
		{data &&
			Array.isArray(data.data) &&
			data.data.map((item: any) => (
				<li key={item.PartId}>
					{item.Title} {item.PartCode}
					{item.Childs && <Tree data={item.Childs} />}
				</li>
			))}
	</ul>
);

interface IProps extends RouteComponentProps<any> {
	part: any;
	step: number;
	selectedParameters: any;
	categoryComments: any;
	categories: any;
	configuration: any;
	tree: any;
	treeModal: boolean;
	loading: boolean;
	anonymousToken: string;
}

interface IState {
	update: boolean;
	saved: false;
	saveMessage: any;
}

class ConfiguratorStep2 extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.updateImage = this.updateImage.bind(this);

		this.state = {
			update: false,
			saved: false,
			saveMessage: null,
		};
	}
	updateImage() {
		this.setState({ update: !this.state.update });
	}
	shouldComponentUpdate(nextProps: any, nextState: any) {
		return (
			nextProps.categories !== this.props.categories ||
			this.props.configuration.loading !== nextProps.configuration.loading ||
			nextProps.treeModal !== this.props.treeModal ||
			nextProps.loading !== this.props.loading ||
			nextProps.step !== this.props.step ||
			nextProps.selectedParameters !== this.props.selectedParameters
		);
	}
	componentDidMount() {
		//Get configuration id from url
		const queryValues = queryString.parse(this.props.location.search);
		if (queryValues && queryValues.id) {
			(this.props as any).buildCategories(queryValues.id, null, null);
		}
	}
	nextPage() {
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/confirmation?bpi=' + Helper.brandPartnerId());
	}
	toggleSaveDialog() {
		(this.props as any).setTreeModal(!this.props.treeModal);
	}
	sendInquiry() {
		this.setState({ saveMessage: locale.saving + '...', saved: false });
		(this.props as any).setLoading(true);

		const state: IState = this.state;
		if (this.props.configuration.configurationId) {
			let url = '/cart/' + this.props.configuration.configurationId;

			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				UserTokenId: this.props.anonymousToken,
				Copy: false,
			};

			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data && data.UserToken) {
						if (data.UserToken !== this.props.anonymousToken) {
							(this.props as any).setAnonymousToken(data.UserToken);
						}
						(this.props as any).setConfigurationPublished(true);
						(this.props as any).setLoading(false);
						this.openInquiryDialog();
					} else if (data && data.Message) {
						(this.props as any).setLoading(false);
						state.saveMessage = data.Message;
						this.setState(state);
						this.toggleSaveDialog();
					}
				})
				.catch((error) => {
					(this.props as any).setLoading(false);
					state.saveMessage = error.message;
					this.setState(state);
				});
		}
	}
	openInquiryDialog() {
		(this.props as any).setInquiryDialogOpen(true);
	}

	render() {
		return (
			<div>
				{this.props.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<div id='configurator' className='step2'>
						<Row>
							<Col className='col-12 col-md-6 col-lg-5 col-xxl-4 no-gutters pr-3 mb-4'>
								<Navbar
									color='faded'
									light
									className={`filters-navbar configurator-filters ${!this.props.configuration.canEdit ? 'disable-edit' : ''}`}
									inert={!this.props.configuration.canEdit ? '' : undefined}
								>
									<Categories
										updateImage={this.updateImage}
										categoryComments={this.props.categoryComments}
										categories={this.props.categories}
										history={this.props.history}
										location={this.props.location}
										match={this.props.match}
										part={this.props.part}
										step={this.props.step}
										selectedParameters={this.props.selectedParameters}
										hasDocument={this.props.configuration.hasDocument}
										comment={this.props.configuration.comment}
										configurationId={this.props.configuration.configurationId}
										selectedParametersTags={this.props.configuration.selectedParametersTags}
									/>
									{this.props.configuration.loading ? <DelayedLoading loaderColor={Helper.themeButtonColor()} /> : null}
									{!this.props.configuration.loading && this.props.categories && this.props.categories.length > 0 ? (
										<div className='configurator-buttons'>
											{!Helper.userLoggedIn() ? (
												<Button color='secondary' className='button send-inquiry' onClick={() => this.sendInquiry()}>
													{locale.sendInquiry}
												</Button>
											): (
												<>
													<Button color='secondary' className='button next-page' onClick={() => this.nextPage()}>
														{locale.nextPage}
													</Button>
													{Helper.userHeadOfSales() && Helper.userIsParentPartner() && (<Button
														color='secondary'
														className='button tree'
														style={{ float: 'right', backgroundColor: Helper.themeButtonColor(), color: Helper.themeButtonFontColor(), width: '27%' }}
														onClick={() => this.toggleTreeModal()}
													>
														{locale.details}
													</Button>)
													}
												</>
											)}
										</div>
									) : null}
								</Navbar>
							</Col>
							<Col className='col-12 col-md-6 col-lg-7 col-xxl-8 configurator-background'>
								<Row>
									<Col xs='12' className='mt-4'>
										<Part history={this.props.history} location={this.props.location} match={this.props.match} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							{Helper.userAdmin() ? (
								<Modal className='treemodal' isOpen={this.props.treeModal} toggle={() => this.toggleTreeModal()}>
									<ModalHeader toggle={() => this.toggleTreeModal()}>{locale.treeDetails}</ModalHeader>
									<ModalBody>
										<FormGroup>
											<Col md='12'>
												<Tree data={this.props.tree} />
											</Col>
										</FormGroup>
										<Button className={'button btn-secondary my-1'} onClick={() => this.toggleTreeModal()}>
											{locale.close}
										</Button>
									</ModalBody>
								</Modal>
							) : (
								<Modal className='inquiry-popup' isOpen={this.props.treeModal} toggle={() => this.toggleTreeModal()}>
									<ModalHeader toggle={() => this.toggleTreeModal()}>{locale.cartSavingSuccess}</ModalHeader>
									<Fragment>
										<ModalBody>{this.state.saveMessage}</ModalBody>
										<ModalFooter>
											{this.state.saved ? (
												<Button color='secondary' onClick={() => this.toggleTreeModal()}>
													{locale.close}
												</Button>
											) : (
												<br />
											)}
										</ModalFooter>
									</Fragment>
								</Modal>
							)}
						</Row>
					</div>
				)}
			</div>
		);
	}

	toggleTreeModal() {
		(this.props as any).setTreeModal(!this.props.treeModal);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(ConfiguratorStep2);
