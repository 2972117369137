import React from 'react';
import Layout from './components/Layout';
import ConfiguratorStep1 from './components/ConfiguratorStep1';
import ConfiguratorStep2 from './components/ConfiguratorStep2';
import ConfiguratorStep3 from './components/ConfiguratorStep3';
import CustomerView from './components/admin/CustomerView';
import CustomerDocument from './components/admin/CustomerDocument';
import CustomerHistory from './components/admin/CustomerHistory';
import CustomerRemarks from './components/admin/CustomerRemarks';
import CustomerCosts from './components/admin/CustomerCosts';
import CustomerCalculation from './components/admin/CustomerCalculationOverview';
import CustomerImages from './components/admin/CustomerImages';
import Inquiry from './components/Inquiry';
import Login from './components/Login';
import CookieInfo from './components/CookieInfo';
import { Route } from 'react-router-dom';
import CustomerConfigurations from './components/admin/CustomerConfigurations';
import ResetPassword from './components/ResetPassword';
import Profile from './components/Profile';
import CustomerDocumentPrint from './components/admin/CustomerDocumentPrint';
import CustomerTerms from './components/admin/CustomerTerms';
import InquirySales from './components/InquirySales';
import InquiryUser from './components/InquiryUser';
import CustomerContact from './components/admin/CustomerContact';

declare function require(moduleName: string): any;
const AnimatedSwitch = require('react-router-transition').AnimatedSwitch;
const basename = process.env.REACT_APP_ROOT_PATH;

const foo = () => (
	<Layout>
		<AnimatedSwitch atEnter={{ opacity: 0 }} atLeave={{ opacity: 0 }} atActive={{ opacity: 1 }} className='switch-wrapper'>
			<Route exact path={`${basename}/configurator/selection*`} component={ConfiguratorStep1} />
			<Route exact path={`${basename}/configurator/configuration*`} component={ConfiguratorStep2} />
			<Route exact path={`${basename}/configurator/confirmation*`} component={ConfiguratorStep3} />

			<Route exact path={`${basename}/configurator/inquiry*`} component={Inquiry} />
			<Route exact path={`${basename}/configurator/inquirysales`} component={InquirySales} />
			<Route exact path={`${basename}/configurator/inquiryuser`} component={InquiryUser} />

			<Route exact path={`${basename}/admin`} component={CustomerView} />
			<Route
				exact
				path={`${basename}/admin/myProjects`}
				render={(props: any) => <CustomerView location={props.location} {...props} viewType={'my'} />}
			/>
			<Route
				exact
				path={`${basename}/admin/allProjects`}
				render={(props: any) => <CustomerView location={props.location} {...props} viewType={'all'} />}
			/>

			<Route exact path={`${basename}/admin/document/configurations`} component={CustomerConfigurations} />
			<Route exact path={`${basename}/admin/document/document`} component={CustomerDocument} />
			<Route exact path={`${basename}/admin/document/contact`} component={CustomerContact} />
			<Route exact path={`${basename}/admin/document/print`} component={CustomerDocumentPrint} />
			<Route exact path={`${basename}/admin/document/history`} component={CustomerHistory} />

			<Route exact path={`${basename}/admin/configuration/configurator`} component={CustomerCalculation} />
			<Route exact path={`${basename}/admin/configuration`} component={CustomerCalculation} />
			<Route
				exact
				path={`${basename}/admin/configuration/remarks`}
				render={(props: any) => <CustomerRemarks location={props.location} {...props} remarksType={'sales'} />}
			/>
			<Route
				exact
				path={`${basename}/admin/configuration/monter`}
				render={(props: any) => <CustomerRemarks location={props.location} {...props} remarksType={'monter'} />}
			/>
			<Route
				exact
				path={`${basename}/admin/configuration/position`}
				render={(props: any) => <CustomerRemarks location={props.location} {...props} remarksType={'position'} />}
			/>
			<Route exact path={`${basename}/admin/configuration/calculation`} component={CustomerCalculation} />
			<Route exact path={`${basename}/admin/configuration/costs`} component={CustomerCosts} />

			<Route exact path={`${basename}/admin/configuration/images`} component={CustomerImages} />
			<Route exact path={`${basename}/admin/document/terms`} component={CustomerTerms} />

			<Route exact path={`${basename}/login*`} component={Login} />
			{/* <Route exact path={`${basename}/register*`} component={Register} /> */}
			<Route exact path={`${basename}/profile`} component={Profile} />

			{/* <Route exact path={`${basename}/activate*`} component={Activate} /> */}
			<Route exact path={`${basename}/logout`} component={Login} />
			<Route exact path={`${basename}/reset*`} component={ResetPassword} />

			<Route exact path={`${basename}/cookie-info*`} component={CookieInfo} />

			<Route exact path={`${basename}/*`} component={ConfiguratorStep1} />
		</AnimatedSwitch>
	</Layout>
);
export default foo;
