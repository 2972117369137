import React, { Fragment } from 'react';
import { Col, Container, Form, FormGroup, Label, Input, FormText, Row } from 'reactstrap';
import './Configurator.css';
import './Login.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps, Link } from 'react-router-dom';
import locale from '../Locale';
import Button from './Button';
import Helper from '../Helper';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {}

interface IState {
	email: string;
	password: string;
	showPassword: boolean;
	validate: {
		emailState: string;
		passState: string;
	};
	loginError: boolean;
	loginMessage: string;
	loading: boolean;
}

class Login extends React.Component<IProps, IState> {
	private timeout: number;

	constructor(props: any) {
		super(props);
		this.state = {
			email: '',
			password: '',
			showPassword: false,
			validate: {
				emailState: '',
				passState: '',
			},
			loginError: false,
			loginMessage: '',
			loading: false,
		};
		this.timeout = 0;
	}

	componentDidMount() {
		if (window.location.pathname.toLowerCase().indexOf('/logout') >= 0) {
			this.setState({ loading: true });
			// Helper.postData<any>("/account/logoff", "")
			//     .then((result: any) => { this.setState({ loading: false });})
			//     .catch((error: any) => {
			//         console.log(error);
			//     });
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			const brandPartnerId = localStorage.getItem('brandPartnerId');
			this.setToken('', '', '', '', '', '', '', '');
			(this.props as any).destroySession();
			if (theme) {
				(this.props as any).setBrand(
					theme.logo,
					theme.logotype,
					theme.title,
					theme.header,
					theme.color,
					theme.headerFontColor,
					brandPartnerId,
					theme.buttonColor,
					theme.buttonFontColor
				);
			}
			setTimeout(() => {
				window.location.href = process.env.REACT_APP_ROOT_PATH + '/configurator/selection';
			}, 700);
		}
	}

	validateEmail(e: React.FormEvent<HTMLInputElement>) {
		const emailRex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const { validate } = this.state;
		let value = e.currentTarget.value;
		if (this.timeout) window.clearTimeout(this.timeout);
		this.timeout = window.setTimeout(() => {
			if (emailRex.test(value)) {
				validate.emailState = 'has-success';
			} else {
				validate.emailState = 'has-danger';
			}
			this.setState({ validate });
		}, 700);
	}

	validatePass(e: React.FormEvent<HTMLInputElement>) {
		const { validate } = this.state;
		let value = e.currentTarget.value;
		if (this.timeout) window.clearTimeout(this.timeout);
		this.timeout = window.setTimeout(() => {
			if (value && value.length) {
				validate.passState = 'has-success';
			} else {
				validate.passState = 'has-danger';
			}
			this.setState({ validate });
		}, 700);
	}

	handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
		const { currentTarget } = event;
		const value = currentTarget.type === 'checkbox' ? currentTarget.checked : currentTarget.value;
		const { name } = currentTarget;
		await this.setState({
			[name]: value,
		} as any);
	};
	private setToken(
		email: string,
		name: string,
		userType: string,
		token: string,
		refreshToken: string,
		isUserOem: string,
		isUserOemAdmin: string,
		isParentPartner: string
	) {
		localStorage.removeItem('theme');
		localStorage.removeItem('brandPartnerId');
		if (token !== '') {
			localStorage.setItem('userToken', token);
			localStorage.setItem('userRole', userType);
			localStorage.setItem('refreshToken', refreshToken);
			localStorage.setItem('isUserOEM', isUserOem);
			localStorage.setItem('isUserOEMAdmin', isUserOemAdmin);
			localStorage.setItem('isParentPartner', isParentPartner);
		} else {
			localStorage.removeItem('userToken');
			localStorage.removeItem('userRole');
			localStorage.removeItem('refreshToken');
			localStorage.removeItem('isUserOEM');
			localStorage.removeItem('isUserOEMAdmin');
			localStorage.removeItem('isParentPartner');
		}
		(this.props as any).setAnonymousToken(null);
		(this.props as any).setUser(email, name, userType, []);
		(this.props as any).clearPart();
	}

	async redirectForm(accessToken: string, refreshToken: string) {
		if (!accessToken) {
			return;
		}
		this.setToken('', '', '', accessToken, refreshToken, '', '', '');
		const url = '/account/info';
		Helper.getData<any>(url).then((data: any) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.saving, data.Message);
				this.setState({ loading: false });
			} else {
				let email = '';
				if (data.Email) {
					email = data.Email;
				}
				let name = '';
				if (data.FirstName) {
					name = data.FirstName;
				}
				if (data.LastName) {
					name += ' ' + data.LastName;
				}
				let userType = '';
				if (data.PartnerUserType) {
					userType = data.PartnerUserType;
				}

				this.setToken(email, name, userType, accessToken, refreshToken, data.IsUserOem, data.IsUserOemAdmin, data.IsParentPartner);
				if (Helper.userEditor()) {
					if (data.BrandInfo) {
						const brandInfo = data.BrandInfo;
						const footer = data.Footer;
						const logo = (brandInfo.Style && brandInfo.Style.HeaderLogo) || '';
						const logotype = (brandInfo.Style && brandInfo.Style.HeaderLogotype) || '';
						const title = (brandInfo && Helper.getLocalizedName(brandInfo.BrandName)) || '';
						const header = (brandInfo.Style && Helper.getLocalizedName(brandInfo.Style.HeaderText)) || '';

						const color = (brandInfo.Style && brandInfo.Style.BackgroundColor) || '';
						const headerFontColor = (brandInfo.Style && brandInfo.Style.HeaderFontColor) || '';
						const buttonColor = (brandInfo.Style && brandInfo.Style.ButtonColor) || '';
						const buttonFontColor = (brandInfo.Style && brandInfo.Style.ButtonFontColor) || '';

						const theme = {
							logo: logo,
							title: title,
							header: header,
							color: color,
							headerFontColor: headerFontColor,
							buttonColor: buttonColor,
							buttonFontColor: buttonFontColor,
						};
						localStorage.setItem('theme', JSON.stringify(theme));
						localStorage.setItem('brandPartnerId', brandInfo.BrandPartnerId);

						(this.props as any).setBrand(
							logo,
							logotype,
							title,
							header,
							color,
							headerFontColor,
							brandInfo.BrandPartnerId,
							theme.buttonColor,
							theme.buttonFontColor
						);

						const footer1text = (footer && footer.Footer1text) || '';
						const footer1url = (footer && footer.Footer1url) || '';
						const footer2text = (footer && footer.Footer2text) || '';
						const footer2url = (footer && footer.Footer2url) || '';
						const footer3text = (footer && footer.Footer3text) || '';
						const footer3url = (footer && footer.Footer3url) || '';
						(this.props as any).setFooter(footer1text, footer1url, footer2text, footer2url, footer3text, footer3url);
					}
					(this.props as any).setNumberOfBrands(data.NumberOfBrands);
					if (Helper.userInstaller()) {
						this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/allProjects');
					} else {
						this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/myProjects');
					}
				} else {
					this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/selection');
				}
			}
		});
	}

	async submitForm(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault();
		if (this.state.email === '') {
			this.setState({
				loginMessage: locale.loginNoUsername,
				loginError: true,
			});
			return;
		}
		if (this.state.password === '') {
			this.setState({
				loginMessage: locale.loginNoPass,
				loginError: true,
			});
			return;
		}
		this.setState({ loading: true });
		const _this = this;
		const details: { [key: string]: string | any } = {
			username: this.state.email,
			password: this.state.password,
			client_id: process.env.REACT_APP_CLIENT_ID,
			client_secret: process.env.REACT_APP_CLIENT_SECRET,
			grant_type: 'password',
		};

		const formBody = Object.keys(details)
			.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(details[key]))
			.join('&');
		const state: IState = this.state;

		Helper.login(process.env.REACT_APP_TOKEN_URL + '/api/token', formBody).then((result: any) => {
			if (result) {
				if (result.error) {
					state.loginError = true;
					state.loginMessage = locale.loginFailed;
					state.loading = false;
					this.setState(state);
				} else {
					const refreshToken = result.refresh_token;
					const accessToken = result.access_token;
					state.loginError = false;
					state.loading = true;
					this.setState(state);
					_this.redirectForm(accessToken, refreshToken);
				}
			}
		});
	}

	render() {
		const { email, password, loginError, showPassword, loginMessage } = this.state;
		const loginFailedVisible = loginError ? 'block' : 'none';

		return (
			<Fragment>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Container>
						<Row form>
							<Col md={6} className='login'>
								<Form className='form' onSubmit={(e) => this.submitForm(e)}>
									<Fragment>
										<Col>
											<h2>{locale.signIn}</h2>
										</Col>
										<Col className='mt-4'>
											<FormGroup>
												<Label>{locale.email}</Label>
												<Input
													type='email'
													name='email'
													id='email'
													placeholder='myemail@email.com'
													value={email}
													valid={this.state.validate.emailState === 'has-success'}
													invalid={this.state.validate.emailState === 'has-danger'}
													onChange={(e) => {
														this.validateEmail(e);
														this.handleChange(e);
													}}
												/>
											</FormGroup>
										</Col>
										<Col>
											<FormGroup>
												<Label for='examplePassword'>{locale.password}</Label>
												<Input
													type={showPassword ? 'text' : 'password'}
													name='password'
													id='password'
													placeholder='********'
													value={password}
													invalid={this.state.validate.passState === 'has-danger'}
													onChange={(e) => {
														this.validatePass(e);
														this.handleChange(e);
													}}
												/>
												{this.state.validate.passState !== 'has-danger' ? (
													<span
														onMouseDown={() =>
															this.setState({
																showPassword: true,
															})
														}
														onMouseUp={() =>
															this.setState({
																showPassword: false,
															})
														}
														className='fa fa-fw fa-eye toggle-password'
													></span>
												) : null}

												<FormText>
													<Link to={process.env.REACT_APP_ROOT_PATH + '/Reset'}>{locale.forgotPassword}</Link>
												</FormText>
											</FormGroup>
										</Col>
										<Col className='mt-4'>
											<Button>{locale.submit}</Button>
										</Col>
										<Col>
											<div
												className={'invalid-feedback mt-2'}
												style={{
													display: loginFailedVisible,
												}}
											>
												{loginMessage}
											</div>
										</Col>
									</Fragment>
								</Form>
							</Col>
						</Row>
					</Container>
				)}
			</Fragment>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(Login);
