import React, { Fragment } from 'react';
import { Row, Col, Form, Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../Configurator.css';
import './Admin.css';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import CustomerEditMenu from './CustomerEditMenu';
import Helper, { documentSatus } from '../../Helper';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from '../Button';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	configuration: any;
	document?: any;
}

interface IState {
	documentation: any[];
	loading: boolean;
	canRequest: boolean;
	canRequestSpecial: boolean;
	canLoadSpecial: boolean;
	statusId: string;
	status: string;
	selectedId: string;
	image: any;
	imageName: string;
	confirmationModal: boolean;
	confirmationTechModal: boolean;
}

class CustomerImages extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			loading: false,
			documentation: [],
			canRequest: false,
			canRequestSpecial: false,
			canLoadSpecial: false,
			statusId: '',
			status: '',
			selectedId: '',
			image: null,
			imageName: '',
			confirmationModal: false,
			confirmationTechModal: false,
		};
	}
	getDocumentation() {
		if (!this.props.configuration || !this.props.configuration.documentConfigurationId) {
			return;
		}
		this.setState({ loading: true });
		const state: IState = this.state;
		const url =
			'/document/configuration/' + this.props.configuration.documentConfigurationId + '/documentation?brandPartnerId=' + Helper.brandPartnerId();

		let documentation: any[] = [];
		Helper.getData<any>(url).then((data) => {
			if (data) {
				if (data.Documentation && data.Documentation.length > 0) {
					data.Documentation.forEach((item: any) => {
						if (item.Url) {
							documentation.push({
								id: item.ConfigurationDocumentationId,
								url: item.Url,
								name: item.FileName,
								userAdded: item.UserModified,
								dateAdded: Helper.dateWithTimeConvert(item.DateModified),
								dateAddedDate: item.DateModified,
								userUploaded: item.IsUserUploaded,
								userUploadedString: item.IsUserUploaded ? locale.yes : locale.no,
							});
						}
					});
				}
				if (
					data.DocumentStatus &&
					data.DocumentStatus !== null &&
					data.DocumentStatus.length > 0 &&
					data.Documentation &&
					data.Documentation.length === 0
				) {
					state.loading = true;
				}
				state.canRequest = data.CanRequestDocumentation;
				state.canRequestSpecial = data.CanRequestSpecialDocumentation;
				state.canLoadSpecial = data.CanLoadSpecialDocumentation;
				state.statusId = data.DocumentStatusId;
				state.status = Helper.getLocalizedName(data.DocumentStatus);
				this.props.configuration.canEdit = data.CanEdit;
			}
			state.documentation = documentation;
			state.selectedId = '';
			this.setState(state);
			this.setState({ loading: false });
		});
	}
	componentDidMount() {
		this.getDocumentation();
	}
	delete() {
		const urlConfiguration =
			'/document/configuration/' +
			this.props.configuration.documentConfigurationId +
			'/documentation/' +
			this.state.selectedId +
			'?brandPartnerId=' +
			Helper.brandPartnerId();
		Helper.deleteData<any>(urlConfiguration)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.deleting, data.Message);
				} else {
					this.getDocumentation();
				}
			})
			.catch(() => (this.props as any).setError(locale.deleting, locale.generalException));
	}
	toggleConfirmationModal(id?: string) {
		const state: IState = this.state;
		state.confirmationModal = !state.confirmationModal;
		state.selectedId = id ? id : '';
		this.setState(state);
	}
	toggleConfirmationTechModal(id?: string) {
		const state: IState = this.state;
		state.confirmationTechModal = !state.confirmationTechModal;
		this.setState(state);
	}
	confirmationDelete() {
		this.delete();
		this.setState({ confirmationModal: false, selectedId: '' });
	}
	confirmationTech() {
		this.requestDocumentation();
		this.setState({ confirmationTechModal: false });
	}
	requestDocumentationConfirmation() {
		if (!this.props.configuration || !this.props.configuration.documentConfigurationId) {
			return;
		}

		if (this.state.statusId === documentSatus.documentstatus_specialtechnicaldocumentationgenerated) {
			this.toggleConfirmationTechModal();
		} else {
			this.requestDocumentation();
		}
	}
	requestDocumentation() {
		this.setState({ loading: true });
		let url =
			'/config/generateDocumentation?documentConfigurationId=' +
			this.props.configuration.documentConfigurationId +
			'&brandPartnerId=' +
			Helper.brandPartnerId();

		Helper.postData<any>(url, '').then((data) => {
			if (data) {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.requestDocumentation, data.Message);
				} else {
					if (data.StatusName) {
						this.setState({
							canRequest: false,
							canRequestSpecial: false,
							canLoadSpecial: false,
							status: Helper.getLocalizedName(data.StatusName),
						});
					}
				}
			}
			this.setState({ loading: false });
		});
	}
	requestSpecialDocumentation() {
		if (!this.props.configuration || !this.props.configuration.documentConfigurationId) {
			return;
		}
		this.setState({ loading: true });

		const url = '/config/requestSpecialDocumentation';
		const body = {
			DocumentConfigurationId: this.props.configuration.documentConfigurationId,
			BrandPartnerId: Helper.brandPartnerId(),
			WebUrl: window.location.protocol + '//' + window.location.host + process.env.REACT_APP_ROOT_PATH + '/configurator/configuration?id=',
		};
		Helper.postData<any>(url, JSON.stringify(body)).then((data) => {
			if (data) {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.requestDocumentation, data.Message);
				} else {
					if (data.StatusName) {
						this.setState({
							canRequest: false,
							canRequestSpecial: false,
							canLoadSpecial: false,
							status: Helper.getLocalizedName(data.StatusName),
						});
					}
				}
			}
			this.setState({ loading: false });
		});
	}
	loadSpecialDocumentation() {
		if (!this.props.configuration || !this.props.configuration.documentConfigurationId) {
			return;
		}
		this.setState({ loading: true });

		const url = '/config/loadSpecialDocumentation';
		const body = {
			DocumentConfigurationId: this.props.configuration.documentConfigurationId,
			BrandPartnerId: Helper.brandPartnerId(),
			WebUrl: window.location.protocol + '//' + window.location.host + process.env.REACT_APP_ROOT_PATH + '/configurator/configuration?id=',
		};
		Helper.postData<any>(url, JSON.stringify(body)).then((data) => {
			if (data) {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.requestDocumentation, data.Message);
				} else {
					if (data.StatusName) {
						this.setState({
							canRequest: false,
							canRequestSpecial: false,
							canLoadSpecial: false,
							status: Helper.getLocalizedName(data.StatusName),
						});
					}
				}
			}
			this.setState({ loading: false });
		});
	}
	uploadFile() {
		(this.refs.fileUploader as HTMLElement).click();
	}
	onImageChange(event: any) {
		if (event.target.files && event.target.files[0]) {
			const fileName = event.target.files[0].name;
			let reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e: any) => {
				this.saveDocument(e.target.result.split(',')[1], fileName);
			};
		}
	}
	saveDocument(file: any, fileName: string) {
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId + '/documentation';
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Base64File: file,
			FileName: fileName,
		};
		Helper.postData<any>(url, JSON.stringify(body))
			.then((data) => {
				if (data) {
					this.setState({
						image: null,
						imageName: '',
					});
					this.getDocumentation();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	render() {
		const options = {
			pageSize: 5,
			noDataText: locale.noResults,
		};
		const disableEditDocument =
			(this.props.document && this.props.document.disableEdit) || !(this.props.configuration && this.props.configuration.canEdit);
		const canRequest = Helper.userIsOemAdmin() ? this.state.canRequest : this.state.canRequest && !disableEditDocument;

		const linkFormatter = (cell: any, row: any) => {
			if (row.url) {
				return (
					<a target='blank' className='btn cell-button' href={row.url + '?t=' + new Date().getTime()}>
						<i className='fa fa-file-image-o' title={locale.open}></i>
					</a>
				);
			} else {
				return '';
			}
		};
		const cellDelete = (cell: any, row: any) => {
			if (disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleConfirmationModal(row.id)} color='none' className='close float-left cell-button' aria-label='Close'>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};
		const dateSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (new Date(a.dateAddedDate) < new Date(b.dateAddedDate)) return 1;
				if (new Date(a.dateAddedDate) > new Date(b.dateAddedDate)) return -1;
			} else if (order === 'desc') {
				if (new Date(a.dateAddedDate) > new Date(b.dateAddedDate)) return 1;
				if (new Date(a.dateAddedDate) < new Date(b.dateAddedDate)) return -1;
			}
			return 0;
		};
		return (
			<div id='configurator' className='step2 admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background'>
							<Row>
								<Col xs='12' className='mt-3'>
									<Form>
										<Row form>
											<Col className='mb-3 admin-form-border'>
												<Row>
													<Col xs='12'>
														<Button
															color='secondary'
															disabled={!canRequest}
															className='mr-3 mb-2 mt-2 mb-sm-0'
															onClick={() => this.requestDocumentationConfirmation()}
														>
															<i className='fa fa-print pr-2' aria-hidden='true'></i>
															{locale.requestDocumentation}
														</Button>
														{Helper.userIsOem() ? (
															<span>
																<Button
																	color='secondary'
																	disabled={!this.state.canRequestSpecial || disableEditDocument}
																	className='mr-3 mb-2 mt-2 mb-sm-0'
																	onClick={() => this.requestSpecialDocumentation()}
																>
																	<i className='fa fa-print pr-2' aria-hidden='true'></i>
																	{locale.requestSpecialDocumentation}
																</Button>
																<Button
																	color='secondary'
																	disabled={!this.state.canLoadSpecial || disableEditDocument}
																	className='mr-3 mb-2 mt-2 mb-sm-0'
																	onClick={() => this.loadSpecialDocumentation()}
																>
																	<i className='fa fa-print pr-2' aria-hidden='true'></i>
																	{locale.loadSpecialDocumentation}
																</Button>
															</span>
														) : null}
													</Col>
												</Row>
												<Row>
													<Col xs='12' className='mt-4'>
														{this.state.status !== '' ? (
															<Fragment>
																<span>{locale.status + ': '}</span>
																<b>{this.state.status}</b>
															</Fragment>
														) : null}
													</Col>
												</Row>
												<Row>
													<Col xs='12' className='mt-4'>
														<Button
															color='secondary'
															onClick={() => this.uploadFile()}
															disabled={disableEditDocument}
															className={'button btn-secondary'}
															style={{
																verticalAlign: 'top',
																backgroundColor: Helper.themeButtonColor(),
																color: Helper.themeButtonFontColor(),
															}}
														>
															{locale.adminDocumentationAdd}
														</Button>
													</Col>
													<Row>
														<Col xs='12' className='mt-4'>
															<input
																type='file'
																onChange={(e) => this.onImageChange(e)}
																id='file'
																ref='fileUploader'
																style={{
																	display: 'none',
																}}
															/>
														</Col>
													</Row>
												</Row>
											</Col>
										</Row>

										<Row>
											<Col className='customer-view pb-3'>
												<BootstrapTable
													data={this.state.documentation}
													options={options}
													striped
													pagination
													keyField='id'
													tableStyle={{
														backgroundColor: 'white',
													}}
												>
													<TableHeaderColumn dataField='id' dataFormat={cellDelete} width='30px'>
														{' '}
													</TableHeaderColumn>

													<TableHeaderColumn dataField='image' dataFormat={linkFormatter} width='40px'>
														{' '}
													</TableHeaderColumn>

													<TableHeaderColumn
														columnTitle
														dataField='name'
														width='350px'
														dataSort
														filter={{
															type: 'TextFilter',
															delay: 500,
															placeholder: 'Filter',
														}}
													>
														{locale.name}
													</TableHeaderColumn>

													<TableHeaderColumn
														columnTitle
														dataField='userAdded'
														filter={{
															type: 'TextFilter',
															delay: 500,
															placeholder: 'Filter',
														}}
														width={'140px'}
														dataSort
													>
														{locale.user}
													</TableHeaderColumn>

													<TableHeaderColumn
														dataField='dateAdded'
														filter={{
															type: 'TextFilter',
															delay: 500,
															placeholder: '',
															style: { opacity: 0 },
														}}
														width={'140px'}
														dataSort
														sortFunc={dateSort}
														dataAlign='right'
														headerAlign='right'
													>
														{locale.date}
													</TableHeaderColumn>
													<TableHeaderColumn
														dataField='userUploadedString'
														width='90px'
														filter={{
															type: 'TextFilter',
															delay: 500,
															placeholder: '',
															style: { opacity: 0 },
														}}
														dataSort
													>
														{locale.userUploaded}
													</TableHeaderColumn>
												</BootstrapTable>
												<Modal size='lg' isOpen={this.state.confirmationModal} toggle={() => this.toggleConfirmationModal()}>
													<ModalHeader toggle={() => this.toggleConfirmationModal()}>{locale.delete}</ModalHeader>
													<ModalBody>
														{locale.confirmDelete}
														<br />
														<br />
														<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationDelete()}>
															{locale.confirm}
														</Button>
														<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
															{locale.cancel}
														</Button>
													</ModalBody>
												</Modal>
												<Modal size='lg' isOpen={this.state.confirmationTechModal} toggle={() => this.toggleConfirmationTechModal()}>
													<ModalHeader toggle={() => this.toggleConfirmationTechModal()}>{locale.requestDocumentation}</ModalHeader>
													<ModalBody>
														{locale.confirmChangeStatusSpecialTechnical}
														<br />
														<br />
														<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationTech()}>
															{locale.confirm}
														</Button>
														<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationTechModal()}>
															{locale.cancel}
														</Button>
													</ModalBody>
												</Modal>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerImages);
