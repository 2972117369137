import React from 'react';
import { Row, Col, Input, Form, Label, FormGroup } from 'reactstrap';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../../Helper';
import CustomerEditMenu from './CustomerEditMenu';
import './Admin.css';
import Button from '../Button';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	document?: any;
}
interface IState {
	Addressing: { value: string; validate: string };
	AddressingD: { value: string; validate: string };
	AddressingR: { value: string; validate: string };
	FirstName: { value: string; validate: string };
	FirstNameD: { value: string; validate: string };
	FirstNameR: { value: string; validate: string };
	LastName: { value: string; validate: string };
	LastNameD: { value: string; validate: string };
	LastNameR: { value: string; validate: string };
	Email: { value: string; validate: string };
	Telephone: { value: string; validate: string };
	TelephoneD: { value: string; validate: string };
	Address: { value: string; validate: string };
	AddressD: { value: string; validate: string };
	AddressR: { value: string; validate: string };
	City: { value: string; validate: string };
	CityD: { value: string; validate: string };
	CityR: { value: string; validate: string };
	Zip: { value: string; validate: string };
	ZipD: { value: string; validate: string };
	ZipR: { value: string; validate: string };
	CountryId: string;
	CountryIdD: string;
	CountryIdR: string;
	Countries: any[];
	CompanyName: { value: string; validate: string };
	CompanyNameD: { value: string; validate: string };
	CompanyNameR: { value: string; validate: string };
	CompanyRegistration: { value: string; validate: string };
	CompanyRegistrationD: { value: string; validate: string };
	CompanyRegistrationR: { value: string; validate: string };
	CompanyVAT: { value: string; validate: string };
	CompanyVATD: { value: string; validate: string };
	CompanyVATR: { value: string; validate: string };
	DeliveryAddress: boolean;
	ReceiptAddress: boolean;
	Enabled: boolean;
	loading: boolean;
}
class CustomerContact extends React.Component<IProps, IState> {
	private timeout: any;

	constructor(props: IProps) {
		super(props);
		this.timeout = 0;
		this.state = {
			Addressing: { value: '', validate: '' },
			AddressingD: { value: '', validate: '' },
			AddressingR: { value: '', validate: '' },
			FirstName: { value: '', validate: '' },
			FirstNameD: { value: '', validate: '' },
			FirstNameR: { value: '', validate: '' },
			LastName: { value: '', validate: '' },
			LastNameD: { value: '', validate: '' },
			LastNameR: { value: '', validate: '' },
			Email: { value: '', validate: '' },
			Telephone: { value: '', validate: '' },
			TelephoneD: { value: '', validate: '' },
			Address: { value: '', validate: '' },
			AddressD: { value: '', validate: '' },
			AddressR: { value: '', validate: '' },
			City: { value: '', validate: '' },
			CityD: { value: '', validate: '' },
			CityR: { value: '', validate: '' },
			Zip: { value: '', validate: '' },
			ZipD: { value: '', validate: '' },
			ZipR: { value: '', validate: '' },
			CountryId: '',
			CountryIdD: '',
			CountryIdR: '',
			Countries: [],
			CompanyName: { value: '', validate: '' },
			CompanyNameD: { value: '', validate: '' },
			CompanyNameR: { value: '', validate: '' },
			CompanyRegistration: { value: '', validate: '' },
			CompanyRegistrationD: { value: '', validate: '' },
			CompanyRegistrationR: { value: '', validate: '' },
			CompanyVAT: { value: '', validate: '' },
			CompanyVATD: { value: '', validate: '' },
			CompanyVATR: { value: '', validate: '' },
			DeliveryAddress: false,
			ReceiptAddress: false,
			Enabled: false,
			loading: false,
		};
	}

	getContactData() {
		const url = '/document/' + this.props.document.documentId + '/contact';

		Helper.getData<any>(url)
			.then((data) => {
				const state: IState = this.state;
				if (data) {
					if (data.ContactData) {
						state.Addressing.value = data.ContactData.Addressing || '';
						state.FirstName.value = data.ContactData.FirstName || '';
						state.LastName.value = data.ContactData.LastName || '';
						state.Email.value = data.ContactData.Email || '';
						state.Telephone.value = data.ContactData.Telephone || '';
						state.Address.value = data.ContactData.Address || '';
						state.City.value = data.ContactData.City || '';
						state.Zip.value = data.ContactData.Zip || '';
						state.CountryId = data.ContactData.CountryId;
						state.CompanyName.value = data.ContactData.CompanyName || '';
						state.CompanyRegistration.value = data.ContactData.CompanyRegistration || '';
						state.CompanyVAT.value = data.ContactData.CompanyVAT || '';
					}
					if (data.DeliveryAddress) {
						state.DeliveryAddress = data.DeliveryAddress;
						if (data.DeliveryAddress === true) {
							state.AddressingD.value = data.DeliveryData.Addressing || '';
							state.FirstNameD.value = data.DeliveryData.FirstName || '';
							state.LastNameD.value = data.DeliveryData.LastName || '';
							state.TelephoneD.value = data.DeliveryData.Telephone || '';
							state.AddressD.value = data.DeliveryData.Address || '';
							state.CityD.value = data.DeliveryData.City || '';
							state.ZipD.value = data.DeliveryData.Zip || '';
							state.CountryIdD = data.DeliveryData.CountryId;
							state.CompanyNameD.value = data.DeliveryData.CompanyName || '';
							state.CompanyRegistrationD.value = data.DeliveryData.CompanyRegistration || '';
							state.CompanyVATD.value = data.DeliveryData.CompanyVAT || '';
						}
					}
					if (data.ReceiptAddress) {
						state.ReceiptAddress = data.ReceiptAddress;
						if (data.ReceiptAddress === true) {
							state.AddressingR.value = data.ReceiptData.Addressing || '';
							state.FirstNameR.value = data.ReceiptData.FirstName || '';
							state.LastNameR.value = data.ReceiptData.LastName || '';
							state.AddressR.value = data.ReceiptData.Address || '';
							state.CityR.value = data.ReceiptData.City || '';
							state.ZipR.value = data.ReceiptData.Zip || '';
							state.CountryIdR = data.ReceiptData.CountryId;
							state.CompanyNameR.value = data.ReceiptData.CompanyName || '';
							state.CompanyRegistrationR.value = data.ReceiptData.CompanyRegistration || '';
							state.CompanyVATR.value = data.ReceiptData.CompanyVAT || '';
						}
					}
					this.setState(state);
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}

	getCountries() {
		this.setState({ loading: true });
		Helper.getData('/selection/countrylist')
			.then((data: any) => {
				let countriesFromApi = data
					.map((country: any) => {
						return { value: country.CountryId, display: Helper.getLocalizedName(country.Name) };
					})
					.sort((a: any, b: any) => a.display.localeCompare(b.display));
				this.setState({
					Countries: [
						{
							value: '',
							display: locale.selectCountry,
						},
					].concat(countriesFromApi),
				});
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}

	componentDidMount() {
		if (!Helper.userAdmin()) {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configurations');
		}
		this.setState({ loading: true });
		this.getContactData();
		this.getCountries();
	}

	handleChange(e: any) {
		const state: any = this.state;
		const { target } = e;
		if (target.type === 'checkbox') {
			state[target.name] = target.checked;
		} else {
			state[target.name].value = target.value;
		}
		state.Enabled = true;
		this.setState(state);
	}
	validateName(e: any) {
		let state = this.state;
		let { validate } = state.FirstName;
		let value = e.target.value;
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (value.length > 0) {
				validate = 'has-success';
			} else {
				validate = 'has-danger';
			}
			state.FirstName.validate = validate;
			this.setState(state);
		}, 700);
	}
	validateEmail(e: any) {
		let state = this.state;
		const emailRex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let { validate } = state.Email;
		let value = e.target.value;
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (emailRex.test(value)) {
				validate = 'has-success';
			} else {
				validate = 'has-danger';
			}
			state.Email.validate = validate;
			this.setState(state);
		}, 700);
	}
	saveContact() {
		this.setState({ loading: true });
		const state: IState = this.state;
		const url = '/document/' + this.props.document.documentId + '/contact';
		const ContactData = {
			Addressing: state['Addressing'].value,
			FirstName: state['FirstName'].value,
			LastName: state['LastName'].value,
			Email: state['Email'].value,
			Telephone: state['Telephone'].value,
			Address: state['Address'].value,
			City: state['City'].value,
			Zip: state['Zip'].value,
			CountryId: state['CountryId'],
			CompanyName: state['CompanyName'].value,
			CompanyRegistration: state['CompanyRegistration'].value,
			CompanyVAT: state['CompanyVAT'].value,
		};
		let DeliveryData;
		if (state['DeliveryAddress']) {
			DeliveryData = {
				Addressing: state['AddressingD'].value,
				FirstName: state['FirstNameD'].value,
				LastName: state['LastNameD'].value,
				Telephone: state['TelephoneD'].value,
				Address: state['AddressD'].value,
				City: state['CityD'].value,
				Zip: state['ZipD'].value,
				CountryId: state['CountryIdD'],
				CompanyName: state['CompanyNameD'].value,
				CompanyRegistration: state['CompanyRegistrationD'].value,
				CompanyVAT: state['CompanyVATD'].value,
			};
		} else {
			DeliveryData = null;
		}
		let ReceiptData;
		if (state['ReceiptAddress']) {
			ReceiptData = {
				Addressing: state['AddressingR'].value,
				FirstName: state['FirstNameR'].value,
				LastName: state['LastNameR'].value,
				Address: state['AddressR'].value,
				City: state['CityR'].value,
				Zip: state['ZipR'].value,
				CountryId: state['CountryIdR'],
				CompanyName: state['CompanyNameR'].value,
				CompanyRegistration: state['CompanyRegistrationR'].value,
				CompanyVAT: state['CompanyVATR'].value,
			};
		} else {
			ReceiptData = null;
		}
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			ContactData: ContactData,
			DeliveryAddress: state['DeliveryAddress'],
			ReceiptAddress: state['ReceiptAddress'],
			DeliveryData: DeliveryData,
			ReceiptData: ReceiptData,
		};

		Helper.postData<any>(url, JSON.stringify(body)).then((saveData) => {
			if (saveData) {
				if (saveData.Message && saveData.Message.length > 0) {
					(this.props as any).setError(locale.save, saveData.Message);
					this.setState({ loading: false });
				} else {
					this.setState(() => this.getContactData());
				}
			}
		});
	}

	render() {
		const {
			Email,
			Addressing,
			AddressingD,
			AddressingR,
			FirstName,
			FirstNameD,
			FirstNameR,
			LastName,
			LastNameD,
			LastNameR,
			Address,
			AddressD,
			AddressR,
			City,
			CityD,
			CityR,
			Zip,
			ZipD,
			ZipR,
			Telephone,
			TelephoneD,
			CompanyName,
			CompanyNameD,
			CompanyNameR,
			CompanyRegistration,
			CompanyRegistrationD,
			CompanyRegistrationR,
			CompanyVAT,
			CompanyVATD,
			CompanyVATR,
			Enabled,
		} = this.state;
		const buttonDisabled =
			!Enabled ||
			FirstName.value === '' ||
			LastName.value === '' ||
			Address.value === '' ||
			City.value === '' ||
			Zip.value === '' ||
			this.state.CountryId === '' ||
			this.state.CountryId === '00000000-0000-0000-0000-000000000000' ||
			Telephone.value === '' ||
			Email.value === '' ||
			Email.validate === 'has-danger' ||
			(this.state.DeliveryAddress &&
				(FirstNameD.value === '' ||
					LastNameD.value === '' ||
					AddressD.value === '' ||
					CityD.value === '' ||
					ZipD.value === '' ||
					this.state.CountryIdD === '' ||
					this.state.CountryIdD === '00000000-0000-0000-0000-000000000000' ||
					TelephoneD.value === '')) ||
			(this.state.ReceiptAddress &&
				(FirstNameR.value === '' ||
					LastNameR.value === '' ||
					AddressR.value === '' ||
					CityR.value === '' ||
					ZipR.value === '' ||
					this.state.CountryIdR === '' ||
					this.state.CountryIdR === '00000000-0000-0000-0000-000000000000'));
		const disableEditDocument = this.props.document && this.props.document.disableEdit;

		return (
			<div id='configurator' className='admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background'>
							<Row>
								<Col xs='12' className='mt-3'>
									<Form>
										<Row form>
											<Col md={12} className='admin-form-border'>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.addressing}</Label>
															<Input
																type='text'
																name='Addressing'
																id='Addressing'
																disabled={disableEditDocument}
																value={Addressing.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.firstName}</Label>
															<Input
																type='text'
																name='FirstName'
																id='FirstName'
																disabled={disableEditDocument}
																value={FirstName.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.lastName}</Label>
															<Input
																type='text'
																name='LastName'
																id='LastName'
																disabled={disableEditDocument}
																value={LastName.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.address}</Label>
															<Input
																type='text'
																name='Address'
																id='Address'
																disabled={disableEditDocument}
																value={Address.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.city}</Label>
															<Input
																type='text'
																name='City'
																id='City'
																disabled={disableEditDocument}
																value={City.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.post}</Label>
															<Input
																type='text'
																name='Zip'
																id='Zip'
																disabled={disableEditDocument}
																value={Zip.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.country}</Label>
															<select
																className='form-control'
																disabled={disableEditDocument}
																value={this.state.CountryId}
																onChange={(e) => this.setState({ CountryId: e.target.value })}
															>
																{this.state.Countries.map((Country) => (
																	<option key={Country.value} value={Country.value}>
																		{Country.display}
																	</option>
																))}
															</select>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.email}</Label>
															<Input
																type='email'
																name='Email'
																id='Email'
																disabled={disableEditDocument}
																value={Email.value}
																valid={Email.validate === 'has-success'}
																invalid={Email.validate === 'has-danger'}
																onChange={(e) => {
																	this.validateEmail(e);
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.telephone}</Label>
															<Input
																type='text'
																name='Telephone'
																id='Telephone'
																disabled={disableEditDocument}
																value={Telephone.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.partner}</Label>
															<Input
																type='text'
																name='CompanyName'
																id='CompanyName'
																disabled={disableEditDocument}
																value={CompanyName.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.companyRegistration}</Label>
															<Input
																type='text'
																name='CompanyRegistration'
																id='CompanyRegistration'
																disabled={disableEditDocument}
																value={CompanyRegistration.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.companyVAT}</Label>
															<Input
																type='text'
																name='CompanyVAT'
																id='CompanyVAT'
																disabled={disableEditDocument}
																value={CompanyVAT.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col xs='12'>
														<h6>{locale.profileDeliveryTitle}</h6>
													</Col>
												</Row>
												<Row form className='contact'>
													<Col md={6} xl={6}>
														<FormGroup>
															<Input
																type='checkbox'
																name='DeliveryAddress'
																checked={this.state.DeliveryAddress}
																id='differentDelivery-checkbox'
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
															<p className='checkbox'>{locale.differentThenContactAddress}</p>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.addressing}</Label>
															<Input
																type='text'
																name='AddressingD'
																id='AddressingD'
																disabled={disableEditDocument}
																value={AddressingD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.firstName}</Label>
															<Input
																type='text'
																name='FirstNameD'
																id='FirstNameD'
																disabled={disableEditDocument}
																value={FirstNameD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.lastName}</Label>
															<Input
																type='text'
																name='LastNameD'
																id='LastNameD'
																disabled={disableEditDocument}
																value={LastNameD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.address}</Label>
															<Input
																type='text'
																name='AddressD'
																id='AddressD'
																disabled={disableEditDocument}
																value={AddressD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.city}</Label>
															<Input
																type='text'
																name='CityD'
																id='CityD'
																disabled={disableEditDocument}
																value={CityD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.post}</Label>
															<Input
																type='text'
																name='ZipD'
																id='ZipD'
																disabled={disableEditDocument}
																value={ZipD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.country}</Label>
															<select
																className='form-control'
																disabled={disableEditDocument}
																value={this.state.CountryIdD}
																onChange={(e) => this.setState({ CountryIdD: e.target.value })}
															>
																{this.state.Countries.map((Country) => (
																	<option key={Country.value} value={Country.value}>
																		{Country.display}
																	</option>
																))}
															</select>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.telephone}</Label>
															<Input
																type='text'
																name='TelephoneD'
																id='TelephoneD'
																disabled={disableEditDocument}
																value={TelephoneD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.partner}</Label>
															<Input
																type='text'
																name='CompanyNameD'
																id='CompanyNameD'
																disabled={disableEditDocument}
																value={CompanyNameD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.DeliveryAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.companyRegistration}</Label>
															<Input
																type='text'
																name='CompanyRegistrationD'
																id='CompanyRegistrationD'
																disabled={disableEditDocument}
																value={CompanyRegistrationD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.companyVAT}</Label>
															<Input
																type='text'
																name='CompanyVATD'
																id='CompanyVATD'
																disabled={disableEditDocument}
																value={CompanyVATD.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form>
													<Col xs='12'>
														<h6>{locale.profileReceiptTitle}</h6>
													</Col>
												</Row>
												<Row form className='contact'>
													<Col md={6} xl={6}>
														<FormGroup>
															<Input
																type='checkbox'
																name='ReceiptAddress'
																checked={this.state.ReceiptAddress}
																id='differentReceipt-checkbox'
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
															<p className='checkbox'>{locale.differentThenContactAddress}</p>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.ReceiptAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.addressing}</Label>
															<Input
																type='text'
																name='AddressingR'
																id='AddressingR'
																disabled={disableEditDocument}
																value={AddressingR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.ReceiptAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.firstName}</Label>
															<Input
																type='text'
																name='FirstNameR'
																id='FirstNameR'
																disabled={disableEditDocument}
																value={FirstNameR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.lastName}</Label>
															<Input
																type='text'
																name='LastNameR'
																id='LastNameR'
																disabled={disableEditDocument}
																value={LastNameR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.ReceiptAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.address}</Label>
															<Input
																type='text'
																name='AddressR'
																id='AddressR'
																disabled={disableEditDocument}
																value={AddressR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.city}</Label>
															<Input
																type='text'
																name='CityR'
																id='CityR'
																disabled={disableEditDocument}
																value={CityR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.ReceiptAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.post}</Label>
															<Input
																type='text'
																name='ZipR'
																id='ZipR'
																disabled={disableEditDocument}
																value={ZipR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label className='required'>{locale.country}</Label>
															<select
																className='form-control'
																disabled={disableEditDocument}
																value={this.state.CountryIdR}
																onChange={(e) => this.setState({ CountryIdR: e.target.value })}
															>
																{this.state.Countries.map((Country) => (
																	<option key={Country.value} value={Country.value}>
																		{Country.display}
																	</option>
																))}
															</select>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.ReceiptAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.partner}</Label>
															<Input
																type='text'
																name='CompanyNameR'
																id='CompanyNameR'
																disabled={disableEditDocument}
																value={CompanyNameR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row form hidden={!this.state.ReceiptAddress}>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.companyRegistration}</Label>
															<Input
																type='text'
																name='CompanyRegistrationR'
																id='CompanyRegistrationR'
																disabled={disableEditDocument}
																value={CompanyRegistrationR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
													<Col md={6} xl={6}>
														<FormGroup>
															<Label>{locale.companyVAT}</Label>
															<Input
																type='text'
																name='CompanyVATR'
																id='CompanyVATR'
																disabled={disableEditDocument}
																value={CompanyVATR.value}
																onChange={(e) => {
																	this.handleChange(e);
																}}
															/>
														</FormGroup>
													</Col>
												</Row>
												<Row>
													<Col className='my-2 ml-1'>
														<Button className={'button btn-secondary'} disabled={buttonDisabled} onClick={() => this.saveContact()}>
															{locale.save}
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerContact);
