import React from 'react';
import { Row, Col, Input, Form, Label, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../Configurator.css';
import './Admin.css';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../../Helper';
import CustomerEditMenu from './CustomerEditMenu';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import salesUser from '../../images/salesUser.png';
import Button from '../Button';
import Loading from 'react-fullscreen-loading';
import DatePicker from 'react-date-picker';

interface IProps extends RouteComponentProps<any> {
	remarksType: string; //montage, sales
	configuration: any;
	document?: any;
	theme?: any;
}
interface IState {
	selectedId: string;
	attachments: any;
	image: any;
	icon: any;
	imageName: string;
	comment: { value: string; validate: string };
	loading: boolean;
	confirmationModal: boolean;
	customerPosition: { value: string; validate: string };
	customerSubPosition: { value: string; validate: string };
	deliveryDate: any;
	commission: { value: string; validate: string };
}
const validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.png'];
class CustomerRemarks extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedId: '',
			attachments: [],
			icon: null,
			image: null,
			imageName: '',
			comment: { value: '', validate: '' },
			loading: false,
			confirmationModal: false,
			customerPosition: { value: '', validate: '' },
			customerSubPosition: { value: '', validate: '' },
			deliveryDate: null,
			commission: { value: '', validate: '' },
		};
	}
	attachmentData(data: any): any {
		let attachments: any = [];
		if (data && data.length > 0) {
			data.forEach((item: any) => {
				let comment = '';
				let icon = salesUser;

				if (this.props.remarksType === 'sales') {
					comment = item.SalesComment;
				} else {
					comment = item.InstallerComment;
				}
				attachments.push({
					id: item.SalesDocumentConfigurationCommentId,
					icon: icon,
					image: item.CommentImageUrl,
					imageName: item.CommentImageName,
					comment: comment,
					date: Helper.dateWithTimeConvert(item.Date),
					dateDate: item.Date,
					user: item.User,
					delete: item.CanDelete,
				});
			});
		}
		return attachments;
	}
	getComments() {
		if (!this.props.configuration.documentConfigurationId || this.props.remarksType === 'position') {
			this.setState({ loading: false });
			return;
		}
		const state: IState = this.state;
		const url =
			this.props.remarksType === 'sales'
				? '/document/configuration/' + this.props.configuration.documentConfigurationId + '/notes?type=0'
				: '/document/configuration/' + this.props.configuration.documentConfigurationId + '/notes?type=1';

		Helper.getData<any>(url)
			.then((data) => {
				let items = this.attachmentData(data);
				state.attachments = items;
				state.comment = { value: '', validate: '' };
				state.image = null;
				state.imageName = '';
				state.loading = false;
				this.setState(state);
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	getDocumentConfigurationData() {
		if (!this.props.configuration.documentConfigurationId || this.props.remarksType !== 'position') {
			this.setState({ loading: false });
			return;
		}
		const state: IState = this.state;
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId;

		Helper.getData<any>(url)
			.then((data) => {
				state.customerPosition.value = data.CustomerPosition || '';
				state.customerSubPosition.value = data.CustomerSubPosition || '';
				state.commission.value = data.Commission || '';
				state.comment.value = data.Comment || ''; // TODO: remove
				let date: any = null;
				if (data.DeliveryDate) {
					date = new Date(data.DeliveryDate);
					date.setTime(date.getTime() + 60 * 60 * 1000 * 12);
				}
				state.deliveryDate = date;
				this.setState(state);
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	componentDidMount() {
		this.setState({ loading: true });
		this.getComments();
		this.getDocumentConfigurationData();
	}
	uploadFile() {
		(this.refs.fileUploader as HTMLElement).click();
	}
	onImageChange(event: any) {
		if (event.target.files && event.target.files[0]) {
			const fileName = event.target.files[0].name;
			let isImage = false;
			for (let i = 0; i < validFileExtensions.length; i++) {
				let ext = validFileExtensions[i];
				if (fileName.substr(fileName.length - ext.length, ext.length).toLowerCase() === ext.toLowerCase()) {
					isImage = true;
					break;
				}
			}
			if (isImage) {
				const reader = new FileReader();
				reader.readAsDataURL(event.target.files[0]);
				reader.onload = (e: any) => {
					this.setState({
						image: e.target.result,
						imageName: fileName,
					});
				};
			} else {
				(this.props as any).setError(locale.imageUpload, locale.notImage);
			}
		}
	}
	saveRemark() {
		this.setState({ loading: true });
		let url = '/document/configuration/' + this.props.configuration.documentConfigurationId;
		const state: IState = this.state;
		if (!state.comment.value) {
			this.setState(state);
			return;
		}

		if (this.props.remarksType === 'sales') {
			url = url + '/notes?type=0';
		} else {
			url = url + '/notes?type=1';
		}
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Comment: state.comment.value,
			FileBase64: state.image,
			FileName: state.imageName,
		};
		Helper.postData<any>(url, JSON.stringify(body))
			.then((saveData) => {
				if (saveData) {
					this.setState({
						image: null,
						imageName: '',
						comment: { value: '', validate: '' },
					});
					this.getComments();
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	handleChange(e: any) {
		const state: any = this.state;
		const { target } = e;
		const value = target.value;
		state[target.name].value = value;
		state.Saved = false;
		this.setState(state);
	}
	setDeliverDate(date: any) {
		const state: any = this.state;
		if (date !== null) date.setTime(date.getTime() + 60 * 60 * 1000 * 12);
		state.deliveryDate = date;
		this.setState(state);
	}
	removeRemark() {
		const url =
			'/document/configuration/' +
			this.props.configuration.documentConfigurationId +
			'/notes/' +
			this.state.selectedId +
			'?brandPartnerId=' +
			Helper.brandPartnerId();
		this.setState({ selectedId: '' });
		Helper.deleteData<any>(url)
			.then((saveData) => {
				if (saveData.Message && saveData.Message.length > 0) {
					(this.props as any).setError(locale.deleting, saveData.Message);
				} else {
					this.getComments();
				}
				this.setState({ loading: false });
			})
			.catch(() => {
				(this.props as any).setError(locale.deleting, locale.generalException);
				this.setState({ loading: false });
			});
	}
	toggleConfirmationModal(id?: string) {
		const state: IState = this.state;
		state.confirmationModal = !state.confirmationModal;
		state.selectedId = id ? id : '';
		this.setState(state);
	}
	confirmationDelete() {
		this.setState({ loading: true });
		this.removeRemark();
		this.setState({ confirmationModal: false });
	}
	buttonSaveClick() {
		this.setState({ loading: true }, () => this.saveDocumentConfiguration());
	}
	saveDocumentConfiguration() {
		const url = '/document/configuration/' + this.props.configuration.documentConfigurationId;

		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			CustomerPosition: this.state.customerPosition.value,
			CustomerSubPosition: this.state.customerSubPosition.value,
			DeliveryDate: this.state.deliveryDate,
			Commission: this.state.commission.value,
		};
		Helper.putData<any>(url, JSON.stringify(body)).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.setState({ loading: false });
		});
	}
	render() {
		const { attachments } = this.state;
		const state: IState = this.state;
		const options = {
			pageSize: 5,
			noDataText: locale.noResults,
		};

		let linkFormatter = (cell: any, row: any) => {
			if (row.imageName && row.imageName !== null) {
				return (
					<a target='blank' className='btn cell-button' href={cell}>
						<i className='fa fa-file-image-o' title={locale.open}></i>
					</a>
				);
			} else {
				return '';
			}
		};
		let iconFormatter = (cell: any) => {
			return `<img style='height:20px' src='${cell}' />`;
		};
		const cellDelete = (cell: any, row: any) => {
			if (!row.delete || disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleConfirmationModal(row.id)} color='none' className='close float-left cell-button' aria-label='Close'>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};
		const dateSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (new Date(a.dateDate) < new Date(b.dateDate)) return 1;
				if (new Date(a.dateDate) > new Date(b.dateDate)) return -1;
			} else if (order === 'desc') {
				if (new Date(a.dateDate) > new Date(b.dateDate)) return 1;
				if (new Date(a.dateDate) < new Date(b.dateDate)) return -1;
			}
			return 0;
		};
		const multilineCell = (cell: any, row: any) => {
			if (cell) {
				return `<textarea class='form-control cell' rows='3' disabled>${cell}</textarea>`;
			} else {
				return '';
			}
		};
		const disableEditDocument =
			(this.props.document && this.props.document.disableEdit) || !(this.props.configuration && this.props.configuration.canEdit);
		const displayDetails = this.props.remarksType === 'position' ? 'block' : 'none';
		const displayComment = this.props.remarksType === 'position' ? 'none' : 'block';

		// Only installer can add new 'Instaler' comments
		const displayCommentAdd =
			(this.props.remarksType === 'monter' && Helper.userInstaller()) || (this.props.remarksType === 'sales' && Helper.userAdmin())
				? 'block'
				: 'none';

		const disabledSave = state.comment.value === '';

		return (
			<div id='configurator' className='admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background'>
							<Row form style={{ display: displayDetails }}>
								<Col md={12} className='admin-form-border mt-3'>
									<Row form>
										<Col md={3}>
											<FormGroup>
												<Label for='CustomerPosition'>{locale.adminDocumentCustomerPosition}</Label>
												<Input
													type='text'
													name='customerPosition'
													id='customerPosition'
													maxLength={255}
													disabled={disableEditDocument}
													value={state.customerPosition.value}
													valid={state.customerPosition.validate === 'has-success'}
													invalid={state.customerPosition.validate === 'has-danger'}
													onChange={(e) => {
														this.handleChange(e);
													}}
												/>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label for='CustomerSubPosition'>{locale.adminDocumentCustomerSubPosition}</Label>
												<Input
													type='text'
													name='customerSubPosition'
													id='customerSubPosition'
													maxLength={255}
													disabled={disableEditDocument}
													value={state.customerSubPosition.value}
													valid={state.customerSubPosition.validate === 'has-success'}
													invalid={state.customerSubPosition.validate === 'has-danger'}
													onChange={(e) => {
														this.handleChange(e);
													}}
												/>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label for='DeliveryDate'>{locale.adminDocumentDeliveryTime}</Label>
												<DatePicker
													name='deliveryDate'
													className='form-control'
													disabled={disableEditDocument}
													value={state.deliveryDate}
													locale={Helper.userLang()}
													onChange={(e: any) => {
														this.setDeliverDate(e);
													}}
												/>
											</FormGroup>
										</Col>
										<Col md={2}>
											<FormGroup>
												<Label
													style={{
														opacity: 0,
													}}
												>
													Save
												</Label>
												<Button disabled={disableEditDocument} onClick={() => this.buttonSaveClick()}>
													{locale.save}
												</Button>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={3}>
											<FormGroup>
												<Label for='Commission'>{locale.adminDocumentCommission}</Label>
												<Input
													type='text'
													name='commission'
													id='commission'
													maxLength={255}
													disabled={disableEditDocument}
													value={state.commission.value}
													valid={state.commission.validate === 'has-success'}
													invalid={state.commission.validate === 'has-danger'}
													onChange={(e) => {
														this.handleChange(e);
													}}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={9}>
											<FormGroup>
												<Label for='Commission'>{locale.comment}</Label>
												<Input type='textarea' name='comment' id='comment' maxLength={255} disabled={true} value={state.comment.value} />
											</FormGroup>
										</Col>
									</Row>
								</Col>
							</Row>
							<Row style={{ display: displayComment }}>
								<Col xs='12' className='mt-3'>
									<Form style={{ display: displayCommentAdd }}>
										<Row form>
											<Col md={6} className='mb-3 admin-form-border'>
												<FormGroup>
													<Label for='comment'>{locale.adminRemark}</Label>
													<Input
														type='textarea'
														name='comment'
														disabled={disableEditDocument}
														onChange={(e) => this.handleChange(e)}
														value={this.state.comment.value}
														style={{ height: '20vh' }}
														id='comment'
													/>
												</FormGroup>

												<Button
													onClick={() => this.uploadFile()}
													disabled={disableEditDocument}
													className={'button btn-secondary'}
													style={{ verticalAlign: 'top', backgroundColor: Helper.themeButtonColor(), color: Helper.themeButtonFontColor() }}
												>
													{locale.adminRemarkAddImage}
												</Button>
												<Button
													onClick={() => this.saveRemark()}
													disabled={disableEditDocument || disabledSave}
													className={'button btn-secondary ml-2'}
													style={{ verticalAlign: 'top', backgroundColor: Helper.themeButtonColor(), color: Helper.themeButtonFontColor() }}
												>
													{locale.save}
												</Button>
											</Col>
											<Col md={6} className='mb-3 admin-form-border'>
												<input type='file' onChange={(e) => this.onImageChange(e)} id='file' ref='fileUploader' style={{ display: 'none' }} />

												<img
													id='target'
													style={{
														maxWidth: '100%',
														maxHeight: '50vh',
													}}
													src={this.state.image}
													alt=''
												/>
											</Col>
										</Row>
									</Form>
									<Row>
										<Col className='customer-view pb-3'>
											<BootstrapTable
												data={attachments}
												options={options}
												striped
												pagination
												keyField='id'
												tableStyle={{
													backgroundColor: 'white',
												}}
											>
												<TableHeaderColumn
													hidden
													dataField='id'
													filter={{
														type: 'TextFilter',
														placeholder: '',
														style: { opacity: 0 },
													}}
													dataSort
												>
													ID
												</TableHeaderColumn>

												<TableHeaderColumn
													hidden
													dataField='imageName'
													filter={{
														type: 'TextFilter',
														placeholder: '',
														style: { opacity: 0 },
													}}
													dataSort
												>
													imageName
												</TableHeaderColumn>

												<TableHeaderColumn dataField='id' dataFormat={cellDelete} width='30px'>
													{' '}
												</TableHeaderColumn>

												<TableHeaderColumn dataField='image' dataFormat={linkFormatter} width='30px'>
													{' '}
												</TableHeaderColumn>

												<TableHeaderColumn
													dataField='icon'
													filter={{
														type: 'TextFilter',
														placeholder: '',
														style: { opacity: 0 },
													}}
													dataSort
													dataFormat={iconFormatter}
													width={'30px'}
												>
													{' '}
												</TableHeaderColumn>

												<TableHeaderColumn
													dataField='comment'
													width='300px'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: 'Filter',
													}}
													dataFormat={multilineCell}
													dataSort
												>
													{locale.adminRemark}
												</TableHeaderColumn>

												<TableHeaderColumn
													columnTitle
													dataField='image'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: 'Filter',
													}}
													width={'350px'}
													dataSort
													hidden
												>
													{locale.image}
												</TableHeaderColumn>

												<TableHeaderColumn
													columnTitle
													dataField='user'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: 'Filter',
													}}
													width={'140px'}
													dataSort
												>
													{locale.user}
												</TableHeaderColumn>

												<TableHeaderColumn
													dataField='date'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: '',
														style: { opacity: 0 },
													}}
													width={'140px'}
													dataSort
													sortFunc={dateSort}
													dataAlign='right'
													headerAlign='right'
												>
													{locale.date}
												</TableHeaderColumn>
											</BootstrapTable>
											<Modal size='lg' isOpen={this.state.confirmationModal} toggle={() => this.toggleConfirmationModal()}>
												<ModalHeader toggle={() => this.toggleConfirmationModal()}>{locale.delete}</ModalHeader>
												<ModalBody>
													{locale.confirmDelete}
													<br />
													<br />
													<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationDelete()}>
														{locale.confirm}
													</Button>
													<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
														{locale.cancel}
													</Button>
												</ModalBody>
											</Modal>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerRemarks);
