const locale = {
	popular: 'Najbolj priljubljeni',
	searchResults: 'Iskalni rezultati',
	price: 'Cena',
	amount: 'Znesek',
	model: 'Model',
	name: 'Naziv',
	company: 'Podjetje',
	save: 'Shrani',
	filterTypeName: 'Tip',
	filterGroupName: 'Stil',
	filtersClear: 'Počisti filtre',
	filters: 'Filtri',
	searchPlaceholder: 'Iskanje...',
	configurationStep1: 'Izbira modela',
	configurationStep2: 'Konfiguracija',
	configurationStep3: 'Pregled konfiguracije',
	configurationStepCustomerView: 'Pregled povpraševanj',
	select: 'Odpri',
	quantity: 'Količina',
	overview: 'Predogled',
	version: 'Verzija',
	modelAndConfig: 'Model / ID konfig.',
	status: 'Status',
	noResults: 'Ni rezultatov',
	noData: 'Ni podatkov',
	next: 'Naprej',
	prev: 'Nazaj',
	nextPage: 'Nadaljuj',
	sendInquiry: 'Pošlji povpraševanje',
	step: 'Korak',
	of: 'od',
	comment: 'Komentar',
	clearConfiguration: 'Ponastavi konfiguracijo',
	cancel: 'Prekliči',
	continue: 'Nadaljuj',
	close: 'Zapri',
	exception: 'Napaka',
	generalException: 'Ups, prišlo je do napake.',
	apiException: 'Napaka na strežniku',
	myProjects: 'Moji projekti',
	allProjects: 'Vsi projekti',
	assignDocument: 'Dodeli (interno)',
	assignDocumentWithinCompany: 'Dodeli znotraj podjetja',
	assignDocumentOutsideCompany: 'Dodeli izven podjetja',
	assignDocumentToContactTitle: 'Stik',
	assignDocumentToContactAddNew: 'Dodaj nov stik',
	assignDocumentToContactConfirmation: 'Ali ste prepričani, da želite zamenjati kontakt?',
	sendDocument: 'Pošlji partnerju',
	toggleProjectStatus: 'Projekt',
	unknownUser: 'Neimenovan',
	saveConfiguration: 'Dodaj v košarico',
	saveConfigurationToDocument: 'Shrani',
	addConfigurationToDocument: 'Dodaj v dokument',
	customerNewOffer: 'Ustvari dokument',
	customerProcess: 'Obdelaj',
	customerSendERP: 'Izvoz v ERP',
	customerImportEdi: 'Uvoz iz ERP',
	customerNewVersion: 'Ponastavi konfiguracijo',
	customerAssign: 'Dodeli osebi',
	customerConfirmOrder: 'Potrdi PN',
	customerConfirmOrderMessage:
		'S to potrditvijo bo naročilo (dokument št. {number}) poslano v izdelavo. S potrditvijo sprejemam s tem povezane finančne obveznosti (obveznost za plačilo).',
	customerConfirmInqury: 'Ustvari ponudbo',
	customerConfirmInquryNewProject: 'Ustvari v novem projektu',
	customerSendToConfirm: 'Pošlji v odobritev',
	customerSend: 'Pošlji',
	customerForward: 'Posreduj',
	customerSendOffer: 'Pošlji dokument',
	customerSendDocument: 'Pošlji dokument',
	customerDelete: 'Storno',
	customerDeleteConfirm: 'Ali ste prepričani, da želite stornirati izbran zapis?',
	configurator: 'Konfigurator',
	open: 'Odpri',
	customerEditingTitleInquiry: 'Urejanje povpraševanja',
	customerEditingTitleOffer: 'Urejanje ponudbe',
	documentEditing: 'Dokument',
	configurationEditing: 'Konfiguracija',
	adminCalculationDiscounts: 'Popusti',
	adminCalculationAddDiscount: 'Dodaj popust',
	adminCalculationAdditional: 'Dodatki',
	adminCalculationCostsNet: 'Storitve',
	adminCalculationAdditionalCosts: 'Dodatni stroški',
	adminCalculationAddAdditionalCost: 'Dodaj dodatek',
	adminCalculationAddNetCost: 'Dodaj storitev',
	adminCalculationConfigurationPrice: 'Cena konfiguracije',
	adminCalculationAdditionalSum: 'Skupna vrednost dodatkov',
	adminCalculationConfigurationWithAdditionalSum: 'Cena konfiguracije z dodatki',
	adminCalculationDiscountPercentageSum: 'Skupen odstotek popusta',
	adminCalculationDiscountSum: 'Skupna vrednost popusta',
	adminCalculationConfigurationWithDiscountSum: 'Cena konfiguracije z dodatki in popusti',
	adminCalculationServiceSum: 'Skupna vrednost storitev',
	adminCalculationSum: 'Skupna cena',
	adminRemark: 'Opomba',
	adminRemarkAddImage: 'Dodaj sliko',
	adminComment: 'Komentar',
	adminDocumentationAdd: 'Naloži prilogo',
	adminDocumentCustomer: 'Kupec',
	adminDocumentContact: 'Stik',
	adminDocumentPartner: 'Odgovorni partner',
	adminDocumentPartnerRecipient: 'Partner',
	adminDocumentDateModified: 'Datum zadnje spremembe',
	adminDocumentStatus: 'Status dokumenta',
	adminDocumentDocumentNumber: 'Številka dokumenta',
	adminDocumentProject: 'Projekt',
	adminDocumentProjectStatus: 'Status projekta',
	adminDocumentDocumentOrder: 'Zap.št.',
	adminDocumentBuyerOrderNumber: 'Št. naročila',
	adminDocumentComission: 'Komision',
	adminDocumentCustomerNumber: 'Št. naročila',
	adminDocumentCustomerPosition: 'Pozicija kupca',
	adminDocumentCustomerSubPosition: 'Subpozicija kupca',
	adminDocumentCommission: 'Komision',
	adminDocumentDeliveryTime: 'Dobavni rok',
	adminMenuDocument: 'Podatki dokumenta',
	adminMenuConfigurations: 'Konfiguracije',
	adminMenuHistory: 'Zgodovina',
	adminMenuConfiguration: 'Konfiguracija',
	adminMenuCalculation: 'Pregled konfiguracije',
	adminMenuComment: 'Komentar stranke',
	adminMenuImages: 'Slike',
	adminMenuSalesRemarks: 'Prodajne opombe',
	adminMenuInstallerRemarks: 'Monter',
	adminMenuPosition: 'Podatki pozicije',
	adminMenuDiscountsCosts: 'Popust, storitve, davek',
	adminMenuTechnicalDocumentation: 'Tehnična dokumentacija',
	adminMenuPrintDocument: 'Natisni in pošlji',
	insideView: 'Pogled znotraj',
	facade: 'Fasada',
	cartSuccess: 'S klikom na ikono košarice lahko uredite njeno vsebino.',
	saving: 'Shranjujem',
	contactTitle: 'Kontaktni podatki in soglasja',
	contactTitleSales: 'Kontaktni podatki',
	inquirySalesDialog: 'Dokument',
	cart: 'Košarica',
	cartSendInquiry: 'Pošlji povpraševanje',
	cartRemove: 'Odstrani',
	cartInquirySentText: ', hvala za poslano povpraševanje. Obdelali ga bomo v najkrajšem možnem času.',
	cartInquirySentText2: 'Hvala za poslano povpraševanje. Obdelali ga bomo v najkrajšem možnem času.',
	cartInquirySentText3: 'Dokument uspešno kreiran',
	cartInquirySent: 'Povpraševanje uspešno poslano',
	cartOverride: 'Ali želite prepisati obstoječo konfiguracijo ali kreirati novo?',
	cartSaving: 'Shranjevanje konfiguracije',
	cartSavingSuccess: 'Uspešno dodano v košarico',
	documentSaving: 'Shranjevanje dokumenta',
	cartButtonOverride: 'Prepiši obstoječo konfiguracijo',
	cartButtonNew: 'Kreiraj novo konfiguracijo',
	cartRemoveTitle: 'Odstranjevanje konfiguracij',
	cartRemovalText: 'Konfiguracije so bile odstranjene.',
	addDiscount: 'Popust',
	addCost: 'Dodatek',
	addNetCost: 'Storitev',
	discountText: 'Naziv',
	discountValue: 'Odstotek (%)',
	discountLevel: 'Nivo',
	discountType: 'Tip popusta',
	discount: 'Odstotek',
	sortOrder: 'Vrstni red',
	discountAmount: 'Vrednost popusta',
	discountValueAfter: 'Vrednost po popustu',
	costText: 'Naziv',
	costValue: 'Vrednost',
	requestDocumentation: 'Zahtevaj dokumentacijo',
	requestSpecialDocumentation: 'Zahtevaj posebno dokumentacijo',
	loadSpecialDocumentation: 'Naloži posebno dokumentacijo',
	user: 'Uporabnik',
	image: 'Slika',
	date: 'Datum',
	customer: 'Stranka',
	partner: 'Partner',
	commission: 'Komision',
	responsiblePerson: 'Odgovorna oseba',
	position: 'Pozicija',
	value: 'Vrednost',
	dataLastChange: 'Datum spr.',
	documentation: 'Dokumentacija',
	inquiries: 'Povpraševanja',
	offers: 'Ponudbe',
	printOffer: 'Natisni dokument',
	printDownload: 'Prenesi dokumente',
	printChangeToSend: 'Spremeni v Poslano',
	printEditSent: 'Uredi e-pošto',
	printEmailSubject: 'Varialis - {number}',
	printEmailBody:
		'Spoštovani,\n\n\nV prilogi lahko najdete ponudbo št. {number}, pripravljeno na osnovi vašega povpraševanja.\n\nLep pozdrav,\nVARIALIS portal\n\n\nOpozorilo: Elektronska pošta lahko vsebuje informacije zaupne narave, namenjene samo naslovniku. Če je bilo zaradi napake v naslovu ali pri prenosu sporočilo poslano drugam, prosimo, da o tem obvestite avtorja elektronskega sporočila. Če sporočilo ni bilo namenjeno vam, informacij v sporočilu ne smete uporabljati, razkriti, širiti, kopirati, tiskati ali kakorkoli uporabiti.',
	email: 'E-naslov',
	password: 'Geslo',
	signIn: 'Prijava',
	signUp: 'Registracija',
	profile: 'Profil',
	signingOut: 'Odjavljam',
	salesman: 'Prodajalec',
	salesmanResponsible: 'Odgovorni prodajalec',
	signOut: 'Odjava',
	submit: 'Potrdi',
	loginSuccess: '',
	loginError: 'Prijava ni uspela.',
	addressing: 'Nagovor',
	firstName: 'Ime',
	firstAndLastName: 'Ime in priimek',
	lastName: 'Priimek',
	loginFailed: 'Prijava ni uspela',
	loginNoPass: 'Vnesite geslo',
	loginNoUsername: 'Vnesite vaš e-naslov',
	forgotPassword: 'Pozabljeno geslo?',
	resetPassword: 'Sprememba gesla',
	resetPasswordMessage: 'Potrditvena e-pošta je bila poslana na vpisani e-naslov. Za nadaljevanje preverite vaš nabiralnik.',
	registrationFailed: 'Registracija ni uspela',
	missingFields: 'Prosimo vnesite vse obvezne podatke',
	registrationSucceeded: 'Hvala za registracijo. Na vpisani e-naslov ste prejeli sporočilo za dokončanje postopka.',
	profileUpdateSucceeded: 'Vaš profil je bil posodobljen.',
	profileDeliveryTitle: 'Naslov za dostavo',
	profileReceiptTitle: 'Naslov za račun',
	profileAddressAdd: 'Dodaj naslov',
	profileAddressEdit: 'Uredi naslov',
	differentThenContactAddress: 'Različen od kontaktnega naslova',
	activationSucceeded: 'Aktivacija uspešna',
	accountActivation: 'Aktivacija',
	confirmPassword: 'Ponovi geslo',
	activate: 'Aktiviraj',
	missingToken: 'Aktivacija računa ni uspela. Poskusite se registrirati ponovno.',
	telephone: 'Telefon',
	vat: 'Davčna številka',
	registrationNumber: 'Matična številka',
	inquiryTitle:
		'Zahvaljujemo se vam za povpraševanje. Za namen obdelave povpraševanja vas prosimo, da izpolnite kontaktne podatke in morebitna soglasja ter pošljete povpraševanje s pritiskom na gumb Pošlji.<br/>S tem soglašate, da vas naše podjetje in naši prodajni partnerji za izpolnitev vaše prošnje o povpraševanju, izdelavo ponudbe ali svetovanjem v zvezi z izdelki kontaktira po elektronski pošti in/ali telefonu. V primeru naročila dovoljujete, da posredujemo vaše podatke preverjenemu strokovnemu osebju za svetovanje in ogled tako, da se lahko naročilo realizira.',
	inquiryTitleCustomer: 'Vpišite kontaktne podatke kupca',
	inquiryTitleExistingCustomer: 'Izberite obstoječi stik',
	inquiryDocumentType: 'Tip dokumenta',
	address: 'Ulica in hišna številka',
	city: 'Mesto',
	zip: 'Poštna številka',
	post: 'Poštna številka',
	country: 'Država',
	selectCountry: 'Izberite državo',
	selectDiscountType: 'Izberite tip popusta',
	companyName: 'Ime podjetja',
	companyRegistration: 'Matična številka podjetja',
	companyVAT: 'ID podjetja za DDV',
	inquiryLegalAdd: 'Dodatno za pravne osebe',
	inquiryConsents: 'Soglasja',
	inquiryConsent1Title: 'Ponudbe in opomniki',
	inquiryConsent1: 'npr. obveščanje o relevantnih ponudbah izdelkov, rezervnih delov, dodatkov in o drugih izdelkih in storitvah.',
	inquiryConsent2Title: 'Obvestila',
	inquiryConsent2:
		'obvestila in vabila na dogodke ob lansiranju novih modelov in katalogov, sejme, dneve odprtih vrat, druge predstavitve in dogodke.',
	inquiryConsent3Title: 'Zbiranje vaših mnenj o izdelkih in storitvah',
	inquiryConsent3: 'raziskave, s katerimi pridobivamo vaše mnenje za izboljšanje izdelkov in storitev.',
	equalPasswords: 'Vnesite ujemajoči gesli',
	type: 'Tip',
	brands: 'Blagovne znamke',
	partners: 'Partnerji',
	delete: 'Odstrani',
	languages: 'Jeziki',
	clearCache: 'Počisti predpomnilnik',
	tags: 'Poišči lastnosti',
	text: 'Tekst',
	desc: 'Opis',
	configuration: 'Konfiguracija',
	document: 'Dokument',
	edit: 'Uredi',
	change: 'Spremeni',
	view: 'Preglej',
	clone: 'Kloniraj',
	copy: 'Kopiraj',
	remove: 'Odstrani',
	default: 'Privzeto',
	add: 'Dodaj',
	deleting: 'Odstranjevanje...',
	adding: 'Dodajanje...',
	cloning: 'Kloniranje...',
	print: 'Natisni',
	submitForProduction: 'Pošlji v izdelavo',
	cartAddingDisabled:
		'Presegli ste dovoljeno število shranjenih projektov. Za neomejeno dodajanje projektov se prosimo prijavite!\nTo storite s klikom na ikono v zgornjem desnem kotu.',
	notSucceeded: 'ni uspelo',
	documentType: 'Tip dokumenta',
	printPrice: 'Cene',
	printColorImage: 'Barvna slika vrat',
	printTechImage: 'Tehnična skica vrat',
	printConditions: 'Prodajni in dobavni pogoji',
	selectPrintTemplate: 'Izberi predlogo dokumenta',
	selectPrintLanguage: 'Izberi jezik dokumenta',
	sendToConfirmation: 'Pošlji v odobritev',
	inConfirmation: 'V potrjevanju',
	isFinished: 'Zaključeno',
	confirm: 'Potrdi',
	reject: 'Zavrni',
	reset: 'Ponastavi',
	downloadPriceFile: 'Prenesi xlsx',
	termsPayment: 'Plačilni pogoji',
	termsSales: 'Dobavni pogoji',
	addTermPayment: 'Dodaj plačilni pogoj',
	addTermSales: 'Dodaj dobavni pogoj',
	editTermPayment: 'Uredi plačilni pogoj',
	editTermSales: 'Uredi dobavni pogoj',
	viewTermPayment: 'Poglej plačilni pogoj',
	viewTermSales: 'Poglej dobavni pogoj',
	term: 'Naziv',
	termsPage: 'Plačilni in dobavni pogoji',
	send: 'Pošlji',
	confirmDelete: 'Ali ste prepričani, da želite izbrisati izbrani vnos?',
	confirmArchive: 'Ali ste prepričani, da želite arhivirati izbrani vnos?',
	confirmReset: 'Ali ste prepričani, da želite ponastaviti izbrano konfiguracijo na privzete vrednosti?',
	confirmClone: 'Ali ste prepričani, da želite klonirati izbrani vnos?',
	confirmCopy: 'Ali ste prepričani, da želite kopirati izbrani vnos?',
	confirmChangeStatusToSend: 'Ali ste prepričani, da želite spremeniti status dokumenta v Poslano?',
	confirmChangeStatusSpecialTechnical: 'Pozor! Ob ponovni izdelavi tehnične dokumentacije bo že izdelana posebna tehnična dokumentacija izgubljena.',
	confirmRequestDocumentation: 'Pozor! Ob ponovni izdelavi tehnične dokumentacije bo že izdelana posebna tehnična dokumentacija izgubljena.',
	confirmSend: 'Ali ste prepričani, da želite poslati dokument?',
	confirmSendDocument:
		'Prejemnik dokumenta je:<br/>- partner: {Partner}<br/>- prodajalec: {Salesman}<br/>- stik: {Contact}<br/><br/> Ali ste prepričani, da želite poslati dokument?',
	changeRecipient: 'Spremeni naslovnika',
	totalPriceWithoutTax: 'Skupna cena brez DDV',
	totalTax: 'Znesek DDV',
	finalPrice: 'Skupna cena z DDV',
	selectTax: 'Izberi davek',
	tax: 'Davek',
	printTemplateSelect: 'Predloga naj vsebuje:',
	userUploaded: 'Naložil uporabnik',
	yes: 'Da',
	no: 'Ne',
	contact: 'Kontakt',
	autoSaveConfiguration: 'Spremembe se shranjujejo samodejno',
	loginToViewPrices: 'Cene so vidne samo registriranim uporabnikom',
	cookieLaw:
		'Spletno mesto za polno delovanje uporablja piškotke. V piškotke shranjujemo vašo uporabo spletnega mesta. Z uporabo spletnega mesta dovoljujete uporabo teh piškotkov. Za več informacij obiščite:',
	cookiesTitle: 'Informacije o uporabi spletnih piškotkov',
	details: 'Podrobnosti',
	sourceDocument: 'Izvirni dok.',
	download: 'Prenesi',
	downloadPDF: 'Prenesi PDF',
	clonePart: 'Artikel',
	imageUpload: 'Nalaganje slike',
	notImage: 'Naložena slika mora biti v formatu jpg, png ali bmp.',
	languageChange: 'Menjava jezika',
	languageChangeConfirm: 'Ob spremembi jezika boste preusmerjeni na začetni zaslon. Neshranjene spremembe konfiguracije bodo zavržene.',
	brandChange: 'Menjava blagovne znamke',
	brandChangeConfirm: 'Ob spremembi blagovne znamke boste preusmerjeni na začetni zaslon. Neshranjene spremembe konfiguracije bodo zavržene.',
	specialOffer: 'Posebna ponudba',
	archive: 'Arhiviraj',
	includeArchived: 'Prikaži arhivirane dokumente',
	showAll: 'Prikaži vse zapise',
	cartEmpty: 'Košarica je prazna',
	clipboard: 'Kopirano v odložišče',
	inputText: 'Prosimo, vnesite tekst',
	inputNumber: 'Prosimo, vnesite število',
	twoDecimalPlaces: 'dve decimalni mesti',
	treeDetails: 'Seznam parametrov',
	deliveryTime: 'Rok',
};
export default locale;
