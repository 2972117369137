import React, { Fragment } from 'react';
import {
	Row,
	Col,
	Input,
	Label,
	FormGroup,
	UncontrolledDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalHeader,
	ModalBody,
} from 'reactstrap';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper, { documentSatus, documentType } from '../../Helper';
import CustomerEditMenu from './CustomerEditMenu';
import './Admin.css';
import Button from '../Button';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	document?: any;
}
interface IState {
	selectedId: string;
	selected: any[];
	chkPrices: boolean;
	chkColorImage: boolean;
	chkTechImage: boolean;
	chkConditions: boolean;
	printTemplates: any;
	languages: any;
	selectedTemplate: any;
	selectedLanguage: any;
	generateDocuments: any[];
	loading: boolean;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	deleteId: string;
	documentNumber: string;
	contact: string;
	email: string;
	disableTechDocumentation: boolean;
}
class CustomerDocumentPrint extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			selectedId: '',
			selected: [],
			chkPrices: false,
			chkColorImage: false,
			chkTechImage: false,
			chkConditions: false,
			printTemplates: [],
			languages: [],
			selectedTemplate: { name: '', value: '' },
			selectedLanguage: { name: '', value: '' },
			generateDocuments: [],
			loading: false,
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			deleteId: '',
			documentNumber: '',
			contact: '',
			email: '',
			disableTechDocumentation: false,
		};
	}
	componentDidMount() {
		if (!Helper.userAdmin()) {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configurations');
		}
		this.setState({ loading: true });
		this.getTemplates();
		this.getLanguages();
		this.getPrintData();
	}

	onRowSelect(row: any, isSelected: boolean) {
		if (isSelected) {
			this.setState({
				selected: [...this.state.selected, row.id].sort(),
			});
		} else {
			this.setState({
				selected: this.state.selected.filter((it) => it !== row.id),
			});
		}
		return false;
	}

	onSelectAll(isSelected: boolean) {
		if (!isSelected) {
			this.setState({ selected: [] });
		} else {
			this.setState({
				selected: this.state.generateDocuments.map((doc: any) => doc.id),
			});
		}
		return false;
	}

	toggleConfirmationModal(title?: string, message?: string, action?: string, id?: string) {
		const state: IState = this.state;
		state.confirmationModal = !state.confirmationModal;
		if (title && message && action) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.confirmationModalAction = action;
			state.deleteId = id ? id : '';
		}
		this.setState(state);
	}

	confirmationAction() {
		if (this.state.confirmationModalAction === 'changeToSent') {
			this.changeStatusToSent();
		} else if (this.state.confirmationModalAction === 'sendDocument') {
			this.printOffer(true);
		} else if (this.state.confirmationModalAction === 'deleteOffer') {
			this.deleteOffer(this.state.deleteId);
		}
	}
	getPrintData() {
		const state: IState = this.state;
		const url = '/document/' + this.props.document.documentId + '/documents?brandPartnerId=' + Helper.brandPartnerId();
		let generateDocuments: any[] = [];

		Helper.getData<any>(url).then((data: any) => {
			if (data) {
				if (data.Documents && data.Documents.length > 0) {
					data.Documents.forEach((item: any) => {
						generateDocuments.push({
							id: item.SalesDocumentGeneratedId,
							fileName: item.FileName,
							type: Helper.getLocalizedName(item.DocumentType),
							typeId: item.DocumentTypeId,
							userAdded: item.CreatedBy,
							dateAdded: Helper.dateWithTimeConvert(item.TimeStamp),
							dateAddedDate: item.TimeStamp,
						});
					});
					state.generateDocuments = generateDocuments;
				}

				state.documentNumber = data.DocumentNumber;
				state.contact = data.Contact;
				state.email = data.Email;
				state.loading = false;
				state.disableTechDocumentation = data.DisableTechDocumentation;
			}
			this.setState(state);
		});
	}

	getTemplates() {
		const state: any = this.state;
		const url = '/document/' + this.props.document.documentId + '/templates';

		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					state.printTemplates = data;
					data.map((template: any) => {
						if (template.IsPrintDefault) {
							state.selectedTemplate.name = template.TemplateName;
							state.selectedTemplate.value = template.DocumentTemplateId;
						}
						return null;
					});
				}
				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	getLanguages() {
		const state: any = this.state;
		const url = '/selection/language/' + this.props.document.documentId;

		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					state.languages = data;
					data.map((language: any) => {
						if (language.IsDefault) {
							state.selectedLanguage.name = language.Name;
							state.selectedLanguage.value = language.LanguageId;
						}
						return null;
					});
				}
				this.setState(state);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	printOffer(send: boolean) {
		if (this.state.selectedTemplate.value === '' || this.state.selectedLanguage.value === '') {
			return;
		}
		this.setState({ loading: true, confirmationModal: false });
		const url = '/document/' + this.props.document.documentId + '/generateDocument';

		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			TemplateId: this.state.selectedTemplate.value,
			LanguageId: this.state.selectedLanguage.value,
			Send: send,
			ShowPrices: this.state.chkPrices,
			ShowImages: this.state.chkColorImage,
			ShowDocumentation: this.state.chkTechImage,
			ShowTerms: this.state.chkConditions,
		};

		Helper.postData<any>(url, JSON.stringify(body)).then((data: any) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.printOffer, data.Message);
			} else if (data) {
				if (send) {
					//send email
					this.setState(() => this.getPrintData());
					(this.props as any).setDocument(
						this.props.document.documentId,
						'',
						null,
						this.props.document.name,
						this.props.document.project,
						true,
						documentSatus.documentstatus_offer,
						this.props.document.currency
					);
				} else {
					//download
					let link = document.createElement('a');
					link.download = data.FileName;
					link.href = `data:${data.MimeType};base64,${data.Contents}`;
					link.click();
					this.setState(() => this.getPrintData());
				}
			}
			this.setState({ loading: false });
		});
	}
	changeStatusToSent() {
		this.setState({ loading: true, confirmationModal: false });
		const url = '/document/' + this.props.document.documentId + '/sendmanually?brandPartnerId=' + Helper.brandPartnerId();
		Helper.postData<any>(url, '').then((data: any) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.printOffer, data.Message);
			} else if (data) {
				this.setState(() => this.getPrintData());
				(this.props as any).setDocument(
					this.props.document.documentId,
					'',
					null,
					this.props.document.name,
					this.props.document.project,
					true,
					documentSatus.documentstatus_offer,
					this.props.document.currency
				);
			}
			this.setState({ loading: false });
		});
	}
	deleteOffer(id: string) {
		this.setState({ loading: true, confirmationModal: false });
		this.onSelectAll(false);
		const url = '/document/' + this.props.document.documentId + '/download/' + id;
		Helper.deleteData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.deleting, data.Message);
				} else {
					this.getPrintData();
				}
				this.setState({ loading: false });
			})
			.catch(() => {
				this.setState({ loading: false });
				(this.props as any).setError(locale.deleting, locale.generalException);
			});
	}
	downloadDocument(id: string, pdf: boolean) {
		this.setState({ loading: true });
		let url = '';
		if (pdf) {
			url = '/document/' + this.props.document.documentId + '/download/' + id + '/pdf';
		} else {
			url = '/document/' + this.props.document.documentId + '/download/' + id + '/doc';
		}
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					const value = data;
					let link = document.createElement('a');
					link.download = value.FileName;
					link.href = `data:${value.MimeType};base64,${value.Contents}`;
					link.click();
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}
	downloadZip() {
		if (!this.state.selected || this.state.selected.length < 1) {
			return;
		}

		this.setState({ loading: true });
		const url = '/document/' + this.props.document.documentId + '/download/zip';

		const body = {
			DocumentIds: this.state.selected,
		};

		Helper.postData<any>(url, JSON.stringify(body)).then((data: any) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.printOffer, data.Message);
			} else if (data) {
				let link = document.createElement('a');
				link.download = data.FileName;
				link.href = `data:${data.MimeType};base64,${data.Contents}`;
				link.click();
				this.setState(() => this.getPrintData());
			}
			this.setState({ loading: false });
		});
	}
	render() {
		const state = this.state;
		const options = {
			pageSize: 5,
			noDataText: locale.noResults,
		};

		// Document can be printed even when it is readonly but cannot be sent
		const disableEditDocument = this.props.document && this.props.document.disableEdit;

		const selectRowProp: any = {
			mode: 'checkbox',
			clickToSelect: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: this.state.selected,
			onSelect: (row: any, isSelected: boolean) => this.onRowSelect(row, isSelected),
			onSelectAll: (isSelected: boolean) => this.onSelectAll(isSelected),
		};

		let mailto = `?subject=${encodeURIComponent(locale.printEmailSubject.replace('{number}', state.documentNumber))}
      &body=${encodeURIComponent(locale.printEmailBody.replace('{contact}', state.contact).replace('{number}', state.documentNumber))}`;

		const linkFormatter = (cell: any, row: any) => {
			if (row.id) {
				return (
					<Fragment>
						<button className='btn cell-button ml-4' onClick={() => this.downloadDocument(row.id, true)}>
							<i className='fa fa-file-pdf-o' title={locale.downloadPDF}></i>
						</button>
						<button className='btn cell-button ml-2' onClick={() => this.downloadDocument(row.id, false)}>
							<i className='fa fa-file-o' title={locale.download}></i>
						</button>
						{row.typeId !== documentType.documenttype_offersent ? (
							<button
								className='close btn cell-button ml-2'
								title={locale.delete}
								style={{ float: 'none' }}
								onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'deleteOffer', row.id)}
							>
								<span aria-hidden='true'>×</span>
							</button>
						) : (
							''
						)}
					</Fragment>
				);
			} else {
				return '';
			}
		};
		const printTemplates =
			Array.isArray(state.printTemplates) &&
			state.printTemplates.map((template: any) => {
				return (
					<DropdownItem
						key={template.DocumentTemplateId}
						onClick={() => {
							this.setState({
								selectedTemplate: {
									name: template.TemplateName,
									value: template.DocumentTemplateId,
								},
							});
						}}
					>
						{template.TemplateName}
					</DropdownItem>
				);
			});
		const languages =
			Array.isArray(state.languages) &&
			state.languages.map((language: any) => {
				return (
					<DropdownItem
						key={language.LanguageId}
						onClick={() => {
							this.setState({
								selectedLanguage: {
									name: language.Name,
									value: language.LanguageId,
								},
							});
						}}
					>
						{language.Name}
					</DropdownItem>
				);
			});
		const dateSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (new Date(a.dateAddedDate) < new Date(b.dateAddedDate)) return 1;
				if (new Date(a.dateAddedDate) > new Date(b.dateAddedDate)) return -1;
			} else if (order === 'desc') {
				if (new Date(a.dateAddedDate) > new Date(b.dateAddedDate)) return 1;
				if (new Date(a.dateAddedDate) < new Date(b.dateAddedDate)) return -1;
			}
			return 0;
		};
		return (
			<div id='configurator' className='admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background'>
							<Row>
								<Col xs='12' className='mt-3 '>
									<Row form>
										<Col md={12} className='mb-3 admin-form-border'>
											<Row>
												<Col className='mb-3'>
													<UncontrolledDropdown className='print-dropdown'>
														<DropdownToggle caret color='selector' className='dropdown-selector'>
															{state.selectedTemplate.name !== '' ? state.selectedTemplate.name : locale.selectPrintTemplate}
															&nbsp;
														</DropdownToggle>
														<DropdownMenu>{printTemplates}</DropdownMenu>
													</UncontrolledDropdown>
													<UncontrolledDropdown className='print-dropdown'>
														<DropdownToggle caret color='selector' className='dropdown-selector'>
															{state.selectedLanguage.name !== '' ? state.selectedLanguage.name : locale.selectPrintLanguage}
															&nbsp;
														</DropdownToggle>
														<DropdownMenu>{languages}</DropdownMenu>
													</UncontrolledDropdown>
												</Col>
											</Row>
											<Row>
												<Col>
													<Label>{locale.printTemplateSelect}</Label>
												</Col>
											</Row>
											<Row>
												<Col className='mt-2 ml-1'>
													<FormGroup check>
														<Label check>
															<Input
																checked={state.chkPrices}
																onChange={(e: any) => {
																	this.setState({
																		chkPrices: e.target.checked,
																	});
																}}
																type='checkbox'
															/>
															&nbsp;
															{locale.printPrice}
														</Label>
													</FormGroup>
													<FormGroup check>
														<Label check>
															<Input
																checked={state.chkColorImage}
																onChange={(e: any) => {
																	this.setState({
																		chkColorImage: e.target.checked,
																	});
																}}
																type='checkbox'
															/>
															&nbsp;
															{locale.printColorImage}
														</Label>
													</FormGroup>
													<FormGroup check>
														<Label check>
															<Input
																checked={state.chkTechImage}
																onChange={(e: any) => {
																	this.setState({
																		chkTechImage: e.target.checked,
																	});
																}}
																disabled={state.disableTechDocumentation}
																type='checkbox'
															/>
															&nbsp;
															{locale.printTechImage}
														</Label>
													</FormGroup>
													<FormGroup check>
														<Label check>
															<Input
																checked={state.chkConditions}
																onChange={(e: any) => {
																	this.setState({
																		chkConditions: e.target.checked,
																	});
																}}
																type='checkbox'
															/>
															&nbsp;
															{locale.printConditions}
														</Label>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col className='mb-1 mt-4'>
													<Button
														color='secondary'
														disabled={state.selectedTemplate.value === '' || state.selectedLanguage.value === ''}
														onClick={() => this.printOffer(false)}
													>
														{locale.printOffer}
													</Button>
													<Button
														color='secondary'
														disabled={state.selectedTemplate.value === '' || state.selectedLanguage.value === '' || disableEditDocument}
														className='ml-2'
														onClick={() => this.toggleConfirmationModal(locale.customerSendOffer, locale.confirmSend, 'sendDocument')}
													>
														{locale.customerSendOffer}
													</Button>
													<Button
														color='secondary'
														disabled={disableEditDocument}
														className='ml-2'
														onClick={() => (window.location.href = `mailto:${this.state.email}${mailto}`)}
													>
														{locale.printEditSent}
													</Button>
													<Button
														color='secondary'
														disabled={disableEditDocument}
														className='ml-2'
														onClick={() => this.toggleConfirmationModal(locale.printChangeToSend, locale.confirmChangeStatusToSend, 'changeToSent')}
													>
														{locale.printChangeToSend}
													</Button>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row>
										<Col className='customer-view pb-3'>
											<Row>
												<Col className='mb-1 mt-4'>
													<Button color='secondary' disabled={!state.selected || state.selected.length < 1} onClick={() => this.downloadZip()}>
														{locale.printDownload}
													</Button>
												</Col>
											</Row>
											<BootstrapTable
												data={this.state.generateDocuments}
												options={options}
												striped
												pagination
												keyField='id'
												selectRow={selectRowProp}
												tableStyle={{
													backgroundColor: 'white',
												}}
											>
												<TableHeaderColumn
													hidden
													dataField='id'
													filter={{
														type: 'TextFilter',
														placeholder: '',
														style: {
															opacity: 0,
														},
													}}
												>
													{' '}
												</TableHeaderColumn>
												<TableHeaderColumn dataField='fileName' width='100px' dataFormat={linkFormatter}>
													{' '}
												</TableHeaderColumn>
												<TableHeaderColumn
													columnTitle
													dataField='fileName'
													width='350px'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: 'Filter',
													}}
												>
													{locale.document}
												</TableHeaderColumn>

												<TableHeaderColumn
													columnTitle
													dataField='type'
													width='130px'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: 'Filter',
													}}
												>
													{locale.type}
												</TableHeaderColumn>

												<TableHeaderColumn
													columnTitle
													dataField='userAdded'
													width='140px'
													filter={{
														type: 'TextFilter',
														delay: 500,
														placeholder: 'Filter',
													}}
													dataSort
												>
													{locale.user}
												</TableHeaderColumn>

												<TableHeaderColumn
													dataField='dateAdded'
													width='140px'
													filter={{
														type: 'TextFilter',
														placeholder: '',
														style: {
															opacity: 0,
														},
													}}
													dataSort
													sortFunc={dateSort}
												>
													{locale.date}
												</TableHeaderColumn>
											</BootstrapTable>
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
				<Modal size='lg' isOpen={this.state.confirmationModal} toggle={() => this.toggleConfirmationModal()}>
					<ModalHeader toggle={() => this.toggleConfirmationModal()}>{this.state.confirmationModalTitle}</ModalHeader>
					<ModalBody>
						{this.state.confirmationModalMessage}
						<br />
						<br />
						<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationAction()}>
							{locale.confirm}
						</Button>
						<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
							{locale.cancel}
						</Button>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerDocumentPrint);
