import Helper, { comment_category, status } from '../Helper';
import { actionCreators } from './Configurator';

function createModelFlat(categories: any[], categoriesPrev: any[], commentId?: string | null): any {
	let categoriesList: any[] = categoriesPrev;
	let parameterList: any[] = [];

	var recursion = function (categories: any) {
		if (categories) {
			categories.forEach((child: any) => {
				if (child) {
					let parameters = child.Parameters;
					let partId = child.PartId;

					if (parameters && parameters.length > 0) {
						parameters.forEach((parameter: any) => {
							if (commentId && parameter.ParameterId === commentId) {
								categoriesList.push({
									id: comment_category,
									name: Helper.getLocalizedName(parameter.Category),
									parentId: parameter.CategoryParentId,
									parameters: [],
									open: false,
									order: parameter.CategoryOrder ? parseInt(parameter.CategoryOrder) : 9999,
								});
							} else {
								let selectedValue = parameter.SelectedValue;
								const isEnabled = parameter.IsEnabled;
								let values = [];
								if (selectedValue) {
									let range = {};
									if (parameter.DataType === 1) {
										//slider
										let min = 0;
										if (selectedValue.MinValue && !isNaN(selectedValue.MinValue)) {
											min = selectedValue.MinValue as number;
										}
										let max = 0;
										if (selectedValue.MaxValue && !isNaN(selectedValue.MaxValue)) {
											max = selectedValue.MaxValue as number;
										}
										range = { min: min, max: max };
									}
									values.push({
										id: selectedValue.ParameterValueId,
										name: selectedValue.Name,
										valueName: selectedValue.ParameterValueName,
										brandValue: selectedValue.ParameterBrandValue,
										range: range,
										value: selectedValue.ParameterValue,
									});
								}

								parameterList.push({
									partId: partId,
									id: parameter.ParameterId,
									paramPartId: parameter.ParameterPartId,
									name:
										Helper.getLocalizedValueOrNull(parameter.ParameterBrandName) ??
										Helper.getLocalizedValueOrNull(parameter.ParameterTitle) ??
										parameter.ParameterName,
									type: parameter.DataType,
									order: parameter.Order ? parseInt(parameter.Order) : 99999,
									values: values,
									categoryId: parameter.CategoryId,
									price: 0,
									isEnabled: isEnabled,
									showPicture: parameter.ShowPicture,
								});

								if (
									parameter.CategoryId &&
									parameter.CategoryStatusId === status.status_active &&
									parameter.CategoryId !== '' &&
									parameter.CategoryId !== null
								) {
									let foundIndex = categoriesList.findIndex((cat: any) => cat.id === parameter.CategoryId);
									if (foundIndex < 0) {
										categoriesList.push({
											id: parameter.CategoryId,
											name: Helper.getLocalizedName(parameter.Category),
											parentId: parameter.CategoryParentId,
											parameters: [],
											open: false,
											order: parameter.CategoryOrder ? parseInt(parameter.CategoryOrder) : 9999,
										});
									}
								}
							}
						});
					}
					if (child.Childs) {
						categoriesList = recursion(child.Childs);
					}
				}
			});
		}
		categoriesList.sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order));
		return categoriesList;
	};
	recursion(categories);

	if (parameterList && categoriesList && parameterList.length > 0 && categoriesList && parameterList.length > 0) {
		parameterList.sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order));

		for (let i = 0; i < categoriesList.length; i++) {
			for (let j = 0; j < parameterList.length; j++) {
				if (categoriesList[i].id === parameterList[j].categoryId) {
					let foundIndex = categoriesList[i].parameters.findIndex((param: any) => param.id === parameterList[j].id);
					if (foundIndex < 0) {
						categoriesList[i].parameters.push(parameterList[j]);
					}
				}
			}
		}
	}
	return categoriesList;
}
function findSelectedParameters(categoriesNext: any, selectedParameters: any) {
	if (categoriesNext && categoriesNext.length > 0 && selectedParameters && selectedParameters.length > 0) {
		let newParameters: any = [];
		const recursion = (categories: any) => {
			categories.forEach((child: any) => {
				let parameters = child.Parameters;
				if (parameters && parameters.length > 0) {
					parameters.forEach((parameter: any) => {
						const selectedValue = parameter.SelectedValue;
						const title = selectedValue
							? Helper.getLocalizedValueOrNull(selectedValue.ParameterBrandValue) ??
							  Helper.getLocalizedValueOrNull(selectedValue.ParameterValue) ??
							  selectedValue.ParameterValueName
							: '';
						newParameters.push({
							parameterId: parameter.ParameterId,
							parameterValue: title,
							parameterValueId: selectedValue && selectedValue.ParameterValueId,
						});
					});
				}
				if (child.Childs) {
					recursion(child.Childs);
				}
			});
		};

		recursion(categoriesNext);

		//Add all existing (prev) selected parameters that are not in new parameters list
		let mergedParameters = selectedParameters.filter(
			(paramPrev: any) => !newParameters.find((paramNext: any) => paramNext.parameterId === paramPrev.parameterId)
		);
		//Add new parameters to list
		mergedParameters = mergedParameters.concat(newParameters);
		return mergedParameters;
	}
	return selectedParameters;
}
function updateTree(categoriesPrev: any, categoriesNext: any, partId: string): any {
	let filterCategories = (categories: any, findPartId: string) => {
		let filteredCategories = categories.map((child: any) => {
			if (child && child.Childs) {
				child.Childs = filterCategories(child.Childs, findPartId);
			}
			if (child && child.PartId === findPartId) {
				child.Childs = [];
				for (let i = 0; i < categoriesNext.length; i++) {
					child.Childs.push(categoriesNext[i]);
				}
			}
			return child;
		});
		return filteredCategories;
	};
	let reducedCategories = filterCategories(categoriesPrev, partId);
	return reducedCategories;
}

function convertToHierarchy(catFlat: any) {
	var arry = catFlat;

	var nodeObjects = createStructure(arry);
	for (var i = nodeObjects.length - 1; i >= 0; i--) {
		var currentNode = nodeObjects[i];
		if (currentNode.parentId === null) {
			continue;
		}
		var parent = getParent(currentNode, nodeObjects);

		if (parent === null) {
			continue;
		}

		parent.categories.push(currentNode);
		nodeObjects.splice(i, 1);
	}
	return nodeObjects;
}
function createStructure(nodes: any): any {
	var objects = [];

	for (var i = 0; i < nodes.length; i++) {
		objects.push({ ...nodes[i], categories: [] });
	}

	return objects;
}
function getParent(child: any, nodes: any) {
	var parent = null;

	for (var i = 0; i < nodes.length; i++) {
		if (nodes[i].id === child.parentId) {
			return nodes[i];
		}
	}

	return parent;
}

export function modelDetailsMapper(tree: any, commentId?: string | null): any {
	if (tree && tree.length) {
		let categories = createModelFlat(tree, [], commentId);
		let categoriesListTree = convertToHierarchy(categories);
		return categoriesListTree;
	}
	return [];
}
function popupParameters(popupParameters: any[]): any[] {
	let parameters: any[] = [];
	if (popupParameters && popupParameters.length > 0) {
		popupParameters.forEach((parameter: any) => {
			let values: any[] = [];
			// Dropdown values (inside, outside)
			const initialValues = parameter.Values;
			if (initialValues) {
				initialValues.forEach((value: any) => {
					const title =
						Helper.getLocalizedValueOrNull(value.ParameterBrandValue) ??
						Helper.getLocalizedValueOrNull(value.ParameterValue) ??
						value.ParameterValueName;
					values.push({
						value: value.ParameterValueId,
						name: title,
						valueName: title,
					});
				});
			}
			let selectedValue = {};
			// Initial selected value
			const initialSelectedValue = parameter.SelectedValue;
			if (initialSelectedValue) {
				const title =
					Helper.getLocalizedValueOrNull(initialSelectedValue.ParameterBrandValue) ??
					Helper.getLocalizedValueOrNull(initialSelectedValue.ParameterValue) ??
					initialSelectedValue.ParameterValueName;
				selectedValue = {
					value: initialSelectedValue.ParameterValueId,
					name: title,
					valueName: title,
				};
			}
			parameters.push({
				name: Helper.getLocalizedName(parameter.Label),
				id: parameter.ParameterId,
				partId: parameter.PartId,
				values: values,
				defaultValue: selectedValue,
			});
		});
	}
	return parameters;
}

function dispatchUpdatedData(data: any, selectedParameters: any, tree: any, partId: string, isPopupParameter: boolean, dispatch: any) {
	const metadata = data.Metadata;
	if (isPopupParameter) {
		return;
	}

	if (data.Tree) {
		const categoriesNextTree = updateTree(tree, data.Tree, partId);
		const categoriesNextTreeClone = JSON.parse(JSON.stringify(categoriesNextTree));
		let newCategories = modelDetailsMapper(categoriesNextTreeClone, metadata.CommentParameterId);

		const selectedParametersNext = findSelectedParameters(data.Tree, selectedParameters);
		dispatch(actionCreators.setSelectedParameters(selectedParametersNext));

		dispatch(actionCreators.setCategories(newCategories));
		dispatch(actionCreators.setTree(categoriesNextTreeClone));
	}
}
const updateCategories =
	(parameterPartId: string, parameterId: string, parameterValueId: string, customValue: string, isPopupParameter: boolean = false) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		dispatch(actionCreators.setConfigurationFlags(undefined, false, undefined, true));
		if (state.configurator && state.configurator.configuration && state.configurator.part && state.configurator.categories) {
			const configurationId = state.configurator.configuration.configurationId;
			const documentId = state.configurator.document.documentId;
			const documentConfigurationId = state.configurator.configuration.documentConfigurationId;
			const selectedParameters = state.configurator.selectedParameters;
			const tree = state.configurator.tree;

			let parameterValueParam = '';
			let customValueParam = '';
			if (parameterValueId && parameterValueId.length > 0) {
				parameterValueParam = '&parameterValueId=' + parameterValueId;
			}
			if (customValue && customValue.length > 0) {
				customValueParam = '&customValue=' + customValue;
			}
			let url = '';
			if (Helper.userAdmin() || Helper.userInstaller()) {
				url =
					'/configurator/admin/update?brandPartnerId=' +
					Helper.brandPartnerId() +
					'&configurationId=' +
					configurationId +
					'&partId=' +
					parameterPartId +
					'&parameterId=' +
					parameterId +
					parameterValueParam +
					customValueParam;
			} else if (Helper.userLoggedIn()) {
				url =
					'/configurator/auth/update?brandPartnerId=' +
					Helper.brandPartnerId() +
					'&configurationId=' +
					configurationId +
					'&partId=' +
					parameterPartId +
					'&parameterId=' +
					parameterId +
					parameterValueParam +
					customValueParam;
			} else {
				url =
					'/configurator/update?brandPartnerId=' +
					Helper.brandPartnerId() +
					'&configurationId=' +
					configurationId +
					'&partId=' +
					parameterPartId +
					'&parameterId=' +
					parameterId +
					parameterValueParam +
					customValueParam;
			}

			if (documentConfigurationId) {
				url += '&documentConfigurationId=' + documentConfigurationId;
			}

			if (documentId) {
				url += '&documentId=' + documentId;
			}

			Helper.getData<any>(url).then((data) => {
				if (data && (data.Tree || data.Metadata)) {
					const metadata = data.Metadata;
					// Create new live image if GenerateLiveImage is true for Part and for Parameter
					// If live generator is disabled on Part -> Show static image from part
					// Else live generator is disabled on Parameter -> Leave the existing image
					if (metadata.GenerateLiveImage) {
						const urlImage = '/configurator/' + configurationId + '/image';
						Helper.getData<any>(urlImage, 'blob').then((image) => {
							dispatch(actionCreators.setImage(URL.createObjectURL(image)));
							dispatch(actionCreators.setConfigurationFlags(undefined, metadata.CanEdit, metadata.InApproval, false));
						});
					} else if (metadata.PartPicture) {
						//No live image generation for Part, show part image
						dispatch(actionCreators.setImage(metadata.PartPicture));
					}
					dispatch(
						actionCreators.setConfigurationFlags(
							undefined,
							metadata.CanEdit,
							metadata.InApproval,
							false,
							undefined,
							undefined,
							metadata.GenerateLiveImage
						)
					);

					// calculate price if not already provided and authenticated
					if (data.Price != null) dispatch(actionCreators.setPartPrice(parseFloat(data.Price)));
					else if (Helper.userLoggedIn()) calculatePrice(data.Metadata.ConfigurationId, dispatch, documentId);

					dispatchUpdatedData(data, selectedParameters, tree, parameterPartId, isPopupParameter, dispatch);
				} else if (data.Message && data.Message.length > 0) {
					Helper.onError(data.Message, 'API updateConfiguration', dispatch, actionCreators);
					actionCreators.setConfigurationFlags(
						undefined,
						state.configuration && !!state.configuration.canEdit,
						state.configuration && !!state.configuration.inApproval,
						false
					);
				}
			});
		}
	};
function dispatchData(data: any, published: boolean, dispatch: any, newDocumentConfigurationId?: string) {
	const metadata = data.Metadata;
	const viewmodel = data.ViewModel;
	let image = '';
	if (metadata.FinalImageUrl && metadata.FinalImageUrl.length > 0) {
		image = metadata.FinalImageUrl + '?t=' + new Date().getTime();
	} else if (metadata.FinalImage && metadata.FinalImage.length > 0) {
		image = metadata.FinalImage;
	} else if (!metadata.GenerateLiveImage && metadata.PartPicture) {
		//No live image generation for part, show part image
		image = metadata.PartPicture;
	}

	dispatch(actionCreators.saveConfiguration(metadata.ConfigurationId, newDocumentConfigurationId));
	dispatch(
		actionCreators.setPart(
			metadata.PartId,
			metadata.PartId,
			metadata.ModelName,
			metadata.ModelTitle,
			metadata.BrandModelName,
			'',
			metadata.ModelPrice
		)
	);
	dispatch(actionCreators.saveConfigurationComment(metadata.Comment));
	dispatch(actionCreators.setImage(image));
	dispatch(actionCreators.setConfigurationFlags(published, metadata.CanEdit, metadata.InApproval, false, metadata.HasDocument, metadata.Position));

	if (viewmodel && viewmodel.Tree) {
		let categories = [];
		const tree = viewmodel.Tree;

		if (tree.length > 0 && tree[0].Childs) {
			categories = modelDetailsMapper(tree[0].Childs, metadata.CommentParameterId);
			console.log('categories', categories);
			dispatch(actionCreators.setCategories(categories));
			dispatch(actionCreators.setTree(tree));
		}
	}
	const popupParams = popupParameters(viewmodel.PopupParameters);
	dispatch(actionCreators.setPopupParameters(popupParams));
}
function calculatePrice(configurationId: string, dispatch: any, documentId?: string) {
	dispatch(actionCreators.clearPartPrice());
	if (Helper.userLoggedIn()) {
		let url = '/configurator/' + configurationId + '/price';
		if (documentId) url += '?documentId=' + documentId;
		Helper.getData<any>(url).then((data) => {
			if (data.Price) {
				dispatch(actionCreators.setPartPrice(parseFloat(data.Price)));
			} else if (data.Message && data.Message.length > 0) {
			}
		});
	}
}
const buildCategories =
	(newConfigurationId?: string, newPartId?: string, documentConfigurationId?: string, forceImage?: boolean, position?: number) =>
	(dispatch: any, getState: any) => {
		const state = getState();
		dispatch(actionCreators.setConfigurationLoading(true));
		const documentId = state.configurator.document.documentId;
		if (newConfigurationId) {
			//new configuration id selected in various places
			let url = '';
			if (Helper.userAdmin() || Helper.userInstaller()) {
				url = '/configurator/admin/create?brandPartnerId=' + Helper.brandPartnerId() + '&configurationId=' + newConfigurationId;
			} else if (Helper.userLoggedIn()) {
				url = '/configurator/auth/create?brandPartnerId=' + Helper.brandPartnerId() + '&configurationId=' + newConfigurationId;
			} else {
				url = '/configurator/create?brandPartnerId=' + Helper.brandPartnerId() + '&configurationId=' + newConfigurationId;
			}
			if (forceImage) {
				url += '&showImage=true';
			}
			if (documentConfigurationId) {
				url += '&documentConfigurationId=' + documentConfigurationId;
			}
			if (documentId) {
				url += '&documentId=' + documentId;
			}
			Helper.getData<any>(url).then((data) => {
				if (data.Message && data.Message.length > 0) {
					Helper.onError(data.Message, 'API createConfiguration', dispatch, actionCreators);
				} else if (data && data.Metadata && data.ViewModel) {
					dispatchData(data, true, dispatch, documentConfigurationId);

					// calculate price if not already provided and authenticated
					if (data.Price != null) dispatch(actionCreators.setPartPrice(parseFloat(data.Price)));
					else if (Helper.userLoggedIn()) calculatePrice(data.Metadata.ConfigurationId, dispatch, documentId);
				} else if (data.Message && data.Message.length > 0) {
					Helper.onError(data.Message, 'API createConfiguration', dispatch, actionCreators);
				}
			});
		} else if (newPartId) {
			//new part selected in step 1
			let url = '';

			if (Helper.userAdmin() || Helper.userInstaller()) {
				url = '/configurator/admin/create?brandPartnerId=' + Helper.brandPartnerId() + '&partId=' + newPartId;
				if (position && position > 0) {
					url += '&p=' + position;
				}
			} else if (Helper.userLoggedIn()) {
				url = '/configurator/auth/create?brandPartnerId=' + Helper.brandPartnerId() + '&partId=' + newPartId;
			} else {
				url = '/configurator/create?brandPartnerId=' + Helper.brandPartnerId() + '&partId=' + newPartId;
			}
			if (forceImage) {
				url += '&showImage=true';
			}
			if (documentConfigurationId) {
				url += '&documentConfigurationId=' + documentConfigurationId;
			}
			if (documentId) {
				url += '&documentId=' + documentId;
			}

			Helper.getData<any>(url).then((data) => {
				if (data && data.Metadata && data.ViewModel && data.Metadata.ConfigurationId) {
					dispatchData(data, false, dispatch, documentConfigurationId);

					// calculate price if not already provided and authenticated
					if (data.Price != null) dispatch(actionCreators.setPartPrice(parseFloat(data.Price)));
					else if (Helper.userLoggedIn()) calculatePrice(data.Metadata.ConfigurationId, dispatch, documentId);
				} else if (data && data.Message && data.Message.length > 0) {
					Helper.onError(data.Message, 'API createConfiguration', dispatch, actionCreators);
				} else Helper.onError2(data.Message, 'API createConfiguration', dispatch, actionCreators);
			});
		}
	};
export const actionConfigCreators = {
	buildCategories,
	updateCategories,
};
