import React from 'react';
import { Row, Col, Label, Input, FormGroup } from 'reactstrap';
import './Configurator.css';
import locale from '../Locale';
import Button from './Button';
import Helper from '../Helper';
import Loading from 'react-fullscreen-loading';

interface IProps {
	setUserAddressData(contactFormData: any): void;
	contact?: any;
}

interface IState {
	Countries: any[];
	ContactAddressName: string;
	DeliveryAddress: boolean;
	DeliveryAddressData: any;
	DeliveryAddressDataId: string;
	ReceiptAddress: boolean;
	ReceiptAddressData: any;
	ReceiptAddressDataId: string;
	SaveMessage: string;
	loading: boolean;
}

export default class InquiryUser extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			Countries: [],
			ContactAddressName: (this.props.contact && this.props.contact.CountryId) || '',
			DeliveryAddress: false,
			DeliveryAddressData: [],
			DeliveryAddressDataId: (this.props.contact && this.props.contact.CountryId) || '',
			ReceiptAddress: false,
			ReceiptAddressData: [],
			ReceiptAddressDataId: (this.props.contact && this.props.contact.CountryId) || '',
			SaveMessage: '',
			loading: false,
		};
	}

	getDeliveryAddressData(deliveryAddressData: any) {
		let state: IState = this.state;
		let deliveryAddress: any = [];
		if (deliveryAddressData.length > 0) {
			deliveryAddressData.forEach((item: any) => {
				deliveryAddress.push({
					id: item.UserAddressId,
					name:
						item.FirstName +
						' ' +
						item.LastName +
						', ' +
						item.Address +
						', ' +
						item.Zip +
						' ' +
						item.City +
						(state.Countries.find((element) => element.value === item.CountryId) !== undefined
							? ', ' + state.Countries.find((element) => element.value === item.CountryId).code
							: '') +
						(item.CompanyName !== '' ? ', ' + item.CompanyName : '') +
						(item.Telephone !== '' ? ', ' + item.Telephone : ''),
					isDefault: item.Default,
				});
				if (item.Default) {
					state.DeliveryAddress = true;
					state.DeliveryAddressDataId = item.UserAddressId;
				}
			});
		}
		state.DeliveryAddressData = deliveryAddress;
		this.setState(state);
	}

	getReceiptAddressData(receiptAddressData: any) {
		let state: IState = this.state;
		let receiptAddress: any = [];
		if (receiptAddressData.length > 0) {
			receiptAddressData.forEach((item: any) => {
				receiptAddress.push({
					id: item.UserAddressId,
					name:
						item.FirstName +
						' ' +
						item.LastName +
						', ' +
						item.Address +
						', ' +
						item.Zip +
						' ' +
						item.City +
						(state.Countries.find((element) => element.value === item.CountryId) !== undefined
							? ', ' + state.Countries.find((element) => element.value === item.CountryId).code
							: '') +
						(item.CompanyName !== '' ? ', ' + item.CompanyName : '') +
						(item.Telephone !== '' ? ', ' + item.Telephone : ''),
					isDefault: item.Default,
				});
				if (item.Default) {
					state.ReceiptAddress = true;
					state.ReceiptAddressDataId = item.UserAddressId;
				}
			});
		}
		state.ReceiptAddressData = receiptAddress;
		this.setState(state);
	}

	async componentDidMount() {
		this.setState({ loading: true });
		await this.getCountries();
		let state: IState = this.state;
		let url = '/account/info';
		Helper.getData<any>(url)
			.then((data: any) => {
				if (data) {
					this.setState({
						ContactAddressName:
							data.FirstName +
							' ' +
							data.LastName +
							', ' +
							data.Address +
							', ' +
							data.Zip +
							' ' +
							data.City +
							(state.Countries.find((element) => element.value === data.CountryId) !== undefined
								? ', ' + state.Countries.find((element) => element.value === data.CountryId).code
								: '') +
							(data.CompanyName !== '' ? ', ' + data.CompanyName : '') +
							(data.Telephone !== '' ? ', ' + data.Telephone : ''),
					});
					if (data.DeliveryAddress && data.DeliveryAddress.length > 0) {
						this.getDeliveryAddressData(data.DeliveryAddress);
					}
					if (data.ReceiptAddress && data.ReceiptAddress.length > 0) {
						this.getReceiptAddressData(data.ReceiptAddress);
					}
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error);
			});
	}

	async getCountries() {
		const data: any = await Helper.getData('/selection/countrylist');
		let countriesFromApi = data
			.map((country: any) => {
				return { value: country.CountryId, code: country.Code, display: Helper.getLocalizedName(country.Name) };
			})
			.sort((a: any, b: any) => a.display.localeCompare(b.display));
		this.setState({
			Countries: [
				{
					value: '',
					code: '',
					display: locale.selectCountry,
				},
			].concat(countriesFromApi),
		});
	}

	handleCheckbox(e: any) {
		const state: IState = this.state;
		const { target } = e;
		if (target.name === 'DeliveryAddress') {
			state.DeliveryAddress = !state.DeliveryAddress;
		} else if (target.name === 'ReceiptAddress') {
			state.ReceiptAddress = !state.ReceiptAddress;
		}
		this.setState(state);
	}

	setUserContactData() {
		const state: IState = this.state;
		let contactFormData: any = null;
		contactFormData = {
			UserAddress: true,
			DeliveryAddress: state['DeliveryAddress'],
			DeliveryId: state['DeliveryAddressDataId'],
			ReceiptAddress: state['ReceiptAddress'],
			ReceiptId: state['ReceiptAddressDataId'],
		};
		this.props.setUserAddressData(contactFormData);
	}

	openProfile() {
		window.location.href = process.env.REACT_APP_ROOT_PATH + '/Profile';
	}

	render() {
		const { ContactAddressName } = this.state;
		return (
			<Row>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Col xs='12' className='inquiry'>
						<FormGroup>
							<Row>
								<Col xs='12' className='pb-4'>
									<Row>
										<Col lg='12' md='12' className='half-column'>
											<Label check htmlFor='ContactAddressName'>
												{locale.contactTitleSales}
											</Label>
											<Input type='text' name='ContactAddressName' id='ContactAddressName' disabled value={ContactAddressName} />
										</Col>
										<Col xs='12' className='full-column'>
											<Row>
												<Col xs='12'>
													<h6>{locale.profileDeliveryTitle}</h6>
												</Col>
											</Row>
										</Col>
										<Col xs='12' className='foot-column'>
											<Row>
												<Input
													type='checkbox'
													name='DeliveryAddress'
													checked={this.state.DeliveryAddress}
													id='differentDelivery-checkbox'
													onClick={(e) => this.handleCheckbox(e)}
													onChange={(e) => {
														this.handleCheckbox(e);
													}}
												/>
												<p className='checkbox'>{locale.differentThenContactAddress}</p>
											</Row>
										</Col>
										<Col lg='12' md='12' className='half-column' hidden={!this.state.DeliveryAddress}>
											<select
												className='form-control'
												value={this.state.DeliveryAddressDataId}
												onChange={(e) => this.setState({ DeliveryAddressDataId: e.target.value })}
											>
												{this.state.DeliveryAddressData.map((Delivery: any) => (
													<option key={Delivery.id} value={Delivery.id}>
														{Delivery.name}
													</option>
												))}
											</select>
										</Col>
										<Col xs='12' className='full-column'>
											<Row>
												<Col xs='12'>
													<h6>{locale.profileReceiptTitle}</h6>
												</Col>
											</Row>
										</Col>
										<Col xs='12' className='foot-column'>
											<Row>
												<Input
													type='checkbox'
													name='ReceiptAddress'
													checked={this.state.ReceiptAddress}
													id='differentReceipt-checkbox'
													onClick={(e) => {
														this.handleCheckbox(e);
													}}
													onChange={(e) => {
														this.handleCheckbox(e);
													}}
												/>
												<p className='checkbox'>{locale.differentThenContactAddress}</p>
											</Row>
										</Col>
										<Col lg='12' md='12' className='half-column' hidden={!this.state.ReceiptAddress}>
											<select
												className='form-control'
												value={this.state.ReceiptAddressDataId}
												onChange={(e) => this.setState({ ReceiptAddressDataId: e.target.value })}
											>
												{this.state.ReceiptAddressData.map((Receipt: any) => (
													<option key={Receipt.id} value={Receipt.id}>
														{Receipt.name}
													</option>
												))}
											</select>
										</Col>
									</Row>
								</Col>
							</Row>
						</FormGroup>
						<Button color='secondary' className='my-2' onClick={() => this.openProfile()}>
							{locale.profile}
						</Button>{' '}
						<Button color='secondary' className='my-2' onClick={() => this.setUserContactData()}>
							{locale.save}
						</Button>{' '}
					</Col>
				)}
			</Row>
		);
	}
}
