import React from 'react';
import { Collapse, NavbarToggler, NavbarBrand, Row, Col } from 'reactstrap';

import './Configurator.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Parameter from './Parameter';
import locale from '../Locale';
import Comment from './Comment';
import Button from './Button';
import { comment_category, deepCompare } from '../Helper';

interface IProps extends RouteComponentProps<any> {
	part: any;
	step: number;
	selectedParameters: any;
	categoryComments: any;
	categories: any;
	selectedParametersTags: any;
	hasDocument: boolean | undefined;
	comment: string | undefined;
	configurationId: string | undefined;
	updateImage?(): void;
}
interface IState {
	categories: any;
}
class Categories extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.updateModelDetails = this.updateModelDetails.bind(this);

		this.state = { categories: props.categories };
	}

	static getDerivedStateFromProps(nextProps: any, prevState: any) {
		if (nextProps.categories !== prevState?.categories) return { categories: nextProps.categories };

		return null;
	}

	shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
		return !deepCompare(this.props, nextProps, true) || !deepCompare(this.state, nextState, true);
	}

	updateModelDetails(parameterPartId: string, parameterId: string, parameterValueId: string, customValue: string) {
		(this.props as any).updateCategories(parameterPartId, parameterId, parameterValueId, customValue);
	}
	getSelectedParameter(parameterId: string): string {
		let selectedParameters = this.props.selectedParameters || [];
		if (this.props.selectedParameters) {
			for (let i = 0; i < this.props.selectedParameters.length; i++) {
				if (selectedParameters[i].parameterId === parameterId) {
					return selectedParameters[i].parameterValue;
				}
			}
		}
		return '';
	}

	categoryToggle(i: number) {
		let state = this.state;
		state.categories[i].open = !state.categories[i].open;
		this.setState(state);
		if (state.categories[i].open) (this.props as any).setStep(i);
	}

	filtersReset() {
		(this.props as any).clearPart();
		(this.props as any).buildCategories(null, this.props.part.id, null);
	}

	nextStep(): void {
		let step = this.props.step;
		if (this.state.categories.length - 1 > step)
			//TMP TODO length-1, just for comment
			(this.props as any).incrementStep();
	}

	prevStep(): void {
		let step = this.props.step;
		if (step > 0) (this.props as any).decrementStep();
	}

	setStep(i: number): void {
		(this.props as any).setStep(i);
	}

	renderCategoriesRecursion(categories: any, isSubCategory: boolean): any {
		if (!categories) {
			return [];
		}
		categories.sort((a: any, b: any) => parseInt(a.order) - parseInt(b.order));
		const step = this.props.step || 0;
		const categoriesList = categories.map((category: any, i: number) => {
			let subsubcategories: any = [];
			if (category.categories && category.categories.length > 0) {
				subsubcategories = this.renderCategoriesRecursion(category.categories, true);
			}
			category.open = false;
			if (i === step) category.open = true;

			let parameterList = [];
			parameterList = category.parameters.map((parameter: any, j: number) => {
				let defaultValue = null;
				if (parameter.values && parameter.values.length > 0) {
					defaultValue = parameter.values[0];
				}
				return (
					<Parameter
						parameterId={parameter.id}
						parameterType={parameter.type}
						showPicture={parameter.showPicture}
						parameterName={parameter.name}
						initialValues={parameter.values}
						isEnabled={parameter.isEnabled}
						history={this.props.history}
						location={this.props.location}
						match={this.props.match}
						selectedParameterValue={
							this.props.selectedParameters.find((x: any) => x.parameterId === parameter.id && x.parameterValue != null)?.parameterValue
						}
						selectedParameterTag={this.props.selectedParametersTags?.find((x: any) => x.parameterId === parameter.id).tag}
						partId={parameter.partId}
						key={parameter.id}
						defaultValue={defaultValue}
						updateModelDetails={this.updateModelDetails}
					/>
				);
			});

			// Comment category
			if (category.id === comment_category) {
				console.log('categoryFound', isSubCategory);
				if (isSubCategory) {
					return (
						<React.Fragment key={category.id}>
							<Row>
								<Col lg='12 mt-4 mb-2'>
									<div className='filter-separator'>
										<span>{category.name}</span>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md='12'>
									<Comment
										history={this.props.history}
										location={this.props.location}
										match={this.props.match}
										hasDocument={this.props.hasDocument}
										comment={this.props.comment}
										configurationId={this.props.configurationId}
									/>
								</Col>
							</Row>
						</React.Fragment>
					);
				} else {
					return (
						<div key={category.id} className='mr-auto pt-1 mb-1 navbar-wrapper '>
							<NavbarBrand onClick={() => this.categoryToggle(i)} className={`text-uppercase ${category.open ? 'disable-cursor' : ''}`}>
								{category.name}
							</NavbarBrand>
							<NavbarToggler className={category.open ? 'disable-cursor expanded' : ''} onClick={() => this.categoryToggle(i)} />
							<Collapse isOpen={category.open} navbar>
								<Row>
									<Col className='mt-1' xs='12'>
										{' '}
									</Col>
									<Col md='12'>
										<Comment
											history={this.props.history}
											location={this.props.location}
											match={this.props.match}
											hasDocument={this.props.hasDocument}
											comment={this.props.comment}
											configurationId={this.props.configurationId}
										/>
									</Col>
								</Row>
								<Row>
									<Col className='mt-3' xs='12'>
										{' '}
									</Col>
								</Row>
								{/* {renderComment} */}
							</Collapse>
						</div>
					);
				}
			} else if (isSubCategory) {
				return (
					<React.Fragment key={category.id}>
						<Row>
							<Col lg='12 mt-4 mb-2'>
								<div className='filter-separator'>
									<span>{category.name}</span>
								</div>
							</Col>
						</Row>
						<Row>{parameterList}</Row>
						<Row>
							<Col xs='12'>{subsubcategories}</Col>
						</Row>
					</React.Fragment>
				);
			} else {
				return (
					<div key={category.id} className='mr-auto pt-2 mb-1 navbar-wrapper '>
						<NavbarBrand onClick={() => this.categoryToggle(i)} className={`text-uppercase ${category.open ? 'disable-cursor' : ''}`}>
							{category.name}
						</NavbarBrand>
						<NavbarToggler className={category.open ? 'disable-cursor expanded' : ''} onClick={() => this.categoryToggle(i)} />
						<Collapse isOpen={category.open} navbar>
							<Row>
								<Col className='mt-1' xs='12'>
									{' '}
								</Col>
								{parameterList}
							</Row>
							<Row>
								<Col xs='12'>{subsubcategories}</Col>
							</Row>
							<Row>
								<Col className='mt-3' xs='12'>
									{' '}
								</Col>
							</Row>
						</Collapse>
					</div>
				);
			}
		});
		return categoriesList;
	}
	render() {
		const categories = this.state.categories;

		const step = this.props.step || 0;

		let categoriesList: any = this.renderCategoriesRecursion(categories, false);

		return (
			<React.Fragment>
				<Row className='w-100'>
					<Col xs='12' className='mb-2 mt-3'>
						<Button className={'button btn-secondary mr-2'} disabled={step === 0} onClick={() => this.prevStep()}>
							{locale.prev}
						</Button>
						<Button
							className='button btn-secondary'
							disabled={step === categories.length - 1 || categories.length === 0}
							onClick={() => this.nextStep()}
						>
							{locale.next}
						</Button>
						<div className='step-of d-inline-block'>
							<label>{locale.step + ' ' + (categories.length > 0 ? this.props.step + 1 : 0) + ' ' + locale.of + ' ' + categories.length}</label>
						</div>
						<div className='configuration-reset text-smaller' onClick={() => this.filtersReset()}>
							{locale.clearConfiguration}
						</div>
					</Col>
				</Row>
				{categoriesList}
			</React.Fragment>
		);
	}
}
export default connect(null, (dispatch) => bindActionCreators(actionCreators, dispatch))(Categories);
