import React from "react";
import locale from "../Locale";
import { Container, Col } from "reactstrap";

const CookieInfo: React.FunctionComponent<any> = props => {
    return (
        <Container className="Register">
            <Col>
                <h2>{locale.cookiesTitle}</h2>
            </Col>
            <Col className="mt-4">...</Col>
        </Container>
    );
};
export default CookieInfo;
