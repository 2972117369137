import React from 'react';
import './Configurator.css';
import moreImage from '../images/mini-more.png';
import { Slider as SliderElement, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { Handle, Track, Tick } from './components';

interface IProps {
	parameterId: string;
	selectedValue: string;
	min: number;
	max: number;
	update: boolean;
	canEdit: boolean;
	setSelectedParameter(name: string, id: string, keepOpen?: boolean, close?: boolean): void;
}

interface IState {
	values: any;
	sliderValue: any;
	inputValue: string;
	updateEnabled: boolean;
}

class Slider extends React.Component<IProps, IState> {
	private timeout: number;

	constructor(props: IProps) {
		super(props);
		this.timeout = 0;
		this.state = {
			values: [this.props.selectedValue],
			sliderValue: [this.props.selectedValue],
			inputValue: this.props.selectedValue,
			updateEnabled: false,
		};
	}

	componentDidUpdate(prevProps: any) {
		if (prevProps !== this.props && this.state.updateEnabled === true) {
			this.setState({ updateEnabled: false });
		}
	}

	onSliderChange(values: any) {
		if (values !== this.props.selectedValue && values !== this.state.sliderValue && this.state.updateEnabled) {
			this.props.setSelectedParameter(values[0], values[0].toString());
		}
	}

	onSliderUpdate(value: any) {
		if (this.state.sliderValue !== value && value !== [this.props.selectedValue]) {
			this.setState({ inputValue: value, updateEnabled: true });
		}
	}

	onInputChange(e: any) {
		const rex = /^[-+]?\d+$/;
		let value = e.target.value;
		if (value === '-') {
			this.setState({ inputValue: value, updateEnabled: true });
		} else if (value === '' || rex.test(value)) {
			this.setState({ inputValue: value, updateEnabled: true });
			if (this.timeout) {
				window.clearTimeout(this.timeout);
			}
			this.timeout = window.setTimeout(() => {
				this.setState({ inputValue: value, sliderValue: [value] });
			}, 1000);
		}
	}

	handleFocus = (event: any) => event.target.select();

	handleOpen() {
		this.props.setSelectedParameter('', '', false, true);
	}

	render() {
		let state = this.state;
		let props = this.props;
		const editClass = props.canEdit ? '' : ' no-pointer-events';
		return (
			<div className={'slider' + editClass}>
				<input autoFocus type='text' value={state.inputValue} onFocus={this.handleFocus} onChange={(e) => this.onInputChange(e)}></input>
				<img src={moreImage} alt={' '} className='filter-image-s' onClick={(e) => this.handleOpen()} />
				<div className='slider-spacer'></div>
				<SliderElement
					mode={1}
					step={1}
					domain={[props.min, props.max]}
					onUpdate={(values: any) => this.onSliderUpdate(values)}
					onChange={(values: any) => this.onSliderChange(values)}
					values={state.sliderValue}
				>
					<Rail>{({ getRailProps }) => <div {...getRailProps()} />}</Rail>
					<Handles>
						{({ handles, getHandleProps }) => (
							<div className='slider-handles'>
								{handles.map((handle) => (
									<Handle key={handle.id} handle={handle} domain={[100, 500]} getHandleProps={getHandleProps} />
								))}
							</div>
						)}
					</Handles>
					<Tracks>
						{({ tracks, getTrackProps }) => (
							<div className='slider-tracks'>
								{tracks.map(({ id, source, target }) => (
									<Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
								))}
							</div>
						)}
					</Tracks>
					<Ticks count={5}>
						{({ ticks }) => (
							<div className='slider-ticks'>
								{ticks.map((tick) => (
									<Tick key={tick.id} tick={tick} count={ticks.length} />
								))}
							</div>
						)}
					</Ticks>
				</SliderElement>
				<div className='slider-spacer-bellow'> </div>
			</div>
		);
	}
}

export default Slider;
