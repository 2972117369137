import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import SubNavMenu from './SubNavMenu';
import AppFooter from './AppFooter';
import locale from '../Locale';
import CookieConsent from 'react-cookie-consent';
import Helper from '../Helper';

interface Iprops {}

export default class Layout extends React.Component<Iprops, any> {
	render() {
		locale.setLanguage(Helper.userLang());

		return (
			<div>
				<div>
					<NavMenu />
					<SubNavMenu />
					<Container fluid>
						{this.props.children}
						<CookieConsent
							location='bottom'
							buttonText={locale.confirm}
							cookieName='doorsGDPR'
							style={{ background: '#2B373B' }}
							buttonStyle={{
								background: Helper.themeButtonColor(),
								color: Helper.themeButtonFontColor(),
								borderRadius: '0.25rem',
								padding: '0.375rem 0.75rem',
							}}
							expires={150}
						>
							{locale.cookieLaw}{' '}
							<a href={process.env.REACT_APP_ROOT_PATH + '/cookie-info'} className={'cookie-info'}>
								{locale.details}
							</a>
						</CookieConsent>
					</Container>
				</div>
				<AppFooter />
			</div>
		);
	}
}
