import { actionConfigCreators } from './Categories';

const setPart = 'SET_PART';
const setPartPrice = 'SET_PARTPRICE';
const clearPartPrice = 'CLEAR_PARTPRICE';
const clearPart = 'CLEAR_PART';
const incrementCategoryStep = 'INCREMENT_STEP';
const decrementCategoryStep = 'DECREMENT_STEP';
const setCategoryStep = 'SET_STEP';
const setCategories = 'SET_CATEGORIES';
const setTree = 'SET_TREE';
const setTreeModal = 'SET_TREE_MODAL';
const setLoading = 'SET_LOADING';
const setPopupParameters = 'SET_POPUPPARAMETERS';

const addCategoryComment = 'ADD_CATEGORY_COMMENT';
const addSelectedParameter = 'ADD_PARAMETER';
const setSelectedParameters = 'SET_PARAMETER';
const clearParameters = 'CLEAR_PARAMETERS';
const addSelectedParameterTag = 'ADD_PARAMETER_TAG';
const addDefaultParameter = 'ADD_DEFAULT_PARAMETER';
const saveConfiguration = 'SAVE_CONFIGURATION';
const clearConfiguration = 'CLEAR_CONFIGURATION';
const saveConfigurationComment = 'SAVE_CONFIGURATION_COMMENT';
const setConfigurationPublished = 'SET_CONFIGURATION_PUBLISHED';
const setConfigurationFlags = 'SET_CONFIGURATION_FLAGS';
const setConfigurationLoading = 'SET_CONFIGURATION_LOADING';

const setAnonymousToken = 'SET_USERTOKEN';
const setContact = 'SET_CONTACT';
const setUser = 'SET_USER';
const setDocument = 'SET_DOCUMENT';
const setDocumentAddingMode = 'SET_DOCUMENT_ADDING';
const setImage = 'SET_IMAGE';
const setError = 'SET_ERROR';
const setLanguage = 'SET_LANGUAGE';
const destroySession = 'DESTROY_SESSION';
const setSearch = 'SET_SEARCH';
const setCartOpen = 'SET_CART_OPEN';
const setInquiryDialogOpen = 'SET_INQUIRYDIALOG_OPEN';
const setShowMessage = 'SET_SHOW_MESSAGE';
const setCartAddingDisabled = 'SETCARTADDINGDISABLED';
const setFooter = 'SET_FOOTER';
const setBrand = 'SET_BRAND';
const setBrands = 'SET_BRANDS';
const setNumberOfBrands = 'SET_NUMBEROFBRANDS';
export interface IGlobalState {
	part: object;
}

export const initialState = {
	part: {},
	step: 0,
	categories: [],
	popupParameters: [],
	tree: null,
	treeModal: false,
	loading: false,
	selectedParameters: [],
	selectedParametersTags: [],
	categoryComments: [],
	anonymousToken: null,
	user: null,
	contact: {},
	configuration: {
		configurationId: null,
		documentConfigurationId: null,
		documentPosition: null,
		configurationPublished: false,
		loading: false,
		loadingImage: false,
		hasDocument: false,
		position: 0,
		image: '',
		inApproval: false,
		canEdit: true,
		loader: false,
	},
	document: {
		documentId: null,
		initialDocumentId: null,
		documentConfigurations: [],
		name: '',
		project: '',
		addingMode: false,
		disableEdit: false,
		documentStatusId: null,
		currency: 'EUR',
	},
	error: { title: '', message: '' },
	theme: null,
	footer: null,
	brandPartnerId: null, //process.env.REACT_APP_BRANDPARTNER,
	language: navigator.language || (navigator as any).userLanguage,
	search: { selectedCategories: [], query: '' },
	navCartOpen: false,
	inquiryDialogOpen: false,
	showMessage: { show: false, title: '', message: '' },
	cartAddingDisabled: false,
	brands: [],
	numberOfBrands: 0,
};

export const actionCreators = {
	setPart: (id: string, partId: string, name: string, title: string, brandName: string, image: string, price: number) => ({
		type: setPart,
		id,
		partId,
		name,
		title,
		brandName,
		image,
		price,
	}),
	setPartPrice: (price: number) => ({ type: setPartPrice, price }),
	clearPartPrice: () => ({ type: clearPartPrice }),
	clearPart: (includeDocument?: boolean, position?: number) => ({
		type: clearPart,
		includeDocument,
		position,
	}),
	incrementStep: () => ({ type: incrementCategoryStep }),
	decrementStep: () => ({ type: decrementCategoryStep }),
	setStep: (i: number) => ({ type: setCategoryStep, i }),
	setCategories: (categories: any) => ({
		type: setCategories,
		categories,
	}),
	setTree: (tree: any) => ({ type: setTree, tree }),
	setTreeModal: (treeModal: any) => ({ type: setTreeModal, treeModal }),
	setLoading: (loading: boolean) => ({ type: setLoading, loading }),
	setPopupParameters: (popupParameters: any[]) => ({
		type: setPopupParameters,
		popupParameters,
	}),

	setAnonymousToken: (userToken: any) => ({
		type: setAnonymousToken,
		userToken,
	}),

	setContact: (contactData: any) => ({ type: setContact, contactData }),
	setUser: (email: string, name: string, userType: string, roles: any[]) => ({
		type: setUser,
		email,
		name,
		userType,
		roles,
	}),

	addSelectedParameter: (param: any) => ({
		type: addSelectedParameter,
		param,
	}),
	setSelectedParameters: (selectedParameters: any[]) => ({
		type: setSelectedParameters,
		selectedParameters,
	}),
	addSelectedParameterTag: (param: any) => ({
		type: addSelectedParameterTag,
		param,
	}),
	addDefaultParameter: (param: any) => ({ type: addDefaultParameter, param }),

	addCategoryComment: (comment: any) => ({
		type: addCategoryComment,
		comment,
	}),

	clearParameters: () => ({ type: clearParameters }),

	saveConfiguration: (configurationId: string, documentConfigurationId?: string, documentPosition?: string) => ({
		type: saveConfiguration,
		configurationId,
		documentConfigurationId,
		documentPosition,
	}),
	clearConfiguration: () => ({ type: clearConfiguration }),
	saveConfigurationComment: (comment?: string) => ({
		type: saveConfigurationComment,
		comment,
	}),
	setConfigurationLoading: (loading: boolean) => ({
		type: setConfigurationLoading,
		loading,
	}),
	setConfigurationPublished: (configurationPublished: boolean) => ({
		type: setConfigurationPublished,
		configurationPublished,
	}),
	setConfigurationFlags: (
		published?: boolean,
		canEdit?: boolean,
		inApproval?: boolean,
		loading?: boolean,
		hasDocument?: boolean,
		position?: number,
		loadingImage?: boolean
	) => ({
		type: setConfigurationFlags,
		published,
		canEdit,
		inApproval,
		loading,
		hasDocument,
		position,
		loadingImage,
	}),

	setDocument: (
		documentId: string,
		initialDocumentId: string,
		name: string,
		project: string,
		documentConfigurations?: any,
		disableEdit?: boolean,
		documentStatusId?: string,
		currency?: string
	) => ({
		type: setDocument,
		documentId,
		initialDocumentId,
		name,
		project,
		documentConfigurations,
		disableEdit,
		documentStatusId,
		currency,
	}),
	setDocumentAddingMode: (adding: boolean) => ({
		type: setDocumentAddingMode,
		adding,
	}),
	setImage: (image: string) => ({ type: setImage, image }),

	setError: (title: string, message: string) => ({
		type: setError,
		title,
		message,
	}),
	setBrand: (
		logo: string,
		logotype: string,
		title: string,
		header: string,
		color: string,
		headerFontColor: string,
		brandPartnerId: string,
		buttonColor: string,
		buttonFontColor: string
	) => ({ type: setBrand, logo, logotype, title, header, color, headerFontColor, brandPartnerId, buttonColor, buttonFontColor }),
	setNumberOfBrands: (numberOfBrands: number) => ({ type: setNumberOfBrands, numberOfBrands }),
	setLanguage: (language: string) => ({ type: setLanguage, language }),
	setFooter: (text1: string, url1: string, text2: string, url2: string, text3: string, url3: string) => ({
		type: setFooter,
		text1,
		url1,
		text2,
		url2,
		text3,
		url3,
	}),

	destroySession: () => ({ type: destroySession }),
	setSearch: (selectedCategories: string[], query: string) => ({
		type: setSearch,
		selectedCategories,
		query,
	}),
	setCartOpen: (open: boolean) => ({ type: setCartOpen, open }),
	setInquiryDialogOpen: (open: boolean) => ({ type: setInquiryDialogOpen, open }),
	setShowMessage: (show: boolean, title: string, message: string) => ({ type: setShowMessage, show, title, message }),
	setCartAddingDisabled: (disabled: boolean) => ({
		type: setCartAddingDisabled,
		disabled,
	}),
	setBrands: (brands: any[]) => ({ type: setBrands, brands }),
	...actionConfigCreators,
};

export const reducer = (state: any, action: any) => {
	state = state || initialState;

	if (action.type === clearPart) {
		if (action.includeDocument)
			return {
				...state,
				selectedParameters: [],
				selectedParametersTags: [],
				part: {},
				step: 0,
				categories: [],
				document: {
					documentId: null,
					initialDocumentId: null,
					currency: 'EUR',
					documentConfigurations: [],
				},
				configuration: {
					configurationId: null,
					documentConfigurationId: null,
					documentPosition: null,
					configurationPublished: false,
					loading: false,
					hasDocument: false,
					position: 0,
					image: '',
					inApproval: false,
					canEdit: true,
				},
			};
		else
			return {
				...state,
				selectedParameters: [],
				selectedParametersTags: [],
				part: {},
				step: 0,
				categories: [],
				configuration: {
					configurationId: null,
					documentConfigurationId: null,
					documentPosition: null,
					configurationPublished: false,
					loading: false,
					hasDocument: false,
					position: action.position,
					image: '',
					inApproval: false,
					canEdit: true,
				},
			};
	}
	if (action.type === setPart) {
		return {
			...state,
			part: {
				id: action.id,
				partId: action.partId,
				name: action.name,
				title: action.title,
				brandName: action.brandName,
				image: action.image,
				price: action.price,
			},
		};
	}
	if (action.type === setPartPrice) {
		return { ...state, part: { ...state.part, price: action.price } };
	}
	if (action.type === clearPartPrice) {
		return { ...state, part: { ...state.part, price: undefined } };
	}
	if (action.type === incrementCategoryStep) {
		return { ...state, step: state.step + 1 };
	}
	if (action.type === decrementCategoryStep) {
		return { ...state, step: state.step - 1 };
	}
	if (action.type === setCategoryStep) {
		return { ...state, step: action.i };
	}
	if (action.type === setCategories) {
		return {
			...state,
			categories: [...action.categories],
		};
	}
	if (action.type === setTree) {
		return { ...state, tree: action.tree };
	}
	if (action.type === setTreeModal) {
		return { ...state, treeModal: action.treeModal };
	}
	if (action.type === setLoading) {
		return { ...state, loading: action.loading };
	}
	if (action.type === setPopupParameters) {
		return { ...state, popupParameters: action.popupParameters };
	}
	if (action.type === setAnonymousToken) {
		return { ...state, anonymousToken: action.userToken };
	}
	if (action.type === setContact) {
		return { ...state, contact: action.contactData };
	}
	if (action.type === addSelectedParameter) {
		let selectedParams = Array.from(state.selectedParameters);
		let foundIndex = selectedParams.findIndex((param: any) => param.parameterId === action.param.parameterId);
		if (foundIndex >= 0) selectedParams[foundIndex] = action.param;
		else selectedParams.push(action.param);
		return { ...state, selectedParameters: selectedParams };
	}
	if (action.type === setSelectedParameters) {
		return { ...state, selectedParameters: action.selectedParameters };
	}
	if (action.type === addSelectedParameterTag) {
		let selectedParamTags = state.selectedParametersTags || [];
		let foundIndex = selectedParamTags.findIndex((param: any) => param.parameterId === action.param.parameterId);
		if (foundIndex >= 0) {
			if (action.param.unselect) {
				selectedParamTags.splice(foundIndex, 1);
			} else {
				selectedParamTags[foundIndex] = action.param;
			}
		} else {
			selectedParamTags.push(action.param);
		}
		return { ...state, selectedParametersTags: selectedParamTags };
	}
	if (action.type === addDefaultParameter) {
		let selectedParams = state.selectedParameters;
		let foundIndex = selectedParams.findIndex((param: any) => param.parameterId === action.param.parameterId);
		if (foundIndex === -1) selectedParams.push(action.param);
		return { ...state, selectedParameters: selectedParams };
	}

	if (action.type === addCategoryComment) {
		let iFound = -1;
		for (let i = 0; i < state.categoryComments.length; i++) {
			if (state.categoryComments[i].categoryId === action.comment.categoryId) {
				iFound = i;
				break;
			}
		}
		return {
			...state,
			categoryComments: [].concat(action.comment, iFound >= 0 ? state.categoryComments.slice(iFound, 1) : state.categoryComments),
		};
	}
	if (action.type === clearParameters) {
		return { ...state, selectedParameters: [], part: action.part, step: 0 };
	}
	if (action.type === saveConfiguration) {
		return {
			...state,
			configuration: {
				...state.configuration,
				configurationId: action.configurationId,
				documentConfigurationId: action.documentConfigurationId,
				documentPosition: action.documentPosition,
				hasDocument: false,
				position: 0,
			},
			selectedParameters: [],
			part: {},
			step: 0,
			categories: [],
			loading: false,
		};
	}
	if (action.type === clearConfiguration) {
		return {
			...state,
			configuration: {
				configurationId: null,
				documentConfigurationId: null,
				documentPosition: null,
				configurationPublished: false,
				loading: false,
				hasDocument: false,
				position: 0,
				image: '',
				inApproval: false,
				canEdit: true,
			},
			selectedParameters: [],
			part: {},
			step: 0,
			categories: [],
			loading: false,
		};
	}
	if (action.type === saveConfigurationComment) {
		return {
			...state,
			configuration: { ...state.configuration, comment: action.comment },
		};
	}
	if (action.type === setConfigurationPublished) {
		return {
			...state,
			configuration: {
				...state.configuration,
				configurationPublished: action.configurationPublished,
			},
		};
	}
	if (action.type === setConfigurationFlags) {
		return {
			...state,
			configuration: {
				...state.configuration,
				configurationPublished: action.published !== undefined ? action.published : state.configuration.configurationPublished,
				canEdit: action.canEdit !== undefined ? action.canEdit : state.configuration.canEdit,
				inApproval: action.inApproval !== undefined ? action.inApproval : state.configuration.inApproval,
				loading: action.loading !== undefined ? action.loading : state.configuration.loading,
				hasDocument: action.hasDocument !== undefined ? action.hasDocument : state.configuration.hasDocument,
				position: action.position !== undefined ? action.position : state.configuration.position,
				loadingImage: action.loadingImage !== undefined ? action.loadingImage : state.configuration.loadingImage,
			},
		};
	}
	if (action.type === setConfigurationLoading) {
		return {
			...state,
			configuration: { ...state.configuration, loading: action.loading },
		};
	}
	if (action.type === setDocument) {
		return {
			...state,
			document: {
				...state.document,
				documentId: action.documentId,
				documentConfigurations: action.documentConfigurations,
				initialDocumentId: action.initialDocumentId,
				name: action.name,
				project: action.project,
				addingMode: false,
				disableEdit: action.disableEdit !== undefined ? action.disableEdit : state.document.disableEdit,
				documentStatusId: action.documentStatusId !== undefined ? action.documentStatusId : state.document.documentStatusId,
				currency: action.currency != null ? action.currency : 'EUR',
			},
		};
	}
	if (action.type === setDocumentAddingMode) {
		return {
			...state,
			document: {
				...state.document,
				addingMode: action.adding,
			},
		};
	}
	if (action.type === setImage) {
		return {
			...state,
			configuration: { ...state.configuration, image: action.image, loadingImage: false },
		};
	}
	if (action.type === setError) {
		return {
			...state,
			error: { title: action.title, message: action.message },
		};
	}
	if (action.type === setBrand) {
		const theme = {
			logo: action.logo,
			logotype: action.logotype,
			title: action.title,
			header: action.header,
			color: action.color,
			headerFontColor: action.headerFontColor,
			buttonColor: action.buttonColor,
			buttonFontColor: action.buttonFontColor,
		};
		localStorage.setItem('theme', JSON.stringify(theme));
		localStorage.setItem('brandPartnerId', action.brandPartnerId);
		return {
			...state,
			theme: theme,
			brandPartnerId: action.brandPartnerId,
		};
	}

	if (action.type === setNumberOfBrands) {
		return { ...state, numberOfBrands: action.numberOfBrands };
	}

	if (action.type === setLanguage) {
		localStorage.setItem('userLanguage', action.language);
		return { ...state, language: action.language };
	}

	if (action.type === setFooter) {
		const footer = {
			footer1text: action.text1,
			footer1url: action.url1,
			footer2text: action.text2,
			footer2url: action.url2,
			footer3text: action.text3,
			footer3url: action.url3,
		};
		localStorage.setItem('footer', JSON.stringify(footer));
		return {
			...state,
			footer: footer,
		};
	}
	if (action.type === setUser) {
		return {
			...state,
			user: {
				email: action.email,
				name: action.name,
				type: action.userType,
				roles: action.roles,
			},
		};
	}
	if (action.type === destroySession) {
		localStorage.removeItem('userToken');
		localStorage.removeItem('userRole');
		localStorage.removeItem('isUserOEM');
		localStorage.removeItem('isUserOEMAdmin');
		localStorage.removeItem('isParentPartner');
		localStorage.removeItem('theme');
		localStorage.removeItem('brandPartnerId');
		return initialState;
	}
	if (action.type === setSearch) {
		return {
			...state,
			search: {
				selectedCategories: action.selectedCategories,
				query: action.query,
			},
		};
	}
	if (action.type === setCartOpen) {
		return { ...state, navCartOpen: action.open };
	}
	if (action.type === setInquiryDialogOpen) {
		return { ...state, inquiryDialogOpen: action.open };
	}
	if (action.type === setShowMessage) {
		return { ...state, showMessage: { show: action.show, title: action.title, message: action.message } };
	}
	if (action.type === setCartAddingDisabled) {
		return { ...state, cartAddingDisabled: action.disabled };
	}
	if (action.type === setBrands) {
		return { ...state, brands: action.brands };
	}
	return state;
};
