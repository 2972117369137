import React from "react";
import { Row, Col } from "reactstrap";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Configurator.css";
import "./Admin.css";
import locale from "../../Locale";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../store/Configurator";
import { RouteComponentProps } from "react-router-dom";
import Helper from "../../Helper";
import CustomerEditMenu from "./CustomerEditMenu";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Loading from "react-fullscreen-loading";

interface IProps extends RouteComponentProps<any> {
    document?: any;
}
interface IState {
    selectedId: string;
    historyList: any;
    loading: boolean;
}
class CustomerHistory extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedId: "",
            historyList: [],
            loading: false
        };
    }
    historyData(data: any): any {
        let historyList: any = [];
        if (data && data.length > 0) {
            data.forEach((item: any) => {
                historyList.push({
                    id: item.Id,
                    image: item.Image,
                    text: item.Message
                });
            });
        }
        return historyList;
    }
    componentDidMount() {
        this.setState({ loading: true });
        const state: IState = this.state;
        let url ="/document/" + this.props.document.documentId + "/history";

        Helper.getData<any>(url)
            .then(data => {
                if (data && data.length > 0) {
                    let historyList: any = [];
                    data.forEach((item: any) => {
                        historyList.push({
                            id: item.SalesDocumentId,
                            configurationId: item.ConfigurationId,
                            dateChanged: Helper.dateWithTimeConvert(item.DateTime),
                            dateChangedDate: item.DateTime,
                            text: Helper.getLocalizedName(item.Text),
                            type: item.ConfigurationId !== null ? (Helper.getLocalizedName(item.Type) +"\n" + item.ConfigurationId) : Helper.getLocalizedName(item.Type),
                            user: item.User,
                            value: Helper.getLocalizedValueOrNull(item.Value)
                        });
                    });
                    state.historyList = historyList;
                    this.setState(state);
                }
                this.setState({ loading: false });
            })
            .catch(error => {
                this.setState({ loading: false });
                console.log(error);
            });
    }
    render() {
        const { historyList } = this.state;
        const options = {
            pageSize: 5,
            noDataText: locale.noResults
        };
        const multilineCell = (cell: any, row: any) => {
            if (cell) {
                return `<textarea class='form-control cell' rows='3' disabled>${cell}</textarea>`;
            } else {
                return "";
            }
        };
        const dateSort = (a: any, b: any, order: string) => {
            if (order === "asc") {
                if (new Date(a.dateChangedDate) < new Date(b.dateChangedDate))
                    return 1;
                if (new Date(a.dateChangedDate) > new Date(b.dateChangedDate))
                    return -1;
            } else if (order === "desc") {
                if (new Date(a.dateChangedDate) > new Date(b.dateChangedDate))
                    return 1;
                if (new Date(a.dateChangedDate) < new Date(b.dateChangedDate))
                    return -1;
            }
            return 0;
        };
        return (
            <div id="configurator" className="admin">
            {this.state.loading ? (
                <Loading loading loaderColor={Helper.themeButtonColor()} />
            ) : (
                <Row>
                    <Col xl="2" lg="3" className="no-gutters mt-3 pr-3">
                        <CustomerEditMenu />
                    </Col>
                    <Col
                        xl="10"
                        lg="9"
                        className="mt-3 configurator-background"
                    >
                        <Row>
                            <Col xs="12" className="mt-3">
                                <BootstrapTable
                                    data={historyList}
                                    options={options}
                                    striped
                                    bordered={false}
                                    pagination
                                    keyField="id"
                                    tableStyle={{ backgroundColor: "white" }}
                                >
                                    <TableHeaderColumn
                                        dataField="type"
                                        filter={{
                                            type: "TextFilter",
                                            delay: 500,
                                            placeholder: "Filter"
                                        }}
                                        width="150px"
                                        dataFormat={multilineCell}
                                    >
                                        {locale.type}
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="text"
                                        filter={{
                                            type: "TextFilter",
                                            delay: 500,
                                            placeholder: "Filter"
                                        }}
                                        width="250px"
                                        dataFormat={multilineCell}
                                    >
                                        {locale.text}
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="value"
                                        filter={{
                                            type: "TextFilter",
                                            delay: 500,
                                            placeholder: "Filter"
                                        }}
                                        width="250px"
                                        dataFormat={multilineCell}
                                    >
                                        {locale.value}
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        columnTitle
                                        dataField="user"
                                        filter={{
                                            type: "TextFilter",
                                            delay: 500,
                                            placeholder: "Filter"
                                        }}
                                        dataSort
                                        width="100px"
                                    >
                                        {locale.user}
                                    </TableHeaderColumn>

                                    <TableHeaderColumn
                                        dataField="dateChanged"
                                        filter={{
                                            type: "TextFilter",
                                            delay: 500,
                                            placeholder: "",
                                            style: { opacity: 0 }
                                        }}
                                        dataSort
                                        sortFunc={dateSort}
                                        width="150px"
                                        dataAlign="right"
                                        headerAlign="right"
                                    >
                                        {locale.date}
                                    </TableHeaderColumn>
                                </BootstrapTable>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            </div>
        );
    }
}

export default connect(
    (state: any) => state.configurator,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(CustomerHistory);
