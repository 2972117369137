import React from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Dropdown,
} from "reactstrap";
import "./Configurator.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../store/Configurator";
import { RouteComponentProps } from "react-router-dom";
import moreImage from "../images/mini-more.png";
import Helper, {dataType, deepCompare} from "../Helper";
import Slider from "./Slider";
import PopupImages from "./PopupImages";
import PopupColors from "./PopupColors";
import Button from "./Button";

interface IProps extends RouteComponentProps<any> {
  parameterId: string;
  parameterName: string;
  parameterType: number;
  showPicture: boolean;
  isEnabled: boolean;
  initialValues: any;
  selectedParameterValue: any;
  selectedParameterTag: any;
  partId: string;
  defaultValue: any;
  updateModelDetails(parameterPartId: string, parameterId: string, parameterValueId: string, customValue: string): void;
}

interface IState {
  values: any;
  open: boolean;
}

class Parameter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
      values: props.initialValues,
    };
  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
    return !deepCompare(this.props, nextProps, true) || !deepCompare(this.state, nextState, true);
  }

  pushParameterValue(parameterValues: any, parameterValue: any, type: number) {
    let value = "";
    let imageUrl = null;
    let tags = null;
    if (type === dataType.color || type === dataType.double || type === dataType.decimal) {
        value = parameterValue.ParameterValue;
      if (parameterValue.ImageUrl) {
        imageUrl = parameterValue.ImageUrl;
      }
    } else if (type === dataType.integer) {
        value = parameterValue.IntValue;
    } else {
      // Dropdown
      value = parameterValue.ParameterValue;
      if (parameterValue.ImageUrl) 
        imageUrl = parameterValue.ImageUrl;
    }
    if (parameterValue.Tags && parameterValue.Tags !== "") {
      tags = Helper.getLocalizedName(parameterValue.Tags);
    }
    parameterValues.push({
      id: parameterValue.ParameterValueId,
      value: value,
      valueName: parameterValue.ParameterValueName,
      brandValue: parameterValue.ParameterBrandValue,
      isDefault: parameterValue.IsDefault,
      imageUrl: imageUrl ? imageUrl.replace("_Original.", "_M.") : "",
      tags: tags,
      color: parameterValue.Color
    });
  }

  parameterToggle() {
    if (this.canEdit()) {
      const state: IState = this.state;
      if (!state.open) {
        const url = "/configurator/values/" + this.props.partId + "?parameterId=" + this.props.parameterId +
         "&brandPartnerId=" + Helper.brandPartnerId();

        Helper.getData<any>(url)
          .then((paramData) => {
            if (paramData && paramData.length > 0) {
              let parameterValues: any = [];
              paramData.forEach((parameterValue: any) => {
                this.pushParameterValue(
                  parameterValues,
                  parameterValue,
                  this.props.parameterType
                );
              });
              this.setState({ open: !state.open, values: parameterValues });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({ open: !state.open });
      }
    }
  }

  getSelectedParameter(): string {
    if (this.props.selectedParameterValue != null)
      return this.props.selectedParameterValue;
    
    // Disabled fields
    if (this.props && this.props.defaultValue) {
      const defaultValue = this.props.defaultValue;
      return Helper.getLocalizedValueOrNull(defaultValue.brandValue) ?? Helper.getLocalizedValueOrNull(defaultValue.value) ?? defaultValue.valueName ?? "";
    }
    return "";
  }
  getSelectedParameterTag(): string {
    if (this.props.selectedParameterTag != null)
      return this.props.selectedParameterTag;
    
    return "";
  }
  canEdit() {
    return this.props.isEnabled;
  }

  setSelectedParameter(
    parameterValue: string,
    parameterValueId: string,
    customValue: string,
    keepOpen?: boolean,
    close?: boolean
  ) {
    if (close) {
      this.setState({ open: false });
    } else {
      if (this.canEdit()) {
        let selectedParameter: any = {
          parameterId: this.props.parameterId,
          parameterValue: parameterValue,
          parameterValueId: parameterValueId,
        };
        (this.props as any).addSelectedParameter(selectedParameter);
        this.props.updateModelDetails(this.props.partId, this.props.parameterId, parameterValueId, customValue);
        if (!keepOpen) {
          this.setState({ open: false });
        }
      }
    }
  }
  setSelectedParameterTag(id: string, unselected: boolean) {
    const selectedParameterTag: any = {
      parameterId: this.props.parameterId,
      tag: id,
      unselect: unselected,
    };
    (this.props as any).addSelectedParameterTag(selectedParameterTag);
  }

  setDefaultParameter(parameterValue: string, parameterValueId: string) {
    let selectedParameter: any = {
      parameterId: this.props.parameterId,
      parameterValue: parameterValue,
      parameterValueId: parameterValueId,
    };
    (this.props as any).addDefaultParameter(selectedParameter);
  }

  setSelectedColorParameter(parameterValue: string, parameterValueId: string) {
    let selectedParameter: any = {
      parameterId: this.props.parameterId,
      parameterValue: parameterValue,
      parameterValueId: parameterValueId,
    };
    (this.props as any).addSelectedParameter(selectedParameter);
    setTimeout(() => this.setState({ open: false }), 500);
  }

  render() {
    const {
      state: { open, values },
      props: { parameterId, parameterName, parameterType, showPicture },
    } = this;
    const canEdit = this.canEdit();

    let defaultValue = { value: "", name: "" };
    let parameterValuesList: any = null;
    let selectedValue = this.getSelectedParameter();
    const selectedTag = this.getSelectedParameterTag();
    let parameterDisabled = false;
    let hasImages = showPicture; //If true show popup else dropdown

    if (parameterType === dataType.color) {
      let colors: any = [];
      values.forEach((value: any) => {
        try {
          if (value.value.indexOf("#") >= 0 && value.value.indexOf("|") >= 0) {
            let colorName = value.value.split("|")[0];
            if (colors.indexOf(colorName) === -1)
              colors.push(value.value.split("|")[0]);
          } else {
            colors.push(value.value);
          }
        } catch (err) {}
      });

      parameterValuesList = (
        <PopupColors
          canEdit={canEdit}
          setSelectedParameter={(name: string, id: string) =>
            this.setSelectedParameter(name, id, "")
          }
          setSelectedParameterTag={(id: string, unselected: boolean) =>
            this.setSelectedParameterTag(id, unselected)
          }
          selectedTag={selectedTag}
          values={values}
          selectedValue={selectedValue}
          parameterId={parameterId}
        />
      );
    } else if (parameterType === dataType.integer) {
      let min = 0;
      let max = 0;
      let initialValues = this.props.initialValues;
      if (initialValues && initialValues.length > 0) {
        if (initialValues[0].range) {
          const range = initialValues[0].range;
          min = range.min;
          max = range.max;
        }
      }
      parameterDisabled = min === max;
      if (parameterDisabled) {
        parameterValuesList = [];
      } else {
        parameterValuesList = (
          <Slider
            canEdit={canEdit}
            update={false}
            min={min}
            max={max}
            setSelectedParameter={(
              name: string,
              value: string,
              keepOpen?: boolean,
              close?: boolean
            ) => this.setSelectedParameter(name, "", value, keepOpen, close)}
            selectedValue={selectedValue}
            parameterId={parameterId}
          />
        );
      }
    } else if (hasImages) {
      parameterValuesList = (
        <PopupImages
          canEdit={canEdit}
          setSelectedParameter={(name: string, id: string) =>
            this.setSelectedParameter(name, id, "")
          }
          setSelectedParameterTag={(id: string, unselected: boolean) =>
            this.setSelectedParameterTag(id, unselected)
          }
          values={values}
          selectedValue={selectedValue}
          selectedTag={selectedTag}
          parameterId={parameterId}
        />
      );
    } else {
      parameterValuesList = values.map((value: any, k: string) => {
        let title = Helper.getLocalizedValueOrNull(value.brandValue) ?? Helper.getLocalizedValueOrNull(value.value) ?? value.valueName;
        if (value.isDefault) {
          // eslint-disable-next-line
          defaultValue = value.value;
          return (
            <DropdownItem
              disabled={!canEdit}
              key={k}
              style={selectedValue === title ? { backgroundColor: "lightgray" } : {}}
              onClick={() =>
                this.setSelectedParameter(title, value.id, "")
              }
            >
              {title}
            </DropdownItem>
          );
        } else {
          return (
            <DropdownItem
              disabled={!canEdit}
              key={k}
              style={selectedValue === title ? { backgroundColor: "lightgray" } : {}}
              onClick={() =>
                this.setSelectedParameter(title, value.id, "")
              }
            >
              {title}
            </DropdownItem>
          );
        }
      });
    }
    return (
      <Col md="12" lg="6">
        <label className="smaller mb-1 mt-1">{parameterName}</label>
        <div>
          {!parameterDisabled ? (
            <Dropdown
              className={"configuration-filter type-" + parameterType}
              isOpen={open}
              direction={parameterType === dataType.integer ? "down" : "up"}
              toggle={() => this.parameterToggle()}
            >
              <DropdownToggle color="selector">
                {selectedValue}
                {canEdit ? (
                  <img src={moreImage} alt={" "} className="filter-image" />
                ) : null}
              </DropdownToggle>
              <DropdownMenu>{parameterValuesList}</DropdownMenu>
            </Dropdown>
          ) : (
            <div className={"configuration-filter type-" + parameterType}>
              <Button style={{ cursor: "default" }} color="selector">
                {selectedValue}
              </Button>
            </div>
          )}
        </div>
      </Col>
    );
  }
}

//export default Parameter;

export default connect(
  null, // (state: any) => state.configurator,
  (dispatch) => bindActionCreators(actionCreators, dispatch)
)(Parameter);
