import React from "react";
import {
    Col,
    Button,
    Container,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import "./Configurator.css";
import "./ResetPassword.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../store/Configurator";
import locale from "../Locale";
import Helper from "../Helper";

interface IState {
    email: string;
    afterReset: boolean;
    validate: {
        emailState: string;
    };
}

class ResetPassword extends React.Component<any, IState> {
    private timeout: number;

    constructor(props: any) {
        super(props);
        this.state = {
            email: "",
            afterReset: false,
            validate: {
                emailState: ""
            }
        };
        this.timeout = 0;
    }

    validateEmail(e: React.FormEvent<HTMLInputElement>) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state;
        let value = e.currentTarget.value;
        if (this.timeout) window.clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            if (emailRex.test(value)) {
                validate.emailState = "has-success";
            } else {
                validate.emailState = "has-danger";
            }
            this.setState({ validate });
        }, 700);
    }

    handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
        const { currentTarget } = event;
        const value =
            currentTarget.type === "checkbox"
                ? currentTarget.checked
                : currentTarget.value;
        const { name } = currentTarget;
        await this.setState({
            [name]: value
        } as any);
    };

    async submitForm(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (this.state.email === "") return;

        const state: IState = this.state;

        Helper.postData<any>(
            "/account/reset",
            JSON.stringify({ username: this.state.email })
        ).then((result: any) => {
            state.afterReset = true;
            this.setState(state);
        });
    }

    render() {
        const { email, afterReset } = this.state;
        return (
            <Container className="reset-password">
                <Form className="form" onSubmit={e => this.submitForm(e)}>
                    <Col>
                        <h2>{locale.resetPassword}</h2>
                        <br></br>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>{locale.email}</Label>
                            <Input
                                type="email"
                                name="email"
                                id="exampleEmail"
                                placeholder="myemail@email.com"
                                value={email}
                                valid={
                                    this.state.validate.emailState ===
                                    "has-success"
                                }
                                invalid={
                                    this.state.validate.emailState ===
                                    "has-danger"
                                }
                                onChange={e => {
                                    this.validateEmail(e);
                                    this.handleChange(e);
                                }}
                            />
                        </FormGroup>
                    </Col>
                    <Col>
                        <Button>{locale.submit}</Button>
                    </Col>
                    <Col>
                        <div
                            className={"valid-feedback mt-2 "}
                            style={{ display: afterReset ? "block" : "none" }}
                        >
                            {locale.resetPasswordMessage}
                        </div>
                    </Col>
                </Form>
            </Container>
        );
    }
}

export default connect(
    (state: any) => state.configurator,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ResetPassword);
