import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../store/Configurator";
import Button from "./Button";
import locale from "../Locale";

interface IProps {
  error?: any;
}
interface IState {
  show: boolean;
}
class ErrorModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      show: false,
    };
  }
  showError() {
    if (this.props.error && this.props.error.message && this.props.error.message !== "") {
      this.setState({ show: true });
    }
  }
  componentDidMount() {
    this.showError();
  }
  componentDidUpdate(nextProps: any) {
    if (nextProps.error !== this.props.error) {
      this.showError();
    }
  }
  render() {
    if (this.props.error) {
      return (
        <Modal
          size="lg"
          isOpen={this.state.show}
          toggle={() => this.toggleModal()}
        >
          <ModalHeader toggle={() => this.toggleModal()}>
            {this.props.error.title}
          </ModalHeader>
          <ModalBody>
            {this.props.error.message}
            <br />
            <br />
            <Button
              className={"button btn-secondary my-2"}
              onClick={() => this.toggleModal()}
            >
              {locale.close}
            </Button>
          </ModalBody>
        </Modal>
      );
    } else {
      return null;
    }
  }
  toggleModal() {
    (this.props as any).setError("", "");
    this.setState({ show: !this.state.show });
  }
}

export default connect(
  (state: any) => state.configurator,
  (dispatch) => bindActionCreators(actionCreators, dispatch)
)(ErrorModal);
