import React from 'react';
import { Col, Container, Form, FormGroup, Label, Input, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './Configurator.css';
import './Register.css';
import locale from '../Locale';
import Helper from '../Helper';
import Button from './Button';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-fullscreen-loading';

interface IState {
	addressing: string;
	firstName: string;
	lastName: string;
	email: string;
	address: string;
	zip: string;
	city: string;
	companyName: string;
	companyRegistration: string;
	companyVAT: string;
	countries: any[];
	countryId: string;
	telephone: string;
	marketing: boolean;
	notifications: boolean;
	personalData: boolean;
	deliveryAddress: any;
	receiptAddress: any;
	addEditModal: boolean;
	addEditModalTitle: string;
	addEditModalAction: string;
	addEditAddressing: string;
	addEditFirstName: string;
	addEditLastName: string;
	addEditAddress: string;
	addEditZip: string;
	addEditCity: string;
	addEditCompanyName: string;
	addEditCompanyRegistration: string;
	addEditCompanyVAT: string;
	addEditCountryId: string;
	addEditTelephone: string;
	addEditCompany: boolean;
	addEditSelectedId: string;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	selectedId: string;
	registerError: boolean;
	registerSuccess: boolean;
	registerMessage: string;
	addEditError: boolean;
	addEditMessage: string;
	loading: boolean;
}

export default class Profile extends React.Component<any, IState> {
	private timeout: any;
	constructor(props: any) {
		super(props);

		this.timeout = 0;
		this.state = {
			addressing: '',
			firstName: '',
			lastName: '',
			email: '',
			address: '',
			zip: '',
			city: '',
			companyName: '',
			companyRegistration: '',
			companyVAT: '',
			countries: [],
			countryId: '',
			telephone: '',
			marketing: false,
			notifications: false,
			personalData: false,
			deliveryAddress: [],
			receiptAddress: [],
			addEditModal: false,
			addEditModalTitle: '',
			addEditModalAction: '',
			addEditAddressing: '',
			addEditFirstName: '',
			addEditLastName: '',
			addEditAddress: '',
			addEditZip: '',
			addEditCity: '',
			addEditCompanyName: '',
			addEditCompanyRegistration: '',
			addEditCompanyVAT: '',
			addEditCountryId: '',
			addEditTelephone: '',
			addEditCompany: false,
			addEditSelectedId: '',
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			selectedId: '',
			registerError: false,
			registerSuccess: false,
			registerMessage: '',
			addEditError: false,
			addEditMessage: '',
			loading: false,
		};
	}

	getDeliveryAddressData(deliveryAddressData: any) {
		let state: IState = this.state;

		let deliveryAddress: any = [];

		if (deliveryAddressData.length > 0) {
			deliveryAddressData.forEach((item: any) => {
				deliveryAddress.push({
					id: item.UserAddressId,
					addressing: item.Addressing,
					firstName: item.FirstName,
					lastName: item.LastName,
					address: item.Address,
					zip: item.Zip,
					city: item.City,
					countryId: item.CountryId,
					company: item.Company,
					companyName: item.CompanyName,
					companyRegistration: item.CompanyRegistration,
					companyVAT: item.CompanyVAT,
					telephone: item.Telephone,
					name:
						item.Addressing +
						' ' +
						item.FirstName +
						' ' +
						item.LastName +
						', ' +
						item.Address +
						', ' +
						item.Zip +
						' ' +
						item.City +
						(state.countries.find((element) => element.value === item.CountryId) !== undefined
							? ', ' + state.countries.find((element) => element.value === item.CountryId).code
							: '') +
						(item.CompanyName !== '' ? ', ' + item.CompanyName : '') +
						(item.Telephone !== '' ? ', ' + item.Telephone : ''),
					isDefault: item.Default,
				});
			});
		}
		state.deliveryAddress = deliveryAddress;

		this.setState(state);
	}

	getReceiptAddressData(receiptAddressData: any) {
		let state: IState = this.state;

		let receiptAddress: any = [];

		if (receiptAddressData.length > 0) {
			receiptAddressData.forEach((item: any) => {
				receiptAddress.push({
					id: item.UserAddressId,
					addressing: item.Addressing,
					firstName: item.FirstName,
					lastName: item.LastName,
					address: item.Address,
					zip: item.Zip,
					city: item.City,
					countryId: item.CountryId,
					company: item.Company,
					companyName: item.CompanyName,
					companyRegistration: item.CompanyRegistration,
					companyVAT: item.CompanyVAT,
					telephone: item.Telephone,
					name:
						item.Addressing +
						' ' +
						item.FirstName +
						' ' +
						item.LastName +
						', ' +
						item.Address +
						', ' +
						item.Zip +
						' ' +
						item.City +
						(state.countries.find((element) => element.value === item.CountryId) !== undefined
							? ', ' + state.countries.find((element) => element.value === item.CountryId).code
							: '') +
						(item.CompanyName !== '' ? ', ' + item.CompanyName : '') +
						(item.Telephone !== '' ? ', ' + item.Telephone : ''),
					isDefault: item.Default,
				});
			});
		}
		state.receiptAddress = receiptAddress;

		this.setState(state);
	}

	removeAddress(isDelivery: boolean) {
		this.setState({ loading: true });
		let url = '';
		if (isDelivery) {
			url += '/account/removeDeliveryAddress?userAddressId=' + this.state.selectedId;
		} else {
			url += '/account/removeReceiptAddress?userAddressId=' + this.state.selectedId;
		}
		this.setState({ selectedId: '' });
		Helper.postData<any>(url, '').then((saveData) => {
			if (saveData && saveData) {
				if (isDelivery) {
					this.getDeliveryAddressData(saveData);
				} else {
					this.getReceiptAddressData(saveData);
				}
			}
			this.setState({ loading: false });
		});
	}

	saveAddressModal(isDelivery: boolean, edit: boolean) {
		this.setState({ loading: true });
		const state: IState = this.state;

		let url = '';
		let body = {};
		if (
			state.addEditFirstName === '' ||
			state.addEditLastName === '' ||
			state.addEditAddress === '' ||
			state.addEditZip === '' ||
			state.addEditCity === '' ||
			state.addEditCountryId === '' ||
			state.addEditCountryId === '00000000-0000-0000-0000-000000000000'
		) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		if (edit) {
			url = '/account/update';
		} else {
			url = '/account/add';
		}
		if (isDelivery) {
			url += 'DeliveryAddress';
		} else {
			url += 'ReceiptAddress';
		}
		body = {
			UserAddressId: state.addEditSelectedId,
			Addressing: state.addEditAddressing,
			FirstName: state.addEditFirstName,
			LastName: state.addEditLastName,
			Address: state.addEditAddress,
			Zip: state.addEditZip,
			City: state.addEditCity,
			CompanyName: state.addEditCompanyName,
			CompanyRegistration: state.addEditCompanyRegistration,
			CompanyVAT: state.addEditCompanyVAT,
			CountryId: state.addEditCountryId,
			Telephone: state.addEditTelephone,
		};
		Helper.postData<any>(url, JSON.stringify(body))
			.then((saveData) => {
				if (saveData && saveData) {
					if (this.timeout) clearTimeout(this.timeout);
					state.addEditMessage = '';
					state.addEditError = false;
					state.addEditModal = false;
					state.addEditModalAction = '';
					state.addEditModalTitle = '';
					state.addEditSelectedId = '';
					state.addEditAddressing = '';
					state.addEditFirstName = '';
					state.addEditLastName = '';
					state.addEditAddress = '';
					state.addEditZip = '';
					state.addEditCity = '';
					state.addEditCompanyName = '';
					state.addEditCompanyRegistration = '';
					state.addEditCompanyVAT = '';
					state.addEditCountryId = '';
					state.addEditTelephone = '';
					this.setState(state);
					if (isDelivery) {
						this.getDeliveryAddressData(saveData);
					} else {
						this.getReceiptAddressData(saveData);
					}
				}
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.log(error);
			});
	}

	setDefaultAddress(isDelivery: boolean, id: string) {
		this.setState({ loading: true });
		let url = '';
		if (isDelivery) {
			url += '/account/setDefaultDeliveryAddress?userAddressId=' + id;
		} else {
			url += '/account/setDefaultReceiptAddress?userAddressId=' + id;
		}
		Helper.postData<any>(url, '').then((saveData) => {
			if (saveData && saveData) {
				if (isDelivery) {
					this.getDeliveryAddressData(saveData);
				} else {
					this.getReceiptAddressData(saveData);
				}
			}
			this.setState({ loading: false });
		});
	}

	toggleConfirmationModal(title?: string, message?: string, action?: string, id?: string) {
		const state: IState = this.state;

		state.confirmationModal = !state.confirmationModal;
		if (title && message && action && id) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.confirmationModalAction = action;
			state.selectedId = id;
		}
		this.setState(state);
	}

	toggleAddressModal(reset: boolean, isDelivery: boolean, edit: boolean, title?: string, id?: string, item?: any) {
		const state: IState = this.state;

		state.addEditModal = !state.addEditModal;
		state.addEditMessage = '';
		state.addEditError = false;
		if (!reset) {
			if (title) {
				state.addEditModalTitle = title;
			}
			if (!edit) {
				if (isDelivery) {
					state.addEditModalAction = 'addDelivery';
				} else {
					state.addEditModalAction = 'addReceipt';
				}
			} else if (id && edit && item) {
				state.addEditSelectedId = id;
				state.addEditAddressing = item.addressing;
				state.addEditFirstName = item.firstName;
				state.addEditLastName = item.lastName;
				state.addEditAddress = item.address;
				state.addEditZip = item.zip;
				state.addEditCity = item.city;
				state.addEditCountryId = item.countryId;
				state.addEditCompany = item.company;
				state.addEditCompanyName = item.companyName;
				state.addEditCompanyRegistration = item.companyRegistration;
				state.addEditCompanyVAT = item.companyVAT;
				state.addEditTelephone = item.telephone;
				if (isDelivery) {
					state.addEditModalAction = 'editDelivery';
				} else {
					state.addEditModalAction = 'editReceipt';
				}
			}
		} else {
			state.addEditModalAction = '';
			state.addEditModalTitle = '';
			state.addEditSelectedId = '';
			state.addEditAddressing = '';
			state.addEditFirstName = '';
			state.addEditLastName = '';
			state.addEditAddress = '';
			state.addEditZip = '';
			state.addEditCity = '';
			state.addEditCompanyName = '';
			state.addEditCompanyRegistration = '';
			state.addEditCompanyVAT = '';
			state.addEditCountryId = '';
			state.addEditTelephone = '';
		}
		this.setState(state);
	}

	confirmationDelete() {
		if (this.state.confirmationModalAction === 'removeDelivery') {
			this.removeAddress(true);
		} else if (this.state.confirmationModalAction === 'removeReceipt') {
			this.removeAddress(false);
		}
		this.setState({ confirmationModal: false });
	}

	confirmationAddress() {
		if (this.state.addEditModalAction === 'addDelivery') {
			this.saveAddressModal(true, false);
		} else if (this.state.addEditModalAction === 'addReceipt') {
			this.saveAddressModal(false, false);
		} else if (this.state.addEditModalAction === 'editDelivery') {
			this.saveAddressModal(true, true);
		} else if (this.state.addEditModalAction === 'editReceipt') {
			this.saveAddressModal(false, true);
		}
	}

	componentDidMount() {
		this.setState({ loading: true });
		let url = '/account/info';
		Helper.getData<any>(url).then((data: any) => {
			if (data) {
				this.setState({
					addressing: data.Addressing,
					firstName: data.FirstName,
					lastName: data.LastName,
					email: data.Email,
					address: data.Address,
					zip: data.Zip,
					city: data.City,
					companyName: data.CompanyName,
					companyRegistration: data.CompanyRegistration,
					companyVAT: data.CompanyVAT,
					countryId: data.CountryId,
					telephone: data.Telephone,
					marketing: data.Marketing,
					notifications: data.Notification,
					personalData: data.PersonalData,
				});
				if (data.DeliveryAddress && data.DeliveryAddress.length > 0) {
					this.getDeliveryAddressData(data.DeliveryAddress);
				}
				if (data.ReceiptAddress && data.ReceiptAddress.length > 0) {
					this.getReceiptAddressData(data.ReceiptAddress);
				}
			}
			this.setState({ loading: false });
		});
		Helper.getData('/selection/countrylist')
			.then((data: any) => {
				let countriesFromApi = data
					.map((country: any) => {
						return { value: country.CountryId, code: country.Code, display: Helper.getLocalizedName(country.Name) };
					})
					.sort((a: any, b: any) => a.display.localeCompare(b.display));
				this.setState({
					countries: [
						{
							value: '',
							code: '',
							display: locale.selectCountry,
						},
					].concat(countriesFromApi),
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleChange = async (event: React.FormEvent<HTMLInputElement>) => {
		const { currentTarget } = event;
		const value = currentTarget.type === 'checkbox' ? currentTarget.checked : currentTarget.value;
		const { name } = currentTarget;
		await this.setState({
			[name]: value,
		} as unknown as Pick<IState, keyof IState>);
	};

	submitForm(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();
		const state: IState = this.state;
		if (
			state.firstName === '' ||
			state.lastName === '' ||
			state.address === '' ||
			state.zip === '' ||
			state.city === '' ||
			state.countryId === '' ||
			state.countryId === '00000000-0000-0000-0000-000000000000'
		) {
			state.registerMessage = locale.missingFields;
			state.registerError = true;
			state.registerSuccess = false;
			this.setState(state);
			return;
		}

		let data = {
			Addressing: state.addressing,
			FirstName: state.firstName,
			LastName: state.lastName,
			Email: state.email,
			Address: state.address,
			Zip: state.zip,
			City: state.city,
			CompanyName: state.companyName,
			CompanyRegistration: state.companyRegistration,
			CompanyVAT: state.companyVAT,
			CountryId: state.countryId,
			Telephone: state.telephone,
			Marketing: state.marketing,
			Notification: state.notifications,
			PersonalData: state.personalData,
		};

		Helper.postData<any>('/account/update', JSON.stringify(data)).then((res) => {
			if (res.Message && res.Message !== '') {
				state.registerMessage = res.Message;
				state.registerError = true;
				state.registerSuccess = false;
				this.setState(state);
			} else {
				state.registerMessage = locale.profileUpdateSucceeded;
				state.registerError = false;
				state.registerSuccess = true;
				this.setState(state);
			}
		});
	}

	render() {
		const {
			addressing,
			firstName,
			lastName,
			email,
			address,
			zip,
			city,
			companyName,
			companyRegistration,
			companyVAT,
			telephone,
			addEditAddressing,
			addEditFirstName,
			addEditLastName,
			addEditAddress,
			addEditZip,
			addEditCity,
			addEditCompanyName,
			addEditCompanyRegistration,
			addEditCompanyVAT,
			addEditTelephone,

			registerError,
			registerMessage,
			registerSuccess,
			addEditError,
			addEditMessage,
		} = this.state;

		const registerFailedVisible = registerError ? 'block' : 'none';
		const registerSuccessVisible = registerSuccess ? 'block' : 'none';
		const addEditFailedVisible = addEditError ? 'block' : 'none';

		const options = {
			noDataText: locale.noData,
		};

		const removeDelivery = (cell: any, row: any) => {
			if (!cell) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'removeDelivery', row.id)} color='none'>
					<i className='fa fa-times' aria-hidden='true'></i>
				</Button>
			);
		};

		const removeReceipt = (cell: any) => {
			if (!cell) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'removeReceipt', cell)} color='none'>
					<i className='fa fa-times' aria-hidden='true'></i>
				</Button>
			);
		};

		const editDelivery = (cell: any, row: any) => {
			if (!cell) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleAddressModal(false, true, true, locale.profileAddressEdit, row.id, row)} color='none'>
					<i className='fa fa-pencil' aria-hidden='true'></i>
				</Button>
			);
		};

		const editReceipt = (cell: any, row: any) => {
			if (!cell) {
				return '';
			}
			return (
				<Button onClick={() => this.toggleAddressModal(false, false, true, locale.profileAddressEdit, row.id, row)} color='none'>
					<i className='fa fa-pencil' aria-hidden='true'></i>
				</Button>
			);
		};

		const setDefaultDelivery = (cell: any, row: any) => {
			if (!cell) {
				return '';
			}
			if (row.isDefault) {
				return (
					<Button color='none' disabled>
						<i className='fa fa-check-square-o' aria-hidden='true'></i>
					</Button>
				);
			}
			return (
				<Button onClick={() => this.setDefaultAddress(true, row.id)} color='none'>
					<i className='fa fa-square-o' aria-hidden='true'></i>
				</Button>
			);
		};

		const setDefaultReceipt = (cell: any, row: any) => {
			if (!cell) {
				return '';
			}
			if (row.isDefault) {
				return (
					<Button color='none' disabled>
						<i className='fa fa-check-square-o' aria-hidden='true'></i>
					</Button>
				);
			}
			return (
				<Button onClick={() => this.setDefaultAddress(false, row.id)} color='none'>
					<i className='fa fa-square-o' aria-hidden='true'></i>
				</Button>
			);
		};

		return (
			<div>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Container>
						<Row form>
							<Col md={6} className='Register'>
								<Form className='form' onSubmit={this.submitForm.bind(this)}>
									<Col>
										<h2>{locale.profile}</h2>
									</Col>
									<Col className='mt-4'>
										<FormGroup>
											<Label for='email'>{locale.email}</Label>
											<Input type='email' name='email' disabled={true} id='email' value={email} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='addressing'>{locale.addressing}</Label>
											<Input type='text' name='addressing' id='addressing' value={addressing} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='firstName' className='required'>
												{locale.firstName}
											</Label>
											<Input type='text' name='firstName' id='firstName' value={firstName} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='lastName' className='required'>
												{locale.lastName}
											</Label>
											<Input type='text' name='lastName' id='lastName' value={lastName} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='address' className='required'>
												{locale.address}
											</Label>
											<Input type='text' name='address' id='address' value={address} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='city' className='required'>
												{locale.city}
											</Label>
											<Input type='text' name='city' id='city' value={city} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='zip' className='required'>
												{locale.zip}
											</Label>
											<Input type='text' name='zip' id='zip' value={zip} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='country' className='required'>
												{locale.country}
											</Label>
											<select
												className='form-control'
												value={this.state.countryId}
												onChange={(e) =>
													this.setState({
														countryId: e.target.value,
													})
												}
											>
												{this.state.countries.map((country) => (
													<option key={country.value} value={country.value}>
														{country.display}
													</option>
												))}
											</select>
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='companyName'>{locale.companyName}</Label>
											<Input type='text' name='companyName' id='companyName' value={companyName} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='companyRegistration'>{locale.companyRegistration}</Label>
											<Input
												type='text'
												name='companyRegistration'
												id='companyRegistration'
												value={companyRegistration}
												onChange={this.handleChange.bind(this)}
											/>
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='companyVAT'>{locale.companyVAT}</Label>
											<Input type='text' name='companyVAT' id='companyVAT' value={companyVAT} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col>
										<FormGroup>
											<Label for='telephone'>{locale.telephone}</Label>
											<Input type='text' name='telephone' id='telephone' value={telephone} onChange={this.handleChange.bind(this)} />
										</FormGroup>
									</Col>
									<Col xs='12' className='full-column'>
										<Row>
											<Col xs='12'>
												<h6>{locale.inquiryConsents}</h6>
											</Col>
										</Row>
									</Col>
									<Col xs='12' className='full-column'>
										<Input
											type='checkbox'
											name='marketing'
											id='marketing-checkbox'
											checked={this.state.marketing}
											onChange={this.handleChange.bind(this)}
										/>

										<p className='checkbox'>
											<strong>{locale.inquiryConsent1Title}</strong> - {locale.inquiryConsent1}
										</p>
									</Col>
									<Col>
										<Input
											type='checkbox'
											name='notifications'
											id='notifications-checkbox'
											checked={this.state.notifications}
											onChange={this.handleChange.bind(this)}
										/>

										<p className='checkbox'>
											<strong>{locale.inquiryConsent2Title}</strong> - {locale.inquiryConsent2}
										</p>
									</Col>
									<Col>
										<Input
											type='checkbox'
											name='personalData'
											id='personal-data-checkbox'
											checked={this.state.personalData}
											onChange={this.handleChange.bind(this)}
										/>

										<p className='checkbox'>
											<strong>{locale.inquiryConsent3Title}</strong> - {locale.inquiryConsent3}
										</p>
									</Col>
									<Col className='mt-4'>
										<Button>{locale.save}</Button>
									</Col>
									<Col>
										<div className={'invalid-feedback mt-2'} style={{ display: registerFailedVisible }}>
											{registerMessage}
										</div>
										<div className={'valid-feedback mt-2 '} style={{ display: registerSuccessVisible }}>
											{registerMessage}
										</div>
									</Col>
								</Form>
							</Col>
						</Row>
						<Row form>
							<Col md={6} className='Register'>
								<Form>
									<Col>
										<h2>{locale.profileDeliveryTitle}</h2>
									</Col>
									<Col className='mt-4'>
										<BootstrapTable data={this.state.deliveryAddress} options={options} striped bordered={true} keyField='id'>
											<TableHeaderColumn dataField='id' dataFormat={removeDelivery} width='58' headerText={locale.remove} columnTitle={locale.remove}>
												{''}
											</TableHeaderColumn>

											<TableHeaderColumn dataField='id' dataFormat={editDelivery} width='58' headerText={locale.edit} columnTitle={locale.edit}>
												{''}
											</TableHeaderColumn>

											<TableHeaderColumn
												dataField='id'
												dataFormat={setDefaultDelivery}
												width='58'
												headerText={locale.default}
												columnTitle={locale.default}
											>
												{''}
											</TableHeaderColumn>

											<TableHeaderColumn columnTitle dataField='name'>
												{locale.name}
											</TableHeaderColumn>
										</BootstrapTable>
										<Button
											onClick={() => this.toggleAddressModal(false, true, false, locale.profileAddressAdd)}
											className={'button btn-secondary mt-3 mb-3'}
										>
											{locale.profileAddressAdd}
										</Button>
									</Col>
								</Form>
							</Col>
						</Row>
						<Row form>
							<Col md={6} className='Register'>
								<Form>
									<Col>
										<h2>{locale.profileReceiptTitle}</h2>
									</Col>
									<Col className='mt-4'>
										<BootstrapTable data={this.state.receiptAddress} options={options} striped bordered={true} keyField='id'>
											<TableHeaderColumn dataField='id' dataFormat={removeReceipt} width='58' headerText={locale.remove} columnTitle={locale.remove}>
												{''}
											</TableHeaderColumn>

											<TableHeaderColumn dataField='id' dataFormat={editReceipt} width='58' headerText={locale.edit} columnTitle={locale.edit}>
												{''}
											</TableHeaderColumn>

											<TableHeaderColumn
												dataField='id'
												dataFormat={setDefaultReceipt}
												width='58'
												headerText={locale.default}
												columnTitle={locale.default}
											>
												{''}
											</TableHeaderColumn>

											<TableHeaderColumn columnTitle dataField='name'>
												{locale.name}
											</TableHeaderColumn>
										</BootstrapTable>
										<Button
											onClick={() => this.toggleAddressModal(false, false, false, locale.profileAddressAdd)}
											className={'button btn-secondary mt-3 mb-3'}
										>
											{locale.profileAddressAdd}
										</Button>
									</Col>
								</Form>
							</Col>
						</Row>
						<Modal size='lg' isOpen={this.state.confirmationModal} toggle={() => this.toggleConfirmationModal()}>
							<ModalHeader toggle={() => this.toggleConfirmationModal()}>{locale.delete}</ModalHeader>
							<ModalBody>
								{locale.confirmDelete}
								<br />
								<br />
								<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationDelete()}>
									{locale.confirm}
								</Button>
								<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
									{locale.cancel}
								</Button>
							</ModalBody>
						</Modal>
						<Modal
							//size="lg"
							isOpen={this.state.addEditModal}
							toggle={() => this.toggleAddressModal(true, false, false)}
						>
							<ModalHeader toggle={() => this.toggleAddressModal(true, false, false)}>{this.state.addEditModalTitle}</ModalHeader>
							<ModalBody>
								<Col>
									<FormGroup>
										<Label for='addEditAddressing'>{locale.addressing}</Label>
										<Input
											type='text'
											name='addEditAddressing'
											id='addEditAddressing'
											value={addEditAddressing}
											onChange={this.handleChange.bind(this)}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditFirstName' className='required'>
											{locale.firstName}
										</Label>
										<Input
											type='text'
											name='addEditFirstName'
											id='addEditFirstName'
											value={addEditFirstName}
											onChange={this.handleChange.bind(this)}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditLastName' className='required'>
											{locale.lastName}
										</Label>
										<Input type='text' name='addEditLastName' id='addEditLastName' value={addEditLastName} onChange={this.handleChange.bind(this)} />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditAddress' className='required'>
											{locale.address}
										</Label>
										<Input type='text' name='addEditAddress' id='addEditAddress' value={addEditAddress} onChange={this.handleChange.bind(this)} />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditCity' className='required'>
											{locale.city}
										</Label>
										<Input type='text' name='addEditCity' id='addEditCity' value={addEditCity} onChange={this.handleChange.bind(this)} />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditZip' className='required'>
											{locale.zip}
										</Label>
										<Input type='text' name='addEditZip' id='addEditZip' value={addEditZip} onChange={this.handleChange.bind(this)} />
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditCountry' className='required'>
											{locale.country}
										</Label>
										<select
											className='form-control'
											value={this.state.addEditCountryId}
											onChange={(e) =>
												this.setState({
													addEditCountryId: e.target.value,
												})
											}
										>
											{this.state.countries.map((addEditCountry) => (
												<option key={addEditCountry.value} value={addEditCountry.value}>
													{addEditCountry.display}
												</option>
											))}
										</select>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditCompanyName'>{locale.companyName}</Label>
										<Input
											type='text'
											name='addEditCompanyName'
											id='addEditCompanyName'
											value={addEditCompanyName}
											onChange={this.handleChange.bind(this)}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditCompanyRegistration'>{locale.companyRegistration}</Label>
										<Input
											type='text'
											name='addEditCompanyRegistration'
											id='addEditCompanyRegistration'
											value={addEditCompanyRegistration}
											onChange={this.handleChange.bind(this)}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditCompanyVAT'>{locale.companyVAT}</Label>
										<Input
											type='text'
											name='addEditCompanyVAT'
											id='addEditCompanyVAT'
											value={addEditCompanyVAT}
											onChange={this.handleChange.bind(this)}
										/>
									</FormGroup>
								</Col>
								<Col>
									<FormGroup>
										<Label for='addEditTelephone'>{locale.telephone}</Label>
										<Input
											type='text'
											name='addEditTelephone'
											id='addEditTelephone'
											value={addEditTelephone}
											onChange={this.handleChange.bind(this)}
										/>
									</FormGroup>
								</Col>
								<Col>
									<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationAddress()}>
										{locale.confirm}
									</Button>
									<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleAddressModal(true, false, false)}>
										{locale.cancel}
									</Button>
								</Col>
								<Col>
									<div className={'invalid-feedback mt-2'} style={{ display: addEditFailedVisible }}>
										{addEditMessage}
									</div>
								</Col>
							</ModalBody>
						</Modal>
					</Container>
				)}
			</div>
		);
	}
}
