import React from 'react';
import { Input } from 'reactstrap';
import Helper from '../Helper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import locale from '../Locale';
interface IProps extends RouteComponentProps<any> {
	hasDocument: boolean | undefined;
	comment: string | undefined;
	configurationId: string | undefined;
}
interface IState {
	comment: string;
}
class Comment extends React.Component<IProps, IState> {
	private timeout: any;

	constructor(props: IProps) {
		super(props);
		this.timeout = 0;

		this.state = {
			comment: this.props.comment || '',
		};
	}
	addCategoryComment(query: string) {
		let url = '/configurator/' + this.props.configurationId + '/updatecomment';
		let body = {
			Comment: query,
		};
		const _this = this;
		Helper.postData<any>(url, JSON.stringify(body))
			.then((saveData) => {
				if (saveData) {
					if (saveData.Message && saveData.Message.length > 0) {
						(this.props as any).setError(locale.comment, saveData.Message);
					} else {
						(_this.props as any).saveConfigurationComment(query);
					}
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	handleChange(e: any) {
		const state: IState = this.state;
		const { target } = e;
		const value = target.value;
		state.comment = value;
		const _this = this;
		this.setState(state);
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			_this.addCategoryComment(value);
		}, 1000);
	}
	render() {
		const disabled = false;
		return (
			<Input
				type='textarea'
				className='smaller'
				style={{ height: '6rem' }}
				maxLength={2000}
				disabled={disabled}
				onChange={(e) => this.handleChange(e)}
				value={this.state.comment}
				name='text'
			/>
		);
	}
}
export default connect(null, (dispatch) => bindActionCreators(actionCreators, dispatch))(Comment);
