import React from 'react';
import { Row, Col, Input, Form, Label, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-datepicker/dist/react-datepicker.css';
import '../Configurator.css';
import './Admin.css';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import CustomerEditMenu from './CustomerEditMenu';
import Helper from '../../Helper';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from '../Button';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	configuration?: any;
	document?: any;
}
interface IState {
	termsPaymentModal: boolean;
	termSalesModal: boolean;
	termsPaymentModalView: boolean;
	termSalesModalView: boolean;
	termsPaymentModalEdit: boolean;
	termSalesModalEdit: boolean;
	termPaymentText: any;
	termPaymentValue: any;
	termPaymentSortOrder: any;
	termSalesText: any;
	termSalesSortOrder: any;
	termPaymentDesc: any;
	termSalesDesc: any;
	saving: boolean;
	configurationPrice: string;
	termsPayments: any;
	termsSales: any;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	selectedId: string;
	addEditError: boolean;
	addEditMessage: string;
}
class CustomerTerms extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			termsPaymentModal: false,
			termSalesModal: false,
			termsPaymentModalView: false,
			termSalesModalView: false,
			termsPaymentModalEdit: false,
			termSalesModalEdit: false,
			termSalesText: { value: '', validate: '' },
			termSalesSortOrder: { value: '', validate: '' },
			termPaymentText: { value: '', validate: '' },
			termPaymentValue: { value: '', validate: '' },
			termPaymentSortOrder: { value: '', validate: '' },
			termSalesDesc: { value: '', validate: '' },
			termPaymentDesc: { value: '', validate: '' },
			saving: false,
			configurationPrice: '',
			termsSales: [],
			termsPayments: [],
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			selectedId: '',
			addEditError: false,
			addEditMessage: '',
		};
	}
	getCalculation() {
		if (!this.props.document.documentId) {
			return;
		}
		let url = '/document/' + this.props.document.documentId + '/terms?brandPartnerId=' + Helper.brandPartnerId();
		Helper.getData<any>(url).then((data: any) => {
			if (data) {
				let termsPaymentsList: any[] = [];
				let termsSalesList: any[] = [];
				const value = data;
				if (value.PaymentMethods) {
					value.PaymentMethods.forEach((method: any, i: number) => {
						termsSalesList.push({
							id: method.TermId,
							name: method.Name,
							isCustom: method.IsCustom,
							desc: method.Description,
							sortOrder: method.SortOrder,
						});
					});
				}
				if (value.PaymentTerms) {
					value.PaymentTerms.forEach((term: any, i: number) => {
						termsPaymentsList.push({
							id: term.TermId,
							name: term.Name,
							isCustom: term.IsCustom,
							desc: term.Description,
							value: term.Value,
							sortOrder: term.SortOrder,
							price: term.Price,
							tableValue: term.Value.toLocaleString('de-DE', {
								style: 'currency',
								currency: 'EUR', // always EUR because of replace
							}).replace('€', '%'),
							tablePrice: term.Price.toLocaleString('de-DE', {
								style: 'currency',
								currency: data.Currency,
							}),
						});
					});
				}
				const state: IState = this.state;
				state.termsSales = termsSalesList;
				state.termsPayments = termsPaymentsList;
				state.termSalesModal = false;
				state.termsPaymentModal = false;
				state.termPaymentText = { value: '', validate: '' };
				state.termPaymentDesc = { value: '', validate: '' };
				state.termPaymentValue = { value: '', validate: '' };
				state.termPaymentSortOrder = { value: '', validate: '' };
				state.termSalesText = { value: '', validate: '' };
				state.termSalesSortOrder = { value: '', validate: '' };
				state.termSalesDesc = { value: '', validate: '' };
				state.confirmationModal = false;
				state.saving = false;
				state.confirmationModal = false;
				state.confirmationModalTitle = '';
				state.confirmationModalMessage = '';
				state.confirmationModalAction = '';
				state.termsPaymentModalView = false;
				state.termSalesModalView = false;
				state.termsPaymentModalEdit = false;
				state.termSalesModalEdit = false;
				state.selectedId = '';
				this.setState(state);
			}
		});
	}

	componentDidMount() {
		if (!Helper.userAdmin()) {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/configuration/monter');
		}
		this.getCalculation();
	}
	toggleTermPaymentModal() {
		const state: IState = this.state;
		state.termPaymentText.value = '';
		state.termPaymentText.validate = '';
		state.termPaymentValue.value = '';
		state.termPaymentValue.validate = '';
		state.termPaymentSortOrder.value = '';
		state.termPaymentSortOrder.validate = '';
		state.termsPaymentModal = !state.termsPaymentModal;
		this.setState(state);
	}
	toggleTermSalesModal() {
		const state: IState = this.state;
		state.termSalesText.value = '';
		state.termSalesText.validate = '';
		state.termSalesSortOrder.value = '';
		state.termSalesSortOrder.validate = '';
		state.termSalesModal = !state.termSalesModal;
		this.setState(state);
	}

	toggleTermPaymentModalView(id?: string) {
		const state: IState = this.state;
		state.termsPaymentModalView = !state.termsPaymentModalView;
		state.termPaymentText = { value: '', validate: '' };
		state.termPaymentDesc = { value: '', validate: '' };
		state.termPaymentValue = { value: '', validate: '' };
		state.termPaymentSortOrder = { value: '', validate: '' };
		state.termSalesText = { value: '', validate: '' };
		state.termSalesSortOrder = { value: '', validate: '' };
		state.termSalesDesc = { value: '', validate: '' };
		if (id && state.termsPayments && state.termsPayments.length > 0) {
			const term = state.termsPayments.find((item: any) => item.id === id);
			state.selectedId = id;
			state.termPaymentText.value = term.name;
			state.termPaymentDesc.value = term.desc;
			state.termPaymentValue.value = term.value;
			state.termPaymentSortOrder.value = term.sortOrder;
		}
		this.setState(state);
	}
	toggleTermSalesModalView(id?: string) {
		const state: IState = this.state;
		state.termSalesModalView = !state.termSalesModalView;
		state.termPaymentText = { value: '', validate: '' };
		state.termPaymentDesc = { value: '', validate: '' };
		state.termPaymentValue = { value: '', validate: '' };
		state.termPaymentSortOrder = { value: '', validate: '' };
		state.termSalesText = { value: '', validate: '' };
		state.termSalesSortOrder = { value: '', validate: '' };
		state.termSalesDesc = { value: '', validate: '' };
		if (id && state.termsSales && state.termsSales.length > 0) {
			const term = state.termsSales.find((item: any) => item.id === id);
			state.selectedId = id;
			state.termSalesText.value = term.name;
			state.termSalesSortOrder.value = term.sortOrder;
			state.termSalesDesc.value = term.desc;
		}
		this.setState(state);
	}

	toggleTermPaymentModalEdit(id?: string) {
		const state: IState = this.state;
		state.termsPaymentModalEdit = !state.termsPaymentModalEdit;
		state.confirmationModalAction = 'updatePaymentTerm';
		state.termPaymentText = { value: '', validate: '' };
		state.termPaymentDesc = { value: '', validate: '' };
		state.termPaymentValue = { value: '', validate: '' };
		state.termPaymentSortOrder = { value: '', validate: '' };
		state.termSalesText = { value: '', validate: '' };
		state.termSalesSortOrder = { value: '', validate: '' };
		state.termSalesDesc = { value: '', validate: '' };
		if (id && state.termsPayments && state.termsPayments.length > 0) {
			const term = state.termsPayments.find((item: any) => item.id === id);
			state.selectedId = id;
			state.termPaymentText.value = term.name;
			state.termPaymentDesc.value = term.desc;
			state.termPaymentValue.value = term.value;
			state.termPaymentSortOrder.value = term.sortOrder;
		}
		this.setState(state);
	}

	toggleTermSalesModalEdit(id?: string) {
		const state: IState = this.state;
		state.termSalesModalEdit = !state.termSalesModalEdit;
		state.confirmationModalAction = 'updateSalesTerm';
		state.termPaymentText = { value: '', validate: '' };
		state.termPaymentDesc = { value: '', validate: '' };
		state.termPaymentValue = { value: '', validate: '' };
		state.termPaymentSortOrder = { value: '', validate: '' };
		state.termSalesText = { value: '', validate: '' };
		state.termSalesSortOrder = { value: '', validate: '' };
		state.termSalesDesc = { value: '', validate: '' };
		if (id && state.termsSales && state.termsSales.length > 0) {
			const term = state.termsSales.find((item: any) => item.id === id);
			state.selectedId = id;
			state.termSalesText.value = term.name;
			state.termSalesSortOrder.value = term.sortOrder;
			state.termSalesDesc.value = term.desc;
		}
		this.setState(state);
	}

	toggleConfirmationModal(title?: string, message?: string, action?: string, id?: string) {
		const state: IState = this.state;
		state.confirmationModal = !state.confirmationModal;
		if (title && message && action) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.confirmationModalAction = action;
		}
		if (id) {
			state.selectedId = id;
		}
		this.setState(state);
	}

	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		const { target } = e;
		const value = target.value;
		state[target.name].value = value;
		state.saved = false;
		this.setState(state);
	}
	validateCostValue(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		let { target } = e;
		let validate = '';
		let value = e.target.value;
		var pattern = /^-?\d{1,6}([,.]\d{1,2})?$/;
		if (pattern.test(value)) {
			validate = 'has-success';
			state.errorNumberInput = false;
		} else {
			validate = 'has-danger';
			state.errorNumberMessage = locale.inputNumber + ' (' + locale.twoDecimalPlaces + ')';
			state.errorNumberInput = true;
		}
		state[target.name].validate = validate;
		this.setState(state);
	}
	validateSortOrder(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		let { target } = e;
		let validate = '';
		let value = e.target.value;
		var pattern = /^\+?(0|[1-9]\d*)$/;
		if (pattern.test(value)) {
			validate = 'has-success';
			state.errorNumberInput = false;
		} else {
			validate = 'has-danger';
			state.errorNumberMessage = locale.inputNumber + ' (' + locale.twoDecimalPlaces + ')';
			state.errorNumberInput = true;
		}
		state[target.name].validate = validate;
		this.setState(state);
	}
	confirmationAction(action: string) {
		if (!this.state.selectedId) {
			return;
		}
		this.setState({ saving: true });
		const state: IState = this.state;
		if (action === 'deleteSalesTerm') {
			const url =
				'/document/' + this.props.document.documentId + '/deliveryTerm/' + this.state.selectedId + '?brandPartnerId=' + Helper.brandPartnerId();
			Helper.deleteData<any>(url).then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				}
				this.getCalculation();
			});
		} else if (action === 'deletePaymentTerm') {
			const url =
				'/document/' + this.props.document.documentId + '/paymentTerm/' + this.state.selectedId + '?brandPartnerId=' + Helper.brandPartnerId();
			Helper.deleteData<any>(url).then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				}
				this.getCalculation();
			});
		} else if (action === 'updateSalesTerm') {
			if (!state.termSalesText.value || !state.termSalesDesc.value || state.termSalesSortOrder.value === '') {
				state.addEditMessage = locale.missingFields;
				state.addEditError = true;
				this.setState(state);
				return;
			}
			const url = '/document/' + this.props.document.documentId + '/deliveryTerm/' + this.state.selectedId;
			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				Name: this.state.termSalesText.value,
				Description: this.state.termSalesDesc.value,
				SortOrder: this.state.termSalesSortOrder.value,
			};
			Helper.putData<any>(url, JSON.stringify(body)).then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				}
				this.getCalculation();
			});
		} else if (action === 'updatePaymentTerm') {
			if (
				!state.termPaymentText.value ||
				!state.termPaymentDesc.value ||
				state.termPaymentValue.value === '' ||
				state.termPaymentSortOrder.value === ''
			) {
				state.addEditMessage = locale.missingFields;
				state.addEditError = true;
				this.setState(state);
				return;
			}
			const url = '/document/' + this.props.document.documentId + '/paymentTerm/' + this.state.selectedId;
			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				Name: this.state.termPaymentText.value,
				Description: this.state.termPaymentDesc.value,
				Value: this.state.termPaymentValue.value.toString().replace(',', '.'),
				SortOrder: this.state.termPaymentSortOrder.value,
			};
			Helper.putData<any>(url, JSON.stringify(body)).then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.exception, data.Message);
				}
				this.getCalculation();
			});
		}
	}
	removeTermPayment(id: string) {
		if (id) {
			this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'deletePaymentTerm', id);
		}
	}
	removeTermSales(id: string) {
		if (id) {
			this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'deleteSalesTerm', id);
		}
	}
	editTermPayment(id: string) {
		if (id) {
			this.toggleTermPaymentModalEdit(id);
		}
	}
	editTermSales(id: string) {
		if (id) {
			this.toggleTermSalesModalEdit(id);
		}
	}
	viewTermPayment(id: string) {
		if (id) {
			this.toggleTermPaymentModalView(id);
		}
	}
	viewTermSales(id: string) {
		if (id) {
			this.toggleTermSalesModalView(id);
		}
	}
	saveTermPayment() {
		this.setState({ saving: true });
		const state: IState = this.state;
		if (!state.termPaymentText.value || !state.termPaymentDesc.value || !state.termPaymentValue.value || !state.termPaymentSortOrder.value) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/' + this.props.document.documentId + '/paymentTerm';
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: this.state.termPaymentText.value,
			Description: this.state.termPaymentDesc.value,
			Value: this.state.termPaymentValue.value.toString().replace(',', '.'),
			SortOrder: this.state.termPaymentSortOrder.value,
		};
		Helper.postData<any>(url, JSON.stringify(body)).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.getCalculation();
		});
	}
	saveTermSales() {
		this.setState({ saving: true });
		const state: IState = this.state;
		if (!state.termSalesText.value || !state.termSalesDesc.value || !state.termSalesSortOrder.value) {
			state.addEditMessage = locale.missingFields;
			state.addEditError = true;
			this.setState(state);
			return;
		}
		const url = '/document/' + this.props.document.documentId + '/deliveryTerm';
		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Name: this.state.termSalesText.value,
			Description: this.state.termSalesDesc.value,
			SortOrder: this.state.termSalesSortOrder.value,
		};
		Helper.postData<any>(url, JSON.stringify(body)).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
			}
			this.getCalculation();
		});
	}
	render() {
		const {
			termPaymentText,
			termSalesText,
			termSalesSortOrder,
			termPaymentDesc,
			termPaymentValue,
			termPaymentSortOrder,
			termSalesDesc,
			termsPaymentModal,
			termSalesModal,
			termSalesModalEdit,
			termSalesModalView,
			termsPaymentModalEdit,
			termsPaymentModalView,
			confirmationModal,
			confirmationModalAction,
			confirmationModalMessage,
			confirmationModalTitle,
			addEditError,
			addEditMessage,
		} = this.state;

		const disabled1 =
			termPaymentText.value === '' ||
			termPaymentDesc.value === '' ||
			termPaymentValue.value === '' ||
			termPaymentValue.validate === 'has-danger' ||
			termPaymentSortOrder.value === '' ||
			termPaymentSortOrder.validate === 'has-danger';

		const disabled2 =
			termSalesText.value === '' || termSalesDesc.value === '' || termSalesSortOrder.value === '' || termSalesSortOrder.validate === 'has-danger';

		const disableEditDocument = this.props.document && this.props.document.disableEdit;
		const addEditFailedVisible = addEditError ? 'block' : 'none';
		const options = {
			pageSize: 5,
			noDataText: locale.noResults,
		};
		const cellDeletePaymentTerm = (cell: any, row: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button
					onClick={() => this.removeTermPayment(cell)}
					color='none'
					disabled={disableEditDocument}
					className='close float-left cell-button'
					aria-label='Close'
				>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};
		const cellDeleteSalesTerm = (cell: any, row: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button
					onClick={() => this.removeTermSales(cell)}
					color='none'
					disabled={disableEditDocument}
					className='close float-left cell-button'
					aria-label='Close'
				>
					<span aria-hidden='true'>×</span>
				</Button>
			);
		};
		const cellEditPaymentTerm = (cell: any, row: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.editTermPayment(cell)} color='none' disabled={disableEditDocument} className='float-left cell-button'>
					<i className='fa fa-edit' aria-hidden='true'></i>
				</Button>
			);
		};
		const cellEditSalesTerm = (cell: any, row: any) => {
			if (!cell || disableEditDocument) {
				return '';
			}
			return (
				<Button onClick={() => this.editTermSales(cell)} color='none' disabled={disableEditDocument} className='float-left cell-button'>
					<i className='fa fa-edit' aria-hidden='true'></i>
				</Button>
			);
		};
		return (
			<div id='configurator' className='admin'>
				{this.state.saving ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className='mt-3 configurator-background calculation'>
							<Row>
								<Col xs='12' className='mt-3'>
									<Form>
										<Row form>
											<Col className='mb-3 admin-form-border'>
												<FormGroup row>
													<Label xs={12}>{locale.termsPayment}</Label>
													<Col xl={12}>
														<BootstrapTable data={this.state.termsPayments} options={options} striped bordered={true} keyField='id'>
															<TableHeaderColumn dataField='id' dataFormat={cellDeletePaymentTerm} width='25px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn dataField='id' dataFormat={cellEditPaymentTerm} width='30px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='name' width='20%' dataSort>
																{locale.name}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='tableValue' dataAlign='right' headerAlign='right' width='20%'>
																{locale.discount}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='desc' width='20%' dataSort columnClassName={'no-elipsis'}>
																{locale.desc}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='tablePrice' dataAlign='right' headerAlign='right' width='20%'>
																{locale.amount}
															</TableHeaderColumn>
															<TableHeaderColumn columnTitle dataField='sortOrder' dataAlign='right' headerAlign='right' width='10%'>
																{locale.sortOrder}
															</TableHeaderColumn>
														</BootstrapTable>
														<Button
															onClick={() => this.toggleTermPaymentModal()}
															disabled={disableEditDocument}
															className={'button btn-secondary mt-3 mb-3'}
														>
															{locale.add}
														</Button>
													</Col>
													<Col>
														<Modal size='lg' isOpen={confirmationModal} toggle={() => this.toggleConfirmationModal()}>
															<ModalHeader toggle={() => this.toggleConfirmationModal()}>{confirmationModalTitle}</ModalHeader>
															<ModalBody>
																{confirmationModalMessage}
																<br />
																<br />
																<Button
																	className={'button btn-secondary my-2'}
																	disabled={disableEditDocument}
																	onClick={() => this.confirmationAction(confirmationModalAction)}
																>
																	{locale.confirm}
																</Button>
																<Button
																	className={'button btn-secondary my-2 ml-3'}
																	disabled={disableEditDocument}
																	onClick={() => this.toggleConfirmationModal()}
																>
																	{locale.cancel}
																</Button>
															</ModalBody>
														</Modal>
														<Modal isOpen={termsPaymentModal} toggle={() => this.toggleTermPaymentModal()}>
															<ModalHeader toggle={() => this.toggleTermPaymentModal()}>{locale.addTermPayment}</ModalHeader>
															<ModalBody>
																<FormGroup>
																	<Label for='text'>{locale.term}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termPaymentText'
																		id='termPaymentText'
																		maxLength={200}
																		minLength={1}
																		value={termPaymentText.value}
																		valid={termPaymentText.validate === 'has-success'}
																		invalid={termPaymentText.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																	<Label for='value'>{locale.discount}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termPaymentValue'
																		id='termPaymentValue'
																		value={termPaymentValue.value.toString().replace('.', ',')}
																		valid={termPaymentValue.validate === 'has-success'}
																		invalid={termPaymentValue.validate === 'has-danger'}
																		onChange={(e) => {
																			this.validateCostValue(e);
																			this.handleChange(e);
																		}}
																	/>
																	<Label for='value'>{locale.sortOrder}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termPaymentSortOrder'
																		id='termPaymentSortOrder'
																		value={termPaymentSortOrder.value}
																		valid={termPaymentSortOrder.validate === 'has-success'}
																		invalid={termPaymentSortOrder.validate === 'has-danger'}
																		onChange={(e) => {
																			this.validateSortOrder(e);
																			this.handleChange(e);
																		}}
																	/>
																	<br />
																	<Label for='text'>{locale.desc}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termPaymentDesc'
																		id='termPaymentDesc'
																		rows='10'
																		value={termPaymentDesc.value}
																		valid={termPaymentDesc.validate === 'has-success'}
																		invalid={termPaymentDesc.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																</FormGroup>
																<Button
																	className={'button btn-secondary my-2'}
																	disabled={disabled1 || disableEditDocument}
																	onClick={() => this.saveTermPayment()}
																>
																	{locale.save}
																</Button>
																<Col>
																	<div className={'invalid-feedback mt-2'} style={{ display: addEditFailedVisible }}>
																		{addEditMessage}
																	</div>
																</Col>
															</ModalBody>
														</Modal>
														<Modal isOpen={termsPaymentModalEdit} toggle={() => this.toggleTermPaymentModalEdit()}>
															<ModalHeader toggle={() => this.toggleTermPaymentModalEdit()}>{locale.editTermPayment}</ModalHeader>
															<ModalBody>
																<FormGroup>
																	<Label for='text'>{locale.term}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termPaymentText'
																		id='termPaymentText'
																		rows='3'
																		value={termPaymentText.value}
																		valid={termPaymentText.validate === 'has-success'}
																		invalid={termPaymentText.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																	<Label for='value'>{locale.discount}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termPaymentValue'
																		id='termPaymentValue'
																		value={termPaymentValue.value.toString().replace('.', ',')}
																		valid={termPaymentValue.validate === 'has-success'}
																		invalid={termPaymentValue.validate === 'has-danger'}
																		onChange={(e) => {
																			this.validateCostValue(e);
																			this.handleChange(e);
																		}}
																	/>
																	<Label for='value'>{locale.sortOrder}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termPaymentSortOrder'
																		id='termPaymentSortOrder'
																		value={termPaymentSortOrder.value}
																		valid={termPaymentSortOrder.validate === 'has-success'}
																		invalid={termPaymentSortOrder.validate === 'has-danger'}
																		onChange={(e) => {
																			this.validateSortOrder(e);
																			this.handleChange(e);
																		}}
																	/>
																	<br />
																	<Label for='text'>{locale.desc}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termPaymentDesc'
																		id='termPaymentDesc'
																		rows='10'
																		value={termPaymentDesc.value}
																		valid={termPaymentDesc.validate === 'has-success'}
																		invalid={termPaymentDesc.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																</FormGroup>

																<Button
																	className={'button btn-secondary my-2'}
																	disabled={disabled1 || disableEditDocument}
																	onClick={() => this.confirmationAction(confirmationModalAction)}
																>
																	{locale.save}
																</Button>
																<Button
																	className={'button btn-secondary my-2 ml-3'}
																	disabled={disableEditDocument}
																	onClick={() => this.toggleTermPaymentModalEdit()}
																>
																	{locale.cancel}
																</Button>
																<Col>
																	<div className={'invalid-feedback mt-2'} style={{ display: addEditFailedVisible }}>
																		{addEditMessage}
																	</div>
																</Col>
															</ModalBody>
														</Modal>
														<Modal isOpen={termsPaymentModalView} toggle={() => this.toggleTermPaymentModalView()}>
															<ModalHeader toggle={() => this.toggleTermPaymentModalView()}>{locale.viewTermPayment}</ModalHeader>
															<ModalBody>
																<FormGroup>
																	<Label for='text'>{locale.term}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termPaymentText'
																		id='termPaymentText'
																		rows='3'
																		value={termPaymentText.value}
																		disabled={true}
																	/>
																	<Label for='value'>{locale.discount}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termPaymentValue'
																		id='termPaymentValue'
																		value={termPaymentValue.value}
																		disabled={true}
																	/>
																	<br />
																	<Label for='text'>{locale.desc}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termPaymentDesc'
																		id='termPaymentDesc'
																		rows='10'
																		value={termPaymentDesc.value}
																		disabled={true}
																	/>
																</FormGroup>

																<Button className={'button btn-secondary my-2'} onClick={() => this.toggleTermPaymentModalView()}>
																	{locale.close}
																</Button>
															</ModalBody>
														</Modal>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label xs={12}>{locale.termsSales}</Label>
													<Col xl={12}>
														<BootstrapTable data={this.state.termsSales} options={options} striped bordered={true} keyField='id'>
															<TableHeaderColumn dataField='id' dataFormat={cellDeleteSalesTerm} width='25px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn dataField='id' dataFormat={cellEditSalesTerm} width='30px'>
																{' '}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='name' width='35%' dataSort>
																{locale.name}
															</TableHeaderColumn>

															<TableHeaderColumn columnTitle dataField='desc' width='35%' dataSort columnClassName={'no-elipsis'}>
																{locale.desc}
															</TableHeaderColumn>
															<TableHeaderColumn columnTitle dataField='sortOrder' dataAlign='right' headerAlign='right' width='10%'>
																{locale.sortOrder}
															</TableHeaderColumn>
														</BootstrapTable>
														<Button
															onClick={() => this.toggleTermSalesModal()}
															disabled={disableEditDocument}
															className={'button btn-secondary mt-3 mb-3'}
														>
															{locale.add}
														</Button>
													</Col>
													<Col>
														<Modal isOpen={termSalesModal} toggle={() => this.toggleTermSalesModal()}>
															<ModalHeader toggle={() => this.toggleTermSalesModal()}>{locale.addTermSales}</ModalHeader>
															<ModalBody>
																<FormGroup>
																	<Label for='text'>{locale.term}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termSalesText'
																		id='termSalesText'
																		maxLength={200}
																		minLength={1}
																		rows='2'
																		value={termSalesText.value}
																		valid={termSalesText.validate === 'has-success'}
																		invalid={termSalesText.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																	<Label for='text'>{locale.sortOrder}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termSalesSortOrder'
																		id='termSalesSortOrder'
																		value={termSalesSortOrder.value}
																		valid={termSalesSortOrder.validate === 'has-success'}
																		invalid={termSalesSortOrder.validate === 'has-danger'}
																		onChange={(e) => {
																			this.validateSortOrder(e);
																			this.handleChange(e);
																		}}
																	/>{' '}
																	<br />
																	<Label for='text'>{locale.desc}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termSalesDesc'
																		id='termSalesDesc'
																		rows='10'
																		value={termSalesDesc.value}
																		valid={termSalesDesc.validate === 'has-success'}
																		invalid={termSalesDesc.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																</FormGroup>

																<Button
																	className={'button btn-secondary my-2'}
																	disabled={disabled2 || disableEditDocument}
																	onClick={() => this.saveTermSales()}
																>
																	{locale.save}
																</Button>
																<Col>
																	<div className={'invalid-feedback mt-2'} style={{ display: addEditFailedVisible }}>
																		{addEditMessage}
																	</div>
																</Col>
															</ModalBody>
														</Modal>
														<Modal isOpen={termSalesModalEdit} toggle={() => this.toggleTermSalesModalEdit()}>
															<ModalHeader toggle={() => this.toggleTermSalesModalEdit()}>{locale.editTermSales}</ModalHeader>
															<ModalBody>
																<FormGroup>
																	<Label for='text'>{locale.term}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termSalesText'
																		id='termSalesText'
																		value={termSalesText.value}
																		valid={termSalesText.validate === 'has-success'}
																		invalid={termSalesText.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																	<Label for='text'>{locale.sortOrder}</Label>
																	<Input
																		type='text'
																		autoComplete='off'
																		name='termSalesSortOrder'
																		id='termSalesSortOrder'
																		value={termSalesSortOrder.value}
																		valid={termSalesSortOrder.validate === 'has-success'}
																		invalid={termSalesSortOrder.validate === 'has-danger'}
																		onChange={(e) => {
																			this.validateSortOrder(e);
																			this.handleChange(e);
																		}}
																	/>
																	<br />
																	<Label for='text'>{locale.desc}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termSalesDesc'
																		id='termSalesDesc'
																		rows='10'
																		value={termSalesDesc.value}
																		valid={termSalesDesc.validate === 'has-success'}
																		invalid={termSalesDesc.validate === 'has-danger'}
																		onChange={(e) => {
																			this.handleChange(e);
																		}}
																	/>
																</FormGroup>

																<Button
																	className={'button btn-secondary my-2'}
																	disabled={disabled2 || disableEditDocument}
																	onClick={() => this.confirmationAction(confirmationModalAction)}
																>
																	{locale.save}
																</Button>
																<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleTermSalesModalEdit()}>
																	{locale.cancel}
																</Button>
																<Col>
																	<div className={'invalid-feedback mt-2'} style={{ display: addEditFailedVisible }}>
																		{addEditMessage}
																	</div>
																</Col>
															</ModalBody>
														</Modal>
														<Modal isOpen={termSalesModalView} toggle={() => this.toggleTermSalesModalView()}>
															<ModalHeader toggle={() => this.toggleTermSalesModalView()}>{locale.viewTermSales}</ModalHeader>
															<ModalBody>
																<FormGroup>
																	<Label for='text'>{locale.term}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termSalesText'
																		id='termSalesText'
																		rows='3'
																		value={termSalesText.value}
																		disabled={true}
																	/>
																	<br />
																	<Label for='text'>{locale.desc}</Label>
																	<Input
																		autoFocus
																		type='textarea'
																		autoComplete='off'
																		name='termSalesDesc'
																		id='termSalesDesc'
																		rows='10'
																		value={termSalesDesc.value}
																		disabled={true}
																	/>
																</FormGroup>

																<Button className={'button btn-secondary my-2'} onClick={() => this.toggleTermSalesModalView()}>
																	{locale.close}
																</Button>
															</ModalBody>
														</Modal>
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerTerms);
