import locale from './Locale';
import { initialState } from './store/Configurator';
import { configureRefreshFetch, fetchJSON } from 'refresh-fetch';
import merge from 'lodash/merge';

// npm run-script build:test
// authenticationToken
interface ICommonUtilitiesHelper {
	getLocalizedName(nameJSON: string, language?: string): string;
	getData(endpoint: string): any;
	postData(endpoint: string, body: string): any;
	userInRole(role: number): boolean;
	themeHeaderColor(): string;
	themeHeaderColorRGBA(): string;
	themeHeaderFontColor(): string;
	dateWithTimeConvert(date: string): string;
	dateConvert(date: string): string;
	userLang(): string;
}
export enum roles {
	admin = 1, // Administrator
	prod = 2, // Salesman
	mon = 3, //Installer
}
export enum status {
	status_active = '9328d7d7-743b-44f2-8f41-86ba7565e686',
	status_inactive = 'e0fc5b4a-3e14-405c-bf6d-3bc151882498',
}
export enum documentSatus {
	documentstatus_draft = 'b1fb15a4-876f-4c3b-952a-8968384d9059',
	documentstatus_confirmed = 'd753e7f9-a82b-4692-88b0-b0fe698e77d2',
	documentstatus_confirmedOem = '48338cfa-ddd5-41d8-bbe1-8208a5c8c249',
	documentstatus_archived = '928217fd-fde1-45ed-8355-c11713cd4bd7',
	documentstatus_error = '77717987-1604-4a74-af2d-e36aef09df93',
	documentstatus_errorcloning = '6be488f1-c7cc-402a-bf48-01f296e7be0f',
	documentstatus_errorfinaldocumentation = 'df224c40-e1c2-48a8-8139-971e8b4929b3',
	documentstatus_errorproddocumentation = '435e32b3-1b6d-4800-9f19-27b9ff7ab3a7',
	documentstatus_errortechdocumentation = '66a71ee8-f1f0-4623-acb1-b1c9d3e60ad1',
	documentstatus_errorsenttoconfirmation = 'e7c4d5d7-1b5f-4074-b19f-3ee034e540eb',
	documentstatus_errorsenttoproduction = '929dd72b-bf67-44fb-aa3a-b53efb642fde',
	documentstatus_generatingtechdocumentation = '1b90ee89-5894-4ee0-b051-763a441ab412',
	documentstatus_generatedtechdocumentation = '413fe2c4-44d3-4bee-a244-1001c63fa886',
	documentstatus_senttoproduction = '3967c297-57f5-47ee-8b8d-912a29a6d6c3',
	documentstatus_cloningstarted = '2e49a00d-b0a9-41f9-9ab6-f8619e8cf0f8',
	documentstatus_cloningfinished = 'e87e8313-7913-403a-8a25-040a3f62440c',
	documentstatus_generatingproddocumentation = '1b0b25cb-462e-4170-b0f4-0e8b2768d163',
	documentstatus_generatedproddocumentation = 'cfd04a3d-826e-4d4f-b41d-4b0b0c8c22ec',
	documentstatus_generatingfinaldocumentation = '64e38fc2-de51-45e0-975b-7c9adf80975e',
	documentstatus_generatedfinaldocumentation = 'b59a12c5-c0ab-4413-b990-a0ab9453e3b9',
	documentstatus_sendingtoproduction = '34022cb4-0ddd-4429-b9ca-bd26e32a636c',
	documentstatus_comment = '4f7633fb-fc44-4a82-b793-f82c02a03199',
	documentstatus_offer = 'ef1bca6c-5133-4a10-b89f-f7050ebb30af',
	documentstatus_exportToERP = 'd9478466-c990-4f1b-9ff2-46fe00d2ae47',
	documentstatus_specialtechnicaldocumentationgenerated = '23260f40-98c4-43b2-b085-f3d4437c5c6a',
	documentstatus_received = '6f2f6fe7-59df-4e03-aa04-879e590de8fb',
	documentstatus_forwarded = 'c91d6046-85d8-4296-aad5-bf7d3a0c429d',
}
export enum projectStatus {
	active = '943b4efb-9ee9-4cff-8b0b-9eb49322c5bc',
	closed = '0a16ff7e-0b5c-4cce-a8a3-e55c2e256c25',
	sending = 'cad6e203-c746-400d-83b3-5f887d8751e5',
	sent = '38434be7-9783-4f38-b026-62b24a8de1f1',
}
export enum documentType {
	documenttype_inquiry = '8fc7c957-c41b-459f-9ab5-dbfa91114b6f',
	documenttype_offer = 'd5ffa956-85a9-4501-bb82-adfc1dd29943',
	documenttype_order = 'c045dc64-ad10-4156-800e-ec704a9cb7fc',
	documenttype_orderconfirmation = 'e6941888-0f09-4fd3-91ec-e93190b51ae3',
	documenttype_documentation = 'aca56c9c-acc6-4f9b-9e8f-af9946b4f1f1',
	documenttype_configuration = 'd1c5ce1c-9f05-4cd0-b873-1a9ccbdd8acf',
	documenttype_offerprinted = '9287f309-36d1-41e9-afdc-198ca8f5b357',
	documenttype_offersent = '60380a7c-0ab6-4ec3-b21c-b307dfab27d4',
}
export enum dataType {
	string = 0,
	integer = 1,
	double = 2,
	decimal = 3,
	data = 4,
	color = 5,
	text = 6,
	comment = 7,
}

export const comment_category = 'e8b4d35c-1bd1-4cff-8f46-e228b6e3895d';

const userLoggedIn = (): boolean => {
	if (localStorage.getItem('userToken')) {
		return true;
	}
	return false;
};

const retrieveRefreshToken = () => localStorage.getItem('refreshToken');
const clearRefreshToken = () => localStorage.removeItem('refreshToken');
const retrieveToken = () => localStorage.getItem('userToken');
const saveToken = (token: string) => localStorage.setItem('userToken', token);
const saveRefreshToken = (token: string) => localStorage.setItem('refreshToken', token);
const clearToken = () => localStorage.removeItem('userToken');

const clearCache = () => {
	clearToken();
	clearRefreshToken();
	localStorage.removeItem('theme');
	localStorage.removeItem('footer');
	localStorage.removeItem('brandPartnerId');
	localStorage.removeItem('isParentPartner');
	localStorage.removeItem('licenseSettings');
	localStorage.removeItem('userRole');
	localStorage.removeItem('isUserOEM');
	localStorage.removeItem('isUserOEMAdmin');

	if (typeof localStorage.getItem('reduxState') === 'string') {
		let reduxState = JSON.parse(localStorage.getItem('reduxState') || '{}');
		reduxState.configurator = JSON.parse(JSON.stringify(initialState));
		reduxState = JSON.stringify(reduxState);
		localStorage.setItem('reduxState', reduxState);
	}
	if (userLoggedIn()) {
		window.location.href = process.env.REACT_APP_ROOT_PATH + '/configurator/selection';
	} else {
		window.location.href = process.env.REACT_APP_ROOT_PATH + '/login';
	}
};

const fetchJSONWithToken = (url: string, options = {}) => {
	const token = retrieveToken();

	let optionsWithToken = options;
	if (token != null) {
		optionsWithToken = merge({}, options, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
	}

	return fetchJSONSkipError(url, optionsWithToken);
};

const fetchJSONSkipError = (url: any | Request | URL, options: Object = {}) => {
	const jsonOptions = merge(
		{
			headers: {
				'Content-Type': 'application/json',
			},
		},
		options
	);

	return fetch(url, jsonOptions).then((response: any) => {
		if (response.status === 401) {
			return fetchJSON(url, options);
		}
		return getResponseBody(response).then((body: any) => ({
			response,
			body,
		}));
	});
};

var getResponseBody = function getResponseBody(response: any) {
	var contentType = response.headers.get('content-type');
	return contentType && contentType.indexOf('json') >= 0 ? response.clone().text().then(tryParseJSON) : response.clone().text();
};

var tryParseJSON = function tryParseJSON(json: any) {
	if (!json) {
		return null;
	}
	return JSON.parse(json);
};

// Decide whether this error returned from API means that we want
// to try refreshing the token. error.response contains the fetch Response
// object, error.body contains the parsed JSON response body
const shouldRefreshToken = (error: any) => {
	return error.status === 401;
};

// Do the actual token refreshing and update the saved token
function refreshToken() {
	const refreshToken = retrieveRefreshToken();
	const body: { [key: string]: string | any } = {
		client_id: process.env.REACT_APP_CLIENT_ID,
		client_secret: process.env.REACT_APP_CLIENT_SECRET,
		grant_type: 'refresh_token',
		refresh_token: refreshToken,
	};
	return fetchJSONWithToken(process.env.REACT_APP_TOKEN_URL + '/api/token', {
		method: 'POST',
		headers: {
			Accept: '*/*',
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		},
		body: Object.keys(body)
			.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(body[key]))
			.join('&'),
	})
		.then((response: any) => {
			if (response.response.ok && response.body.access_token != null) {
				saveToken(response.body.access_token);
				saveRefreshToken(response.body.refresh_token);
			} else if (!response.response.ok || response.body.error === 'invalid_grant') {
				clearCache();
				throw new Error('Invalid refresh token');
			}

			return response;
		})
		.catch((error) => {
			// If we failed by any reason in refreshing, just clear the token,
			// it's not that big of a deal
			clearCache();
			throw error;
		});
}

const fetchRefresh = configureRefreshFetch({
	shouldRefreshToken,
	refreshToken,
	fetch: fetchJSONWithToken,
});

class CommonUtilities implements ICommonUtilitiesHelper {
	public brandPartnerId() {
		return localStorage.getItem('brandPartnerId') ? localStorage.getItem('brandPartnerId') : process.env.REACT_APP_BRANDPARTNER;
	}
	public isDefaultBrandPartner(): boolean {
		const brandPartnerId = localStorage.getItem('brandPartnerId');
		if (brandPartnerId === process.env.REACT_APP_BRANDPARTNER) {
			return true;
		}
		return false;
	}
	public userLang(): string {
		const userLanguage = localStorage.getItem('userLanguage');
		if (userLanguage && userLanguage.length > 0) {
			return userLanguage;
		} else {
			const userLang = navigator.language || (navigator as any).userLanguage;
			return userLang.split('-')[0];
		}
	}
	public userLoggedIn(): boolean {
		return userLoggedIn();
	}
	public userInRole(role: number): boolean {
		const userRole = localStorage.getItem('userRole');
		if (userRole && localStorage.getItem('userToken') && userRole.toLowerCase() === roles[role]) {
			return true;
		}
		return false;
	}
	public userIsOem(): boolean {
		const isUserOEM = localStorage.getItem('isUserOEM');
		if (isUserOEM === 'true') {
			return true;
		}
		return false;
	}
	public userIsOemAdmin(): boolean {
		const isUserOEMAdmin = localStorage.getItem('isUserOEMAdmin');
		if (isUserOEMAdmin === 'true') {
			return true;
		}
		return false;
	}
	public userIsParentPartner(): boolean {
		const isParentPartner = localStorage.getItem('isParentPartner');
		if (isParentPartner === 'true') {
			return true;
		}
		return false;
	}
	public userAdmin(): boolean {
		return this.userInRole(roles.admin) || this.userInRole(roles.prod);
	}
	public userHeadOfSales(): boolean {
		return this.userInRole(roles.admin);
	}
	public userInstaller(): boolean {
		return this.userInRole(roles.mon);
	}
	public userSales(): boolean {
		return this.userInRole(roles.prod);
	}
	public userRegisteredCustomer(): boolean {
		if (localStorage.getItem('userToken') && userLoggedIn() && !this.userEditor()) {
			return true;
		}
		return false;
	}
	public userEditor(): boolean {
		return this.userAdmin() || this.userInstaller();
	}
	public themeHeaderColor(): string {
		if (localStorage.getItem('theme')) {
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			if (theme.color) {
				return theme.color;
			}
		}
		return '#86A33C';
	}
	public themeHeaderColorRGBA(): string {
		if (localStorage.getItem('theme')) {
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			if (theme.color) {
				let inputColor = theme.color;
				let r = parseInt(inputColor.slice(1, 3), 16),
					g = parseInt(inputColor.slice(3, 5), 16),
					b = parseInt(inputColor.slice(5, 7), 16);
				inputColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 0.2 + ')';
				return inputColor;
			}
		}
		return 'rgba(134, 163, 60, 0.25)';
	}
	public themeHeaderFontColor(): string {
		if (localStorage.getItem('theme')) {
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			if (theme.headerFontColor) {
				return theme.headerFontColor;
			}
		}
		return '#fff';
	}
	public themeButtonColor(): string {
		if (localStorage.getItem('theme')) {
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			if (theme.buttonColor) {
				return theme.buttonColor;
			}
		}
		return '#86A33C';
	}
	public themeBottonColorWithShadowRGBA(): string {
		if (localStorage.getItem('theme')) {
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			if (theme.buttonColor) {
				let inputColor = theme.buttonColor;
				let r = parseInt(inputColor.slice(1, 3), 16),
					g = parseInt(inputColor.slice(3, 5), 16),
					b = parseInt(inputColor.slice(5, 7), 16);
				inputColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 0.2 + ')';
				return inputColor;
			}
		}
		return 'rgba(0, 0, 0, 0.2)';
	}
	public themeButtonFontColor(): string {
		if (localStorage.getItem('theme')) {
			const themeCached = localStorage.getItem('theme') as string;
			const theme = JSON.parse(themeCached);
			if (theme.buttonFontColor) {
				return theme.buttonFontColor;
			}
		}
		return '#fff';
	}
	public dateWithTimeConvert(date: string): string {
		if (date && date.length > 0) {
			let newDate = new Date(date);
			let offset = newDate.getTimezoneOffset();
			offset = Math.abs(offset / 60);
			newDate.setHours(newDate.getHours() + offset);
			return newDate.toLocaleString(this.userLang()).substr(0, newDate.toLocaleString(this.userLang()).lastIndexOf(':'));
		}
		return '';
	}
	public dateConvert(date: string): string {
		if (date && date.length > 0) {
			let newDate = new Date(date);
			return newDate.toLocaleDateString(this.userLang());
		}
		return '';
	}
	//get string from localized json. Used for API values reading, like {sl:"tekst", en:"text"}
	public getLocalizedName(nameJSON: string, language?: string) {
		try {
			if (typeof nameJSON !== 'number' || isNaN(nameJSON)) {
				const name = JSON.parse(nameJSON);
				const lang = language || this.userLang();
				if (lang === 'en' && name.en) return name.en;
				else if (lang === 'sl' && name.sl) return name.sl;
				else if (lang === 'de' && name.de) return name.de;
				else if (lang === 'fr' && name.fr) return name.fr;
				//fallback language
				if (name.sl) return name.sl;
				return '';
			} else {
				return nameJSON;
			}
		} catch (e) {
			return nameJSON;
		}
	}
	public getLocalizedValueOrNull(nameJSON: string, language?: string) {
		try {
			if (typeof nameJSON !== 'number' || isNaN(nameJSON)) {
				const name = JSON.parse(nameJSON);
				const lang = language || this.userLang();
				if (lang === 'en' && name.en) return name.en;
				else if (lang === 'sl' && name.sl) return name.sl;
				else if (lang === 'de' && name.de) return name.de;
				else if (lang === 'fr' && name.fr) return name.fr;

				if (typeof name === 'string' || typeof name === 'number') {
					return name;
				}
				return null;
			} else {
				return nameJSON;
			}
		} catch (e) {
			return nameJSON;
		}
	}
	public onError(data: any, title: string, dispatch: any, actionCreators: any): void {
		dispatch(actionCreators.setError(title, data));
		dispatch(actionCreators.setConfigurationFlags(undefined, false, undefined, false));
	}
	public onError2(data: any, title: string, dispatch: any, actionCreators: any): void {
		dispatch(actionCreators.setError(title, data));
		dispatch(actionCreators.setConfigurationFlags(undefined, false, undefined, false));
	}
	public async getData<TResponse>(endpoint: string, type?: 'json' | 'blob') {
		try {
			const token = retrieveToken();
			let data: any = await fetchRefresh(process.env.REACT_APP_API_URL + endpoint, {
				method: 'GET',
				headers: {
					Accept: 'application/json; odata=verbose',
					'X-Response-Language': this.userLang(),
					Authorization: `Bearer ${token}`,
				},
				credentials: 'omit',
			});
			let response = data.response;
			if (response.status > 399 && response.status < 600) {
				let json: any = await response.json();
				let message = {
					Message: json && json.detail ? json.detail : response.status,
				};
				return message;
			} else {
				if (type === 'blob') return await response.blob();

				let json: TResponse = await response.json();
				return json;
			}
		} catch (error) {
			return locale.apiException;
		}
	}

	public async login<TResponse>(endpoint: string, body: string) {
		try {
			let response = await fetch(endpoint, {
				method: 'POST',
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
					'X-Response-Language': this.userLang(),
				},
				credentials: 'omit',
				body: body,
			});
			let json: TResponse = await response.json();
			return json;
		} catch (error) {
			throw error;
		}
	}
	public async postData<TResponse>(endpoint: string, body: string) {
		try {
			const token = retrieveToken();
			let data: any = await fetchRefresh(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					'X-Response-Language': this.userLang(),
					Authorization: `Bearer ${token}`,
				},
				credentials: 'omit',
				body: body,
			});
			let response = data.response;
			if (response.status > 399 && response.status < 600) {
				let json: any = await response.json();
				let message = {
					Message: json && json.detail ? json.detail : response.status,
				};
				return message;
			} else {
				let json: TResponse = await response.json();
				return json;
			}
		} catch (error) {
			return locale.apiException;
		}
	}

	public async putData<TResponse>(endpoint: string, body: string) {
		try {
			const token = retrieveToken();
			let data: any = await fetchRefresh(process.env.REACT_APP_API_URL + endpoint, {
				method: 'PUT',
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/json',
					'X-Response-Language': this.userLang(),
					Authorization: `Bearer ${token}`,
				},
				credentials: 'omit',
				body: body,
			});
			let response = data.response;
			if (response.status > 399 && response.status < 600) {
				let json: any = await response.json();
				let message = {
					Message: json && json.detail ? json.detail : response.status,
				};
				return message;
			} else {
				let json: TResponse = await response.json();
				return json;
			}
		} catch (error) {
			return locale.apiException;
		}
	}

	public async deleteData<TResponse>(endpoint: string) {
		try {
			const token = retrieveToken();
			let data: any = await fetchRefresh(process.env.REACT_APP_API_URL + endpoint, {
				method: 'DELETE',
				headers: {
					Accept: '*/*',
					'X-Response-Language': this.userLang(),
					Authorization: `Bearer ${token}`,
				},
				credentials: 'omit',
			});
			let response = data.response;
			if (response.status > 399 && response.status < 600) {
				let json: any = await response.json();
				let message = {
					Message: json && json.detail ? json.detail : response.status,
				};
				return message;
			} else {
				let json: TResponse = await response.json();
				return json;
			}
		} catch (error) {
			return locale.apiException;
		}
	}

	public addDefaultSrc(e: any) {
		e.target.src = process.env.PUBLIC_URL + '/images/noimage.png';
	}
}
export default new CommonUtilities();

//  adapted from https://stackoverflow.com/a/1144249
export function deepCompare(left: any, right: any, ignoreNullUndefinedDiffs?: boolean) {
	var leftChain: any, rightChain: any;

	function compare2Objects(x: any, y: any) {
		var p;

		// ignore null/undefiend diffs
		if (ignoreNullUndefinedDiffs && left == null && right == null) return true;

		// remember that NaN === NaN returns false
		// and isNaN(undefined) returns true
		if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
			return true;
		}

		// Compare primitives and functions.
		// Check if both arguments link to the same object.
		// Especially useful on the step where we compare prototypes
		if (x === y) {
			return true;
		}

		// Works in case when functions are created in constructor.
		// Comparing dates is a common scenario. Another built-ins?
		// We can even handle functions passed across iframes
		if (
			(typeof x === 'function' && typeof y === 'function') ||
			(x instanceof Date && y instanceof Date) ||
			(x instanceof RegExp && y instanceof RegExp) ||
			(x instanceof String && y instanceof String) ||
			(x instanceof Number && y instanceof Number)
		) {
			return x.toString() === y.toString();
		}

		// At last checking prototypes as good as we can
		if (!(x instanceof Object && y instanceof Object)) {
			return false;
		}

		if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
			return false;
		}

		if (x.constructor !== y.constructor) {
			return false;
		}

		if (x.prototype !== y.prototype) {
			return false;
		}

		// Check for infinitive linking loops
		if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
			return false;
		}

		// Quick checking of one object being a subset of another.
		// todo: cache the structure of arguments[0] for performance
		for (p in y) {
			if (!ignoreNullUndefinedDiffs && y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
				return false;
			} else if (typeof y[p] !== typeof x[p]) {
				return false;
			}
		}

		for (p in x) {
			if (ignoreNullUndefinedDiffs && x.hasOwnProperty(p) && !y.hasOwnProperty(p) && x[y] == null) continue;

			if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
				return false;
			} else if (typeof y[p] !== typeof x[p]) {
				return false;
			}

			switch (typeof x[p]) {
				case 'object':
				case 'function':
					leftChain.push(x);
					rightChain.push(y);

					if (!compare2Objects(x[p], y[p])) {
						return false;
					}

					leftChain.pop();
					rightChain.pop();
					break;

				default:
					if (x[p] !== y[p]) {
						return false;
					}
					break;
			}
		}

		return true;
	}

	if (arguments.length < 1) {
		return true; //Die silently? Don't know how to handle such case, please help...
		// throw "Need two or more arguments to compare";
	}

	leftChain = [];
	rightChain = [];

	return compare2Objects(left, right);
}
