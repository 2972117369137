import React from 'react';
import { Button as ReactStrapButton } from 'reactstrap';
import loadingImage from '../images/loading.gif';
import Helper from '../Helper';

interface IProps {
	onClick?(): void;
	className?: string;
	style?: any;
	disabled?: boolean;
	enabled?: string;
	color?: string;
	theme?: any;
	saved?: boolean;
	isNarrow?: boolean;
	loading?: boolean;
	saving?: boolean;
	children?: any;
}
const Button: React.FunctionComponent<IProps> = (props) => {
	let disabled = props.disabled;
	if (props.saved || props.loading || props.saving) {
		disabled = true;
	}
	let styles = {};
	if (props.color !== 'none' && props.color !== 'gray' && props.color !== 'selector') {
		if (props.style) styles = props.style;
		else styles = { backgroundColor: Helper.themeButtonColor(), color: Helper.themeButtonFontColor() };
	}
	return (
		<ReactStrapButton
			style={styles}
			disabled={disabled}
			enabled={props.enabled}
			color={props.color}
			className={(props.className || '') + ' action-button' + (props.saved ? ' saved' : '') + (props.isNarrow ? ' narrow' : '')}
			onClick={props.onClick}
		>
			{props.children}
			{(props.loading || props.saving) && !props.saved ? <img className='saving' alt={'Loading...'} src={loadingImage} /> : null}
		</ReactStrapButton>
	);
};

export default Button;
