import React, { Fragment } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { NavLink, DropdownMenu, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import Helper from '../../Helper';
import locale from '../../Locale';

function CustomerEditMenu(props: any) {
	const baseName = process.env.REACT_APP_ROOT_PATH;
	const navLinkClass = 'text-uppercase navbar-brand admin-menu-item';
	const pathName = window.location.pathname.toLowerCase().replace(/\/$/, '');

	const adminDocument = [
		{
			id: 1,
			url: baseName + '/admin/document/configurations',
			title: locale.adminMenuConfigurations,
		},
		{
			id: 2,
			url: baseName + '/admin/document/document',
			title: locale.adminMenuDocument,
		},
		{
			id: 3,
			url: baseName + '/admin/document/terms',
			title: locale.termsPage,
		},
		{
			id: 4,
			url: baseName + '/admin/document/contact',
			title: locale.contact,
		},
		{
			id: 5,
			url: baseName + '/admin/document/print',
			title: locale.adminMenuPrintDocument,
		},
		{
			id: 6,
			url: baseName + '/admin/document/history',
			title: locale.adminMenuHistory,
		},
	];

	const installerDocument = [
		{
			id: 1,
			url: baseName + '/admin/document/configurations',
			title: locale.adminMenuConfigurations,
		},
		{
			id: 2,
			url: baseName + '/admin/document/document',
			title: locale.adminMenuDocument,
		},
	];

	const adminConfiguration = [
		{
			id: 1,
			url: baseName + '/admin/configuration/calculation',
			title: locale.adminMenuCalculation,
		},
		{
			id: 2,
			url: baseName + '/admin/configuration/position',
			title: locale.adminMenuPosition,
		},
		{
			id: 3,
			url: baseName + '/admin/configuration/costs',
			title: locale.adminMenuDiscountsCosts,
		},
		{
			id: 5,
			url: baseName + '/admin/configuration/images',
			title: locale.adminMenuTechnicalDocumentation,
		},
		{
			id: 6,
			url: baseName + '/admin/configuration/remarks',
			title: locale.adminMenuSalesRemarks,
		},
		{
			id: 7,
			url: baseName + '/admin/configuration/monter',
			title: locale.adminMenuInstallerRemarks,
		},
	];

	const installerConfiguration = [
		{
			id: 1,
			url: baseName + '/admin/configuration/calculation',
			title: locale.adminMenuCalculation,
		},
		{
			id: 4,
			url: baseName + '/admin/configuration/images',
			title: locale.adminMenuTechnicalDocumentation,
		},
		{
			id: 6,
			url: baseName + '/admin/configuration/monter',
			title: locale.adminMenuInstallerRemarks,
		},
	];

	const adminDocumentNavigation = adminDocument.map((link: any) => {
		return (
			<Fragment key={link.id}>
				<div className='mr-auto pt-1 mb-2 navbar-wrapper admin-menu '>
					<NavLink tag={RRNavLink} className={navLinkClass} to={link.url}>
						{link.title}
					</NavLink>
				</div>
			</Fragment>
		);
	});

	const installerDocumentNavigation = installerDocument.map((link: any) => {
		return (
			<Fragment key={link.id}>
				<div className='mr-auto pt-1 mb-2 navbar-wrapper admin-menu '>
					<NavLink tag={RRNavLink} className={navLinkClass} to={link.url}>
						{link.title}
					</NavLink>
				</div>
			</Fragment>
		);
	});

	const adminConfigurationNavigation = adminConfiguration.map((link: any) => {
		return (
			<Fragment key={link.id}>
				<div className='mr-auto pt-1 mb-2 navbar-wrapper admin-menu'>
					<NavLink tag={RRNavLink} className={navLinkClass} to={link.url}>
						{link.title}
					</NavLink>
				</div>
			</Fragment>
		);
	});

	const installerConfigurationNavigation = installerConfiguration.map((link: any) => {
		return (
			<Fragment key={link.id}>
				<div className='mr-auto pt-1 mb-2 navbar-wrapper admin-menu '>
					<NavLink tag={RRNavLink} className={navLinkClass} to={link.url}>
						{link.title}
					</NavLink>
				</div>
			</Fragment>
		);
	});
	if (window.location.pathname.toLowerCase().indexOf('/admin/configuration') >= 0) {
		if (Helper.userInstaller()) {
			return (
				<Fragment>
					<div className='nav-form'>{installerConfigurationNavigation}</div>
					<UncontrolledDropdown className='nav-dropdown'>
						<DropdownToggle caret color='selector' className='dropdown-selector' style={{ width: '100%' }}>
							{installerConfiguration.filter((e) => e.url.toLowerCase() === pathName).map((e) => e.title)}
							&nbsp;
						</DropdownToggle>
						<DropdownMenu className='nav-items'>{installerConfigurationNavigation}</DropdownMenu>
					</UncontrolledDropdown>
				</Fragment>
			);
		} else if (Helper.userAdmin()) {
			return (
				<Fragment>
					<div className='nav-form'>{adminConfigurationNavigation}</div>
					<UncontrolledDropdown className='nav-dropdown'>
						<DropdownToggle caret color='selector' className='dropdown-selector' style={{ width: '100%' }}>
							{adminConfiguration.filter((e) => e.url.toLowerCase() === pathName).map((e) => e.title)}
							&nbsp;
						</DropdownToggle>
						<DropdownMenu className='nav-items'>{adminConfigurationNavigation}</DropdownMenu>
					</UncontrolledDropdown>
				</Fragment>
			);
		}
	} else {
		if (Helper.userInstaller()) {
			return (
				<Fragment>
					<div className='nav-form'>{installerDocumentNavigation}</div>
					<UncontrolledDropdown className='nav-dropdown'>
						<DropdownToggle caret color='selector' className='dropdown-selector' style={{ width: '100%' }}>
							{installerDocument.filter((e) => e.url.toLowerCase() === pathName).map((e) => e.title)}
							&nbsp;
						</DropdownToggle>
						<DropdownMenu className='nav-items'>{installerDocumentNavigation}</DropdownMenu>
					</UncontrolledDropdown>
				</Fragment>
			);
		} else if (Helper.userAdmin()) {
			return (
				<Fragment>
					<div className='nav-form'>{adminDocumentNavigation}</div>
					<UncontrolledDropdown className='nav-dropdown'>
						<DropdownToggle caret color='selector' className='dropdown-selector' style={{ width: '100%' }}>
							{adminDocument.filter((e) => e.url.toLowerCase() === pathName).map((e) => e.title)}
							&nbsp;
						</DropdownToggle>
						<DropdownMenu className='nav-items'>{adminDocumentNavigation}</DropdownMenu>
					</UncontrolledDropdown>
				</Fragment>
			);
		}
	}
	return null;
}

export default CustomerEditMenu;
