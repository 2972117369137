import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './Configurator.css';
import locale from '../Locale';
import Button from './Button';

interface IProps {
	message: string;
	title?: string;
	isError: boolean;
	close?(): void;
}

interface IState {
	isOpen: boolean;
}

export default class Notify extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: true,
		};
	}
	toggleNotifyModal() {
		const state: IState = this.state;
		state.isOpen = !state.isOpen;
		this.setState(state);
		// this.props.close;
	}

	render() {
		return (
			<Modal backdrop='static' isOpen={this.state.isOpen} toggle={() => this.toggleNotifyModal()} className='inquiry-popup'>
				<ModalHeader toggle={() => this.toggleNotifyModal()}>{this.props.isError ? locale.exception : this.props.title}</ModalHeader>
				<ModalBody>{this.props.message}</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => this.toggleNotifyModal()}>
						{locale.close}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
