import React, { Fragment } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledCollapse, NavbarBrand } from 'reactstrap';
import './Configurator.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../Helper';
import locale from '../Locale';
import Notify from './Notify';
import Part from './Part';
import Button from './Button';
import queryString from 'query-string';
import Loading from 'react-fullscreen-loading';

interface IState {
	saveChoiceModal: boolean;
	notifyError: string;
	categories: any;
	saveTitle: any;
	saveMessage: any;
	addingDisabled: boolean;
	sent: boolean;
	rejected: boolean;
	approved: boolean;
	rejectedSent: boolean;
	approvedSent: boolean;
	overview: any;
	configurationPrice: any;
	showZeroPrices: boolean;
	textConfirmation: string;
	sendConfirmationModal: boolean;
	saved: boolean;
	loading: boolean;
	canSave: boolean;
	inquiryModal: boolean;
}
interface IOwnProps extends RouteComponentProps<any> {
	part: any;
	step: number;
	selectedParameters: any;
	configuration: any;
	document: any;
	categories: any;
	anonymousToken: string;
	theme?: any;
	cartAddingDisabled: boolean;
}
interface IStateProps {}
type ActionCreators = typeof actionCreators;
type IProps = IOwnProps & ActionCreators & IStateProps;

class ConfiguratorStep3 extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			saveChoiceModal: false,
			notifyError: '',
			categories: [],
			saveMessage: null,
			saveTitle: null,
			addingDisabled: this.props.cartAddingDisabled,
			sent: false,
			rejected: false,
			approved: false,
			rejectedSent: false,
			approvedSent: false,
			overview: null,
			configurationPrice: 0,
			showZeroPrices: false,
			textConfirmation: '',
			sendConfirmationModal: false,
			saved: false,
			loading: false,
			canSave: false,
			inquiryModal: false,
		};
	}
	toggleSendConfirmationModal() {
		let state: IState = this.state;
		state.textConfirmation = '';
		state.rejectedSent = false;
		state.approvedSent = false;
		state.sendConfirmationModal = !state.sendConfirmationModal;
		this.setState(state);
	}
	componentDidUpdate(nextProps: any) {
		if (
			nextProps.categories !== this.props.categories ||
			this.props.configuration !== nextProps.configuration ||
			this.props.part.price !== nextProps.part.price
		) {
			this.setState({ canSave: false });
			if (this.props.configuration.loading === nextProps.configuration.loading && this.props.configuration.image === nextProps.configuration.image) {
				this.getOverview(this.props.configuration.configurationId);
			}
		}
	}
	componentDidMount() {
		//Get configuration id from url
		this.setState({ loading: true, canSave: false });
		const queryValues = queryString.parse(this.props.location.search);
		if (queryValues && queryValues.id) {
			if (queryValues.bpi) {
				if (queryValues.bpi.toString() !== Helper.brandPartnerId()) {
					this.getTheme(queryValues.bpi.toString());
				}
				localStorage.setItem('brandPartnerId', queryValues.bpi.toString());
			}
			this.getFooter();

			this.props.buildCategories(queryValues.id.toString(), undefined, undefined, true);
			this.getOverview(queryValues.id.toString());
		} else if (this.props.configuration && this.props.configuration.configurationId) {
			this.getOverview(this.props.configuration.configurationId);
		}
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		const { target } = e;
		const value = target.value;
		state[target.name] = value;
		state.saved = false;
		this.setState(state);
	}

	getTheme(brandPartnerId: string) {
		const url = '/brand/' + brandPartnerId + '/data';
		Helper.getData<any>(url).then((data: any) => {
			if (data) {
				const logo = (data.Style && data.Style.HeaderLogo) || '';
				const logotype = (data.Style && data.Style.HeaderLogotype) || '';
				const title = Helper.getLocalizedName(data.BrandName) || '';
				const header = (data.Style && Helper.getLocalizedName(data.Style.HeaderText)) || '';
				const color = (data.Style && data.Style.BackgroundColor) || '';
				const headerFontColor = (data.Style && data.Style.HeaderFontColor) || '';
				const buttonColor = (data.Style && data.Style.ButtonColor) || '';
				const buttonFontColor = (data.Style && data.Style.ButtonFontColor) || '';

				const theme = {
					logo: logo,
					title: title,
					header: header,
					color: color,
					headerFontColor,
					buttonColor,
					buttonFontColor,
				};
				localStorage.setItem('theme', JSON.stringify(theme));
				localStorage.setItem('brandPartnerId', data.BrandPartnerId);

				(this.props as any).setBrand(logo, logotype, title, header, color, headerFontColor, data.BrandPartnerId, buttonColor, buttonFontColor);
			}
		});
	}

	getFooter() {
		const url = '/footer/' + Helper.brandPartnerId();
		Helper.getData<any>(url).then((data: any) => {
			if (data) {
				const footer1text = data.Footer1text || '';
				const footer1url = data.Footer1url || '';
				const footer2text = data.Footer2text || '';
				const footer2url = data.Footer2url || '';
				const footer3text = data.Footer3text || '';
				const footer3url = data.Footer3url || '';
				const footer = {
					footer1text: footer1text,
					footer1url: footer1url,
					footer2text: footer2text,
					footer2url: footer2url,
					footer3text: footer3text,
					footer3url: footer3url,
				};
				localStorage.setItem('footer', JSON.stringify(footer));
				(this.props as any).setFooter(footer1text, footer1url, footer2text, footer2url, footer3text, footer3url);
			}
		});
	}

	getOverview(id: string) {
		if (this.props.document && this.props.document.documentId && Helper.userAdmin()) {
			let url = '';
			if (Helper.userLoggedIn()) {
				url = '/configurator/auth/' + id + '/overview?brandPartnerId=' + Helper.brandPartnerId() + '&documentId=' + this.props.document.documentId;
			} else {
				url = '/configurator/' + id + '/overview?brandPartnerId=' + Helper.brandPartnerId();
			}
			Helper.getData<any>(url).then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.saving, data.Message);
					this.setState({ loading: false, canSave: true });
				} else {
					this.setState({
						overview: data.Categories,
						configurationPrice: data.ConfigurationPrice,
						showZeroPrices: data.ShowZeroPrices,
						loading: false,
						canSave: true,
					});
				}
			});
		} else {
			const queryValueId = queryString.parse(this.props.location.search).id || '';
			if (id && id.length && (id !== queryValueId || !this.state.overview)) {
				let url = '';
				if (Helper.userLoggedIn()) {
					url = '/configurator/auth/' + id + '/overview?brandPartnerId=' + Helper.brandPartnerId();
				} else {
					url = '/configurator/' + id + '/overview?brandPartnerId=' + Helper.brandPartnerId();
				}
				Helper.getData<any>(url).then((data) => {
					if (data.Message && data.Message.length > 0) {
						(this.props as any).setError(locale.saving, data.Message);
						this.setState({ loading: false, canSave: true });
					} else {
						this.setState({
							overview: data.Categories,
							configurationPrice: data.ConfigurationPrice,
							showZeroPrices: data.ShowZeroPrices,
							loading: false,
							canSave: true,
						});
					}
				});
			}
		}
	}
	toggleSaveChoiceModal(openInquiryDialog: boolean) {
		if (!this.props.configuration.configurationId) {
			return;
		}
		if (
			this.props.document &&
			this.props.document.documentId &&
			this.props.configuration &&
			this.props.configuration.configurationId &&
			this.props.configuration.configurationPublished &&
			this.props.configuration.hasDocument &&
			Helper.userAdmin()
		) {
			//Admin mode - update existing configuration on existing selected document
			this.setState({ loading: true });
			const url =
				'/configurator/' +
				this.props.configuration.configurationId +
				'/clone/' +
				this.props.document.documentId +
				'?brandPartnerId=' +
				Helper.brandPartnerId();

			Helper.postData<any>(url, '')
				.then((data) => {
					if (data.Message && data.Message.length > 0) {
						(this.props as any).setError(locale.saving, data.Message);
						this.setState({ loading: false });
					} else {
						if (data.ConfigurationId && data.DocumentConfigurationId) {
							(this.props as any).saveConfiguration(data.ConfigurationId, data.DocumentConfigurationId, data.DocumentPosition);
						}
						this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configurations');
					}
				})
				.catch(() => {
					(this.props as any).setError(locale.exception, locale.generalException);
					this.setState({ loading: false });
				});
		} else if (
			this.props.document &&
			this.props.document.documentId &&
			this.props.configuration &&
			this.props.configuration.configurationId &&
			Helper.userAdmin()
		) {
			//Admin mode - add configuration to existing selected document
			this.setState({ loading: true });
			let urlConfiguration =
				'/document/' +
				this.props.document.documentId +
				'?configurationId=' +
				this.props.configuration.configurationId +
				'&brandPartnerId=' +
				Helper.brandPartnerId();

			if (this.props.configuration.position && this.props.configuration.position > 0) {
				urlConfiguration += '&p=' + this.props.configuration.position;
			}

			Helper.postData<any>(urlConfiguration, '')
				.then((data) => {
					if (data.Message && data.Message.length > 0) {
						(this.props as any).setError(locale.saving, data.Message);
						this.setState({ loading: false });
					} else {
						this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configurations');
					}
				})
				.catch(() => {
					(this.props as any).setError(locale.exception, locale.generalException);
					this.setState({ loading: false });
				});
		} else {
			// Normal user
			if (
				this.props.configuration &&
				this.props.configuration.configurationPublished &&
				(this.props.anonymousToken || localStorage.getItem('userToken'))
			) {
				//open overwrite dialog if already published
				const state: IState = this.state;
				state.saved = false;
				state.saveChoiceModal = !state.saveChoiceModal;
				state.saveTitle = locale.cartSaving;
				state.saveMessage = locale.cartOverride;
				state.loading = false;
				this.setState(state);
			} else {
				//just publish if not yet published
				const state: IState = this.state;
				state.saved = false;
				state.saveChoiceModal = !state.saveChoiceModal;
				state.saveTitle = locale.cartSaving;
				state.saveMessage = locale.saving + '...';
				state.loading = true;
				this.setState(state);
				this.addToCart(false, openInquiryDialog);
			}
		}
	}

	saveConfiguration(createNew: boolean) {
		this.setState({ saveMessage: locale.saving + '...', saved: false });
		this.addToCart(createNew, false);
	}

	private AddToCartMessage = () => {
		return (
			<span>
				{locale.cartSuccess}
				<br />
				<br />
				<button
					type='button'
					onClick={() => this.openCart()}
					style={{ color: Helper.themeButtonFontColor(), backgroundColor: Helper.themeButtonColor() }}
					aria-haspopup='true'
					aria-expanded='false'
					className='bordered dropdown-toggle btn btn-secondary'
				>
					<span className='fa fa-cart-plus' style={{ marginLeft: '0px', fontSize: '24px' }} aria-hidden='true'></span>
					&nbsp;
					<span className='clearfix d-none d-md-inline-block'>{locale.cart}</span>
				</button>
			</span>
		);
	};
	addToCart(copy: boolean, openInquiryDialog: boolean) {
		const state: IState = this.state;
		if (this.props.configuration.configurationId) {
			let url = '/cart/' + this.props.configuration.configurationId;

			const body = {
				BrandPartnerId: Helper.brandPartnerId(),
				UserTokenId: this.props.anonymousToken,
				Copy: copy ? true : false,
			};

			Helper.postData<any>(url, JSON.stringify(body))
				.then((data) => {
					if (data && data.UserToken) {
						if (data.UserToken !== this.props.anonymousToken) {
							(this.props as any).setAnonymousToken(data.UserToken);
						}
						(this.props as any).setConfigurationPublished(true);
						state.loading = false;
						state.saved = true;
						state.saveMessage = this.AddToCartMessage();
						state.saveTitle = locale.cartSavingSuccess;
						this.setState(state);
						if (openInquiryDialog) this.openInquiryDialog();
					} else if (data && data.Message) {
						state.saveMessage = data.Message;
						state.saved = true;
						state.loading = false;
						this.setState(state);
					}

					// Build configuration for new id
					if (copy) {
						(this.props as any).buildCategories(data.ConfigurationId);
						(this.props as any).setConfigurationPublished(true);

						state.loading = false;
						this.setState(state, () => this.getOverview(data.ConfigurationId));
					}
				})
				.catch((error) => {
					state.notifyError = error.message;
					state.saveMessage = error.message;
					state.loading = false;
					state.saved = true;
					this.setState(state);
				});
		}
	}

	renderCategoriesRecursion(categories: any, isSubCategory: boolean): any {
		if (!categories || categories.length === 0 || !Array.isArray(categories)) {
			return [];
		}
		const categoriesList = categories.map((category: any, i: number) => {
			let subsubcategories: any = [];
			if (category.Categories && category.Categories.length > 0) {
				subsubcategories = this.renderCategoriesRecursion(category.Categories, true);
			}
			category.open = true;
			let parameterList = [];
			parameterList = category.Parameters.map((parameter: any, j: number) => {
				return (
					<Row key={j} className='py-1'>
						<Col>
							{Helper.getLocalizedValueOrNull(parameter.ParameterBrandName) ??
								Helper.getLocalizedValueOrNull(parameter.ParameterTitle) ??
								parameter.ParameterName}
						</Col>
						<Col>
							{Helper.getLocalizedValueOrNull(parameter.ParameterBrandValue) ??
								Helper.getLocalizedValueOrNull(parameter.ParameterValue) ??
								parameter.ParameterValueName}
						</Col>
						<Col className='text-right pr-4'>
							{Helper.userLoggedIn() && (parameter.ParameterPrice !== 0 || category.ShowZeroPrices)
								? parameter.ParameterPrice.toLocaleString('de-DE', {
										style: 'currency',
										currency: this.props.document.currency,
								  })
								: ''}
						</Col>
					</Row>
				);
			});
			if (isSubCategory) {
				return (
					<React.Fragment key={i}>
						<Row>
							<Col lg='12 mt-4 mb-2'>
								<div className='filter-separator'>
									<span>
										<b>{Helper.getLocalizedName(category.ParameterCategoryName)}</b>
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className='mt-1' xs='12'>
								{parameterList}
							</Col>
						</Row>
						<Row>
							<Col xs='12'>{subsubcategories}</Col>
						</Row>
					</React.Fragment>
				);
			} else {
				return (
					<div key={i} className='mr-auto pt-2 mb-1 navbar-wrapper'>
						<NavbarBrand className='w-100 font-weight-bold'>
							{Helper.getLocalizedName(category.ParameterCategoryName)}{' '}
							{Helper.userLoggedIn() && (category.ParameterCategoryPrice !== 0 || category.ShowZeroPrices) ? (
								<span className='float-right mr-2'>
									{category.ParameterCategoryPrice.toLocaleString('de-DE', {
										style: 'currency',
										currency: this.props.document.currency,
									})}{' '}
								</span>
							) : null}
						</NavbarBrand>
						<UncontrolledCollapse defaultOpen={true} toggler={'#toggler'} navbar>
							<Row>
								<Col className='mt-1' xs='12'>
									{parameterList}
								</Col>
							</Row>
							<Row>
								<Col xs='12'>{subsubcategories}</Col>
							</Row>
							<Row>
								<Col className='mt-3' xs='12'>
									{' '}
								</Col>
							</Row>
						</UncontrolledCollapse>
					</div>
				);
			}
		});
		return categoriesList;
	}
	prevPage() {
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/configuration');
	}
	openCart() {
		const state: IState = this.state;
		state.saved = false;
		state.saveChoiceModal = false;
		this.setState(state);
		(this.props as any).setCartOpen(true);
	}
	openInquiryDialog() {
		const state: IState = this.state;
		state.saved = false;
		state.saveChoiceModal = false;
		this.setState(state);
		(this.props as any).setInquiryDialogOpen(true);
	}
	downloadDocument() {
		const url = '/configurator/' + this.props.configuration.configurationId + '/pricefile?documentId=' + this.props.document.documentId;
		Helper.getData<any>(url)
			.then((data) => {
				if (data) {
					const value = data;
					let link = document.createElement('a');
					link.download = value.FileName;
					link.href = `data:${value.MimeType};base64,${value.Contents}`;
					link.click();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	render() {
		const categoriesList: any = this.renderCategoriesRecursion(this.state.overview, false);
		const hasDocument: boolean = this.props.document && this.props.document.documentId;

		let buttons: any = null;
		const disableEditDocument = this.props.document && this.props.document.disableEdit;
		if (
			this.props.configuration &&
			this.props.configuration.configurationId &&
			this.props.configuration.documentConfigurationId &&
			this.props.document &&
			this.props.document.initialDocumentId &&
			!disableEditDocument
		) {
			//Admin edit - configuration is saved automatically after each change TODO: Change
			buttons = (
				<Fragment>
					<Button
						color='secondary'
						className='float-left d-print-none ml-2'
						onClick={() => this.toggleSaveChoiceModal(false)}
						disabled={!hasDocument || !this.state.canSave}
					>
						{locale.saveConfigurationToDocument}
					</Button>
					{Helper.userHeadOfSales() && Helper.userIsParentPartner() ? (
						<Button color='secondary' className='float-left d-print-none ml-2' onClick={() => this.downloadDocument()}>
							{locale.downloadPriceFile}
						</Button>
					) : null}
				</Fragment>
			);
		} else if (Helper.userAdmin() && !disableEditDocument) {
			// Admin adding mode
			buttons = (
				<Fragment>
					<Button
						color='secondary'
						className='float-left d-print-none'
						onClick={() => this.toggleSaveChoiceModal(false)}
						disabled={!hasDocument || !this.state.canSave}
					>
						{locale.saveConfigurationToDocument}
					</Button>
					{Helper.userHeadOfSales() && Helper.userIsParentPartner() ? (
						<Button color='secondary' className='float-left d-print-none ml-2' onClick={() => this.downloadDocument()}>
							{locale.downloadPriceFile}
						</Button>
					) : null}
				</Fragment>
			);
		} else if (!Helper.userAdmin() && !disableEditDocument) {
			//Normal save configuration mode for non admins
			buttons = (
				<Button
					color='secondary'
					className='float-left d-print-none'
					onClick={() => this.toggleSaveChoiceModal(false)}
					disabled={!this.state.canSave}
				>
					{locale.saveConfiguration}
				</Button>
			);
		}
		if (this.state.notifyError !== '') return <Notify message={this.state.notifyError} isError={true} />;

		return (
			<div>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<div id='configurator' className='step2'>
						<Modal backdrop='static' isOpen={this.state.saveChoiceModal} toggle={() => this.toggleSaveChoiceModal(false)} className='inquiry-popup'>
							<ModalHeader toggle={() => this.toggleSaveChoiceModal(false)}>{this.state.saveTitle}</ModalHeader>
							<Fragment>
								<ModalBody>{this.state.saveMessage}</ModalBody>
								<ModalFooter>
									{this.state.saved ? (
										<Button color='secondary' onClick={() => this.toggleSaveChoiceModal(false)}>
											{locale.close}
										</Button>
									) : this.state.saveMessage && this.state.saveMessage.toString().indexOf(locale.saving) < 0 ? (
										<Fragment>
											<Button color='secondary' className='small-button' onClick={() => this.saveConfiguration(false)}>
												{locale.cartButtonOverride}
											</Button>
											<Button className='ml-2 small-button' color='secondary' onClick={() => this.saveConfiguration(true)}>
												{locale.cartButtonNew}
											</Button>
										</Fragment>
									) : (
										<br />
									)}
								</ModalFooter>
							</Fragment>
						</Modal>
						<Row>
							<Col className='col-12 col-md-6 col-lg-5 col-xxl-4 no-gutters pr-3 pb-5'>
								<Row className='w-100'>
									<Col className='mb-1 mt-3'>
										{buttons}
										<i className='fa fa-expand cursor-pointer float-right d-print-none' id='toggler'></i>
									</Col>
								</Row>
								<Row className='d-none d-print-block'>
									<Col style={{ height: '110px' }}></Col>
								</Row>
								<Fragment>
									<h4 className='d-none d-print-block p-3'>{this.props.part && this.props.part.name}</h4>

									<div className='overview mt-2 ml-1'>
										<Row className='py-1'>
											<Col className='configuration-price'>{locale.adminCalculationConfigurationPrice}</Col>
											<Col></Col>
											<Col className='text-right pr-4 configuration-price'>
												{Helper.userLoggedIn() && (this.state.configurationPrice > 0 || this.state.showZeroPrices)
													? this.state.configurationPrice.toLocaleString('de-DE', {
															style: 'currency',
															currency: this.props.document.currency,
													  })
													: ''}
											</Col>
										</Row>
										{categoriesList}
									</div>
									{!Helper.userLoggedIn() ? <div className='my-3'>* {locale.loginToViewPrices}</div> : null}
									{!Helper.userLoggedIn() && (
										<div className='configurator-buttons'>
											<Button
												color='secondary'
												className='button'
												style={{ backgroundColor: Helper.themeButtonColor(), color: Helper.themeButtonFontColor(), width: '100%' }}
												onClick={() => this.toggleSaveChoiceModal(true)}
											>
												{locale.sendInquiry}
											</Button>
										</div>
									)}
								</Fragment>
							</Col>
							<Col className='col-12 col-md-6 col-lg-7 col-xxl-8 configurator-background'>
								<Row>
									<Col xs='12' className='mt-4'>
										<Part print={true} history={this.props.history} location={this.props.location} match={this.props.match} />
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(ConfiguratorStep3);
