const locale = {
	popular: 'Beliebteste Modelle',
	searchResults: 'Suchergebnisse',
	price: 'Preis',
	amount: 'Betrag',
	model: 'Model',
	name: 'Name',
	company: 'Unternehmen',
	save: 'Speichern',
	filterTypeName: 'Typ',
	filterGroupName: 'Stil',
	filtersClear: 'Filter löschen',
	filters: 'Filter',
	searchPlaceholder: 'Suchen...',
	configurationStep1: 'Modellauswahl',
	configurationStep2: 'Konfiguration',
	configurationStep3: 'Konfigurationsübersicht',
	configurationStepCustomerView: 'Dokumentenübersicht',
	select: 'Auswählen',
	quantity: 'Menge',
	overview: 'Überblick',
	version: 'Version',
	modelAndConfig: 'Model / ID Konfig.',
	status: 'Status',
	noResults: 'Keine Ergebnisse',
	noData: 'Keine Daten',
	next: 'Weiter',
	prev: 'Züruck',
	nextPage: 'Nächste Seite',
	sendInquiry: 'Anfrage absenden',
	step: 'Schritt',
	of: 'von',
	comment: 'Kommentar',
	clearConfiguration: 'Konfiguration zurücksetzen',
	cancel: 'Abbrechen',
	continue: 'Fortsetzen',
	close: 'Schließen',
	exception: 'Fehler',
	generalException: 'Ups! Irgenwas lief schief.',
	apiException: 'Serverfehler',
	myProjects: 'Meine Projekte',
	allProjects: 'Alle Projekte',
	assignDocument: 'Zuweisen (intern)',
	assignDocumentWithinCompany: 'Innerhalb des Unternehmens zuordnen',
	assignDocumentOutsideCompany: 'Außerhalb des Unternehmens zuordnen',
	assignDocumentToContactTitle: 'Ansprechpartner',
	assignDocumentToContactAddNew: 'Ansprechpartner hinzufügen',
	assignDocumentToContactConfirmation: 'Möchten Sie den Ansprechpartner wirklich ändern?',
	sendDocument: 'An Partner senden',
	toggleProjectStatus: 'Projekt',
	unknownUser: 'Unbekannt',
	saveConfiguration: 'Zum Warenkorb hinzufügen',
	saveConfigurationToDocument: 'Speichern',
	addConfigurationToDocument: 'Zum Dokument hinzufügen',
	customerNewOffer: 'Dokument erstellen',
	customerProcess: 'Bearbeiten',
	customerSendERP: 'An ERP senden',
	customerImportEdi: 'Aus ERP importieren',
	customerNewVersion: 'Konfiguration zurücksetzen',
	customerAssign: 'Person zuordnen',
	customerConfirmOrder: 'AB freigeben',
	customerConfirmOrderMessage:
		'Mit dieser Bestätigung ist die Bestellung (Dokument Nr. {number}) zur Produktion freigegeben. Mit Bestätigung akzeptiere ich damit verbundene finanzielle Verpflichtungen (Zahlungspflicht).',
	customerConfirmInqury: 'Angebot erstellen',
	customerConfirmInquryNewProject: 'Im neuen Projekt erstellen',
	customerSendToConfirm: 'Zur Bestätigung senden',
	customerSend: 'Senden',
	customerForward: 'Weiterleiten',
	customerSendOffer: 'Dokument senden',
	customerSendDocument: 'Dokument senden',
	customerDelete: 'Löschen',
	customerDeleteConfirm: 'Möchten Sie den ausgewählten Eintrag wirklich löschen?',
	configurator: 'Konfigurator',
	open: 'Öffnen',
	customerEditingTitleInquiry: 'Anfrage bearbeiten',
	customerEditingTitleOffer: 'Angebot bearbeiten',
	documentEditing: 'Dokument',
	configurationEditing: 'Konfiguration',
	adminCalculationDiscounts: 'Rabatte',
	adminCalculationAddDiscount: 'Rabatt hinzufügen',
	adminCalculationAdditional: 'Zusatzoptionen',
	adminCalculationCostsNet: 'Dienstleistungen',
	adminCalculationAdditionalCosts: 'Zusatzkosten',
	adminCalculationAddAdditionalCost: 'Zusatzkosten hinzufügen',
	adminCalculationAddNetCost: 'Dienstleistung hinzufügen',
	adminCalculationConfigurationPrice: 'Konfigurationspreis',
	adminCalculationAdditionalSum: 'Summe Zusatzkosten',
	adminCalculationConfigurationWithAdditionalSum: 'Konfigurationspreis inkl. Zusatzoptionen',
	adminCalculationDiscountPercentageSum: 'Gesamtrabatt (%)',
	adminCalculationDiscountSum: 'Gesamtrabatt (Wert)',
	adminCalculationConfigurationWithDiscountSum: 'Konfigurationspreis inkl. Zusatzoptionen und Rabatte',
	adminCalculationServiceSum: 'Summe Dienstleistungen',
	adminCalculationSum: 'Gesamtpreis',
	adminRemark: 'Anmerkung',
	adminRemarkAddImage: 'Bild hochladen',
	adminComment: 'Kommentar',
	adminDocumentationAdd: 'Dokument hochladen',
	adminDocumentCustomer: 'Kunde',
	adminDocumentContact: 'Ansprechpartner',
	adminDocumentPartner: 'Verantwortlicher Partner',
	adminDocumentPartnerRecipient: 'Partner',
	adminDocumentDateModified: 'Letzte Änderung',
	adminDocumentStatus: 'Status',
	adminDocumentDocumentNumber: 'Dokumentnummer',
	adminDocumentProject: 'Projekt',
	adminDocumentProjectStatus: 'Proj. Status',
	adminDocumentDocumentOrder: 'Dok. Nr.',
	adminDocumentBuyerOrderNumber: 'Bestellnummer',
	adminDocumentComission: 'Kommission',
	adminDocumentCustomerNumber: 'Bestellnummer',
	adminDocumentCustomerPosition: 'Position',
	adminDocumentCustomerSubPosition: 'Subposition',
	adminDocumentCommission: 'Kommission',
	adminDocumentDeliveryTime: 'Liefertermin',
	adminMenuDocument: 'Dokumentdaten',
	adminMenuConfigurations: 'Konfigurationen',
	adminMenuHistory: 'Chronik',
	adminMenuConfiguration: 'Konfiguration',
	adminMenuCalculation: 'Konfigurationsübersicht',
	adminMenuComment: 'Kommentar',
	adminMenuImages: 'Bilder',
	adminMenuSalesRemarks: 'Verkaufsanmerkungen',
	adminMenuInstallerRemarks: 'Monteur',
	adminMenuPosition: 'Positionsdaten',
	adminMenuDiscountsCosts: 'Rabatte, Dienstleistungen, USt.',
	adminMenuTechnicalDocumentation: 'Technische Dokumentation',
	adminMenuPrintDocument: 'Drucken und senden',
	insideView: 'Innenansicht',
	facade: 'Fassade',
	cartSuccess: 'Durch Klicken auf das Warenkorbsymbol können Sie dessen Inhalt bearbeiten.',
	saving: 'Speichern',
	contactTitle: 'Kontaktdaten und Zustimmungen',
	contactTitleSales: 'Kontaktdaten',
	inquirySalesDialog: 'Dokument',
	cart: 'Warenkorb',
	cartSendInquiry: 'Anfrage absenden',
	cartRemove: 'Löschen',
	cartInquirySentText: ', vielen Dank für Ihre Anfrage. Sie wird so schnell wie möglich bearbeitet.',
	cartInquirySentText2: 'Vielen Dank für Ihre Anfrage. Sie wird so schnell wie möglich bearbeitet.',
	cartInquirySentText3: 'Dokument erstellt',
	cartInquirySent: 'Anfrage erfolgreich gesendet',
	cartOverride: 'Möchten Sie die vorhandene Konfiguration überschreiben oder eine neue erstellen?',
	cartSaving: 'Die Konfiguration wird gespeichert',
	cartSavingSuccess: 'Ihre Konfiguration wurde zum Warenkorb hinzugefügt',
	documentSaving: 'Das Dokument wird gespeichert',
	cartButtonOverride: 'Vorhandene Konfiguration überschreiben',
	cartButtonNew: 'Neue Konfiguration erstellen',
	cartRemoveTitle: 'Die Konfigurationen werden gelöscht',
	cartRemovalText: 'Die Konfigurationen wurden gelöscht',
	addDiscount: 'Rabatt',
	addCost: 'Zusatzoption',
	addNetCost: 'Dienstleistung',
	discountText: 'Name',
	discountValue: 'Prozent (%)',
	discountLevel: 'Niveau',
	discountType: 'Typ des Rabatts',
	discount: 'Prozent',
	sortOrder: 'Reihenfolge',
	discountAmount: 'Rabattwert',
	discountValueAfter: 'Preis nach Rabatt',
	costText: 'Name',
	costValue: 'Menge',
	requestDocumentation: 'Technische Zeichnung anfordern',
	requestSpecialDocumentation: 'Sonderzeichnung anfordern',
	loadSpecialDocumentation: 'Sonderzeichnung hochladen',
	user: 'Benutzer',
	image: 'Bild',
	date: 'Datum',
	customer: 'Kunde',
	partner: 'Partner',
	commission: 'Kommission',
	responsiblePerson: 'Verantwortliche',
	position: 'Position',
	value: 'Wert',
	dataLastChange: 'Datum der Änderung',
	documentation: 'Dokumentation',
	inquiries: 'Anfragen',
	offers: 'Angebote',
	printOffer: 'Dokument drucken',
	printDownload: 'Herunterladen',
	printChangeToSend: 'Zu Gesendet ändern',
	printEditSent: 'E-Mail vorbereiten',
	printEmailSubject: 'Varialis - {number}',
	printEmailBody:
		'Sehr geehrte Damen und Herren,\n\nIm Anhang erhalten Sie das Angebot Nr. {number} zu Ihrer Anfrage. \n\nMit freundlichen Grüßen,\nVARIALIS portal\n\n\n\nHaftungsausschluss: E-Mails können vertrauliche Informationen enthalten, die nur für den Adressaten bestimmt sind. Falls die Nachricht aufgrund eines Adress- oder Übermittlungsfehlers an anderer Stelle verschickt wurde, informieren Sie bitte den Verfasser der E-Mail. Wenn die Nachricht nicht für Sie bestimmt war, dürfen die Informationen in der Nachricht nicht verwendet, weitergegeben, verbreitet, kopiert, gedruckt oder in irgendeiner Weise verwendet werden.',
	email: 'E-Mail Adresse',
	password: 'Passwort',
	signIn: 'Anmeldung',
	signUp: 'Registrierung',
	profile: 'Profil',
	signingOut: 'Sie werden abgemeldet.',
	salesman: 'Sachbearbeiter',
	salesmanResponsible: 'Verantwortlicher Sachbearbeiter',
	signOut: 'Abmelden',
	submit: 'Absenden',
	loginSuccess: '',
	loginError: 'Beim Anmelden ist ein Problem aufgetreten.',
	addressing: 'Anrede',
	firstName: 'Vorname',
	firstAndLastName: 'Vor- und Nachname',
	lastName: 'Nachname',
	loginFailed: 'Anmeldung fehlgeschlagen',
	loginNoPass: 'Geben Sie Ihr Passwort ein',
	loginNoUsername: 'Geben sie ihre E-Mail Adresse ein',
	forgotPassword: 'Passwort vergessen?',
	resetPassword: 'Passwort ändern',
	resetPasswordMessage:
		'Eine Bestätigungsnachricht wurde an die von Ihnen eingegebene E-Mail-Adresse gesendet. Bitte prüfen Sie Ihren Posteingang, um fortzufahren.',
	registrationFailed: 'Registrierung fehlgeschlagen',
	missingFields: 'Bitte geben Sie alle erforderlichen Felder ein.',
	registrationSucceeded:
		'Danke für die Registrierung. Sie haben eine Nachricht an die von Ihnen eingegebene E-Mail-Adresse erhalten, um den Vorgang abzuschließen.',
	profileUpdateSucceeded: 'Ihre Profildaten wurden aktualisiert.',
	profileDeliveryTitle: 'Lieferadresse',
	profileReceiptTitle: 'Rechnungsadresse',
	profileAddressAdd: 'Adresse hinzufügen',
	profileAddressEdit: 'Adresse bearbeiten',
	differentThenContactAddress: 'Anders als Kontaktadresse',
	activationSucceeded: 'Aktivierung erfolgreich',
	accountActivation: 'Account Aktivierung',
	confirmPassword: 'Passwort bestätigen',
	activate: 'Aktivieren',
	missingToken: 'Kontoaktivierung fehlgeschlagen. Bitte versuchen Sie erneut, sich zu registrieren.',
	telephone: 'Telefon',
	vat: 'USt-ID Nr.',
	registrationNumber: 'Registrationsnummer',
	inquiryTitle:
		'Vielen Dank für Ihre Anfrage. Zum Zwecke der Bearbeitung füllen Sie bitte die Kontaktdaten aus, prüfen ggf. (optionale) Zustimmungen zur Datenschutzerklärung und senden die Anfrage über den Senden-Button ab.<br/>Hiermit erklären Sie sich damit einverstanden, dass unser Unternehmen und unsere Vertriebspartner Sie per E-Mail und/oder kontaktieren Telefonisch nutzen wir die bereitgestellten Informationen, um Ihre Anfrage zu bearbeiten, ein Angebot abzugeben oder eine Produktberatung zu erteilen. Im Falle einer Bestellung gestatten Sie uns, Ihre Daten an geprüftes Fachpersonal zur Beratung und Vor-Ort-Besichtigung weiterzugeben, damit die Bestellung abgewickelt werden kann.',
	inquiryTitleCustomer: 'Kundenkontaktdaten ausfüllen',
	inquiryTitleExistingCustomer: 'Ansprechpartner auswählen',
	inquiryDocumentType: 'Dokument Typ',
	address: 'Straße und Hausnummer (Adresse)',
	city: 'Ort',
	zip: 'Postleitzahl',
	post: 'Postleitzahl',
	country: 'Land',
	selectCountry: 'Land auswählen',
	selectDiscountType: 'Typ des Rabatts auswählen',
	companyName: 'Firmenname',
	companyRegistration: 'Handelsregisternummer',
	companyVAT: 'USt-IdNr.',
	inquiryLegalAdd: 'Zusätzlich für juristische Personen',
	inquiryConsents: 'Zustimmungen',
	inquiryConsent1Title: 'Angebote und Erinnerungen',
	inquiryConsent1: 'z.B. Informieren über relevante Angebote von Produkten, Ersatzteilen, Zubehör und anderen Produkten und Dienstleistungen.',
	inquiryConsent2Title: 'Benachrichtigungen',
	inquiryConsent2:
		'Ankündigungen und Einladungen zu Veranstaltungen zur Markteinführung neuer Modelle und Kataloge, Messen, Tage der offenen Tür, sonstige Präsentationen und Veranstaltungen.',
	inquiryConsent3Title: 'Umfragen',
	inquiryConsent3: 'Forschung, um Ihre Meinung zur Verbesserung von Produkten und Dienstleistungen einzuholen.',
	equalPasswords: 'Bitte gleiche Passwörter eingeben',
	type: 'Typ',
	brands: 'Marken',
	partners: 'Partner',
	delete: 'Löschen',
	languages: 'Sprachen',
	clearCache: 'Cache löschen',
	tags: 'Tag suchen',
	text: 'Text',
	desc: 'Beschreibung',
	configuration: 'Konfiguration',
	document: 'Dokument',
	change: 'Ändern',
	edit: 'Bearbeiten',
	view: 'Überblick',
	clone: 'Klonen',
	copy: 'Kopieren',
	remove: 'Löschen',
	default: 'Default',
	add: 'Hinzufügen',
	deleting: 'Löschen...',
	adding: 'Hinzufügen...',
	cloning: 'Klonieren...',
	print: 'Drucken',
	submitForProduction: 'Zur Produktion senden',
	cartAddingDisabled:
		'Sie haben die maximal zulässige Anzahl gespeicherter Projekte erreicht. Bitte loggen Sie sich für unbegrenzte Projekte ein.\nZur Registrierung oder Anmeldung klicken Sie bitte auf das Icon in der oberen rechten Ecke.',
	notSucceeded: 'gescheitert',
	documentType: 'Typ des Dokuments',
	printPrice: 'Preise',
	printColorImage: 'Farbbild',
	printTechImage: 'Technische Zeichnung',
	printConditions: 'Zahlungs- und Lieferbedingungen',
	selectPrintTemplate: 'Dokumentvorlage auswählen',
	selectPrintLanguage: 'Sprache des Dokuments auswählen',
	sendToConfirmation: 'Zur Bestätigung senden',
	inConfirmation: 'Im Bestätigungsprozess',
	isFinished: 'Abgeschlossen',
	confirm: 'Bestätigen',
	reject: 'Ablehnen',
	reset: 'Zurücksetzen',
	downloadPriceFile: 'Xlsx herunterladen',
	termsPayment: 'Zahlungsbedingungen',
	termsSales: 'Lieferbedingungen',
	addTermPayment: 'Zahlungsbedingung hinzufügen',
	addTermSales: 'Lieferbedingung hinzufügen',
	editTermPayment: 'Zahlungsbedingung bearbeiten',
	editTermSales: 'Lieferbedingung bearbeiten',
	viewTermPayment: 'Zahlungsbedingungen anzeigen',
	viewTermSales: 'Lieferbedingungen anzeigen',
	term: 'Bedingung',
	termsPage: 'Zahlungs- und Lieferbedingungen',
	send: 'Senden',
	confirmDelete: 'Möchten Sie den ausgewählten Eintrag wirklich löschen?',
	confirmArchive: 'Möchten Sie den ausgewählten Eintrag wirklich archivieren?',
	confirmReset: 'Möchten Sie die ausgewählte Konfiguration wirklich auf die Standardeinstellungen zurücksetzen?',
	confirmClone: 'Möchten Sie den ausgewählten Eintrag wirklich klonieren?',
	confirmCopy: 'Möchten Sie den ausgewählten Eintrag wirklich kopieren?',
	confirmChangeStatusToSend: 'Möchten Sie den Status des Dokuments wirklich auf »Gesendet« ändern?',
	confirmChangeStatusSpecialTechnical:
		'Aufmerksamkeit! Durch wiederholte Anfrage nach Technische Zeichnung geht die erstellte spezielle technische Dokumentation verloren.',
	confirmRequestDocumentation:
		'Aufmerksamkeit! Durch wiederholte Anfrage nach Technische Zeichnung geht die erstellte spezielle technische Dokumentation verloren.',
	confirmSend: 'Möchten Sie das Dokument wirklich senden?',
	confirmSendDocument:
		'Empfänger des Dokuments ist:<br/>- Partner: {Partner}<br/>- Sachbearbeiter: {Salesman}<br/>- Ansprechpartner: {Contact}<br/><br/> Möchten Sie das Dokument wirklich senden?',
	changeRecipient: 'Empfänger ändern',
	totalPriceWithoutTax: 'Gesamtpreis ohne USt.',
	totalTax: 'Total USt.',
	finalPrice: 'Gesamtpreis inkl. USt.',
	selectTax: 'Steuersatz auswählen',
	tax: 'USt.',
	printTemplateSelect: 'Die Vorlage soll enthalten:',
	userUploaded: 'Vom Benutzer hochgeladen',
	yes: 'Ja',
	no: 'Nein',
	contact: 'Kontaktperson',
	autoSaveConfiguration: 'Änderungen werden automatisch gespeichert',
	loginToViewPrices: 'Preise sind nur für registrierten Benutzer sichtbar',
	cookieLaw:
		'Die Website verwendet Cookies, um ihre volle Funktionalität zu gewährleisten. Cookies speichern Ihre Nutzung der Website. Durch die Nutzung der Website stimmen Sie der Verwendung dieser Cookies zu. Besuchen Sie für weitere Informationen:',
	cookiesTitle: 'Informationen zur Verwendung von Cookies',
	details: 'Übersicht',
	sourceDocument: 'Ursprungsdokument',
	download: 'Herunterladen',
	downloadPDF: 'PDF herunterladen',
	clonePart: 'Artikel',
	imageUpload: 'Bild hochladen',
	notImage: 'Das hochgeladene Bild muss vom Typ jpg, png oder bmp sein.',
	languageChange: 'Sprache ändern',
	languageChangeConfirm:
		'Durch Ändern der Sprache werden Sie zum Startbildschirm weitergeleitet. Nicht gespeicherte Konfigurationsänderungen werden verworfen.',
	brandChange: 'Marke ändern',
	brandChangeConfirm:
		'Durch Ändern der Marke werden Sie zum Startbildschirm weitergeleitet. Nicht gespeicherte Konfigurationsänderungen werden verworfen.',
	specialOffer: 'Sonderangebot',
	archive: 'Archivieren',
	includeArchived: 'Archivierte Dokumente anzeigen',
	showAll: 'Alle anzeigen',
	cartEmpty: 'Der Warenkorb ist leer',
	clipboard: 'In die Zwischenablage kopiert',
	inputText: 'Bitte Text eingeben',
	inputNumber: 'Bitte Nummer eingeben',
	twoDecimalPlaces: 'zwei Dezimalstellen',
	treeDetails: 'Parameterliste',
	deliveryTime: 'Liefertermin',
};
export default locale;
