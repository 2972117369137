import React, { Fragment } from "react";
import { DropdownItem } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store/Configurator";

interface IProps {
    toggleConfirmationModal(): void;
}
interface IState {
    languages: any[];
}
class Languages extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            languages: [
                { name: "SI", code: "sl" },
                { name: "DE", code: "de" },
                { name: "EN", code: "en" },
                { name: "FR", code: "fr" }
            ]
        };
    }

    setLanguage(code: string) {
        localStorage.setItem("selectedLang", code);
        this.props.toggleConfirmationModal();
    }

    render() {
        const items = this.state.languages.map((item: any, i: number) => (
            <DropdownItem key={i} onClick={() => this.setLanguage(item.code)}>
                {item.name}
            </DropdownItem>
        ));
        return <Fragment>{items}</Fragment>;
    }
}
export default connect(
    (state: any) => state.configurator,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Languages);
