import React from 'react';
import { DropdownItem } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../store/Configurator';
import Helper from '../Helper';
import locale from '../Locale';

interface IProps {
	brands?: any[];
	toggleConfirmationModal(): void;
}
interface IState {
	brands: any[];
}
class Brands extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			brands: this.props.brands || [],
		};
	}
	setBrand(i: number) {
		const state = this.state;
		const theme = {
			logo: state.brands[i].style.logo,
			logotype: state.brands[i].style.logotype,
			title: state.brands[i].brandName,
			header: state.brands[i].style.title,
			color: state.brands[i].style.color,
			headerFontColor: state.brands[i].style.headerFontColor,
			brandPartnerId: state.brands[i].brandPartnerId,
			buttonColor: state.brands[i].style.buttonColor,
			buttonFontColor: state.brands[i].style.buttonFontColor,
		};
		localStorage.setItem('selectedBrand', JSON.stringify(theme));
		this.props.toggleConfirmationModal();
	}

	componentDidMount() {
		if (!Helper.userAdmin() && !Helper.userInstaller()) {
			return;
		}
		const state: IState = this.state;

		if (this.state.brands.length === 0) {
			const url = '/brand';
			Helper.getData<any>(url)
				.then((data) => {
					if (data.Message && data.Message.length > 0) {
						(this.props as any).setError(locale.exception, data.Message);
						this.setState(state);
					} else {
						if (data && data.length > 0) {
							state.brands = data.map((item: any) => ({
								brandId: item.BrandId,
								brandPartnerId: item.BrandPartnerId,
								brandName: Helper.getLocalizedName(item.BrandName),
								style: {
									color: item.Style.BackgroundColor,
									headerFontColor: item.Style.HeaderFontColor,
									buttonColor: item.Style.ButtonColor,
									buttonFontColor: item.Style.ButtonFontColor,
									logo: item.Style.HeaderLogo,
									logotype: item.Style.HeaderLogotype,
									title: Helper.getLocalizedName(item.Style.HeaderText),
								},
							}));
						}
						(this.props as any).setBrands(state.brands);
						this.setState(state);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}
	render() {
		const items = this.state.brands.map((item: any, i: number) => (
			<DropdownItem key={i} onClick={() => this.setBrand(i)}>
				{item.brandName}
			</DropdownItem>
		));
		return items;
	}
}
export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(Brands);
