import React from 'react';
import { Row, Col, UncontrolledCollapse, NavbarBrand } from 'reactstrap';
import '../Configurator.css';
import './Admin.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import CustomerEditMenu from './CustomerEditMenu';
import Helper from '../../Helper';
import Loading from 'react-fullscreen-loading';
import locale from '../../Locale';

interface IProps extends RouteComponentProps<any> {
	configuration: any;
	document?: any;
	categories: any;
}
interface IState {
	overview: any;
	images: any;
	configurationPrice: string;
	currency: string;
	configurationId: string;
	loading: boolean;
}
class CustomerCalculationOverview extends React.Component<IProps, IState> {
	static sumCategory = 0;
	constructor(props: IProps) {
		super(props);
		this.state = {
			overview: [],
			images: [],
			configurationId: this.props.configuration.configurationId,
			loading: false,
			configurationPrice: '0',
			currency: 'EUR',
		};
	}
	renderCategoriesRecursion(categories: any, isSubCategory: boolean): any {
		if (!categories || categories.length === 0 || !Array.isArray(categories)) {
			return [];
		}
		const categoriesList = categories.map((category: any, i: number) => {
			let subsubcategories: any = [];
			if (category.Categories && category.Categories.length > 0) {
				subsubcategories = this.renderCategoriesRecursion(category.Categories, true);
			}
			category.open = true;
			let parameterList = [];
			parameterList = category.Parameters.map((parameter: any, j: number) => {
				return (
					<Row className='py-1' key={j}>
						<Col>
							{Helper.getLocalizedValueOrNull(parameter.ParameterBrandName) ??
								Helper.getLocalizedValueOrNull(parameter.ParameterTitle) ??
								parameter.ParameterName}
						</Col>
						<Col>
							{Helper.getLocalizedValueOrNull(parameter.ParameterBrandValue) ??
								Helper.getLocalizedValueOrNull(parameter.ParameterValue) ??
								parameter.ParameterValueName}
						</Col>
						<Col className='text-right pr-4'>
							{Helper.userLoggedIn() && (parameter.ParameterPrice !== 0 || category.ShowZeroPrices)
								? parameter.ParameterPrice.toLocaleString('de-DE', {
										style: 'currency',
										currency: this.state.currency,
								  })
								: ''}
						</Col>
					</Row>
				);
			});
			if (isSubCategory) {
				return (
					<React.Fragment key={i}>
						<Row>
							<Col lg='12 mt-4 mb-2'>
								<div className='filter-separator'>
									<span>
										<b>{Helper.getLocalizedName(category.ParameterCategoryName)}</b>
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col className='mt-1' xs='12'>
								{parameterList}
							</Col>
						</Row>
						<Row>
							<Col xs='12'>{subsubcategories}</Col>
						</Row>
					</React.Fragment>
				);
			} else {
				return (
					<div key={i} className='mr-auto pt-2 mb-1 navbar-wrapper'>
						<NavbarBrand className='w-100 font-weight-bold'>
							{Helper.getLocalizedName(category.ParameterCategoryName)}{' '}
							{Helper.userLoggedIn() && (category.ParameterCategoryPrice !== 0 || category.ShowZeroPrices) ? (
								<span className='float-right mr-2'>
									{category.ParameterCategoryPrice.toLocaleString('de-DE', {
										style: 'currency',
										currency: this.state.currency,
									})}{' '}
								</span>
							) : null}
						</NavbarBrand>
						<UncontrolledCollapse defaultOpen={true} toggler={'#toggler'} navbar>
							<Row>
								<Col className='mt-1' xs='12'>
									{parameterList}
								</Col>
							</Row>
							<Row>
								<Col xs='12'>{subsubcategories}</Col>
							</Row>
							<Row>
								<Col className='mt-3' xs='12'>
									{' '}
								</Col>
							</Row>
						</UncontrolledCollapse>
					</div>
				);
			}
		});
		return categoriesList;
	}
	componentDidMount() {
		this.getOverview(this.props.configuration.documentConfigurationId);
	}
	componentDidUpdate(nextProps: any) {
		if (nextProps.configuration.documentConfigurationId !== this.props.configuration.documentConfigurationId) {
			this.getOverview(this.props.configuration.documentConfigurationId);
		}
	}
	getOverview(id: string) {
		this.setState({ loading: true });
		if (!id) {
			return;
		}
		let url = '/document/configuration/' + id + '/overview';
		Helper.getData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.saving, data.Message);
					this.setState({
						loading: false,
					});
				} else {
					this.setState({
						overview: data.Categories,
						images: data.Images,
						configurationPrice: data.ConfigurationPrice.toLocaleString('de-DE', {
							style: 'currency',
							currency: data.Currency ?? 'EUR',
						}),
						currency: data.Currency ?? 'EUR',
						loading: false,
					});
					this.props.configuration.canEdit = data.CanEdit;
				}
			})
			.catch(() => {
				this.setState({ loading: false });
				(this.props as any).setError(locale.exception, locale.generalException);
			});
	}
	render() {
		const categoriesList = this.renderCategoriesRecursion(this.state.overview, false);

		return (
			<div id='configurator' className='admin'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
							<CustomerEditMenu />
						</Col>
						<Col xl='10' lg='9' className={'mt-3 configurator-background calculation'}>
							<Row>
								<Col className='pr-3 pb-5'>
									<Row className='w-100 d-none'>
										<Col>
											<i className='fa fa-expand float-right' id='toggler'></i>
										</Col>
									</Row>
									<Row form>
										<Col md='6' className='overview admin-form-border mt-3 w-100' style={{ minHeight: 200 }}>
											<Row className='py-1'>
												<Col className='configuration-price'>{locale.adminCalculationConfigurationPrice}</Col>

												<Col className='text-right pr-4 configuration-price' style={{ marginRight: 1 }}>
													{this.state.configurationPrice}
												</Col>
											</Row>
											{categoriesList}
										</Col>
										<Col md='6' className='pt-2'>
											{this.state.images.map((img: any, i: number) => {
												return (
													<img
														id='target'
														key={i}
														style={{
															maxWidth: '100%',
															maxHeight: '50vh',
															padding: '20px',
														}}
														src={img}
														alt=''
													/>
												);
											})}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerCalculationOverview);
