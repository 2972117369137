import React from "react";
import Loading from "react-fullscreen-loading";

const DelayedLoading: React.FC<{loaderColor: any}> = ({ loaderColor }) => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => setShow(true), 2000);
        document.body.style.pointerEvents = "none";

        return () => {
            document.body.style.pointerEvents = "unset";
        }
    }, []);

    return show ? <Loading loading loaderColor={loaderColor} /> : null;
}

export default DelayedLoading;