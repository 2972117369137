import React from "react";
import { Container, Row, Col } from "reactstrap";
import Helper from "../Helper";
import "./Popup.css";

interface IProps {
  parameterId: string;
  selectedValue: any;
  selectedTag: string;
  values: any;
  canEdit: boolean;
  setSelectedParameter(name: string, id: string): void;
  setSelectedParameterTag(id: string, unselected: boolean): void;
}
interface IState {
  style: {
    name: string;
    mdValue: number;
  };
  values: any;
  suggestions: any;
  tags: any;
}

export default class PopupImages extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      style: {
        name: "smallElements",
        mdValue: 4,
      },
      values: [],
      suggestions: [],
      tags: [],
    };
  }
  componentDidMount() {
    const suggestions = this.props.values
      .filter(
        (value: any, i: number) =>value.tags && this.props.values.findIndex((value2: any) => value.tags === value2.tags) === i
      )
      .map((value: any) => ({
        id: value.tags,
        name: value.tags,
        selected: this.props.selectedTag === value.tags,
      }));

    let values: any = [];
    if (this.props.selectedTag !== "") {
      this.props.values.forEach((value: any) => {
        if (this.props.selectedTag === value.tags) {
          values.push(value);
        }
      });
    } else {
      values = [...this.props.values];
    }
    this.setState({
      suggestions: suggestions,
      values: values,
    });
  }
  handleTagSelect(id: string) {
    let values: any = [];
    let unselected = false;
    let selectedSuggestions = this.state.suggestions.map((sug: any) => {
      if (sug.id === id) {
        if (!sug.selected) {
          sug.selected = true;
        } else {
          unselected = true;
          sug.selected = false;
        }
      } else {
        sug.selected = false; //set all unselected
      }
      return sug;
    });
    if (unselected) {
      values = [...this.props.values];
    } else {
      this.props.values.forEach((value: any) => {
        if (id === value.tags) {
          values.push(value);
        }
      });
    }
    this.props.setSelectedParameterTag(id, unselected);
    this.setState({ suggestions: selectedSuggestions, values: values });
  }
  render() {
    const {
      state: { values, style },
    } = this;

    const imageList = values.map((value: any, i: number) => {
      let title = Helper.getLocalizedValueOrNull(value.brandValue) ?? Helper.getLocalizedValueOrNull(value.value) ?? value.valueName;

      return (
        <Col
          xs="6"
          md={style.mdValue}
          key={i}
          title={title}
          className="popup-icon-container"
          onClick={() => this.props.setSelectedParameter(title, value.id)}
        >
          <p>
            {title}
          </p>
          <div
            className={
              "popup-icon " +
              (this.props.selectedValue === title ? "active" : "")
            }
            style={{
              backgroundImage: "url(" + value.imageUrl + ")",
            }}
          ></div>
        </Col>
      );
    });
    const editClass = this.props.canEdit ? "" : "no-pointer-events";
    return (
      <div id="popup">
        <Container
          className={`popup-container ${editClass} ${this.state.style.name}`}
        >
          <Row className="rowContainer">
            {this.state.suggestions && this.state.suggestions.length ? (
              <Col xs="12" id="categoryHeading" className="categoryHeading">
                {this.state.suggestions.map((sug: any, i: number) => {
                  return (
                    <button
                      key={i}
                      type="button"
                      className={
                        "react-tags__selected-tag" +
                        (sug.selected ? " active" : "")
                      }
                      onClick={() => this.handleTagSelect(sug.id)}
                    >
                      <span className="react-tags__selected-tag-name">
                        {sug.name}
                      </span>
                    </button>
                  );
                })}
              </Col>
            ) : null}
            {imageList}
          </Row>
        </Container>
      </div>
    );
  }
}
