import React, { Fragment } from 'react';

function PartImage(props: any) {

    const { alt, imageBase64 } = props;
    let src = imageBase64;

    if (src && src !== null && src !==""){
        return (
            <Fragment>
                <img src={src} alt={alt} className="parameter-image" />
            </Fragment>
        );
    }
    else {
        return null;
    }
}

export default PartImage;