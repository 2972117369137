import React from 'react';
import { Row, Col, Label, Input, FormGroup, NavbarToggler, Collapse } from 'reactstrap';
import './Configurator.css';
import locale from '../Locale';
import Button from './Button';
import Helper, { documentType, projectStatus } from '../Helper';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

interface IProps {
	setUserContactData(documentTypeId: string, projectId: string, newProject: boolean, contactData: any, contactId: string): void;
	project: { id: string; number: string; status: string };
}

interface IState {
	Addressing: { value: string; validate: string };
	FirstName: { value: string; validate: string };
	LastName: { value: string; validate: string };
	Email: { value: string; validate: string };
	Telephone: { value: string; validate: string };
	Address: { value: string; validate: string };
	City: { value: string; validate: string };
	Zip: { value: string; validate: string };
	CountryId: string;
	Countries: any[];
	CompanyName: { value: string; validate: string };
	CompanyRegistration: { value: string; validate: string };
	CompanyVAT: { value: string; validate: string };
	Marketing: boolean;
	Notifications: boolean;
	PersonalData: boolean;
	SaveMessage: string;
	contacts: any[];
	existingUser: boolean;
	newUser: boolean;
	selectedId: string;
	DocumentTypes: any[];
	DocumentTypeId: string;
	ProjectId: string;
	ProjectStatusId: string;
	NewProject: boolean;
}

export default class InquirySales extends React.Component<IProps, IState> {
	private timeout: any;

	constructor(props: IProps) {
		super(props);

		this.timeout = 0;

		this.state = {
			Addressing: { value: '', validate: '' },
			FirstName: { value: '', validate: '' },
			LastName: { value: '', validate: '' },
			Email: { value: '', validate: '' },
			Telephone: { value: '', validate: '' },
			Address: { value: '', validate: '' },
			City: { value: '', validate: '' },
			Zip: { value: '', validate: '' },
			CountryId: '',
			Countries: [],
			CompanyName: { value: '', validate: '' },
			CompanyRegistration: { value: '', validate: '' },
			CompanyVAT: { value: '', validate: '' },
			Marketing: false,
			Notifications: false,
			PersonalData: false,
			SaveMessage: '',
			contacts: [],
			existingUser: false,
			newUser: true,
			selectedId: '',
			DocumentTypeId: documentType.documenttype_offer,
			DocumentTypes: [],
			ProjectId: (this.props.project && this.props.project.id) || '',
			ProjectStatusId: (this.props.project && this.props.project.status) || '',
			NewProject:
				!this.props.project ||
				this.props.project.id === '' ||
				this.props.project.id === '00000000-0000-0000-0000-000000000000' ||
				this.props.project.status === projectStatus.closed ||
				this.props.project.status === projectStatus.sending ||
				this.props.project.status === projectStatus.sent
					? true
					: this.props.project && this.props.project.status === projectStatus.active
					? true
					: false,
		};
	}
	async componentDidMount() {
		await this.getCountries();
		await this.getDocumentTypes();
		const state: any = this.state;
		const url = '/brand/' + Helper.brandPartnerId() + '/contacts';

		Helper.getData<any>(url)
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.saving, data.Message);
				} else if (data && data) {
					state.contacts = data;
				}
				this.setState(state);
			})
			.catch(() => {
				(this.props as any).setError(locale.exception, locale.generalException);
			});
	}

	handleCheckbox(e: any) {
		const state: IState = this.state;
		const { target } = e;
		if (target.name === 'NewProject') {
			state.NewProject = !state.NewProject;
		}
		this.setState(state);
	}

	async getCountries() {
		const data: any = await Helper.getData('/selection/countrylist');
		let countriesFromApi = data
			.map((country: any) => {
				return { value: country.CountryId, display: Helper.getLocalizedName(country.Name) };
			})
			.sort((a: any, b: any) => a.display.localeCompare(b.display));
		this.setState({
			Countries: [
				{
					value: '',
					display: locale.selectCountry,
				},
			].concat(countriesFromApi),
		});
	}

	async getDocumentTypes() {
		const data: any = await Helper.getData('/selection/documenttype/' + Helper.brandPartnerId());
		let typesFromApi = data.map((type: any) => {
			return { value: type.DocumentTypeId, display: Helper.getLocalizedName(type.Name) };
		});
		this.setState({
			DocumentTypes: typesFromApi,
		});
	}

	validateEmail(e: any) {
		let state = this.state;
		const emailRex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let { validate } = state.Email;
		let value = e.target.value;
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (emailRex.test(value)) {
				validate = 'has-success';
			} else {
				validate = 'has-danger';
			}
			state.Email.validate = validate;
			this.setState(state);
		}, 700);
	}

	handleChange(e: any) {
		const state: any = this.state;
		const { target } = e;
		if (target.type === 'checkbox') {
			state[target.name] = target.checked;
		} else {
			state[target.name].value = target.value;
		}
		this.setState(state);
	}

	setUserContactData() {
		const state: IState = this.state;
		let contactFormData: any = null;
		if (state.newUser && state.FirstName.value !== '' && state.LastName.value !== '' && state.Email.value !== '') {
			const ContactData = {
				Addressing: state['Addressing'].value,
				FirstName: state['FirstName'].value,
				LastName: state['LastName'].value,
				Email: state['Email'].value,
				Telephone: state['Telephone'].value,
				Address: state['Address'].value,
				City: state['City'].value,
				Zip: state['Zip'].value,
				CountryId: state['CountryId'],
				CompanyName: state['CompanyName'].value,
				CompanyRegistration: state['CompanyRegistration'].value,
				CompanyVAT: state['CompanyVAT'].value,
				Marketing: state['Marketing'],
				Notifications: state['Notifications'],
				PersonalData: state['PersonalData'],
			};
			contactFormData = {
				ContactData: ContactData,
				DeliveryAddress: false,
				ReceiptAddress: false,
			};
			this.props.setUserContactData(state.DocumentTypeId, state.ProjectId, state.NewProject, contactFormData, '');
		} else if (state.existingUser && state.selectedId !== '') {
			this.props.setUserContactData(state.DocumentTypeId, state.ProjectId, state.NewProject, null, state.selectedId);
		}
	}

	usersToggle(i: number) {
		if (i === 1) {
			this.setState({ newUser: true, existingUser: false });
		} else {
			this.setState({ newUser: false, existingUser: true });
		}
	}

	handleRowSelect(row: any) {
		this.setState({ selectedId: row.ContactId });
	}
	render() {
		const {
			Addressing,
			Email,
			FirstName,
			LastName,
			Address,
			City,
			Zip,
			Telephone,
			CompanyName,
			CompanyRegistration,
			CompanyVAT,
			existingUser,
			newUser,
			contacts,
			selectedId,
			ProjectId,
			ProjectStatusId,
		} = this.state;
		const disabled =
			(FirstName.value === '' ||
				LastName.value === '' ||
				Address.value === '' ||
				City.value === '' ||
				Zip.value === '' ||
				this.state.CountryId === '' ||
				this.state.CountryId === '00000000-0000-0000-0000-000000000000' ||
				Telephone.value === '' ||
				Email.value === '' ||
				Email.validate === 'has-danger') &&
			selectedId === '';
		const options = {
			sizePerPage: 5,
			noDataText: locale.noResults,
		};
		const selectRowProp: any = {
			mode: 'radio', // single row selection
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: [selectedId],
			onSelect: (row: any) => this.handleRowSelect(row),
		};

		const isProjectClosed =
			ProjectStatusId === projectStatus.closed || ProjectStatusId === projectStatus.sending || ProjectStatusId === projectStatus.sent;
		const disabledProject = ProjectId === '' || ProjectId === '00000000-0000-0000-0000-000000000000';

		return (
			<Row>
				<Col xs='12' className='inquiry'>
					<FormGroup>
						<Row style={{ marginBottom: '20px' }}>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='DocumentTypeId'>
									{' '}
									{locale.inquiryDocumentType}
								</Label>
								<select
									className='form-control'
									value={this.state.DocumentTypeId}
									onChange={(e) => this.setState({ DocumentTypeId: e.target.value })}
									style={{ marginBottom: '10px' }}
								>
									{this.state.DocumentTypes.map((type) => (
										<option key={type.value} value={type.value}>
											{type.display}
										</option>
									))}
								</select>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Label check htmlFor='ProjectNumber'>
									{locale.adminDocumentProject}
								</Label>
								<Input
									type='text'
									name='ProjectNumber'
									id='ProjectNumber'
									disabled={true}
									value={this.props.project.number}
									onChange={(e) => {
										this.handleChange(e);
									}}
								/>
							</Col>
							<Col lg='6' md='12' className='half-column'>
								<Input
									type='checkbox'
									name='NewProject'
									id='newproject-checkbox'
									checked={this.state.NewProject}
									disabled={disabledProject || isProjectClosed}
									onChange={(e) => {
										this.handleCheckbox(e);
									}}
								/>

								<p className='checkbox' style={{ padding: '0 20px 0 30px' }}>
									{locale.customerConfirmInquryNewProject}
								</p>
							</Col>
						</Row>
						<Row form>
							<Col xs='12' className='head-column mb-2' onClick={() => this.usersToggle(1)} style={{ cursor: 'pointer' }}>
								<span className='toggler-title'>{locale.inquiryTitleCustomer}</span>
								<NavbarToggler />
							</Col>
							<Col xs='12' className='pb-4'>
								<Collapse isOpen={newUser}>
									<Row>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='Addressing'>
												{locale.addressing}
											</Label>
											<Input
												type='text'
												name='Addressing'
												id='Addressing'
												value={Addressing.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'></Col>

										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='FirstName' className='required'>
												{locale.firstName}
											</Label>
											<Input
												type='text'
												name='FirstName'
												id='FirstName'
												value={FirstName.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='LastName' className='required'>
												{locale.lastName}
											</Label>
											<Input
												type='text'
												name='LastName'
												id='LastName'
												value={LastName.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='Address' className='required'>
												{locale.address}
											</Label>
											<Input
												type='text'
												name='Address'
												id='Address'
												value={Address.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='City' className='required'>
												{locale.city}
											</Label>
											<Input
												type='text'
												name='City'
												id='City'
												value={City.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='Zip' className='required'>
												{locale.post}
											</Label>
											<Input
												type='text'
												name='Zip'
												id='Zip'
												value={Zip.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='Country' className='required'>
												{locale.country}
											</Label>
											<select className='form-control' value={this.state.CountryId} onChange={(e) => this.setState({ CountryId: e.target.value })}>
												{this.state.Countries.map((Country) => (
													<option key={Country.value} value={Country.value}>
														{Country.display}
													</option>
												))}
											</select>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='Email' className='required'>
												{locale.email}
											</Label>
											<Input
												type='email'
												name='Email'
												id='Email'
												value={Email.value}
												valid={Email.validate === 'has-success'}
												invalid={Email.validate === 'has-danger'}
												onChange={(e) => {
													this.validateEmail(e);
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='Telephone' className='required'>
												{locale.telephone}
											</Label>
											<Input
												type='text'
												name='Telephone'
												id='Telephone'
												value={Telephone.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col xs='12' className='full-column'>
											<Row>
												<Col xs='12'>
													<h6>{locale.inquiryLegalAdd}</h6>
												</Col>
											</Row>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='CompanyName'>
												{locale.companyName}
											</Label>
											<Input
												type='text'
												name='CompanyName'
												id='CompanyName'
												value={CompanyName.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='CompanyRegistration'>
												{locale.companyRegistration}
											</Label>
											<Input
												type='text'
												name='CompanyRegistration'
												id='CompanyRegistration'
												value={CompanyRegistration.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
										<Col lg='6' md='12' className='half-column'>
											<Label check htmlFor='CompanyVAT'>
												{locale.companyVAT}
											</Label>
											<Input
												type='text'
												name='CompanyVAT'
												id='CompanyVAT'
												value={CompanyVAT.value}
												onChange={(e) => {
													this.handleChange(e);
												}}
											/>
										</Col>
									</Row>
								</Collapse>
							</Col>
							<Col xs='12' className='head-column mb-3' onClick={() => this.usersToggle(2)} style={{ cursor: 'pointer' }}>
								<span className='toggler-title'>{locale.inquiryTitleExistingCustomer}</span>
								<NavbarToggler />
							</Col>
							<Col xs='12' className='customer-table'>
								<Collapse isOpen={existingUser}>
									<BootstrapTable
										data={contacts}
										tableStyle={{
											backgroundColor: 'white',
										}}
										options={options}
										striped
										hover
										bordered={false}
										pagination
										keyField='ContactId'
										selectRow={selectRowProp}
									>
										<TableHeaderColumn dataField='ContactId' dataSort hidden>
											ID
										</TableHeaderColumn>

										<TableHeaderColumn
											columnTitle
											dataField='Name'
											width='25%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.firstAndLastName}
										</TableHeaderColumn>
										<TableHeaderColumn
											columnTitle
											dataField='Title'
											width='25%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.name}
										</TableHeaderColumn>
										<TableHeaderColumn
											columnTitle
											dataField='Partner'
											width='50%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.partner}
										</TableHeaderColumn>
										<TableHeaderColumn
											columnTitle
											dataField='User'
											width='50%'
											dataSort
											filter={{
												type: 'TextFilter',
												delay: 500,
												placeholder: 'Filter',
											}}
										>
											{locale.user}
										</TableHeaderColumn>
									</BootstrapTable>
								</Collapse>
							</Col>
						</Row>
					</FormGroup>
					<Button color='secondary' className='my-2' disabled={disabled} onClick={() => this.setUserContactData()}>
						{locale.save}
					</Button>
				</Col>
			</Row>
		);
	}
}
