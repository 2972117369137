import React, { Fragment } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';

import CustomerEditMenu from './CustomerEditMenu';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Button from '../Button';
import Helper, { documentSatus } from '../../Helper';
import NumericInput from 'react-numeric-input';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	configuration?: any;
	document?: any;
	theme?: any;
	part?: any;
}
interface IState {
	selectedId: string;
	selectedDocConfigId: string;
	selectedDocPosition: string;
	selectedStatus: string;
	selectedPartId: string;
	configurations: any;
	sentConfirmation: boolean;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	action: string;
	saving: boolean;
	changedNumericInput: string;
	newQuantity: string;
	preventDoubleClick: boolean;
	loading: boolean;
}
class CustomerConfigurations extends React.Component<IProps, IState> {
	private timeout: any;

	constructor(props: IProps) {
		super(props);

		this.timeout = 0;

		this.state = {
			selectedId: '',
			selectedDocConfigId: '',
			selectedDocPosition: '',
			selectedStatus: '',
			selectedPartId: '',
			configurations: [],
			sentConfirmation: false,
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			action: '',
			saving: false,
			changedNumericInput: '',
			newQuantity: '',
			preventDoubleClick: false,
			loading: false,
		};
	}
	componentDidMount() {
		this.configurationData(true);
	}

	setConfigurations(configData: any, currency: any) {
		let configurations: any = [];
		let selectedId = '';
		let selectedDocConfigId = '';
		let selectedDocPosition = '';
		let selectedStatus = '';
		let selectedPartId = '';
		if (configData && configData.length > 0) {
			for (let i = 0; i < configData.length; i++) {
				let item = configData[i];
				configurations.push({
					id: item.ConfigurationId,
					documentConfigurationId: item.SalesDocumentConfigurationId,
					position: item.ConfigurationPosition,
					configurationName: Helper.userIsParentPartner()
						? (Helper.getLocalizedName(item.ConfigurationBrandName) ?? '') + '</br> (' + Helper.getLocalizedName(item.ConfigurationName) + ')'
						: Helper.getLocalizedName(item.ConfigurationBrandName),
					image: item.Image,
					dateChanged: Helper.dateWithTimeConvert(item.ConfigurationDateModified),
					dateChangedDate: item.ConfigurationDateModified,
					value: item.TotalPrice.toLocaleString('de-DE', {
						style: 'currency',
						currency: currency,
					}),
					configurationPrice: item.ConfigurationPrice.toLocaleString('de-DE', {
						style: 'currency',
						currency: currency,
					}),
					quantity: item.Quantity,
					status: Helper.getLocalizedName(item.DocumentStatus),
					statusId: item.DocumentStatusId,
					partId: item.PartId,
					clonePart: item.ClonePart,
					deliveryDate: Helper.dateConvert(item.DeliveryDate),
					customerPosition: item.CustomerPosition,
				});
				if (this.props.configuration && item.ConfigurationId === this.props.configuration.configurationId) {
					selectedId = item.ConfigurationId;
					selectedDocConfigId = item.SalesDocumentConfigurationId;
					selectedDocPosition = item.ConfigurationPosition;
					selectedStatus = item.DocumentStatusId;
					selectedPartId = item.PartId;
				}
			}
		}
		this.setState({
			configurations: configurations,
			selectedId: selectedId,
			selectedDocConfigId: selectedDocConfigId,
			selectedDocPosition: selectedDocPosition,
			selectedStatus: selectedStatus,
			selectedPartId: selectedPartId,
			loading: false,
		});
	}
	configurationData(refresh: boolean) {
		if (!this.props.document) {
			return;
		}
		if (refresh || !this.props.document.documentConfigurations) {
			this.setState({ loading: true });
			const url = '/document/' + this.props.document.documentId;
			Helper.getData<any>(url).then((data: any) => {
				if (data) {
					const configData = data.Configurations || [];
					this.setConfigurations(configData, data.Currency ?? 'EUR');
				}
			});
		} else {
			const configData = this.props.document.documentConfigurations;
			this.setConfigurations(configData, this.props.document.currency ?? 'EUR');
		}
	}
	handleSelectAndRedirect(url: string) {
		(this.props as any).clearPart();
		(this.props as any).buildCategories(this.state.selectedId, null, this.state.selectedDocConfigId);
		setTimeout(() => {
			this.props.history.push(process.env.REACT_APP_ROOT_PATH + url);
		}, 500);
	}
	handleRowSelect(row: any) {
		this.setState({
			selectedId: row.id,
			selectedDocConfigId: row.documentConfigurationId,
			selectedDocPosition: row.position,
			sentConfirmation: false,
			selectedStatus: row.statusId,
			selectedPartId: row.partId,
		});
	}
	onDoubleClick(row: any) {
		(this.props as any).clearPart();
		(this.props as any).saveConfiguration(row.id, row.documentConfigurationId, row.position);
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/configuration/calculation');
	}
	edit(selectedStatus: any) {
		if (selectedStatus === documentSatus.documentstatus_specialtechnicaldocumentationgenerated) {
			this.toggleConfirmationModal(locale.change, locale.confirmChangeStatusSpecialTechnical, 'edit');
		} else {
			this.handleSelectAndRedirect('/configurator/configuration');
		}
	}
	open() {
		(this.props as any).clearPart();
		(this.props as any).saveConfiguration(this.state.selectedId, this.state.selectedDocConfigId, this.state.selectedDocPosition);
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/admin/configuration/calculation');
	}
	copy() {
		this.setState({ saving: true, action: 'copy' });
		const urlConfiguration = '/document/configuration/' + this.state.selectedDocConfigId + '/copy/?brandPartnerId=' + Helper.brandPartnerId();

		this.setState({ selectedId: '', selectedDocConfigId: '' });
		Helper.postData<any>(urlConfiguration, '')
			.then((data) => {
				if (data.Message && data.Message.length > 0) {
					(this.props as any).setError(locale.copy, data.Message);
					this.setState({ saving: false, action: '' });
				} else {
					this.setState({ saving: false, action: '' }, () => this.configurationData(true));
				}
			})
			.catch(() => (this.props as any).setError(locale.exception, locale.generalException));
	}
	add() {
		(this.props as any).clearPart();
		this.props.history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/selection');
	}
	delete() {
		const url = '/document/configuration/' + this.state.selectedDocConfigId + '?brandPartnerId=' + Helper.brandPartnerId();
		this.setState({ selectedId: '', selectedDocConfigId: '' });
		Helper.deleteData<any>(url)
			.then((saveData) => {
				if (saveData.Message && saveData.Message.length > 0) {
					(this.props as any).setError(locale.deleting, saveData.Message);
					this.setState({ saving: false, confirmationModal: false });
				} else {
					(this.props as any).clearPart();
					this.setState({ saving: false, confirmationModal: false }, () => this.configurationData(true));
				}
			})
			.catch(() => (this.props as any).setError(locale.deleting, locale.generalException));
	}
	toggleConfirmationModal(title?: string, message?: string, action?: string) {
		const state: IState = this.state;
		state.sentConfirmation = false;
		state.confirmationModal = !state.confirmationModal;
		if (title && message && action) {
			state.confirmationModalTitle = title;
			state.confirmationModalMessage = message;
			state.action = action;
		}

		this.setState(state);
	}
	confirmationAction(action: string) {
		if (action === 'edit') {
			const state: IState = this.state;
			state.confirmationModal = !state.confirmationModal;
			this.setState(state);
			this.handleSelectAndRedirect('/configurator/configuration');
		} else {
			this.setState({ saving: true }, () => this.delete());
		}
	}
	validateText(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		let { target } = e;
		let { validate } = state[target.name];
		let value = e.target.value;
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (value.length > 0) {
				validate = 'has-success';
			} else {
				validate = 'has-danger';
			}
			state[target.name].validate = validate;
			this.setState(state);
		}, 700);
	}
	handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const state: any = this.state;
		const { target } = e;
		const value = target.value;
		state[target.name] = value;
		state.saved = false;
		state.sentConfirmation = false;
		this.setState(state);
	}
	cancelSetQuantity() {
		this.setState({
			changedNumericInput: '',
			newQuantity: '',
			preventDoubleClick: false,
		});
	}
	setQuantity() {
		if (this.state.newQuantity === '' || this.state.changedNumericInput === '') {
			return;
		}
		const url = '/document/configuration/' + this.state.changedNumericInput + '/changeQuantity';

		const body = {
			BrandPartnerId: Helper.brandPartnerId(),
			Quantity: this.state.newQuantity,
		};
		Helper.putData<any>(url, JSON.stringify(body))
			.then((saveData) => {
				if (saveData.Message && saveData.Message.length > 0) {
					(this.props as any).setError(locale.deleting, saveData.Message);
				} else {
					(this.props as any).clearPart();
				}
				this.setState({
					changedNumericInput: '',
					newQuantity: '',
					preventDoubleClick: false,
				});
				this.configurationData(true);
			})
			.catch(() => (this.props as any).setError(locale.deleting, locale.generalException));
	}
	render() {
		const configurations = this.state.configurations;
		const { selectedId, selectedStatus, confirmationModal, saving, confirmationModalMessage, confirmationModalTitle, action, loading } = this.state;

		const disableEditDocument = this.props.document && this.props.document.disableEdit;
		const buttonsDisabled = !selectedId || selectedId.length === 0 || this.props.configuration.loading;
		const buttonsDisabledTech = !selectedStatus || selectedStatus === documentSatus.documentstatus_generatingtechdocumentation;

		const selectRowProp: any = {
			mode: 'radio', // single row selection
			clickToSelect: true,
			hideSelectColumn: true,
			bgColor: Helper.themeBottonColorWithShadowRGBA(),
			selected: [selectedId],
			onSelect: (row: any) => this.handleRowSelect(row),
		};
		const options = {
			onRowDoubleClick: (row: any) => this.onDoubleClick(row),
			noDataText: locale.noResults,
		};
		const imgFormatter = (cell: any, row: any) => {
			if (cell && cell !== null) {
				return `<img style='width:65px' src='${cell}' />`;
			} else {
				return '';
			}
		};
		const nameFormatter = (cell: any, row: any) => {
			if (cell && cell !== null) {
				return `<span>${cell} <span/>`;
			} else {
				return '';
			}
		};
		const idCopy = (cell: any, row: any) => {
			if (cell && cell !== null) {
				return (
					<Fragment>
						<i
							color='secondary'
							className='fa fa-clipboard'
							onClick={() => {
								navigator.clipboard.writeText(`${cell}`);
								alert(locale.clipboard + ' - ConfigurationID: ' + cell);
							}}
						></i>
					</Fragment>
				);
			} else {
				return '';
			}
		};
		const qtyFormatter = (cell: any, row: any) => {
			if (cell && cell !== null) {
				const disabled =
					this.state.changedNumericInput !== row.documentConfigurationId ||
					this.state.newQuantity === '0' ||
					!this.state.newQuantity ||
					disableEditDocument;
				const disabledCancel = this.state.changedNumericInput !== row.documentConfigurationId;
				return (
					<Fragment>
						<NumericInput
							disabled={disableEditDocument}
							value={this.state.changedNumericInput === row.documentConfigurationId ? this.state.newQuantity : cell}
							min={1}
							max={100}
							onChange={(value: any) =>
								this.setState({
									changedNumericInput: row.documentConfigurationId,
									newQuantity: value,
									preventDoubleClick: true,
								})
							}
						/>
						<br />
						<Button className={disabled ? 'd-none' : 'mt-2 mr-1 small-button'} onClick={() => this.setQuantity()}>
							{locale.save}
						</Button>
						<Button className={disabledCancel ? 'd-none' : 'mt-2 small-button'} onClick={() => this.cancelSetQuantity()}>
							{locale.cancel}
						</Button>
					</Fragment>
				);
			} else {
				return '';
			}
		};
		const dateSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (new Date(a.dateChangedDate) < new Date(b.dateChangedDate)) return 1;
				if (new Date(a.dateChangedDate) > new Date(b.dateChangedDate)) return -1;
			} else if (order === 'desc') {
				if (new Date(a.dateChangedDate) > new Date(b.dateChangedDate)) return 1;
				if (new Date(a.dateChangedDate) < new Date(b.dateChangedDate)) return -1;
			}
			return 0;
		};
		let loadingDiv = null;
		if (loading || saving) {
			loadingDiv = <Loading loading loaderColor={Helper.themeButtonColor()} />;
		}

		return (
			<div id='configurator' className='admin'>
				<Row>
					<Col xl='2' lg='3' className='no-gutters mt-3 pr-3'>
						<CustomerEditMenu />
					</Col>
					<Col xl='10' lg='9' className='mt-3 configurator-background'>
						{loadingDiv ? (
							loadingDiv
						) : (
							<Row>
								<Col xs='12' className='mt-3'>
									<BootstrapTable
										data={configurations}
										selectRow={selectRowProp}
										options={options}
										striped
										hover
										pagination
										bordered={false}
										keyField='id'
										containerClass='customer-configurations'
										tableStyle={{
											backgroundColor: 'white',
										}}
									>
										<TableHeaderColumn dataField='id' hidden>
											ID
										</TableHeaderColumn>

										<TableHeaderColumn dataField='documentConfigurationId' hidden>
											ID
										</TableHeaderColumn>

										<TableHeaderColumn dataField='position' width='50px' dataSort dataAlign='left' headerAlign='left'>
											{locale.position}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='customerPosition' columnClassName='no-elipsis' width='100px' dataAlign='right' headerAlign='right'>
											{locale.adminDocumentCustomerPosition}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='configurationName' dataFormat={nameFormatter} width='250px' dataSort columnClassName='no-elipsis'>
											{locale.name}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='clonePart' width='80px' dataSort>
											{locale.clonePart}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='quantity' width='180' dataSort dataAlign='center' headerAlign='center' dataFormat={qtyFormatter}>
											{locale.quantity}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='deliveryDate' width='100px' dataSort dataAlign='right' headerAlign='right'>
											{locale.deliveryTime}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='configurationPrice' width='100px' dataSort dataAlign='right' headerAlign='right'>
											{locale.adminCalculationConfigurationPrice}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='value' width='100px' dataSort dataAlign='right' headerAlign='right'>
											{locale.totalPriceWithoutTax}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='image' width='100px' dataFormat={imgFormatter} dataSort dataAlign='right' headerAlign='right'>
											{locale.overview}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='dateChanged' width='140px' dataSort sortFunc={dateSort} dataAlign='right' headerAlign='right'>
											{locale.date}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='status' width='180px' dataSort>
											{locale.status}
										</TableHeaderColumn>

										<TableHeaderColumn dataField='id' width='40px' dataFormat={idCopy}>
											Id
										</TableHeaderColumn>
									</BootstrapTable>
									<Col>
										<Row>
											<Col className='admin-form-border' style={{ marginTop: '-10px' }}>
												{Helper.userAdmin() ? (
													<Fragment>
														<Button color='secondary' className='button mr-2 my-1' disabled={buttonsDisabled} onClick={() => this.open()}>
															{locale.details}
														</Button>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															disabled={buttonsDisabled || disableEditDocument || buttonsDisabledTech}
															onClick={() => this.edit(selectedStatus)}
														>
															{locale.change}
														</Button>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															disabled={buttonsDisabled || disableEditDocument || buttonsDisabledTech}
															onClick={() => this.copy()}
															saving={saving && action === 'copy'}
														>
															{locale.copy}
														</Button>
														<Button color='secondary' className='button mr-2 my-1' disabled={disableEditDocument} onClick={() => this.add()}>
															{locale.add}
														</Button>
														<Button
															color='secondary'
															className='button mr-2 my-1'
															disabled={buttonsDisabled || disableEditDocument || buttonsDisabledTech}
															onClick={() => this.toggleConfirmationModal(locale.delete, locale.confirmDelete, 'delete')}
														>
															{locale.delete}
														</Button>
													</Fragment>
												) : null}
												{Helper.userInstaller() ? (
													<Fragment>
														<Button color='secondary' className='button mr-2 my-1' disabled={buttonsDisabled} onClick={() => this.open()}>
															{locale.open}
														</Button>
													</Fragment>
												) : null}
											</Col>
										</Row>
									</Col>
								</Col>
							</Row>
						)}
					</Col>
				</Row>

				<Modal size='lg' isOpen={confirmationModal} toggle={() => this.toggleConfirmationModal()}>
					<ModalHeader toggle={() => this.toggleConfirmationModal()}>{confirmationModalTitle}</ModalHeader>
					<ModalBody>
						{confirmationModalMessage}
						<br />
						<br />
						<Button className={'button btn-secondary my-2'} onClick={() => this.confirmationAction(action)} saving={saving}>
							{locale.confirm}
						</Button>
						<Button className={'button btn-secondary my-2 ml-3'} onClick={() => this.toggleConfirmationModal()}>
							{locale.cancel}
						</Button>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(CustomerConfigurations);
